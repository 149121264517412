//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InfoMainBlock from "./InfoMainBlock.vue"
import IconImage from "../IconImage.vue"
import ListItem from "../custom/ListItem.vue"
import { mapGetters } from "vuex"
import { CHANNEL } from "../../store/modulesNames"
import { ACT_CHANNEL_SEARCH } from "../../store/actionsTypes"
import { GET_CHANNEL_SEARCH_STRING } from "../../store/gettersTypes"
import ScrollToMessageMixin from "../chat/ScrollToMessageMixin"
import checkMessageDataExist from "../chat/checkMessageDataExist"
import ContentLoader from "../main/body/MainContentLoader.vue"
import moment from "moment"

const defaultChannelsCount = 25
const excerptLength = 30

const checkIsScrollVisible = () => {
    let isVisible = false
    return new Promise((resolve) => {
        setTimeout(() => {
            const elem = document.getElementById("channel-search-area")
            if (elem) {
                isVisible = elem.scrollHeight > elem.clientHeight
                resolve(isVisible)
            } else resolve(false)
        }, 100)
    })
}

export default {
    name: "SearchInChannel",
    components: {
        IconImage,
        ListItem,
        InfoMainBlock,
        ContentLoader
    },
    props: ["params", "canBack"],
    mixins: [ScrollToMessageMixin, checkMessageDataExist],
    mounted() {
        this.$nextTick(() => {
            const input = document
                .getElementsByClassName("search-input-wrapper show")[0]
                .getElementsByTagName("input")[0]
            input && input.focus()
        })
    },
    data() {
        return {
            pubs: [],
            foundPubs: [],
            searchString: "",
            chunkStartId: 0,
            firstSearch: true,
            searchCountMult: 1,
            noRecords: this.$t("media-search.pubs-not-found"),
            isLookingFor: false,
            itemSize: 71,
            isSearchMore: true,
            isScrollVisible: false,
            isNewSearch: false,
            mediaTypes: { 
                image: this.$t("media-search.image"),
                key: this.$t("media-search.video"),
                audio: this.$t("media-search.audio"),
                file: this.$t("file"),
                link: this.$t("media-search.link") 
            },
        }
    },
    computed: {
        bufferSize() {
            return this.itemSize * 10
        },
        scrollerStyle() {
            return this.foundPubs.length ? "height: 100%;" : ""
        },
        isLoader() {
            return (
                this.firstSearch && this.searchString && !this.foundPubs.length
            )
        },
        getSearchString() {
            return this[GET_CHANNEL_SEARCH_STRING]
        },
        isNoResults() {
            return (
                !this.firstSearch &&
                !this.isLookingFor &&
                !this.foundPubs.length &&
                this.searchString.length >= 2
            )
        },
        showSearchMore() {
            return this.isScrollVisible && this.isSearchMore
        },
        ...mapGetters(CHANNEL, [GET_CHANNEL_SEARCH_STRING]),
    },
    methods: {
        getPhoto(cid) {
            return this.$store.getters["contacts/getMergedContactById"](cid).photo || ""
        },
        getFio(cid) {
            return this.$store.getters["contacts/getMergedContactById"](cid).fio || ""
        },
        channel(chId) { 
            return this.$store.getters['channels/getChannel']({ chId }) 
        },
        publication(pubId) { 
            return this.$store.getters['channel/getPublicationById']({ pubId }) 
        },
        getMessageItem(message) {
            const ch = this.channel(message.chId)
            const pub = this.publication(message.pubId)
            const cid = pub && pub.cid
            const fio = cid ? this.getFio(cid) : ch.name
            const photo = cid ? this.getPhoto(cid) : ch.icon && app.getChannelPhotoUrl(ch.icon) || ''
            return {
                id: message.pubId,
                chId: message.chId,
                pubId: message.pubId,
                fio,
                photo,
                title: message.title,
                info: message.info,
                date: this.formatTime(message.time),
                time: date_helper.secondsLeftToTimeFormat(message.time),
                text: this.getItemText(message),
            }
        },
        getItemText(message) {
            let title = message.hasOwnProperty("title") ? message.title : '' 
            let info = message.hasOwnProperty("info") ? message.info : ''
            let text = title + " " + info
            const firstPartFound = text.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1
            let dataText = ''
            message.data.forEach(data => {
                if (data.type === declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT) {
                    if (!data.hasOwnProperty("fields") && data.hasOwnProperty("cid")) {
                        let fio = this.getFio(data.cid)
                        data.fio = fio
                    }
                }
                dataText += getDataText(data, this.searchString, this.mediaTypes)
            })
            if (dataText.length) {
                if (firstPartFound) text += '\n' + dataText
                else text = dataText
            }
            return text
        },
        formatTime(timeCall) {
            let result
            let timeCallFormat = moment().subtract(timeCall, "seconds")
            let today = moment().startOf("day")
            let yesterday = moment()
                .subtract(1, "days")
                .startOf("day")
            let twoDays = moment()
                .subtract(2, "days")
                .startOf("day")
            if (timeCallFormat.isAfter(today)) {
                result = moment()
                    .subtract(timeCall, "seconds")
                    .format("HH:mm")
            } else if (
                timeCallFormat.isBefore(today) &&
                timeCallFormat.isAfter(yesterday)
            ) {
                result = this.$t("channel-comp.yesterday")
            } else if (
                timeCallFormat.isBefore(yesterday) &&
                timeCallFormat.isAfter(twoDays)
            ) {
                result = this.$t("channel-comp.before-yest")
            } else {
                result = moment()
                    .subtract(timeCall, "seconds")
                    .format("DD MMM YYYY")
            }
            return result
        },
        async addPubs(isAfterNotFound = false) {
            this.isLookingFor = true
            let oldestMessage = this.pubs.pop()
            this.chunkStartId = oldestMessage ? oldestMessage.pubId : 0
            if (isAfterNotFound && !this.isScrollVisible) {
                this.searchCountMult++
                if (this.searchCountMult > 4) {
                    this.searchCountMult = 0
                    this.isSearchMore = false
                }
            }

            let params = {
                chId: this.params.chId,
                filter: this.searchString,
                fromPubId: this.chunkStartId,
                count: this.searchCountMult * this.searchCountMult * defaultChannelsCount,
            }
            const pubsChunk = await this.$store.dispatch(
                `${CHANNEL}/${ACT_CHANNEL_SEARCH}`,
                params
            )
            //console.log("!! -> file: SearchInChannel.vue -> line 218 -> addPubs -> pubsChunk", pubsChunk)

            let concatArr = []
            if (pubsChunk.length) { 
                concatArr = this.pubs.concat(pubsChunk)
                oldestMessage = concatArr[concatArr.length - 1]
            }
            if (oldestMessage && this.chunkStartId === oldestMessage.pubId) {
                if (concatArr.length) this.pubs = concatArr  
                this.isSearchMore = false
                this.isLookingFor = false
                return
            } else {
                if (!pubsChunk.length) {
                    if (!this.searchCountMult) await this.addPubs(true)
                }
                this.chunkStartId = oldestMessage ? oldestMessage.pubId : 0
                if (concatArr.length) this.pubs = concatArr  
                //console.log("!! -> file: SearchInChannel.vue -> line 234 -> addPubs -> concatArr", concatArr)
                await this.searchChannel()
            }
            this.isLookingFor = false
        },
        async searchChannel(val = this.searchString) {
            this.isLookingFor = true
            this.foundPubs = []
            if (!this.pubs.length || this.isNewSearch) {
                let params = {
                    chId: this.params.chId,
                    filter: val,
                    fromPubId: 0,
                    count: defaultChannelsCount,
                }
                this.pubs = await this.$store.dispatch(
                    `${CHANNEL}/${ACT_CHANNEL_SEARCH}`,
                    params
                )
            }
            this.pubs.forEach(chMsg => {
                let msgItem = this.getMessageItem(chMsg)
                let index = -1, msg, excerptDelta = 7
                if (msgItem) {
                    msg = msgItem.text
                    if (typeof msg === "string")
                        index = msg.toLowerCase().indexOf(val.toLowerCase())
                }
                if (val.length >= 2 && index > -1) {
                    const isStart = (index - excerptDelta <= 0) 
                    const newIndex = isStart ? 0 : index - excerptDelta
                    let excerptStart = isStart ? msg.slice(0, index) : "..." + msg.slice(newIndex - 1, index)
                    const foundInFile = Object.values(this.mediaTypes).some(v => msg.slice(0, index - 1).indexOf(v) > -1)
                    if (foundInFile) excerptStart = msg.slice(0, index)
                    let excerpt = excerptStart + msg.slice(index, index + excerptLength) //excerptStart + excerptEnd
                    let highlightString = msg.slice(index, index + val.length)
                    msgItem.excerpt = excerpt
                    msgItem.highlights = highlightString
                    this.foundPubs.push(msgItem)
                }
            })
            this.firstSearch = false
            if (!this.foundPubs.length && this.isSearchMore) {
                await this.addPubs(true)
                return
            }
            this.isScrollVisible = await checkIsScrollVisible()
            if (this.foundPubs.length && this.isSearchMore && !this.isScrollVisible) {
                await this.addPubs()
            } 
            this.isNewSearch = false
            this.isLookingFor = false
        },
        showPublication(msg) {
            this.checkPublicationExist(msg.chId, msg.pubId)
        },
        scrollToElement(item) {
            const { chId, pubId } = item
            this.checkPublicationExist(chId, pubId)
        },
        forwardMessage(message) {
            message = JSON.parse(JSON.stringify(message))
            let data = message.msg
            let entities = message.entities
            let dataType, author = message.author_tmp ? message.author : message.senderId
            if (message.type === 'text') {
                dataType = declarations.msgDataTypes.MSG_DATA_TYPE_TEXT
            } else {
                if (message.sub_type === 'text') {
                    data = {
                        type: message.sub_type,
                        text: data,
                    }
                    if (!entities) entities = this.extractInputTextFormat(data.text).entities || []
                    data.entities = entities
                } else {
                    data.type = message.sub_type
                }
                if (data.file) {
                    data.file = data.file.split('/').pop();
                }
                if (message.sub_type === 'location') {
                    let { type, latitude, longitude } = data
                    data = { type, latitude, longitude }
                }
                if (message.sub_type === 'contact') {
                    data.fields = message.fields
                }

                data = JSON.stringify(data)
                dataType = declarations.msgDataTypes.MSG_DATA_TYPE_DATA
            }
            this.modalOpen({
                component: SelectChatToForward,
                props: {
                    msg: { dataType, data, author },
                }
            })
        },
        removeMsg(message) {
            this.modalOpen({
                component: DelChatMessage,
                props: {
                    msg: message,
                    btnOk: {
                        cb: () => { 
                            this.isTabSwitched = true
                            this.searchMessages()
                        }
                    }
                }
            })
        },
        onContextMenu(e, message) {
            let handlers = []
            if (message.chId && message.pubId) {
                handlers.push({                                                                         
                    item_name: this.$t('media-search.goto-publication'),
                    handler: this.showPublication,
                    data: message
                })
            }
            // handlers.push({
            //     item_name: this.$t('media-search.forward'),
            //     handler: this.forwardMessage,
            //     data: message
            // })
            // if (message.id) {
            //     handlers.push({
            //         item_name: this.$t('media-search.delete'),
            //         handler: this.removeMsg,
            //         data: message
            //     })
            // }
            this.cmOpen(e, handlers, "right-bottom")
        },
    },
    watch: {
        getSearchString(newVal, oldVal) {
            // if (!newVal) newVal = ''
            // if (!oldVal) oldVal = ''
            // const lcNewVal = newVal.toLowerCase(), lcOldVal = oldVal.toLowerCase()
            // if (!newVal || newVal.length <= 2) this.isNewSearch = false
            // else if (newVal.length >= oldVal.length && lcNewVal.indexOf(lcOldVal) === 0)
            //     this.isNewSearch = false
            // else this.isNewSearch = true
            this.isNewSearch = true
            this.searchString = newVal
        },
        searchString(val) {
            if (val && val.length >= 2) {
                if (!this.isLookingFor) this.searchChannel(val)
            } 
        }
    }
}

function getDataText(data, searchString, mediaTypes) {
    let text = ''
    let fileName = data && data.hasOwnProperty("name") ? data.name + '.' + data.extension + ' ' : data.extension || ''
    const foundFile = fileName.toLowerCase().indexOf(searchString.toLowerCase()) > -1
    switch (data.type) {
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT:
            const fields = Array.isArray(data.fields) && data.fields
            const phones = fields && fields.filter(f => f.type === "phone" || 
                                                        f.type === "workphone")
            const phone = phones && phones.length && phones[0].value
            const mails = fields && fields.filter(f => f.type === "mail")
            const mail = mails && mails.length && mails[0].value
            const fio = (fields && fields.fio) || data.fio || phone || mail
            text = fio
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_LOCATION:
            text = data.longitude + " " + data.latitude
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE:
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO:
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_AUDIO:
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_FILE:
            if (foundFile) text = `${mediaTypes[data.type]}: ` + fileName + '\n'
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_POLL:
            text = data.topic
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_TEXT:
            text = data.text
            break
        default:
            text = ""
    }
    return text
}
