var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "radio-btns" }, [
    _vm.groupSelect
      ? _c(
          "div",
          {
            staticClass: "radio-btn group-select circle-animation",
            attrs: { title: _vm.$t("select-group") },
            on: {
              click: function ($event) {
                return _vm.$emit("groupSelect")
              },
            },
          },
          [_c("i", { staticClass: "fa fa-users" })]
        )
      : _vm._e(),
    _vm.repeatLast
      ? _c(
          "div",
          {
            staticClass: "radio-btn echo-btn circle-animation",
            attrs: { title: _vm.$t("listen-again") },
            on: {
              click: function ($event) {
                return _vm.$emit("repeatLast")
              },
            },
          },
          [_c("i", { staticClass: "far fa-redo fa-rotate-145" })]
        )
      : _vm._e(),
    _vm.info
      ? _c(
          "div",
          {
            staticClass: "radio-btn more circle-animation",
            attrs: { title: _vm.$t("info") },
            on: {
              click: function ($event) {
                return _vm.$emit("info", $event)
              },
            },
          },
          [_c("i", { staticClass: "fa fa-info" })]
        )
      : _vm._e(),
    _vm.settings
      ? _c(
          "div",
          {
            staticClass: "radio-btn more circle-animation",
            attrs: { title: _vm.$t("settings-text") },
            on: {
              click: function ($event) {
                return _vm.$emit("settings", $event)
              },
            },
          },
          [_c("i", { staticClass: "fa fa-cog" })]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }