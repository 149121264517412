//
//
//
//
//
//
//


export default {
    name: 'bot-commands',
    props: ['input', 'bot'],
    data() {
        return {}
    },
    created() {
        addEventListener("keyup", event => {
            if (this.bot && event.keyCode === 27) this.close();
        });
    },
    computed: {
        commands() {
            return this.bot && this.bot.commands.filter(item => {
                if(item.command.toLowerCase().indexOf(this.input) > -1) return item;
            });
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        sendCommand(command) {
            this.$emit('send', command);
        }
    }
}
