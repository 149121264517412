//
//
//
//
//
//
//
//
//

import { i18n } from '../../../ext/i18n';
import ActionsContainer from "./ActionsContainer.vue";
import ListItem from "../custom/ListItem.vue";
import InfoIcon from "./InfoIcon.vue";
import SelectContactInfoExport from "./SelectContactInfoExport.vue";
import {DLP_SOURCE_TYPES} from '../../store/modules/dlp'

export default {
    name: "ContactDetailedInfo",
    props: ['params'],
    components: { ActionsContainer, ListItem, InfoIcon, SelectContactInfoExport },
    data: function() {
        let info = i18n.messages[i18n.locale].information
        return {
            exportBtnText: 'Export',
            exportBtnClass: 'fal fa-address-card',
            detailed_props: [
                { prop_name: 'surname', description: info.surname },
                { prop_name: 'name', description: info.name },
                { prop_name: 'secondname', description: info.secondname },
                { prop_name: 'organization', description: info.organization },
                { prop_name: 'office', description: info.department },
                { prop_name: 'post', description: info.position },
                { prop_name: 'room', description: info.room },
                { prop_name: 'leader', description: info.head },
                { prop_name: 'helper', description: info.helper },
                { prop_name: 'worktime', description: info.worktime },
                { prop_name: 'birthday', description: info.birthday },
                { prop_name: 'icq', description: info.icq },
                { prop_name: 'site', description: info.website },
                { prop_name: 'workaddresses', description: info.workaddress },
                { prop_name: 'homeaddresses', description: info.homeaddress },
                { prop_name: 'addresses', description: info.otheraddress },
                { prop_name: 'partner', description: info.spouse },
                { prop_name: 'children', description: info.children },
                { prop_name: 'note', description: info.note }
            ]
        };
    },
    computed: {
        cid() { return this.params.cid },
        contact() {
            let obj = app.store.getters['contacts/getMergedContactById'](this.cid);
            let contacts = {};
            obj.fields && obj.fields.forEach(item => {
                if(item.type in contacts && !Array.isArray(contacts[item.type])) {
                    let x = contacts[item.type];
                    contacts[item.type] = [];
                    contacts[item.type].push(x);
                    contacts[item.type].push(item.value);
                } else if(item.type in contacts && Array.isArray(contacts[item.type])) contacts[item.type].push(item.value);
                else contacts[item.type] = item.value;
            });
            return contacts;
        },
        title() {
            let obj = app.store.getters['contacts/getMergedContactById'](this.cid);
            return obj.fio
        },
        details () {
            return this.detailed_props.reduce((prev, cur) => {
                if (cur.prop_name === 'worktime' && this.contact.hasOwnProperty('workstart') && this.contact.hasOwnProperty('workfinish')) {
                    prev.push({
                        title: cur.description,
                        text: this.contact['workstart'] + ' - ' + this.contact['workfinish']
                    });
                } else if (this.contact.hasOwnProperty(cur.prop_name)) {
                    if(Array.isArray(this.contact[cur.prop_name])) {
                        this.contact[cur.prop_name].forEach(item => {
                            prev.push({
                                title: cur.description,
                                text: item
                            });
                        })
                    } else {
                        prev.push({
                            title: cur.description,
                            text: this.contact[cur.prop_name]
                        });
                    }
                }
                return prev;
            }, []);
        },
        details_en() {
            return this.detailed_props.reduce((prev, cur) => {
                if (cur.prop_name === 'worktime' && this.contact.hasOwnProperty('workstart') && this.contact.hasOwnProperty('workfinish')) {
                    prev.push({
                        title: cur.prop_name,
                        text: this.contact['workstart'] + ' - ' + this.contact['workfinish']
                    });
                } else if (this.contact.hasOwnProperty(cur.prop_name)) {
                    if(Array.isArray(this.contact[cur.prop_name])) {
                        this.contact[cur.prop_name].forEach(item => {
                            prev.push({
                                title: cur.prop_name,
                                text: item
                            });
                        })
                    } else {
                        prev.push({
                            title: cur.prop_name,
                            text: this.contact[cur.prop_name]
                        });
                    }
                }
                return prev;
            }, []);
        }
    },
    methods: {
        doContextMenu(event, value) {
            let handlers = []
            event.currentTarget.style.backgroundColor = 'var(--item-bgcolor-hover)'
            handlers.push({item_name: i18n.messages[i18n.locale].copy, handler: () => {
                this.$copyText(value, undefined, {
                    sourceType: DLP_SOURCE_TYPES.CONTACT,
                    sourceData: {
                        cid: this.cid,
                    },
                })
            }});
            this.cmOpen(event, handlers);
        },
        showModalExportWindow() {
            let obj = app.store.getters['contacts/getMergedContactById'](this.cid);
            let fio = `${obj.fio} ${obj.secondname}`;
            fio = fio.replace(/undefined/gi,'')
            let contactArr = this.details_en
                .filter(k=>!k.title.includes('name'));
            contactArr.unshift({title: "fio", text: fio});
            let emails = this.contact.mail;
            if (!!emails && Array.isArray(emails)) {
                emails.forEach(email=>{
                    contactArr.push({title: "mail", text: email});
                })
            } else if (!!emails) {
                contactArr.push({title: "mail", text: emails});
            }
            let workphones = (Object.entries(this.contact)).filter(([key, value]) => key.includes('phone')).map(([k,v])=> v);
            if (!!workphones && Array.isArray(workphones)) {
                workphones.forEach(workphone=>{
                    contactArr.push({title: "workphone", text: workphone});
                })
            } else if (!!workphones) {
                contactArr.push({title: "workphone", text: workphones});
            }
            contactArr = contactArr.map(obj=>({ ...obj, elementClass:'', checked: true }));
            this.modalOpen({
                component: SelectContactInfoExport,
                props: { fieldsArr: contactArr }
            })
        }
    }
}
