var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "message-text",
    domProps: { innerHTML: _vm._s(_vm.text) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }