//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import {CONTENT_MANAGER} from '../../store/modulesNames'
import {ACT_PUSH_MAIN_TYPE} from '../../store/actionsTypes'

import {MAIN_TYPES} from '../../store/modules/content-manager'

import MainContentContainer from '../main/body/MainContentContainer.vue'
import ListItem from '../custom/ListItem.vue'

export default {
    name: "themes",
    components: { MainContentContainer, ListItem },
    data() {
        return {};
    },
    methods: {
        ...mapActions(CONTENT_MANAGER, [ACT_PUSH_MAIN_TYPE]),
        setType() {
            this[ACT_PUSH_MAIN_TYPE]({type: MAIN_TYPES.DECORATION})
        }
    }
};
