import event_bus from '../../eventBus'
import proto from '../../protocol'
import storage from '../../storage'
import { getCredential, setCredential } from '../../common/CredentialStoreClient'
import ipc from '../../../electron/ipc'
import {
    GET_LOGIN_STATUS,
    GET_IS_LOGINED,
    GET_IS_LOADED,
    GET_LOGIN_DATA,
    GET_LOGIN_ERROR_TEXT,
    GET_DAYS_IN_OPERATION,
    GET_SERVER_API,
    GET_IS_ROLES_SUPPORTED,
    GET_MY_CONTACT,
    GET_SIDE_TYPE,
    GET_RADIO_PARAMS,
    GET_CONNECTION_STATUS,
    GET_OAUTH2_ENABLED_TYPES,
    GET_OAUTH2_PARAMS_BY_TYPE,
    GET_OAUTH2_SETTINGS,
    GET_ROSCHAT_REDIRECTED_SERVER,
    GET_SID,
    GET_IS_ELECTRON,
    IS_TOKENS_SET,
} from '../gettersTypes'
import {
    ACT_SET_LOGGING_IN,
    ACT_ON_LOGGED_IN,
    ACT_LOGIN,
    ACT_RELOGIN,
    ACT_LOGOUT,
    ACT_SAVE_LOGIN_DATA,
    ACT_OPEN_LOGOUT_DIALOG,
    ACT_CLEAR_RELOGIN_INTERVAL,
    ACT_SET_SERVER_API,
    ACT_UPDATE_USER_PARAMS,
    ACT_SET_UID,
    ACT_SET_LOGIN,
    ACT_SET_USER_PROFILE_REVISION,
    ACT_ACS_SET_CID,
    ACT_ACS_UPDATE_CONTACTS,
    ACT_ACS_STOP_UPDATE,
    ACT_ACS_SET_ROLE,
    ACT_ACS_SET_TYPE,
    ACT_ALARM_SET_PROPS,
    ACT_CHECK_NEW_VERSION,
    ACT_LOCATIONS_UPDATE_CONFIG,
    ACT_DAYS_IN_OPERATION,
    ACT_CLEAR_SEARCHED_CONTACTS,
    ACT_UPDATE_UNWATCHED_MESSAGES,
    ACT_SET_CONTACT_STATUS,
    ACT_SET_LAST_TIME_ACTIVE,
    ACT_START_OAUTH,
    ACT_HANDLE_TOKENS_EVENT,
    ACT_DROP_LOGIN_DATA,
    ACT_MODAL_OPEN,
    ACT_UPDATE_OAUTH2_TOKENS,
    ACT_MAIN_TYPE_CLEAR,
} from '../actionsTypes'
import {
    MUT_SET_RELOGIN_INTERVAL,
    MUT_CLEAR_RELOGIN_INTERVAL,
    MUT_SET_LOGINED_STATE,
    MUT_SET_LOADED,
    MUT_SET_SIDE_TYPE,
    MUT_SET_LOGIN_DATA,
    MUT_DAYS_IN_OPERATION,
    MUT_SET_SERVER_API,
    MUT_SET_SUPPORT,
    MUT_CLEAR_CONTACTS,
    MUT_CLEAR_CHATS,
    MUT_CLEAR_CHANNELS,
    MUT_SET_LOGIN_TOKENS,
    MUT_LOGIN_DROP_TOKENS,
} from '../mutationsTypes'
import {
    ACS,
    ALARM,
    CONTENT_MANAGER,
    LOGIN,
    USERDATA,
    CLIENTDATA,
    CONTACTS,
    CHATS,
    CHANNELS,
    LOCATIONS,
    RADIO,
    SOCKET,
    USERPROFILE,
    CALLS,
    MODAL,
} from '../modulesNames'

import { i18n } from '../../../ext/i18n'
import moment from 'moment'
import axios from "axios"

import { SIDE_TYPES } from './content-manager'

const locale = i18n.messages[i18n.locale]

export const LOGIN_STATUSES = {
    NOT_LOGGED_IN: 0,
    LOGGING_IN: 1,
    RELOGGING_IN: 2,
    LOGGED_IN: 3,
    //ACCESS_DENIED: 4,
}

export const LOGIN_RESP_STATUSES = {
    OK: 'ok',
    PIN_REQUIRED: 'pin-required',
    RELOGIN_REQUIRED: 'relogin-required',
    ERROR: 'error',
}

const CLIENT_ID = 'roschat'

const CREDENTIAL_KEYS = {
    OUATH2_TOKENS: 'ouath2tokens',
    IO_COOKIE: 'socket.io-cookie'
}

const getLoginData = function () {
    let tokens = getCredential(CREDENTIAL_KEYS.OUATH2_TOKENS)
    if (tokens)  {
        try {
            tokens = JSON.parse(tokens)
        } catch (e) {
            tokens = null
        }
    }
    const server_host = storage.getServerHost()
    const login = storage.getLogin()
    const password = storage.getPassword()
    const remember_me = storage.getRememberMe()
    return {
        server_host,
        login,
        password,
        tokens,
        remember_me: remember_me && Boolean((login && password) || tokens),
    }
}

// state
const state = {
    login_status: LOGIN_STATUSES.NOT_LOGGED_IN,
    loaded: false,
    login_data: getLoginData(),
    relogin_interval: 0,
    daysInOperation: 1,
    timerDaysInOperation: null,
    serverAPI: 0,
    tokensIsSet: false,
    ouath2type: '',
};


// getters
const getters = {
    [GET_LOGIN_STATUS] (state) {
        return state.login_status
    },
    [GET_IS_LOGINED] (state) {
        return state.login_status === LOGIN_STATUSES.LOGGED_IN
    },
    [GET_IS_LOADED] (state) {
        return state.loaded
    },
    [GET_LOGIN_DATA] (state) {
        const data = { ...state.login_data }
        if (data.tokens) {
            data.remember_me = true
        }
        if (state.tokensIsSet) {
            data.remember_me = true
            data.tokens = { type: state.ouath2type }
        }
        return data
    },
    [GET_LOGIN_ERROR_TEXT]: state => error => {
        let error_msg
        switch (error) {
            case 'bad-login-psw':
                error_msg = locale['wrong-login-pw']
                break
            case 'license-limit':
                error_msg = locale['conn-limit-err']
                break
            case 'client-blocked':
                error_msg = locale['login-attempts-err']
                break
            case 'unsupported-client':
                error_msg = locale['unsupported-client-err']
                break
            case 'not-connected':
                error_msg = locale['no-serv-conn']
                break
            case 'connection-refused':
                error_msg = locale['connection-refused']
                break
            case 'access-denied':
                error_msg = locale['loginPage']['access-denied']
                break
            case 'connection-refused-retry':
                error_msg = locale['retry-or-logout']
                break
            default:
                error_msg = locale.errors.unknown
                break
        }
        return error_msg
    },
    [GET_DAYS_IN_OPERATION] (state) {
        return state.daysInOperation
    },
    [GET_SERVER_API] (state) {
        return state.serverAPI
    },
    [GET_OAUTH2_ENABLED_TYPES]: (state, getters, rootState, rootGetters) => {
        const oauth2Settings = rootGetters[`${USERDATA}/${GET_OAUTH2_SETTINGS}`] || {}
        return Object.keys(oauth2Settings)
    },
    [GET_OAUTH2_PARAMS_BY_TYPE]: (state, getters, rootState, rootGetters) => (type) => {
        const oauth2Settings = rootGetters[`${USERDATA}/${GET_OAUTH2_SETTINGS}`] || {}
        return oauth2Settings[type]
    },
    [IS_TOKENS_SET]: (state) => {
        return state.tokensIsSet
    },
}

// actions
const actions = {
    [ACT_SET_LOGGING_IN]: ({commit}) => {
        commit(MUT_SET_LOGINED_STATE, LOGIN_STATUSES.LOGGING_IN)
    },
    [ACT_LOGIN] ({ state, dispatch, commit, rootGetters }, user) {
        commit(MUT_SET_LOGINED_STATE, LOGIN_STATUSES.LOGGING_IN)
        return new Promise(async (resolve, reject) => {
            let result = {}
            try {
                if (!user.pinRequired) {
                    if (rootGetters[`${SOCKET}/${GET_CONNECTION_STATUS}`] !== 'connected') await app.init()
                    result = await proto.login({
                        ...(user.tokens && { oauth2: user.tokens } || {
                            login: user.login,
                            password: user.password,
                        })
                    })
                } else result.uid = user.uid
                if (result.status === LOGIN_RESP_STATUSES.PIN_REQUIRED) resolve(result)
                else {
                    commit(MUT_SET_LOGIN_DATA, user)
                    commit(MUT_SET_LOGINED_STATE, LOGIN_STATUSES.LOGGED_IN)
                    dispatch(ACT_ON_LOGGED_IN, {result, user})
                    if (user.remember_me) dispatch(ACT_SAVE_LOGIN_DATA, user)
                    dispatch(ACT_DAYS_IN_OPERATION)
                    ipc && ipc.send('loggedin-electron-event')
                    resolve(result)
                }
            } catch (e) {
                reject(e)
            }
        });
    },
    async [ACT_RELOGIN] ({ dispatch, commit, getters }) {
        const loginData = getters[GET_LOGIN_DATA]
        const { login, password, tokens } = loginData
        commit(MUT_SET_LOGINED_STATE, LOGIN_STATUSES.RELOGGING_IN)
        let alert_props = {}
        if (!((login && password) || tokens)) {
            commit(MUT_CLEAR_RELOGIN_INTERVAL)
            return
        }

        try {
            let result = await proto.login({
                silentMode: true,
                ...(tokens && {oauth2: tokens} || {
                    login,
                    password
                })
            })
            if (result.status === LOGIN_RESP_STATUSES.RELOGIN_REQUIRED) {
                dispatch(ACT_DROP_LOGIN_DATA)
                dispatch(ACT_LOGOUT, {storageClear: false})
                return
            } else {
                commit(MUT_SET_LOGINED_STATE, LOGIN_STATUSES.LOGGED_IN)
                commit(MUT_CLEAR_RELOGIN_INTERVAL)
                dispatch(ACT_ON_LOGGED_IN, {result, user: loginData})
            }
        } catch (e) {
            if (e === LOGIN_RESP_STATUSES.RELOGIN_REQUIRED) {
                dispatch(ACT_DROP_LOGIN_DATA)
                dispatch(ACT_LOGOUT, {storageClear: false})
                return
            }
            commit(MUT_SET_RELOGIN_INTERVAL, () => dispatch(ACT_RELOGIN))
            alert_props.msg = this.getters[`${LOGIN}/${GET_LOGIN_ERROR_TEXT}`](e)
        }
        if (((login && password) || tokens)) dispatch(`${ALARM}/${ACT_ALARM_SET_PROPS}`, alert_props, { root: true })
        dispatch(ACT_DAYS_IN_OPERATION)
    },
    async [ACT_ON_LOGGED_IN]({ state, dispatch, commit, rootGetters }, {result, user}) {
        proto.getUserInfo(async function (data) {
            if (result.tokens) dispatch(ACT_UPDATE_OAUTH2_TOKENS, {type: 'roschat', ...result.tokens})
            dispatch(ACT_SET_SERVER_API, result.APILevel)
            dispatch(`${USERDATA}/${ACT_SET_CONTACT_STATUS}`, 'online', { root: true })
            dispatch(`${CLIENTDATA}/${ACT_SET_LAST_TIME_ACTIVE}`, { data: new moment().unix() }, { root: true })
            dispatch(`${USERDATA}/userInfo`, data, { root: true })
            dispatch(`${USERDATA}/${ACT_SET_UID}`, result.uid, { root: true })
            dispatch(`${USERDATA}/${ACT_SET_LOGIN}`, user.login, { root: true })
            dispatch(`${USERPROFILE}/${ACT_SET_USER_PROFILE_REVISION}`, data && data.extParams.profileRev, { root: true })
            dispatch(`${ACS}/${ACT_ACS_SET_CID}`, result.uid, { root: true })
            dispatch(`${ACS}/${ACT_ACS_UPDATE_CONTACTS}`, null, { root: true })
            dispatch(`${ACS}/${ACT_ACS_SET_TYPE}`, data && data.extParams && data.extParams.acsType, { root: true })
            dispatch(`${ACS}/${ACT_ACS_SET_ROLE}`, data && data.extParams && data.extParams.acsRole, { root: true })
            dispatch(`${LOCATIONS}/${ACT_LOCATIONS_UPDATE_CONFIG}`, data && data.extParams && data.extParams.locationsRev, { root: true })
            dispatch(`${CLIENTDATA}/setTimeUpdateInt`, null, { root: true })
            dispatch(`${CLIENTDATA}/${ACT_CHECK_NEW_VERSION}`, null, { root: true })
            if (data && data.extParams.support) commit(`${CONTACTS}/${MUT_SET_SUPPORT}`, data.extParams.support, { root: true })
            await dispatch(`${USERDATA}/${ACT_UPDATE_USER_PARAMS}`, null, { root: true })
            ipc && ipc.send('loggedin-electron-event')
            event_bus.$emit('loggedin', result.uid, data, user.login, user.password)
            
            dispatch('phone/setStun', null, { root: true })
            proto.addOptions([{ name: 'webrtc' }])
            await dispatch(`${CHATS}/update`, null, { root: true })
            await dispatch(`${CHATS}/${ACT_UPDATE_UNWATCHED_MESSAGES}`, null, { root: true })
            await dispatch(`${CHANNELS}/update`, null, { root: true })
            await dispatch(`${CALLS}/updateCalls`, null, { root: true })
            await dispatch(`${CONTACTS}/update`, { serverAPILevel: result.APILevel }, { root: true })
            commit(MUT_SET_LOADED, true)

            let isRadioAllowed = true
            const isRMSupported = rootGetters[`${CONTACTS}/${GET_IS_ROLES_SUPPORTED}`]
            if (isRMSupported) {
                const actions = rootGetters[`${CONTACTS}/${GET_MY_CONTACT}`].actions || {}
                if (actions.hasOwnProperty('use-radio')) isRadioAllowed = actions["use-radio"]
            }
            let side_type = rootGetters[`${CONTENT_MANAGER}/${GET_SIDE_TYPE}`]
            // const isExistedSideType = Object.values(SIDE_TYPES).some(val => val === side_type)
            // if (!isExistedSideType) side_type = SIDE_TYPES.CONTACTS_LIST
            if (side_type === SIDE_TYPES.RADIO &&
                (!rootGetters[`${RADIO}/${GET_RADIO_PARAMS}`].length || !isRadioAllowed)) {
                commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_TYPE}`, 'contacts-list', { root: true })
            } else commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_TYPE}`, side_type, { root: true })

        })
    },
    [ACT_LOGOUT] ({ state, dispatch, commit }, payload = {}) {
        ipc && ipc.send('loggedout-electron-event')
        let { storageClear = true } = payload
        commit(MUT_SET_LOGINED_STATE, LOGIN_STATUSES.NOT_LOGGED_IN)
        dispatch(ACT_UPDATE_OAUTH2_TOKENS)
        proto.logout()
        dispatch(`${ACS}/${ACT_ACS_STOP_UPDATE}`, null, { root: true })
        dispatch(`${ALARM}/${ACT_ALARM_SET_PROPS}`, {}, { root: true })
        dispatch(`${USERDATA}/${ACT_CLEAR_SEARCHED_CONTACTS}`, null, { root: true })
        commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_TYPE}`, 'contacts-list', { root: true })
        dispatch(`${CONTENT_MANAGER}/${ACT_MAIN_TYPE_CLEAR}`, null, {root: true})
        commit(`${CONTACTS}/${MUT_CLEAR_CONTACTS}`, null, { root: true })
        commit(`${CHATS}/${MUT_CLEAR_CHATS}`, null, { root: true })
        commit(`${CHANNELS}/${MUT_CLEAR_CHANNELS}`, null, { root: true })
        commit(MUT_CLEAR_RELOGIN_INTERVAL)
        commit(MUT_SET_SERVER_API)
        if (storageClear) storage.clear()
        event_bus.$emit('logout')
    },
    [ACT_SAVE_LOGIN_DATA] ({ state, commit, dispatch }, data) {
        storage.setLogin(data.login)
        storage.setPassword(data.password)
        storage.setRememberMe(data.remember_me)
        commit(MUT_SET_LOGIN_DATA, data)
    },
    async [ACT_DROP_LOGIN_DATA]({ dispatch, getters }) {
        const defaultLoginData = {
            login: '',
            password: '',
            remember_me: false,
            tokens: null,
        }
        dispatch(ACT_SAVE_LOGIN_DATA, defaultLoginData)
        await dispatch(ACT_UPDATE_OAUTH2_TOKENS)
    },
    [ACT_OPEN_LOGOUT_DIALOG] ({ dispatch, commit }) {
        dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
            name: 'confirm',
            props: {
                text: locale['logout-conf'],
                btnOk: {
                    cb: () => dispatch(ACT_LOGOUT)
                },
            }
        }, {root: true})
    },
    [ACT_DAYS_IN_OPERATION]({ state, commit }) {
        if(state.timerDaysInOperation) return

        let d = new Date();
        let sec =  d.getHours() * 3600 + d.getMinutes() * 60 + d.getSeconds();

        state.timerDaysInOperation = setInterval(() => {
            if(sec % 86400 === 0) commit(MUT_DAYS_IN_OPERATION)
            else sec += 1
        }, 1000)
    },
    [ACT_CLEAR_RELOGIN_INTERVAL]({commit}) {
        commit(MUT_CLEAR_RELOGIN_INTERVAL)
    },
    [ACT_SET_SERVER_API]({commit}, api) {
        commit(MUT_SET_SERVER_API, api)
    },
    [ACT_START_OAUTH]({getters, rootGetters, state}, type) {
        const { authorization_endpoint } = getters[GET_OAUTH2_PARAMS_BY_TYPE](type) || {}
        const isElectron = rootGetters[`${CLIENTDATA}/${GET_IS_ELECTRON}`]
        const urlState = isElectron ? rootGetters[`${CLIENTDATA}/${GET_SID}`] : location.href
        const serverUrl = rootGetters[`${USERDATA}/${GET_ROSCHAT_REDIRECTED_SERVER}`]
        const authUrl = new URL(authorization_endpoint)
        const cbUrl = `${serverUrl}/oauth2callback/${type}`

        authUrl.searchParams.append('client_id', CLIENT_ID)
        authUrl.searchParams.append('redirect_uri', cbUrl)
        authUrl.searchParams.append('response_type', 'code')
        authUrl.searchParams.append('scope', 'openid')
        authUrl.searchParams.append('state', urlState)

        if (rootGetters[`${CLIENTDATA}/${GET_IS_ELECTRON}`]) {
            ipc.send('oauth-open', {url: authUrl.href})
        } else {
            state.ouath2type = type
            state.tokensIsSet = true
            window.open(authUrl.href, '_self')
        }
    },
    async [ACT_HANDLE_TOKENS_EVENT]({dispatch, commit, getters, rootGetters}, payload) {
        dispatch(ACT_UPDATE_OAUTH2_TOKENS, payload)
        if (rootGetters[`${CLIENTDATA}/${GET_IS_ELECTRON}`]) {
            if (!getters[GET_IS_LOGINED]) dispatch(ACT_LOGIN, {tokens: payload})
            ipc.send('oauth-close')
        }
    },
    async [ACT_UPDATE_OAUTH2_TOKENS]({state, commit, rootGetters}, payload) {
        if (rootGetters[`${CLIENTDATA}/${GET_IS_ELECTRON}`]) {
            commit(MUT_SET_LOGIN_TOKENS, payload)
        } else {
            const hostPort = utils.getPageProtoHostPort()
            const serverUrl = new URL(hostPort)
            serverUrl.pathname = 'oauth2UpdateCookieTokens'
            serverUrl.searchParams.append('access_token', payload && payload.access_token || '')
            serverUrl.searchParams.append('refresh_token', payload && payload.refresh_token || '')

            try { //@todo cors error, при этом куки устанавливает
                await axios.get(serverUrl.href, {withCredentials: true})
            } catch (e) {}

            state.ouath2type = payload && payload.type || ''
            state.tokensIsSet = Boolean(state.ouath2type)
        }
    },
}

// mutations
const mutations = {
    [MUT_SET_RELOGIN_INTERVAL] (state, cb) {
        if (!state.relogin_interval) state.relogin_interval = setInterval(cb, 10 * 1000)
    },
    [MUT_CLEAR_RELOGIN_INTERVAL] (state) {
        if (state.relogin_interval) {
            clearInterval(state.relogin_interval)
            state.relogin_interval = 0
        }
    },
    [MUT_SET_LOGINED_STATE] (state, log_state) {
        state.login_status = log_state
    },
    [MUT_SET_LOADED] (state, loaded) {
        state.loaded = loaded
    },
    [MUT_SET_LOGIN_DATA] (state, data) {
        Object.assign(state.login_data, data)
    },
    [MUT_DAYS_IN_OPERATION] (state) {
        //console.log('MUT_DAYS_IN_OPERATION')
        state.daysInOperation += 1
    },
    [MUT_SET_SERVER_API] (state, api = 0) {
        state.serverAPI = api
    },
    [MUT_SET_LOGIN_TOKENS] (state, tokens) {
        state.login_data.tokens = tokens
        setCredential(CREDENTIAL_KEYS.OUATH2_TOKENS, tokens && JSON.stringify(tokens) || '')
        if (!tokens) setCredential(CREDENTIAL_KEYS.IO_COOKIE, '')
    },
    [MUT_LOGIN_DROP_TOKENS](state) {
        state.tokensIsSet = false
        state.ouath2type = ''
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
