//
//
//
//
//

import {mapGetters, mapActions} from 'vuex';
import Chats from './Chats.vue';
import ContactsList from './ContactsList.vue';
import CallsList from './CallsList.vue';
import More from './More.vue';
import Radio from '../radio/Radio.vue';
import {GET_SIDE_TYPE} from "../../store/gettersTypes";
import {CONTENT_MANAGER} from "../../store/modulesNames";

   export default {
       name: "SideContent",
       components: {
           'calls-list': CallsList,
           Chats,
           More,
           Radio,
           ContactsList,
       },
       computed: {
           ...mapGetters(CONTENT_MANAGER, {sideType: GET_SIDE_TYPE}),
       },
       watch: {
           sideType: function() {
               let self = this,
                   statusWatchCalls = this.$store.getters['calls/getWatchedCallsStatus'];
               let watchCalls = this.$store.getters['calls/getUnwatchedCalls'];
               if(!this.sideType.calls && statusWatchCalls) {
                   watchCalls.forEach(function (call) {
                       self.$store.dispatch('calls/updateStatus', {id: call.id, status: 'deleted'});
                   });
               }
           }
       }
   }
