//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex"
import { VIDEO_CONF_NEW } from "../../store/modulesNames"
import { GET_VC_CONF_MEMBERS } from "../../store/gettersTypes"

import videoConfSectionMixin from "./videoConfSectionMixin"
import VideoConfStatus from "./videoConfStatus.vue"
import videoConfMembers from "./videoConfMembers.vue"
import videomostMembersVideo from "./videomostMembersVideo.vue"

import {CONF_TYPES} from "../../constants"

export default {
    name: "videoConfSectionMain",
    mixins: [videoConfSectionMixin],
    components: {
        VideoConfStatus,
        videoConfMembers,
    },
    props: {
        membersListOpened: {
            type: Boolean,
            required: true
        }
    },
    created() {
        let theme = this.$store.getters['clientdata/getTheme']
        if (theme === 'light' || (theme === 'system' && !isDarkSystemTheme)) document.documentElement.setAttribute("data-theme", "")
        if (theme === 'dark' || (theme === 'system' && isDarkSystemTheme)) document.documentElement.setAttribute("data-theme", "dark")
    },
    computed: {
        videoComp() {
            switch (this.type) {
                case CONF_TYPES.VM:
                    return videomostMembersVideo

            }
        },
        members() {
            return this.confIdGetterData && this[GET_VC_CONF_MEMBERS](...this.confIdGetterData) || []
        },
        ...mapGetters(VIDEO_CONF_NEW, [GET_VC_CONF_MEMBERS]),
    },
}
