import {
    ACT_GET_CALL_INFO,
    ACT_UPDATE_CALL,
    ACT_GET_CALLS_INFO,
    ACT_UPDATE_CALLS,
    ACT_GET_TA,
    ACT_UPDATE_TA,
    ACT_TOGGLE_MICROPHONE,
    ACT_TOGGLE_SPEAKER,
    ACT_TOGGLE_DND,
    ACT_PRESS_DIAL_KEY,
    ACT_DIAL_NUMBER,
    ACT_SET_ACTIVE_CALL,
    ACT_TRANSIT,
    ACT_CONFERENCE,
    ACT_SELECT_ASSISTANT,
    ACT_TERMINATE_ASISTANT,
} from '../../actionsTypes'

import {MUT_ADD_TRANSIT_ID, MUT_CLEAR_DATA} from "../../mutationsTypes"


import module from '../../modules/asistants'
import proto from "../../../protocol"
import {PHONE_TYPES} from "../../../constants"


const getters = {
    getConnectionStatus (state, _getters, _rootState, rootGetters) {
        return rootGetters['socket/getConnectionStatus']
    },
}

Object.assign(module.getters, getters)

const actions = {
    [ACT_GET_CALL_INFO]: async ({dispatch}, id) => {
        let call = await proto.getCallInfo(id)
        dispatch(ACT_UPDATE_CALL, call)
    },
    [ACT_GET_CALLS_INFO]: async ({dispatch}) => {
        let calls = await proto.getCallsInfo()
        dispatch(ACT_UPDATE_CALLS, calls)
    },
    [ACT_GET_TA]: async ({dispatch}) => {
        const ta = await proto.getTaInfo()
        dispatch(ACT_UPDATE_TA, ta)
    },
    [ACT_TOGGLE_MICROPHONE]: () => proto.microphone(),
    [ACT_TOGGLE_SPEAKER]: () => proto.speaker(),
    [ACT_TOGGLE_DND]: () => proto.dnd(),
    [ACT_PRESS_DIAL_KEY]: ({ state }, key) => proto.pressDialKey(key),
    [ACT_DIAL_NUMBER]: ({ dispatch }, digits) => proto.dialNumber(digits),
    [ACT_SET_ACTIVE_CALL]: ({ state, commit }, id) => proto.setActiveCall(id),
    [ACT_TERMINATE_ASISTANT]: ({ state }, id) => proto.releaseCall(id),
    [ACT_TRANSIT]: ({ state, commit })  => {
        if (state.calls.length > 1) {
            proto.transit({ id1: state.calls[0].id, id2: state.calls[1].id })
            commit(MUT_ADD_TRANSIT_ID, [state.calls[0].id, state.calls[1].id])
        }
    },
    [ACT_CONFERENCE]: ({ state })  => {
        if (state.calls.length > 1) proto.conference({ id1: state.calls[0].id, id2: state.calls[1].id })
    },
    [ACT_SELECT_ASSISTANT] ({ state, commit, dispatch, rootGetters }, type) {
        if (type === state.typeAssistant) return
        commit(MUT_CLEAR_DATA)
        proto.setUserPhone({ type })
        dispatch(ACT_GET_CALLS_INFO)
        dispatch(ACT_GET_TA)
        localStorage.setItem(PHONE_TYPES.ASSISTANT, type)
        commit('setTypeAssistant', type)
    },
}

Object.assign(module.actions, actions)

export default module
