'use strict';
import Vue from 'vue';
import proto from '../../protocol';
import {
    GET_SELECTED_CONTACT,
    GET_SUPPORT,
    GET_INFO_HISTORY_LENGTH,
    GET_INFO_NEED_OPEN,
    GET_INFO_OPEN,
    GET_BIRTHDAY_NOTIFY,
    GET_MERGED_CONTACTS_BY_IDS,
    GET_FAVOURITES,
    GET_FAVOURITES_CIDS,
    GET_CONTACT_STATUS_TEXT,
    GET_PHOTO_URL_BY_CID,
    GET_CURRENT_DAY,
    GET_MERGED_CONTACT_BY_ID,
    GET_CONTACT_BY_ID,
    GET_LOCAL_CONTACT_BY_ID,
    GET_MERGED_CONTACT_BY_PHONE,
    GET_MERGED_CONTACT_BY_EMAIL,
    GET_FILTER_VALUES_LIST_BY_TYPE,
    GET_UID,
    GET_MY_CONTACT,
    GET_MY_FULL_NAME,
    GET_PHONE_LIST_BY_ID,
    GET_CONTACTS_MERGED,
    GET_CONTACTS_GLOBAL,
    GET_CONTACTS_LOCAL,
    GET_SERVER_API,
    GET_ROLE_REVISION,
    GET_SIDE_BAR_NOTIFICATION,
    GET_IS_ROLES_SUPPORTED,
    GET_CHATS,
    GET_IS_SEARCH_FILTER_SUPPORTED,
    GET_CONTACTS_FILTER_BY_NAME,
} from '../gettersTypes'
import {
    ACT_INFO_PUSH,
    ACT_SELECTED_CONTACT,
    ACT_CHANGE_CONTACT,
    ACT_ADD_CONTACT,
    ACT_ADD_BOT_CONTACT,
    ACT_ADD_CONTACT_CONNECTIONS,
    ACT_DELETE_CONTACT,
    ACT_PUSH_FAVOURITES,
    ACT_UPDATE_CONTACT_STATUS,
    ACT_LOAD_BOT_CONTACT,
    ACT_UPDATE_CONTACTS_BIRTHDAYS,
    ACT_SEARCH_CONTACTS,
    ACT_UPDATE_SEARCHED_CONTACT,
    ACT_ADD_CONTACT_SHORT_INFO,
    ACT_REPLACE_MAIN_TYPE,
    ACT_UPDATE_SEARCH_FILTER,
    ACT_SET_CONTACTS_FILTER,
} from '../actionsTypes'
import {
    MUT_SET_SELECTED_CONTACT,
    MUT_CHANGE_BOT,
    MUT_SET_SUPPORT,
    MUT_SET_SIDE_BAR_NOTIFICATION,
    MUT_DELETE_SIDE_BAR_NOTIFICATION,
} from '../mutationsTypes';
import {
    CONTENT_MANAGER,
    BOTS,
    INFO,
    USERDATA,
    LOGIN,
    CHATS,
} from '../modulesNames'

import { i18n } from '../../../ext/i18n'
import moment from 'moment';

import { MAIN_TYPES, SIDE_TYPES } from "./content-manager";
import {CONTACTS_SEARCH_TYPES} from '../../constants'


let locale = i18n.messages[i18n.locale]

const systemCid = 0

// @todo убрать в 2023
let birthdaysWatchedOld = localStorage.getItem('birthdays_watched')
if (birthdaysWatchedOld) localStorage.removeItem('birthdays_watched')

const state = {
    contacts: {},
    localContacts: {},
    contactsMerged: {},
    favourites: [],
    birthdaysList: {},
    searchedList: {},
    shortList: {},
    contactsBirthdays: {},
    changeFavouritesMode: false,
    birthdays_watched: birthdaysWatchedOld || moment().add(-1, 'days').format('DD.MM.YYYY'), // @todo убрать birthdaysWatchedOld в 2023
    birthdayContactInfo: {},
    selected: null,
    support: null,
    contactsStatuses: {},
    filterList: {},
    filters: {},
    rev: 0,
};

const getters = {
    [GET_ROLE_REVISION]: state => state.rev,
    [GET_SELECTED_CONTACT]: state => state.selected,
    changeFavouritesModeP: state => state.changeFavouritesMode,
    [GET_FAVOURITES]: state => state.favourites.map(({cid}) => getContactById(state.contactsMerged, cid)),
    [GET_FAVOURITES_CIDS]: state => state.favourites.map(({cid}) => cid),
    [GET_IS_ROLES_SUPPORTED](state, getters) {
        const serverAPILevel = getters[GET_SERVER_API]
        return serverAPILevel > declarations.serverAPILevels.LEVEL_11
    },
    [GET_IS_SEARCH_FILTER_SUPPORTED](state, getters) {
        const serverAPILevel = getters[GET_SERVER_API]
        return serverAPILevel >= declarations.serverAPILevels.LEVEL_13
    },
    getBirthdays(state, getters, rootState, rootGetters) {
        let birthdays = []
        let contacts = getters[GET_CONTACTS_MERGED]
        const serverAPI = getters[GET_SERVER_API]
        const BIRTHDAY_SHOW_PERIOD = rootGetters[`${USERDATA}/${GET_BIRTHDAY_NOTIFY}`]
        if (BIRTHDAY_SHOW_PERIOD === 10) return // значение не беспокоить
        contacts = serverAPI < declarations.serverAPILevels.LEVEL_12 ? getters[GET_CONTACTS_MERGED] : Object.values(state.birthdaysList)
        contacts.forEach(contact => {
            const start_day = moment().subtract(1, 'days');
            const end_day = moment().add(BIRTHDAY_SHOW_PERIOD, 'days');
            let birthday = contact && contact.birthday || null
            let bObj = moment(birthday, 'DD.MM')
            if (!birthday || !bObj.isValid()) return
            birthday = `${bObj.format('DD.MM')}.${start_day.format('YYYY')}`
            if (birthday && moment(birthday, 'DD.MM.YYYY').isAfter(start_day) && moment(birthday, 'DD.MM.YYYY').isBefore(end_day)) {
                birthday && birthdays.push({
                    cid: contact.cid,
                    date: bObj.calendar(null, {sameDay: `[${locale.settings.today}]`, nextDay: `[${locale.tomorrow}]`, nextWeek: 'DD MMMM', sameElse: 'DD MMMM'}),
                    dateComp: bObj.format('DD.MM'),
                    fio: contact.fio,
                    photo: contact.photo
                })
            }
        })

        birthdays.sort((a,b) => {
            let bd1 = moment(a.dateComp, 'DD.MM.YYYY');
            let bd2 = moment(b.dateComp, 'DD.MM.YYYY');
            if (moment(bd1).isAfter(moment(bd2))) return 1;
            else if (moment(bd1).isBefore(moment(bd2))) return -1;
            else return utils.textSortFn(a.fio, b.fio)
        });
        if ((state.birthdays_watched &&
             moment(state.birthdays_watched, 'DD.MM.YYYY')
             .isSame(moment(), 'day'))) return false
        else return birthdays
    },
    getContactsBirthdays: (state) => {
        return Object.values(state.contactsBirthdays)
    },
    getContactBirthdayInfo: state => state.actContactBirthdayInfo,
    [GET_CONTACTS_GLOBAL]: state => Object.values(state.contacts),
    [GET_CONTACTS_MERGED]: (state) => Object.values(state.contactsMerged),
    [GET_CONTACTS_LOCAL]: state => Object.values(state.localContacts),
    [GET_CONTACTS_FILTER_BY_NAME]: state => (name) => state.filters[name] || {},
    getRolesRevision(state) { return state.rev },
    [GET_CONTACT_BY_ID]: state => cid => {
        let contact = getContactById(state.contacts, cid)
        if (!contact.cid) contact = getContactById(state.searchedList, cid)
        return contact
    },
    [GET_LOCAL_CONTACT_BY_ID]: state => cid => getContactById(state.localContacts, cid),
    contactHaveLocalChanges: (state, getters) => cid => Boolean(getters[GET_LOCAL_CONTACT_BY_ID](cid)),
    [GET_MERGED_CONTACT_BY_ID]: state => cid => {
        let contact = getContactById(state.contactsMerged, cid)
        if (!contact.cid) contact = getContactById(state.searchedList, cid)
        if (!contact.cid) contact = getContactById(state.shortList, cid)
        return contact
    },
    [GET_MERGED_CONTACTS_BY_IDS]: state => cids => {
        return cids.map(cid => getContactById(state.contactsMerged, cid)).sort((a, b) => a.fio.toLowerCase() > b.fio.toLowerCase() ? 1 : -1)
    },
    [GET_FILTER_VALUES_LIST_BY_TYPE]: (state, getters) => (type) => {
        return [...new Set(state.filterList[type] ?? [])].sort((a, b) => a.toUpperCase() > b.toUpperCase() ? 1 : -1)
        // let res = getters[GET_CONTACTS_MERGED].reduce((prev, cur) => {
        //     if (cur[type]) prev.push(cur[type].trim())
        //     return prev
        // }, [])
        // return [...new Set(res)].sort((a, b) => a.toUpperCase() > b.toUpperCase() ? 1 : -1)
    },
    getLocalUsers: (state, getters) => {
        return getters[GET_CONTACTS_LOCAL].reduce((prev, contact) => {
            if (!state.contacts[contact.cid]) prev.push(contact.cid)
            return prev
        }, [])
    },
    getLocalChangedFields: (state, getters) => cid => {
        let globalContactFields = getters[GET_CONTACT_BY_ID](cid).fields
        let mergedContactFields = getters[GET_MERGED_CONTACT_BY_ID](cid).fields
        return getChangedFields(globalContactFields, mergedContactFields)
    },
    [GET_PHONE_LIST_BY_ID]: (state, getters) => (cid, isSearched = false) => {
            let multiplePhone = ['workphone','homephone','mobilephone','phone','faxe'];
            let contact = getters[GET_MERGED_CONTACT_BY_ID](cid)
            if (!( contact && contact.fields && contact.fields.length)) return []
            let phones = multiplePhone.reduce((phones, fieldType) => {
                const filteredPhones = contact.fields.filter(({type}) => type === fieldType)
                return filteredPhones.length ? phones.concat(filteredPhones) : phones
            }, [])
            let phones_unique = phones.filter((e, i) => phones.findIndex(a => a.value === e.value) === i)
            return phones_unique
    },
    getMailsListById: state => (cid, isSearched = false) => {
        let contact = getters[GET_MERGED_CONTACT_BY_ID](cid)
        let mails = []
        if (contact && contact.fields && contact.fields.length) {
            mails = contact.fields.filter(({type}) => type === 'mail')
        }
        return mails || []
    },
    getContactByPhone: (state, getters) => (phone) => {
        return getters[GET_CONTACTS_GLOBAL].find((contact) => {
            return !!([].concat(contact.workphones, contact.homephones, contact.mobilephones, contact.phones).find((cur_phone) => {
                return cur_phone === phone
            }))
        })
    },
    [GET_MERGED_CONTACT_BY_PHONE]: (state, getters) => phone => {
        return getters[GET_CONTACTS_MERGED].find((contact) => {
            return !!([].concat(contact.workphones, contact.homephones, contact.mobilephones, contact.phones).find((cur_phone) => {
                return cur_phone === phone
            }))
        })
    },
    [GET_MERGED_CONTACT_BY_EMAIL]: (state, getters) => email => {
        const contactsWithEmails = getters[GET_CONTACTS_MERGED].filter(c => c.mails)
        return contactsWithEmails.find(c => c.mails.some(m => m === email))
    },
    [GET_CONTACT_STATUS_TEXT]: (state) => payload => {
        let status = locale.updating.toLowerCase()
        if (!state.contactsStatuses || !state.contactsStatuses[payload.cid] || state.contactsStatuses[payload.cid].pending) return status
        else if('statusTime' in state.contactsStatuses[payload.cid] ) {
            if(state.contactsStatuses[payload.cid].statusTime === -1) status = locale.mainPage.offline;
            if(state.contactsStatuses[payload.cid].statusTime === 0) status = locale.mainPage.online;
            else if (state.contactsStatuses[payload.cid].statusTime > 0) {
                let today = moment().startOf('day');
                let yesterday = moment().subtract(1, 'days').startOf('day');
                let time = state.contactsStatuses[payload.cid].statusTime;
                let timeFormat = moment().subtract(time, 'seconds');
                if(time < 60) {
                    status = locale.mainPage['last-seen-recently'];
                }  else if(time < 43200) {
                    const roundingDefault = moment.relativeTimeRounding()
                    moment.relativeTimeRounding(Math.floor)
                    status = locale.mainPage['last-seen'] + moment().to(timeFormat)
                    moment.relativeTimeRounding(roundingDefault)
                } else if(timeFormat.isAfter(today)) {
                    status = locale.mainPage['last-seen-today'] + moment().subtract(time, 'seconds').format('HH:mm');
                } else if(timeFormat.isAfter(yesterday)) {
                    status = locale.mainPage['last-seen-yesterday'] + moment().subtract(time, 'seconds').format('HH:mm');
                } else {
                    status = locale.mainPage['last-seen'] + moment().subtract(time, 'seconds').format('DD MMMM YYYY');
                }
            }
        } else {
            status = locale.mainPage.offline
        }
        return status
    },
    [GET_SUPPORT](state) {
        return state.support;
    },
    [GET_MY_CONTACT](state, getters, rootState, rootGetters) {
        let uid = rootGetters[`${USERDATA}/${GET_UID}`]
        return getters[GET_MERGED_CONTACT_BY_ID](uid)
    },
    [GET_MY_FULL_NAME](state, getters) {
        return (getters[GET_MY_CONTACT] || {}).fio
    },
    [GET_SERVER_API](state, getters, rootState, rootGetters) {
        return rootGetters[`${LOGIN}/${GET_SERVER_API}`]
    },
};

const actions = {
    [ACT_SET_CONTACTS_FILTER] ({state}, payload) {
        state.filters = { ...payload }
    },
    changeFavouritesMode: (obj, mode) => {
        obj.commit('changeFavouritesMode', mode);
    },
    [ACT_ADD_CONTACT_CONNECTIONS]: async (obj, cids) => {
        let data = {}
        data.connections = cids
        try {
            await proto.addContactConnections(data)
        } catch(e) {
            console.error(e);
        }
    },
    [ACT_PUSH_FAVOURITES]: async (obj, cids) => {
        for (let i in obj.state.favourites) {
            if (cids.includes(obj.state.favourites[i].cid)) return;
        }

        const favourites = obj.state.favourites.concat(cids.map((cid) => ({cid})));
        try {
            await proto.setFavouritesList(favourites);
            obj.commit('favourites', favourites);
        } catch(e) {
            console.error(e);
        }
    },
    changeFavouritesEvent: (obj, favourites) => {
        for(let i = 0; i < favourites.length; i++) favourites[i].cid = +favourites[i].cid;
        obj.commit('favourites', favourites);
    },
    setFavouritesList: async (obj, cids) => {
        const tmp = {};
        const favourites = obj.state.favourites;
        for (let i = 0; i < favourites.length; i++) {
            tmp[favourites[i].cid] = favourites[i];
        }
        const newFavourites = [];
        for (let i = 0; i < cids.length; i++) {
            if (!!tmp[cids[i]]) newFavourites.push(tmp[cids[i]]);
            else newFavourites.push({cid: cids[i]});
        }
        try {
            await proto.setFavouritesList(newFavourites);
            obj.commit('favourites', newFavourites);
        } catch(e) {
            console.error(e);
        }
    },
    spliceFavourite: async (obj, contact_id) => {
        for(let i = 0; i < obj.state.favourites.length; i++) {
            if (obj.state.favourites[i].cid === contact_id) obj.state.favourites.splice(i, 1);
        }
        try {
            await proto.setFavouritesList(obj.state.favourites);
        } catch(e) {
            console.log('Unable to delete favourite');
        }
    },
    addFavourite: async (obj, contact_id) => {
        if (!contact_id) return
        const isExist = obj.state.favourites.find(cid => cid === contact_id)
        if (isExist) return
        obj.state.favourites.push({cid: contact_id})
        try {
            await proto.setFavouritesList(obj.state.favourites)
        } catch(e) {
            console.log('Unable to add to favourites')
        }
    },
    async [ACT_SELECTED_CONTACT]({commit, dispatch, getters, rootGetters}, contact_id) {
        const root = true
        let contact ={}
        if (getters[GET_IS_ROLES_SUPPORTED]) {
            contact = getters[GET_MERGED_CONTACT_BY_ID](contact_id)
            if (!contact.actions) {
               try {
                   await dispatch(ACT_UPDATE_SEARCHED_CONTACT, {cid: contact_id})
               } catch (e) {}
            } 
        }
        const isBot = contact.hasOwnProperty('isBot') && contact.isBot 
        if (isBot) {
            dispatch(ACT_ADD_BOT_CONTACT, contact)
        }
        dispatch('acs/setCid', contact_id, {root});
        commit(MUT_SET_SELECTED_CONTACT, contact_id);
        dispatch('chats/selected', {cid: contact_id, cidType: 'user'}, {root});
        const openInfo = rootGetters[`${INFO}/${GET_INFO_OPEN}`];
        const infoHistoryLength = rootGetters[`${INFO}/${GET_INFO_HISTORY_LENGTH}`];
        if (openInfo && !infoHistoryLength) {
            let params = { isBot }
            dispatch(`${INFO}/${ACT_INFO_PUSH}`, {type: openInfo.type, params}, {root: true});
        }
        dispatch(`${CONTENT_MANAGER}/${ACT_REPLACE_MAIN_TYPE}`, { type: MAIN_TYPES.CONTACT, params: {cid: contact_id} }, {root});
    },
    async searchContacts({ commit }, params) {
        let { filter } = params
        if (!filter || !filter.length || filter.length < 2) return
        const contacts = await proto.searchContacts(params)
        const globalContacts = contacts.filter(c => c.type === 'global')
        const localContacts = contacts.filter(c => c.type === 'local')
        const mergedContacts = contacts.filter(c => c.type === 'merged')
        commit('updateContacts', globalContacts)
        commit('updateLocalContacts', localContacts)
        commit('updateLocalContacts', mergedContacts)
    },
    async updateFavourites(obj, params) {
        const favourites = await proto.getFavouritesList()
        obj.commit('favourites', favourites);
    },
    async updateRoleRevision({ getters, commit }, params) {
        const revObj = await proto.getRoleRevision()
        const rev = revObj && revObj.rev
        if (getters['getRolesRevision'] < rev) commit('setRoleRevision', revObj)
    },
    async [ACT_UPDATE_CONTACTS_BIRTHDAYS]({ commit, rootGetters }) {
        const BIRTHDAY_SHOW_PERIOD = rootGetters[`${USERDATA}/${GET_BIRTHDAY_NOTIFY}`]
        let birthdaysList = await proto.getBirthdays({ interval: BIRTHDAY_SHOW_PERIOD })
        birthdaysList = birthdaysList.map(contact => {
            contact.fio['birthday'] = contact.rawBirthday
            return prepareRoleContactToProjectFormat(contact)
        })
        commit('setBirthDaysList', birthdaysList)
    },
    async actContactBirthdayInfo({ rootGetters, dispatch, commit }, cid) {
        let contact = await proto.getContact({ cid, type: "global" })
        contact.fio = fio(contact)
        contact.photo = app.store.getters['userdata/getPhotoUrlbyCid'](contact.cid)
        commit('setContactBirthdayInfo', contact)
        const openInfo = rootGetters[`${INFO}/${GET_INFO_NEED_OPEN}`];
        if (openInfo) {
            let params = {}
            params.cid = cid
            params.birthdayInfo = true
            dispatch(`${INFO}/${ACT_INFO_PUSH}`, {type: 'contact-info', params}, {root: true})
        }
    },
    async update({ getters, dispatch, rootGetters }, params) {
        await dispatch('updateFavourites')
        if (getters[GET_IS_ROLES_SUPPORTED]) {
            await dispatch('updateRoleRevision')
            await dispatch('updateContactsBirthdays')
            const favCids = getters[GET_FAVOURITES_CIDS]
            const chats = rootGetters[`${CHATS}/${GET_CHATS}`]
            const chatsCids = [...new Set(chats.filter(item => item.cid && item.cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER).map(item => item.cid))]
            chatsCids.push(systemCid)
            const supportCid = (getters[GET_SUPPORT] || {}).cid
            if (supportCid) chatsCids.push(supportCid)
            const groupChats = chats.filter(item => item.cid && item.cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP) //.map(item => item.cid))]
            let groupChatsCids = []
            groupChats.forEach(groupChat => {
                const groupChatCids = [...new Set(groupChat.contacts.map(c => c.cid))]
                groupChatsCids = [...new Set(groupChatsCids.concat(groupChatCids))]
            })
            const callsHistory = rootGetters[`calls/getCalls`]
            const callsCids = [...new Set(callsHistory.filter(item => item.cid).map(item => item.cid))]
            const connectionsCids = [...new Set(chatsCids.concat(groupChatsCids).concat(callsCids).concat(favCids))]
            await dispatch(ACT_ADD_CONTACT_CONNECTIONS, connectionsCids)
        }
        await dispatch('refreshUpdateContacts')
    },
    async changeRolesRevisionEvent({ getters, commit, dispatch }, params) {
        let { cid, rev } = params
        if (cid === getters[GET_MY_CONTACT].cid) {
            dispatch('refreshUpdateContacts')
            commit('setRoleRevision', { rev })
        } else dispatch('updateSingleContact', { cid })
    },
    async updateSingleContact({ getters, dispatch, commit }, { type, cid }) {
        let contact = {}, payload = {}
        if (type) {
            contact = await proto.getContact({cid, type})
            payload = {type, contact }
            commit('updateContact', payload)
        } else {
            contact = await proto.getContact({cid, type: 'global'})
            payload = {type: 'global', contact }
            commit('updateContact', payload)
            let local_contact = await proto.getContact({cid, type: 'local'})
            let { error } = local_contact
            if (!error) {
                payload.type = 'local'
                payload.contact = local_contact
                commit('updateContact', payload)
            }
        }
        if (cid === getters[GET_MY_CONTACT].cid) dispatch('updateMyContactRoleActionsInfo')
    },
    [ACT_ADD_CONTACT_SHORT_INFO]({ getters, dispatch, commit }, contact) {
        if (!contact || !contact.cid || (getters[`${GET_MERGED_CONTACT_BY_ID}`](contact.cid).cid) || !contact.fio) return
        const newContact = prepareRoleContactToProjectFormat(contact)
        commit('addContactShort', newContact)
    },
    async [ACT_UPDATE_SEARCHED_CONTACT]({ getters, dispatch, commit }, { type = 'global', cid }) {
        const contact = await proto.getContact({cid, type})
        prepareContactToProjectFormat(contact)
        commit('updateSearchedContact', contact)
    },
    async contactConnectionChangeEvent({ getters, commit }, params) {
        let { cid, type, contact } = params
        let existed_contact = getters[GET_CONTACT_BY_ID](cid)
        switch (type) {
            case "remove":
                if (existed_contact) commit('deleteContact', { cid, type: 'global' })
            break;
            case "add":
                if (existed_contact) commit('updateContact', { type: 'global', contact })
                else commit('addContact', { type: 'global', contact })
            break;
        }
    },
    async refreshUpdateContacts({ dispatch, commit }) {
        const globalContacts = await proto.getContacts(true)
        const localContacts = await proto.getContacts(false)
        commit('updateContacts', globalContacts)
        commit('updateLocalContacts', localContacts)
        commit('concatContactsMerged')    
        dispatch('updateMyContactRoleActionsInfo')
    },
    updateMyContactRoleActionsInfo({ getters, rootGetters, commit }) {
        const my_contact = getters[GET_MY_CONTACT]
        const { actions = {} } = my_contact
        let payload = {}, currentMsg = '', isExist = false
        Object.keys(actions).forEach(k => {
            switch (k) {
                case 'accept-call':
                    payload.type = SIDE_TYPES.CALLS_LIST
                    if (!actions[k]) {
                        if (actions['make-call']) {
                            payload.msg = locale.roles["no-accept-call"]
                            commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                        }
                    }
                    if (actions[k] && actions['make-call']) {
                        payload.msg = ''
                        commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    break
                case 'make-call':
                    if (!actions[k]) {
                        payload.type = SIDE_TYPES.CALLS_LIST
                        if (!actions['accept-call']) payload.msg = locale.roles["no-make-receive-call"]
                        else payload.msg = locale.roles["no-make-call"]
                        commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    break
                // case 'create-group':
                case 'create-chats':
                    currentMsg = rootGetters[`${CONTENT_MANAGER}/${GET_SIDE_BAR_NOTIFICATION}`](SIDE_TYPES.CHATS)
                    isExist = currentMsg.indexOf(locale.roles["no-create-group"]) > -1
                    payload.type = SIDE_TYPES.CHATS
                    if (!actions[k] && !isExist) {
                        payload.msg = currentMsg ? currentMsg + locale.roles["no-create-group"] : locale.roles["no-create-group"]
                        commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    if (actions[k] && isExist) {
                        payload.msg = locale.roles["no-create-group"]
                        commit(`${CONTENT_MANAGER}/${MUT_DELETE_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    break
                case 'send-message':
                    currentMsg = rootGetters[`${CONTENT_MANAGER}/${GET_SIDE_BAR_NOTIFICATION}`](SIDE_TYPES.CHATS)
                    isExist = currentMsg.indexOf(locale.roles["no-send-message"]) > -1
                    payload.type = SIDE_TYPES.CHATS
                    if (!actions[k] && !isExist) {
                        payload.msg = currentMsg ? currentMsg + locale.roles["no-send-message"]: locale.roles["no-send-message"]
                        commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    if (actions[k] && isExist) {
                        payload.msg = locale.roles["no-send-message"]
                        commit(`${CONTENT_MANAGER}/${MUT_DELETE_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    break
                case 'receive-message':
                    currentMsg = rootGetters[`${CONTENT_MANAGER}/${GET_SIDE_BAR_NOTIFICATION}`](SIDE_TYPES.CHATS)
                    isExist = currentMsg.indexOf(locale.roles["no-receive-message"]) > -1
                    payload.type = SIDE_TYPES.CHATS
                    if (!actions[k] && !isExist) {
                        payload.msg =  currentMsg ? currentMsg + locale.roles["no-receive-message"]: locale.roles["no-receive-message"]
                        commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    if (actions[k] && isExist) {
                        payload.msg = locale.roles["no-receive-message"]
                        commit(`${CONTENT_MANAGER}/${MUT_DELETE_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    break
                case 'send-media-message':
                    currentMsg = rootGetters[`${CONTENT_MANAGER}/${GET_SIDE_BAR_NOTIFICATION}`](SIDE_TYPES.CHATS)
                    isExist = currentMsg.indexOf(locale.roles["no-send-media-message"]) > -1
                    if (!actions[k] && !isExist) {
                        payload.type = SIDE_TYPES.CHATS
                        payload.msg = currentMsg ? currentMsg + locale.roles["no-send-media-message"] : locale.roles["no-send-media-message"]
                        commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    if (actions[k] && isExist) {
                        payload.msg = locale.roles["no-send-media-message"]
                        commit(`${CONTENT_MANAGER}/${MUT_DELETE_SIDE_BAR_NOTIFICATION}`, payload, {root: true})
                    }
                    break
            }
        })
    },
    async [ACT_LOAD_BOT_CONTACT]({ commit, getters }, cid) {
        let contact = getters[GET_MERGED_CONTACT_BY_ID](cid)
        if (!contact.cid) {
            let type = 'global'
            contact = await proto.getContact({cid, type})
            if (contact) {
                commit('addContact', {contact, type, temp: true})
            }
        }
    },
    [ACT_ADD_BOT_CONTACT]({ getters, commit }, bot) {
        let botContact = getters[GET_MERGED_CONTACT_BY_ID](bot.cid)
        if (!botContact || !(botContact && botContact.fields)) {
            botContact = prepareRoleContactToProjectFormat(bot)
            commit('addContactShort', botContact)
        }
        botContact.fields.forEach(item => {
            if(item.type === 'isBot') {
                botContact.fields.forEach( item => {
                    switch(item.type) {
                        case 'botCommands':
                            botContact.commands = item.value;
                            break;
                        case 'botDescription':
                            botContact.description = item.value;
                            break;
                        case 'botHelloDescription':
                            botContact.helloDescription = item.value;
                            break;
                        case 'botName':
                            botContact.name = item.value;
                            break;
                        case 'botTitle':
                            botContact.title = item.value;
                            break;
                    }
                })
            }
        })
        commit(`${BOTS}/${MUT_CHANGE_BOT}`, botContact, {root: true})
    },
    async [ACT_UPDATE_CONTACT_STATUS]({commit, state, getters}, params) {
        if (state.contactsStatuses && state.contactsStatuses[params] && state.contactsStatuses[params].pending) return
        commit('setContactsStatuses', {cid: params, pending: true})
        const data = await proto.getContactsStatus(params)
        data.forEach(item => {
            item['pending'] = false
            commit('setContactsStatuses', item)
        })
    },
    async [ACT_CHANGE_CONTACT]({state, commit, dispatch}, data) {
        let res = await proto.changeContact(data)
        if (data.cid) commit('updateContact', {contact: data, type: 'local'})
    },
    async [ACT_ADD_CONTACT] ({dispatch, commit, getters}, data) {
        let cid = await proto.addContact(data)
        let contact = {...{cid}, ...data}
        commit(`addContact`, {type: 'local', contact})
        dispatch('calls/updateCallsAfterContactAdded', {contact}, { root: true })

    },
    async [ACT_DELETE_CONTACT]({dispatch}, data) {
        proto.deleteContact(data)
        await dispatch('spliceFavourite', data.cid)
        dispatch('updateFavourites')
        dispatch('calls/updateCallsAfterContactDeleted', {cid: data.cid}, { root: true })
    },
    async [ACT_SEARCH_CONTACTS]({state, getters, commit}, {
        search = '',
        searchBots = false,
        filter = null,
        isLocal = false,
        type = CONTACTS_SEARCH_TYPES.VIEW_CONTACT,
        fromId = 0,
        count = 50,
        extFilter
    }) {
        let result = { contacts: [], isListPage: false, isLoadPossible: false, lastCid: 0 }
        let arrFields = ['surname', 'name', 'secondname', 'workphone', 'homephone', 'mobilephone', 'phone', 'fax', 'mail', 'nik']
        if (searchBots) arrFields.push('botTitle')
        let arrSearch = search.split(' ').filter(item => item.length > 0)

        if (arrSearch.length === 0) arrSearch[0] = ''
        let contacts = []
        if (getters[GET_IS_ROLES_SUPPORTED] && (search.length >= 2 || extFilter)) {
            let params = {
                filter: search,
                searchType: type,
                fromId,
                count,
                ...(extFilter && {extFilter})
            }
            contacts = await proto.searchContacts(params)
            contacts.forEach((contact) => prepareContactToProjectFormat(contact))
            result.isListPage = true
            result.isLoadPossible = contacts.length === count
            const lastContact = contacts[contacts.length - 1]
            if (lastContact) result.lastCid = lastContact.cid
        } else {
            contacts = isLocal ? state.localContacts : state.contactsMerged
            let filteredContacts = Object.values(contacts).filter(contact => {
                if (filter !== null) {
                    if (!filter && !search) return false
                }
                if (filter === null && !search) return true
                let filterKeys = filter && Object.keys(filter) || []
                if (!filterKeys.every((key) => contact[key] && filter[key].includes(contact[key]))) return false
                
                let fieldsContactToSearch = [];
                let arrResultsSearch = [];
                let multipleName = ['surname','name','secondname'];
                
                contact.fields.forEach(item => {
                    if (item) {
                        let res = arrFields.includes(item.type);
                        if (res && typeof item.value === 'string') {
                            let arrayWords = item.value.split(' ')
                            if (arrayWords.length > 1 && multipleName.includes(item.type)) {
                                arrayWords.forEach(word => {
                                    fieldsContactToSearch.push(word.toLowerCase())
                                })
                            }
                            fieldsContactToSearch.push(item.value.toLowerCase())
                        }
                    }
                });
                
                arrSearch.forEach(item => {
                    let resultSearch = fieldsContactToSearch.some(field => {
                        let res = field.toLowerCase().replace(/ё/g, "е").indexOf(item.toLowerCase().replace(/ё/g, "е"))
                        return res === 0;
                    });
                    arrResultsSearch.push(resultSearch);
                });
                
                if (arrResultsSearch.includes(false)) return false
                else return true
            });
            
            let arr1 = []
            let arr2 = []
            filteredContacts.forEach(contact => {
                try {
                    let res = contact.fio && contact.fio.toLowerCase().indexOf(arrSearch[0].toLowerCase())
                    if(res && res === 0) arr1.push(contact)
                    else arr2.push(contact)
                } catch (e) {
                    console.log("!! -> file: contacts.js -> contact", contact)
                    console.log("!! -> file: contacts.js -> e", e)
                }
            })
            contacts = arr1.concat(arr2);
            contacts.sort((a, b) => a.fio > b.fio ? 1 : -1)
        }
        result.contacts = contacts
        return result
    },
    async [ACT_UPDATE_SEARCH_FILTER]({state}){
        let list = await proto.getContactsOrganizationFilter()
        list.forEach((typeList) => state.filterList[typeList.type] = typeList.values.map(item => item.value))
    }
};

const mutations = {
    changeFavouritesMode: (state, mode) => {state.changeFavouritesMode = mode;},
    favourites: (state, favourites) => state.favourites = favourites,
    [MUT_SET_SELECTED_CONTACT](state, contact_id) {
        state.selected = contact_id;
    },
    setBirthdaysWatched(state) {
        state.birthdays_watched = moment().format('DD.MM.YYYY')
    },
    setBirthdaysUnwatched(state) {
        state.birthdays_watched = moment().add(-1, 'days').format('DD.MM.YYYY')
    },
    clear: (state) => {
        state.contacts = {}
        state.localContacts = {}
        state.contactsMerged = {}
    },
    setRoleRevision(state, { rev }) {
        state.rev = rev
    },
    setContactBirthdayInfo(state, contact) {
        state.birthdayContactInfo = contact
    },
    addContactBirthday(state, contact) {
        if ('cid' in getContactById(state.contactsBirthdays, contact.cid)) return
        contact.fio = fio(contact)
        contact.photo = app.store.getters['userdata/getPhotoUrlbyCid'](contact.cid)
        Vue.set(state.contactsBirthdays, contact.cid, {...contact})
    },
    addContact: (state, {contact, type, temp = false}) => {
        contact.fio = fio(contact)

        contact.fields.forEach( item => {
            if(item.type === 'isBot') {
                let obj = {temp};
                obj.cid = contact.cid;
                obj.photo =  app.store.getters['userdata/getPhotoUrlbyCid'](contact.cid);
                contact.fields.forEach( item => {
                    switch(item.type) {
                        case 'botCommands':
                            obj.commands = item.value;
                            break;
                        case 'botDescription':
                            obj.description = item.value;
                            break;
                        case 'botHelloDescription':
                            obj.helloDescription = item.value;
                            break;
                        case 'botName':
                            obj.name = item.value;
                            break;
                        case 'botTitle':
                            obj.title = item.value;
                            break;
                    }
                });
                app.store.commit(`${BOTS}/${MUT_CHANGE_BOT}`, obj);
            }
        });

        let contactMerged = null

        if (type === 'global') {
            if ('cid' in getContactById(state.contacts, contact.cid)) return
            contact.photo = app.store.getters[`userdata/${GET_PHOTO_URL_BY_CID}`](contact.cid)
            if (state.support && 'cid' in state.support && state.support.cid === contact.cid) contact.official = true
            Vue.set(state.contacts, contact.cid, {...contact})
            Vue.set(state.contactsMerged, contact.cid, {...contact})
        } else if (type === 'local') {
            if ('cid' in getContactById(state.localContacts, contact.cid)) return
            contact.photo = app.store.getters['userdata/getLocalPhotoUrl'](contact.cid)
            Vue.set(state.localContacts, contact.cid, contact)
            contactMerged = getContactById(state.contactsMerged, contact.cid)
            if ('cid' in contactMerged) {
                contactMerged.fields = mergedArrays(contactMerged.fields, contact.fields)
                contactMerged.fio = fio(contactMerged)
                contactMerged.photo = contact.photo
                prepareMergedContact(contactMerged)
            } else {
                Vue.set(state.contactsMerged, contact.cid, {...contact})
            }
        }
    },
    deleteContact: (state, {cid, type}) => {
        let contact = getContactById(state.localContacts, cid)
        let contactMerged = getContactById(state.contactsMerged, cid)
        let globalContact = getContactById(state.contacts, cid)
        
        if (contact) {
            delete state.localContacts[cid]
            if (globalContact) Vue.set(state.contactsMerged, cid, utils.cloneObject(state.contacts[cid]))
        }
        if (type === 'global') {
            if (globalContact) delete state.contacts[cid]
            if (contactMerged) delete state.contactsMerged[cid]
        }
    },
    updateContact: (state, {contact: params, type}) => {
        if(type === 'global') {
            let old_contact = getContactById(state.contacts, params.cid)
            old_contact && Vue.set(state.contacts, params.cid, params)
        } else if (type === 'local') {
            let old_contact = getContactById(state.localContacts, params.cid)
            if (old_contact) delete state.localContacts[params.cid]
            Vue.set(state.localContacts, params.cid, params)
        }
        let old_contactMerged = getContactById(state.contactsMerged, params.cid)
        let globalContact = getContactById(state.contacts, params.cid)
        let localContact = getContactById(state.localContacts, params.cid)
        
        if (!globalContact.cid) globalContact = localContact
        else if (!localContact.cid) localContact = globalContact

        let mergedContact = mergedArrays(globalContact.fields, localContact.fields)
        let contact = utils.cloneObject(globalContact)

        contact.fields = mergedContact
        contact.fio = fio(contact)
        localContact.fio = contact.fio
        if (type === 'global') contact.photo = app.store.getters['userdata/getPhotoUrlbyCid'](params.cid)
        else contact.photo = app.store.getters['userdata/getLocalPhotoUrl'](params.cid)
        localContact.photo = contact.photo

        if (old_contactMerged) {
            prepareMergedContact(contact)
            Vue.set(state.contactsMerged, params.cid, contact)
        }
    },
    concatContactsMerged: (state) => {
        let globalContacts = utils.cloneObject(state.contacts)
        let localContactsArray = utils.cloneObject(Object.values(state.localContacts))

        localContactsArray.forEach((localContact) => {
            let cid = localContact.cid
            let globalContact = globalContacts[cid]
            let mergedContact = localContact
            if (globalContact) {
                mergedContact.fields = mergedArrays(globalContact.fields, localContact.fields)
            }
            mergedContact.fio = fio(mergedContact)
            prepareMergedContact(mergedContact)
            Vue.set(state.contactsMerged, cid, mergedContact)
        })
    },
    updateContacts: (state, contacts) => {
        for (let i = 0, count = contacts.length; i < count; i++) {
            let contact = getContactById(state.contacts, contacts[i].cid),
            new_contact = contacts[i];

            new_contact.fio = fio(new_contact);
            new_contact.photo = app.store.getters['userdata/getPhotoUrlbyCid'](new_contact.cid);
            formatToApiLavelOld(new_contact);

            const payload = {type: 'global', contact: new_contact, first: true};

            if (!contact.cid) {
                mutations.addContact(state, payload);
            } else {
                mutations.updateContact(state, payload);
            }

        }
    },
    setBirthDaysList(state, contacts) {
        let cids = contacts.map(({cid}) => cid)
        Object.keys(state.birthdaysList).forEach((cid) => {
            if (!cids.includes(cid)) delete state.birthdaysList[cid]
        })
        for (let i = 0, count = contacts.length; i < count; i++) {
            let new_contact = contacts[i]
            Vue.set(state.birthdaysList, new_contact.cid, new_contact)
        }
    },
    updateLocalContacts: (state, contacts) => {
        for (let i = 0, count = contacts.length; i < count; i++) {
            let contact = getContactById(state.localContacts, contacts[i].cid),
            new_contact = contacts[i];

            formatToApiLavelOld(new_contact);
            new_contact.fio = fio(new_contact);
            new_contact.photo = app.store.getters['userdata/getLocalPhotoUrl'](new_contact.cid);

            const actions = { 'accept-call': true, 'create-chats': true, 'make-call': true, 'receive-message': true,
                            'search-contacts': true, 'send-media-message': true, 'send-message': true, 'use-radio': true}
            new_contact.actions = actions

            const payload = {type: 'local', contact: new_contact, first: true};

            if (!contact.cid) {
                mutations.addContact(state, payload);
            } else {
                mutations.updateContact(state, payload);
            }
        }
    }, 
    updateContactActions: (state, payload) => {
        let { cid, actions } = payload
        const contact = getContactById(state.contacts, cid)
        if (contact) contact.actions = actions
    },
    setContactsStatuses: function (state, params) {
        let { cid, status = {}, pending } = params
        let { statusTime = -1 } = status
        if (!state.contactsStatuses[cid]) Vue.set(state.contactsStatuses, cid,  {})
        let contactStatus = state.contactsStatuses[cid]
        Vue.set(contactStatus, 'statusTime',  statusTime)
        if (pending) {
            Vue.set(contactStatus, 'pending',  pending)
            setTimeout(() => Vue.delete(contactStatus, 'pending'), 500)
        }
    },
    updateContactStatus: function (state, params) {
        state.contacts_status = params;
    },
    [MUT_SET_SUPPORT](state, data) {
        if(data && 'cid' in data) data.cid = Number(data.cid);
        state.support = data;
    },
    addContactShort(state, contact) {
        Vue.set(state.shortList, contact.cid, contact)
    },
    updateSearchedContact(state, contact) {
        Vue.set(state.searchedList, contact.cid, contact)
    },
};

function getContactById(contacts, cid) {
    let result = contacts[cid]
    if (!result) {
        result = {
            fio: locale['search-comp']['unknown-contact'],
            fields: []
        };
    }
    return result;
}

function prepareMergedContact(contact) {
    try {
        let contactMergedFormat = {fields: contact.fields}
        formatToApiLavelOld(contactMergedFormat)
        Object.assign(contact, contactMergedFormat)
    } catch (e) {
        console.error('prepareMergedContact error', e);
    }
}

function getChangedFields(globalFields, mergedFields) {
    return mergedFields.filter(mf => {
        return !globalFields.some(gf => {
            return mf.type === gf.type || mf.value === gf.value
        })
    })
}

function formatToApiLavelOld(contact) {
    let contactFieldTypes = {
        SURNAME: 'surname',
        NAME: 'name',
        SECONDNAME: 'secondname',
        ORGANIZATION: 'organization',
        OFFICE: 'office',
        POST: 'post',
        ROOM: 'room',
        NIK: 'nik',
        LEARDER: 'leader',
        HELPER: 'helper',
        WORKSTART: 'workstart',
        WORKFINISH: 'workfinish',
        WORKPHONE: 'workphones',
        HOMEPHONE: 'homephones',
        MOBILEPHONE: 'mobilephones',
        PHONE: 'phones',
        FAX: 'faxes',
        MAIL: 'mails',
        ICQ: 'icqs',
        SITE: 'sites',
        WORKADDRESS: 'workaddresses',
        HOMEADDRESS: 'homeaddresses',
        ADDRESS: 'addresses',
        BIRTHDAY: 'birthday',
        PARTNER: 'partner',
        CHILDREN: 'children',
        NOTE: 'note'
    };
    let multiple = [
        contactFieldTypes.WORKPHONE,
        contactFieldTypes.HOMEPHONE,
        contactFieldTypes.MOBILEPHONE,
        contactFieldTypes.PHONE,
        contactFieldTypes.FAX,
        contactFieldTypes.MAIL,
        contactFieldTypes.ICQ,
        contactFieldTypes.SITE,
        contactFieldTypes.WORKADDRESS,
        contactFieldTypes.HOMEADDRESS,
        contactFieldTypes.ADDRESS
    ];

    (contact.fields || []).forEach(function(field) {
        if(field.type) {
            let _type = field.type.toUpperCase();
            let old_type = contactFieldTypes[_type];
            if (!contactFieldTypes.hasOwnProperty(_type)) return;
            if (multiple.indexOf(old_type) === -1) {
                contact[old_type] = field.value;
            } else {
                if (!contact[old_type]) contact[old_type] = [];
                contact[old_type].push(field.value)
            }
        }
    });
}

function mergedArrays(array1, array2) {
    let arr1 = Array.isArray(array1) && utils.cloneObject(array1);
    let arr2 = Array.isArray(array2) && utils.cloneObject(array2);

    let arr3 = [];
    let multiple = ['workphone','homephone','mobilephone','phone','faxe','mail','icq','site','workaddress','homeaddress','address','children'];

    if(!arr1 || arr1.length === 0) {
        arr3 = arr2;
    } else if(!arr2 || arr2.length === 0) {
        arr3 = arr1;
    } else {
        for(let i = 0; i < arr1.length; i++) {
            arr3.push(arr1[i]);
        }

        for(let i = 0; i < arr2.length; i++) {
            let type2 = arr2[i].type;
            let value2 = arr2[i].value;
            let name2 = arr2[i].name;
            let exist = false;

            if(multiple.indexOf(type2) > -1) {
                for(let i = 0; i < arr3.length; i++) {
                    if(arr3[i].type === type2 && arr3[i].name === name2 && arr3[i].value === value2) {
                        exist = true;
                        break;
                    }
                }
                if(!exist) arr3.push(arr2[i]);
            } else {
                for(let i = 0; i < arr3.length; i++) {
                    if(arr3[i].type === type2) {
                        arr3[i].value = value2
                        exist = true;
                        break;
                    }
                }
                if(!exist) arr3.push(arr2[i]);
            }
        }
    }
    return arr3;
}

function fio(contact) {
    let fio;
    for(let i = 0; contact.fields.length > i; i++) {
        if(contact.fields[i].type === 'isBot') contact.isBot = contact.fields[i].value;
        if (contact.fields[i].type === 'botTitle') contact.botTitle = contact.fields[i].value;
    }
    if(contact.isBot) {
        fio = contact.botTitle;
    } else {
        let surname = getSingleFieldValue(contact, 'surname')
        let name = getSingleFieldValue(contact, 'name')
        let nik = getSingleFieldValue(contact, 'nik')
        let fields = contact.fields
        const phones = fields && fields.filter(f => f.type === 'phone' || f.type === 'workphone')
        const phone = phones && phones.length && phones[0].value
        const mails = fields && fields.filter(f => f.type === 'mail')
        const mail = mails && mails.length && mails[0].value

        if (!surname && !name) {
                fio = nik || phone || mail || ''
                let isExisted = contact.cid && getContactById(state.contacts, contact.cid)
                if (!fio.trim().length) {
                    if (isExisted) fio = locale['search-comp']['noname-contact']
                    else fio = locale['search-comp']['unknown-contact']
                }
        } else {
            fio = getInitials(surname, name)
        }
    }
    return fio
}

function getInitials(surname, name) {
    return [surname, name].map(cur => {
        if (cur) return cur.trim()
    }).filter(cur => !!cur).join(' ');
}

function getSingleFieldValue (contact, fieldType) {
    return ((contact && contact.fields || []).find((field) => field.type === fieldType) || {}).value
}

function sortArray(contacts) {
    let resault = contacts.sort(function(a, b) {
        if (a.fio === b.fio) return 0;
        if (a.fio < b.fio) return -1;
        if (a.fio > b.fio) return 1;
    });
    return resault;
}

function prepareRoleContactToProjectFormat(roleContact) {
    const contact = {
        fields: Object.entries(roleContact.fio).map(([key, val]) => ({type: key, value: val})),
        cid: roleContact.cid
    }
    prepareContactToProjectFormat(contact)
    return contact
}

function prepareContactToProjectFormat(new_contact) {
    formatToApiLavelOld(new_contact)
    new_contact.fio = fio(new_contact)
    new_contact.photo = app.store.getters['userdata/getLocalPhotoUrl'](new_contact.cid)
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}