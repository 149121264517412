//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import RecycleContactsList from "../components/modal/RecycleContactsList.vue"
import ContactsListItem from "../components/listItems/ContactsListItem.vue"
import {CONTACTS} from "../store/modulesNames";
import {ACT_SEARCH_CONTACTS} from '../store/actionsTypes'

export default {
    name: 'search-contacts',
    components: {RecycleContactsList, ContactsListItem},
    props: ['search'],
    data() {
        return {
            defaultHeightSearch: 42,
            heightSearch: 42,
            listHeight: 71
        }
    },
    watch: {
        contacts() {
            if(this.search.length < 2) this.heightSearch = 42;
            this.setHeightSearch(this.contacts);
        },
        search() {
            if (this.$refs.rcl && this.$refs.rcl.$el) {
                if (this.contacts.length < 4)
                    this.$refs.rcl.$el.children[0].style.overflowY = "hidden";
                else
                    this.$refs.rcl.$el.children[0].style.overflowY = "auto";
            }
        }
    },
    asyncComputed: {
        async contacts() {
            let contacts = []
            try {
                const searchResult = await app.store.dispatch(`${CONTACTS}/${ACT_SEARCH_CONTACTS}`, {search: this.searchVal})
                contacts = searchResult.contacts
            } catch (e) {}
            return contacts
        }
    },
    computed: {
        searchVal() {
            return this.search.slice(1).toLowerCase().split(/\s(.+)/)[0];
        },
        /*contacts() {
            return app.store.getters['contacts/filtered'](this.searchVal) || []
        },*/
    },
    methods: {
        addContact(contact) {
            this.$emit('contact', contact);
        },
        setHeightSearch(contacts) {
            if(this.search.length >= 2) {
                switch (contacts.length) {
                    case 0:
                        this.heightSearch = this.defaultHeightSearch;
                        break;
                    case 1:
                        this.heightSearch = this.listHeight * contacts.length;
                        break;
                    case 2:
                        this.heightSearch = this.listHeight * contacts.length;
                        break;
                    case 3:
                        this.heightSearch = this.listHeight * contacts.length;
                        break;
                    default:
                        this.heightSearch = this.listHeight * 4;
                        break;
                }
            }
        }
    }
}
