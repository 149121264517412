//
//
//
//
//
//

import {mapGetters, mapMutations} from 'vuex';
import {INFO, CONTENT_MANAGER} from "../../store/modulesNames";
import {GET_INFO_OPEN, GET_MAIN_TYPE, GET_IS_FIRST_TIME_OPENED} from "../../store/gettersTypes";
import {MUT_INFO_CLEAR} from "../../store/mutationsTypes";
import {MAIN_TYPES} from "../../store/modules/content-manager";
import InfoWrapper from './InfoWrapper.vue';

export default {
    name: "Info",
    components: {InfoWrapper},
    data() {
        return {
            transitionName: 'none',
        }
    },
    computed: {
        ...mapGetters(INFO, {currentInfo: GET_INFO_OPEN, GET_IS_FIRST_TIME_OPENED}),
        ...mapGetters(CONTENT_MANAGER, [GET_MAIN_TYPE]),
        getTransitionName() {
            let firstTime = this.GET_IS_FIRST_TIME_OPENED
            if (!firstTime) this.$nextTick(()=> this.transitionName = 'info-fade') 
            return this.transitionName
        }
    },
    methods: {
        ...mapMutations(INFO, [MUT_INFO_CLEAR]),
    },
    watch: {
        // currentInfo(val) {
        //     console.log("!! -> file: Info.vue -> line 37 -> currentInfo -> val", val)
        // },
        [GET_MAIN_TYPE](type) {
            // Закрыть без сохранения если не контакт, чат или канал
            if (![MAIN_TYPES.CHANNEL, MAIN_TYPES.CHAT, MAIN_TYPES.CONTACT, MAIN_TYPES.NOTE].includes(type)) this[MUT_INFO_CLEAR]()
        }
    }
}
