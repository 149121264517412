//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import modal_mixin from "./modal-mixin"
import { mapGetters, mapActions } from 'vuex'
import { RADIO, USERDATA } from '../../store/modulesNames'
import {
    GET_RADIO_SETTINGS,
    IS_MICROPHONE_ENABLED,
} from '../../store/gettersTypes'
import {
    ACT_SET_RADIO_SETTINGS,
} from '../../store/actionsTypes'
import CustomRadio from "../custom/CustomRadio.vue"
import { PTT_BTN_MODES } from '../../store/modules/radio'
import CustomCheckboxSlider from "../custom/CustomCheckboxSlider.vue"

export default {
    name: "RadioSettings",
    mixins: [modal_mixin],
    components: { CustomCheckboxSlider, CustomRadio },
    mounted() {
        this.settings = utils.cloneObject(this[GET_RADIO_SETTINGS])
    },
    data() {
        return {
            title: this.$t('radio-settings'),
            compModalProps: {
                delay: 200,
                pivotY: 0.05,
                width: 450,
                height: 'auto',
            },
            settings: {},
            newSettings: {},
            pttBtnModes: [
                {
                    value: PTT_BTN_MODES.DEFAULT,
                    label: this.$t('radio-settings-ptt-mode-default'),
                }, {
                    value: PTT_BTN_MODES.SWITCH,
                    label: this.$t('radio-settings-ptt-mode-switch'),
                }, {
                    value: PTT_BTN_MODES.NONE,
                    label: this.$t('radio-settings-ptt-mode-none')
                }
            ],
        }
    },
    computed: {
        pttBtnMode() {
            return this.newSettings.pttBtnMode
        },
        signalsSound() {
            return this.newSettings.signalsSound
        },
        ...mapGetters(RADIO, [GET_RADIO_SETTINGS]),
        ...mapGetters(USERDATA, { isMicEnabled: IS_MICROPHONE_ENABLED }),
    },
    methods: {
        ...mapActions(RADIO, [ACT_SET_RADIO_SETTINGS])
    },
    watch: {
        pttBtnMode(val) {
            if (this.isMicEnabled) this[ACT_SET_RADIO_SETTINGS]({prop: 'pttBtnMode', value: val})
        },
        signalsSound(val) {
            this[ACT_SET_RADIO_SETTINGS]({prop: 'signalsSound', value: val})
        },
    },
}
