import ipc from "../../../electron/ipc"
import { get, set, createStore } from "idb-keyval"
const customStore = createStore("roschat", "roschat-store")

export const getIDB = (key) => { return get(key, customStore)}
export const setIDB = (key, value) => { set(key, value, customStore)}

import moment from 'moment'
import { AJAX, BLOB, DLP, CHATS, CONTACTS } from '../../store/modulesNames'
import {
    ACT_ABORT_AJAX_CALL,
    ACT_AJAX_GET_FILE,
    ACT_SEND_FILE_SAVE_EVENT,
} from '../../store/actionsTypes'
import { DLP_SOURCE_TYPES } from '../../store/modules/dlp'
import {
    GET_AJAXX_CALL_IS_EXIST_BY_FILE,
    GET_AJAXX_CALL_PROGRESS_BY_FILE,
    GET_FILE_BLOB,
    GET_SELECTED_CHAT, 
    GET_MY_CONTACT, 
    GET_MERGED_CONTACT_BY_ID, 
    GET_IS_ROLES_SUPPORTED,
} from '../../store/gettersTypes'
import { mapGetters, mapActions } from 'vuex'

import Logger from '../../common/Logger'
const logger = new Logger('chat-message-mixin')

const mixin = {
    computed: {
        xhrFile() { return this.message && this.message.msg && this.message.msg.file },
        xhrId() { return this.xhrFile && this[GET_AJAXX_CALL_IS_EXIST_BY_FILE](this.xhrFile) },
        isPending() { return this[GET_AJAXX_CALL_IS_EXIST_BY_FILE](this.xhrFile) },
        loadProgress() { return this[GET_AJAXX_CALL_PROGRESS_BY_FILE](this.xhrFile) },
        link() { return this[GET_FILE_BLOB](this.xhrFile) },
        isRolesModelSupported() {
            return this[GET_IS_ROLES_SUPPORTED]
        },
        myContactActions() {
            return this[GET_MY_CONTACT].actions || []
        },
        getChatContact() {
            const cid = this[GET_SELECTED_CHAT] && this[GET_SELECTED_CHAT].cid
            if (!cid) return {}
            return this[GET_MERGED_CONTACT_BY_ID](cid)
        },
        getAllowedActions() {
            return this.getChatContact && this.getChatContact.actions || {}
        },
        isSendMediaMessage() {
            if (!this.isRolesModelSupported) return true
            let isAllowed = true
            if (this.myContactActions.hasOwnProperty('send-media-message')) isAllowed = this.myContactActions['send-media-message']
            if (this.cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP) return isAllowed
            if (this.getAllowedActions.hasOwnProperty('send-media-message')) isAllowed = isAllowed && this.getAllowedActions['send-media-message']
            return isAllowed
        },
        ...mapGetters(AJAX, [GET_AJAXX_CALL_IS_EXIST_BY_FILE, GET_AJAXX_CALL_PROGRESS_BY_FILE]),
        ...mapGetters(BLOB, [GET_FILE_BLOB]),
        ...mapGetters(CHATS, [GET_SELECTED_CHAT]),
        ...mapGetters(CONTACTS, [GET_MY_CONTACT, GET_MERGED_CONTACT_BY_ID, GET_IS_ROLES_SUPPORTED]),
    },
    methods: {
        abortAjaxCall() {
            if (this.xhrId) this[ACT_ABORT_AJAX_CALL](this.xhrId)
        },
        withPreview(type) {
            return ['video', 'image'].includes(type)
        },
        async getPreview(msg) {
            const storedPreview = await getIDB(msg.previewFile)
            if (storedPreview) {
                window.URL.revokeObjectURL(storedPreview)
                return window.URL.createObjectURL(storedPreview)
            }
            if (msg.previewFile) {
                let preview = this.$store.getters['userdata/getHost'] + '/' + declarations.http_post_dirs.CHAT_DATA_DIR + msg.previewFile
                const blobFile = await this.getBlobFromURL(preview)
                await setIDB(msg.previewFile, blobFile)
                return preview
            }
            else if (msg.preview) return 'data:image/png;base64,' + msg.preview
        },
        async getGifPreview(msg) {
            const storedPreview = await getIDB(msg.file)
            if (storedPreview) {
                window.URL.revokeObjectURL(storedPreview)
                return window.URL.createObjectURL(storedPreview)
            }
            if (msg.previewFile) {
                let preview = this.$store.getters['userdata/getHost'] + '/' + msg.file
                const blobFile = await this.getBlobFromURL(preview)
                await setIDB(msg.file, blobFile)
                return preview
            }
        },
        getDownloadFileName(type, name, extension) {
            let filename = name + (extension ? '.' + extension : '')
            let date = moment().format('YYYY-MM-DD_HH-MM-SS')
             if (!name || !filename) filename = (type + '_' + date) + (extension ? '.' + extension : '')
            return filename
        },
        async downloadFile(message, isSaveFile = false) {
            let link = this.$store.getters['blob/getFileBlob'](message.msg.file)
            let fileName = this.getDownloadFileName(message.sub_type, message.msg.name, message.msg.extension)
            fileName = fileName && fileName.replace(/:/g,'_') // fix file name for Windows
        // #if process.env.WEBPACK_BUILD_TARGET === 'web'
            let a = document.createElement('a')
            a.setAttribute('href', link)
            a.setAttribute('download', fileName)
            let evt = document.createEvent('MouseEvents')
            evt.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
            a.dispatchEvent(evt)
        // #endif

        // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//             let params = {}
//             const uid = link && link.replace('blob:file:///', '')
//             let fileKey
//             if (uid) fileKey = 'FilePath:' + uid + fileName
//             else fileKey = 'FilePath:' + fileName
// 
//             let storedPath
//             try {
//                 storedPath = await getIDB(fileKey)
//             } catch {}
//             if (storedPath && !isSaveFile) {
//                 params.fpath = storedPath
//                 try {
//                     let openedFilePath = await ipc.sendAsync('open-file', params)
//                     await setIDB(fileKey, openedFilePath)
//                     return true
//                 } catch (e) {
//                     logger.error(e, 'open-file-path error')
//                 }
//             }
//             let fileBlob = await this.getBlobFromURL(link)
//             const reader = new FileReader()
//             reader.onload = async () => {
//                 if (reader.readyState == 2) {
//                     let buffer = new Buffer(reader.result)
//                     params = { fileName, buffer }
//                     try {
//                         const savedFilePath = await ipc.sendAsync('download-file', params)
//                         if (savedFilePath) await setIDB(fileKey, savedFilePath)
//                     } catch (e) {
//                         logger.error(e, 'download-file error')
//                     }
//                 }
//             }
//             try {
//                 await reader.readAsArrayBuffer(fileBlob)
//             } catch (e) {
//                 logger.error(e, 'File read error')
//             }
        // #endif
            this.$store.dispatch(`${DLP}/${ACT_SEND_FILE_SAVE_EVENT}`, {
                fileName:  message.msg.file,
                sourceType: DLP_SOURCE_TYPES.CHAT_MESSAGE,
                sourceData: {
                    id: message.id,
                    cid: message.cid,
                    cidType: message.cidType,
                }
            })
        },
        getSlicedText(dom) {
            let path = [dom.body.childNodes[0]]
            let maxChars = this.maxCharsWithShortMessage
            let chars = 0
            let pop = false
            let full = false
            while (path.length) {
                let node = path[path.length - 1]
                if (!pop && !full) {
                    if (node.nodeType === Node.TEXT_NODE) {
                        let len = node.textContent.length
                        if (chars + len >= maxChars) {
                            if (chars + len !== maxChars) {
                                let sliceVal = node.textContent.slice(0, maxChars - chars)
                                node.textContent = sliceVal
                            }
                            full = true
                        }
                        chars += node.textContent.length
                    } else if (node.classList[0] === 'emoji') {
                        chars += 1
                        if (chars >= maxChars) full = true
                    } else if (node.nodeType === 1) {
                        let childs = node.childNodes
                        let textChildNode = childs[0] && childs[0].nodeType === Node.TEXT_NODE ? childs[0] : null
                        if (textChildNode) {
                            let len = textChildNode.textContent.length
                            if (chars + len >= maxChars) {
                                if (chars + len !== maxChars) {
                                    let sliceVal = textChildNode.textContent.slice(0, maxChars - chars)
                                    textChildNode.textContent = sliceVal
                                }
                                chars += len
                                full = true
                            }
                        }
                        if (chars >= maxChars) full = true
                    }
                }
                if (!pop && node.childNodes.length) {
                    let child = node.childNodes[0]
                    path.push(child)
                } else if (node.nextSibling) {
                    let child = node.nextSibling
                    if (full) {
                        child.remove()
                    } else {
                        path.splice(path.length - 1, 1, child)
                    }
                } else {
                    path.pop()
                    pop = true
                    continue
                }
                pop = false
            }
            return dom.body.innerHTML
        },
        async loadMessageFile (params, cb) {
            let blob
            if (this.$store.getters['blob/getFileBlob'](params.file)) return cb && cb()
            try {
                blob = await getIDB(params.file)
            } catch {}
            if (blob) {
                window.URL.revokeObjectURL(blob)
                const blob_url = window.URL.createObjectURL(blob)
                this.$store.commit('blob/updateFileBlob', {
                    file_name: params.file,
                    link: blob_url
                })
            } else {
                try {
                    blob = await this[ACT_AJAX_GET_FILE]({url: params.file, fileSize: params.size, encKey: params.key, link: true})
                    setIDB(params.file, blob)
                } catch (e) {
                    logger.info('get file error')
                }
            }
            cb && cb()
        },
        async getBlobFromURL(url) {
            let blob
            await fetch(url).then(res => blob = res.blob())
            return blob
        },
        async saveFileBlobAtIDB(fileName, blob) {
            await setIDB(fileName, blob)
        },
        ...mapActions(AJAX, [ACT_AJAX_GET_FILE, ACT_ABORT_AJAX_CALL])
    }
}

export default mixin