//
//
//
//
//
//
//
//
//
//

 
import ListItemMixin from './ListItemMixin';

export default {
    name: 'NameListItem',
    mixins: [ListItemMixin],
    props: {
        contact: {
            type: String,
            required: true
        },
        marked: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
