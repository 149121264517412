/** Chat **/
export const CHAT_TYPES = {
    USER:   'user',
    GROUP:  'group',
}

export const SELECTED_MSG_TYPES = {
    EDITED: 'edited',
    REPLY:  'reply',
}

/** Phone **/
export const PHONE_TYPES = {
    PHONE:      'phone',
    CONFERENCE: 'conf',
    ASSISTANT:  'assistant',
}

export const ASSISTANT_TYPES = {
    SIP_BOOSTER: 'sipBooster',
    DX_BOOSTER_PULT: 'dxBoosterPult',
    MX_BOOSTER_PULT: 'mxBoosterPult',
    DX_BOOSTER_ANALOG: 'dxBoosterAnalog',
    MX_BOOSTER_ANALOG: 'mxBoosterAnalog',
}

export const ANSWERED_CALL_STATUSES = {
    CREATED:        'created',
    CONNECTING:     'connecting',
    CONNECTED:      'connected',
    TALK:           'talk',
    HOLD:           'hold',
    DISCONNECTED:   'disconnected',
}

/** Conference **/
export const CONFERENCE_MEMBERS_MAX = 6

/** HotKeys **/
export const HOTKEYS_ACTION_GLOBAL = {
    SHOW_HIDE:              0,
    CALL_CLIPBOARD_NUMBER:  1,
}

export const HOTKEYS_ACTION_LOCAL = {
    SEARCH:                 0,
}

export const HOTKEYS_USER_SETTINGS_DEFAULT = [
    {
        id: HOTKEYS_ACTION_GLOBAL.SHOW_HIDE,
        global: true,
        title: "",
        hotKeysStr: "Ctrl + Shift + O",
        electronStr: "CmdOrCtrl+Shift+O",
    },
    {
        id: HOTKEYS_ACTION_GLOBAL.CALL_CLIPBOARD_NUMBER,
        global: true,
        title: "",
        hotKeysStr: "Ctrl + Shift + R",
        electronStr: "CmdOrCtrl+Shift+R",
    },
    {
        id: HOTKEYS_ACTION_LOCAL.SEARCH,
        local: true,
        title: "",
        hotKeysStr: "CtrlLeft + ShiftLeft + S",
        electronStr: "CtrlLeft+ShiftLeft+S",
    },
]

/** Radio Support **/
export const SUPPORT_GROUP_CID = 0
// export const CHECK_CALL_CID = 2523

/** Video Conf **/
export const CONF_TYPES = {
    VM:         'vm',
    ROSCHAT:    'roschat'
}

export const MEDIA_DEVICES = {
    AUDIO_INPUT:    'audioinput',
    AUDIO_OUTPUT:   'audiooutput',
    VIDEO_INPUT:    'videoinput',
}

export const CONFERENCE_SCHEMAS = {
    PUZZLE:     'puzzle',
    TRIBUNE:    'tribune',
    SHARE:      'share',
    MY_SHARE:   'my-share'
}

/** Text Formatting **/
export const ENTITIES = {
    BOLD:   'bold',
    ITALIC:   'italic',
    UNDERLINE:   'underline',
    STRIKE:   'strikethrough',
    CONTACT:   'contact',
    BOT:   'bot',
    CHANNEL:   'channel',
    PUBLICATION:   'publication',
    BOTCOMMAND: 'bot-command',
    TEXTBOTCOMMAND: 'textBotCommand',
    URL:     'url',
    TEXTURL: 'textUrl',
    PHONE:    'phone',
    MAIL:      'mail',
}

export const ENTITY_TAGS = [
    { name: ENTITIES.BOLD, open: '<b>', close: '</b>', rcs: '**' },
    { name: ENTITIES.ITALIC, open: '<i>', close: '</i>', rcs: '__' },
    { name: ENTITIES.UNDERLINE, open: '<u>', close: '</u>', rcs: '++' },
    { name: ENTITIES.STRIKE, open: '<s>', close: '</s>', rcs: '~~' },
]

export const CONTACTS_SEARCH_TYPES = {
    MAKE_CALL: 'make-call',
    SEND_MESSAGE: 'send-message',
    VIEW_CONTACT: 'view-contact',
    SEND_MEDIA_MESSAGE: 'send-media-message',
}

export const INTEGRATION_NAMES = {
    MS_EXCHANGE: 'exchange'
}

export const ACS_TYPES = {
    OM: 'om',
    ORION: 'orion',
    PERCO: 'perco',
    NONE: 'none',
}

export const USER_PARAMS_PROPS = {
    HTTP_SERVER: 'httpServer',
    STUN_SERVER: 'stunServer',
    ACS_TYPE: 'acsType',
    ACS_ROLE: 'acsRole',
    PROFILE_REV: 'profileRev',
    LOCATIONS_REV: 'locationsRev',
    SUPPORT: 'support',
    DISABLE_DIRECT_CALL: 'disableDirectCall',
    VIDEOMOST_URL: 'videomostUrl',
    SERVICES: 'services',
    ECHO_CONTACT: 'echoContact',
    RADIO_SERVER: 'radioServer',
    RADIOS_ERVER_V4: 'radioServerV4',
    DLP_TYPE: 'dlpType',
    SCHEDULE_TYPES: 'scheduleTypes',
}