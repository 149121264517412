var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "div" }, [
    _vm.message.author
      ? _c("div", { staticClass: "forward" }, [
          _c("span", { staticClass: "fwd title" }, [
            _c("i", { staticClass: "fas fa-share" }),
            _c("p", [_vm._v(_vm._s(_vm.$t("chat.fwd-message")))]),
          ]),
          _vm.fioById(_vm.message.author)
            ? _c("span", { staticClass: "fwd author" }, [
                _vm._v(
                  _vm._s(_vm.$t("chat.author")) +
                    ": " +
                    _vm._s(_vm.fioById(_vm.message.author))
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _c("div", { staticClass: "chat-document-file-message" }, [
      !_vm.link && !_vm.isPending
        ? _c(
            "div",
            {
              staticClass: "document-file-load-btn message-btn",
              on: {
                click: _vm.doLoadDocumentFile,
                contextmenu: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _vm.readOnly
                ? _c("i", { staticClass: "fal fa-lock" })
                : _c("i", { staticClass: "fa fa-long-arrow-down" }),
            ]
          )
        : _vm.isPending
        ? _c(
            "div",
            {
              staticClass: "upload-abort-btn message-btn",
              on: { click: _vm.abortAjaxCall },
            },
            [
              _c("progress-circle", {
                key: _vm.message.id || _vm.message.chId,
                attrs: {
                  load_progress: _vm.loadProgress,
                  radius: 16,
                  width: 40,
                  height: 40,
                },
              }),
              _c("i", { staticClass: "fa fa-ban" }),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "document-file-download-btn message-btn",
              on: {
                click: _vm.doLoadDocumentFile,
                contextmenu: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [_c("i", { staticClass: "fa fa-file" })]
          ),
      _c("div", { staticClass: "document-file-name" }, [
        _c("div", { staticClass: "file-name" }, [
          _vm._v(_vm._s(_vm.msg.name) + "." + _vm._s(_vm.msg.extension)),
        ]),
        _c("div", { staticClass: "file-properties" }, [
          _vm.showSize
            ? _c("div", { staticClass: "file-size" }, [
                _vm._v(" " + _vm._s(_vm.prettySize)),
              ])
            : _vm._e(),
          _vm.showFullDate
            ? _c("div", { staticClass: "file-date" }, [
                _vm._v(
                  _vm._s(_vm.message.date) + " " + _vm._s(_vm.message.time)
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("a", { staticStyle: { display: "none" } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }