//
//
//
//
//
//
//
//
//
//

import ModalDialog from '../ModalDialog.vue'
import modal_mixin from '../modal-mixin'
import CustomDropDown from "../../custom/CustomDropDown.vue"
import ModalBtn from "../ModalBtn.vue"
import MsExchangeSettings from './MSExchangeSettings.vue'

import { INTEGRATION_NAMES } from '../../../constants'

export default {
    name: "AddIntegration",
    components: { ModalDialog, CustomDropDown, ModalBtn, MsExchangeSettings },
    mixins: [modal_mixin],
    data() {
        return {
            compModalProps: {
                pivotY: 0.38,
                pivotX: 0.72,
                width: 360,
                height: 360,
                clickToClose: false,
            },
            cancelChange: false,
            integrationsList: [
                { value: 0, name: 'Не выбрано', icon: "", selected: false },
                { value: 1, name: INTEGRATION_NAMES.MS_EXCHANGE.toUpperCase(), icon: "img/MS_Exchange.png", selected: false },
                // { value: 2, name: 'Test Integration', icon: "img/MS_Exchange.png", selected: false }
            ],
            selected: { value: '', name: 'Не выбрано' },
            selectedVal: 0,
        }
    },
    computed: {
        isAddBtnDisabled() {
            return !this.selectedVal
        }
    },
    methods: {
        addBtn() {
            this.modalClose()
            this.modalOpen({
                component: MsExchangeSettings,
                props: {}
            })
        },
        cancelBtn() {
            this.cancelChange = true
            this.modalClose()
        },
        selectIntegration(val) {
            this.selectedVal = val
        }
    },
}
