
import { mapGetters } from 'vuex'
import MediaViewer from './MediaViewer.vue'
import ChatMessageMixin from '../chat/chat-message-mixin'

import {BLOB, CHAT, CONTACTS, DLP} from '../../store/modulesNames'
import {ACT_SEND_FILE_SAVE_EVENT} from '../../store/actionsTypes'
import {DLP_SOURCE_TYPES} from '../../store/modules/dlp'
import {
    GET_FILE_BLOB,
    GET_MERGED_CONTACT_BY_ID,
    GET_MESSAGE_BY_ID,
} from '../../store/gettersTypes'

export default {
    name: 'ChatMessageMediaViewer',
    props: {
        src: {
            type: Object,
            required: true
        },
        message: {
            type: Object,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        readOnly: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    mixins: [ChatMessageMixin],
    extends: MediaViewer,
    async created() {
        await this.loadMessageFile({id: this.message.id, file: this.message.msg.file, size: this.message.msg.size})
        this.preload = false
    },
    asyncComputed: {
        preview() {
            if (this.type === 'pdf') return ''
            return this.getPreview(this.message.msg)
        },
    },
    computed: {
        user() {
            return this[GET_MERGED_CONTACT_BY_ID](this.message.senderId)
        },
        path() {
            return this[GET_FILE_BLOB](this.src.file)
        },
        time() {
            let msg = this[GET_MESSAGE_BY_ID](this.message.id)
            let time = msg ? msg.time : this.message.time
            return time && this.getTimeFromSecondsLeft(time) || ''
        },
        extension() {
            return this.src.extension
        },
        subtitle() {
            if (this.message && this.message.msg && this.message.msg.text) return this.message.msg.text
        },
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
        ...mapGetters(CHAT, [GET_MESSAGE_BY_ID]),
        ...mapGetters(BLOB, [GET_FILE_BLOB]),
    },
    methods: {
        onItemSaved() {
            this.$store.dispatch(`${DLP}/${ACT_SEND_FILE_SAVE_EVENT}`, {
                fileName:  this.message.msg.file,
                sourceType: DLP_SOURCE_TYPES.CHAT_MESSAGE,
                sourceData: {
                    id: this.message.id,
                    cid: this.message.cid,
                    cidType: this.message.cidType,
                }
            })
        },
    },
    // destroyed() {
    //     if (this.blob_url) window.URL.revokeObjectURL(this.blob_url)
    //     console.log("!! -> file: ChatMessageMediaViewer.vue -> line 91 -> destroyed -> this.blob_url", this.blob_url)
    // }
}
