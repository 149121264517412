import proto from '../../protocol'
import radioproto from '../../radioprotocol'
import store from './store'
// #if process.env.WEBPACK_BUILD_TARGET === 'electron'
// import ipc from '../../../electron/ipc'
// #endif
import { i18n } from '../../../ext/i18n'
import {
    GET_CONTACT_BY_ID,
    GET_LOCAL_CONTACT_BY_ID,
    GET_MERGED_CONTACT_BY_ID,
    GET_TYPE_ASISTANT,
    GET_ANSWERED_CALLS,
    GET_MERGED_CONTACT_BY_EMAIL,
    GET_ROSCHAT_SERVER,
    GET_IS_ELECTRON,
    GET_SELECTED_CHANNEL,
    GET_IS_ROLES_SUPPORTED,
} from '../gettersTypes'
import {
    ACT_HANDLE_TOKENS_EVENT,
    ACT_BOT_REMOVE,
    ACT_HANDLE_CHANNEL_CHANGE_EVENT,
    ACT_TETRA_HANDLE_STATUS_EVENT,
    ACT_TETRA_HANDLE_MSG_EVENT,
    ACT_SAVE_REMOTE_PROCESSING,
    ACT_TERMINATION_EVENT,
    ACT_SEND_OFFER_CONFERENCE,
    ACT_CONFERENCE_ANSVER,
    ACT_CONFERENCE_TERMINATION,
    ACT_CHANGE_CONTACT_STATUS,
    ACT_RC_RADIO_HANDLE_STATUS_EVENT,
    ACT_RC_RADIO_HANDLE_GRANT_EVENT,
    ACT_RC_RADIO_HANDLE_VOICE_EVENT,
    ACT_RC_RADIO_HANDLE_DISCONNECT_EVENT,
    ACT_RC_RADIO_HANDLE_MESSAGE_EVENT,
    CALLS_EVENTS_CONTROLLER,
    ACT_UPDATE_TA,
    ACT_HIDE_INCOMMING_CALL,
    ACT_UPDATE_CALLS,
    ACT_UPDATE_CALL,
    ACT_TOGGLE_MICROPHONE,
    ACT_TOGGLE_SPEAKER,
    ACT_TRANSIT,
    ACT_CONFERENCE,
    ACT_PRESS_DIAL_KEY,
    ACT_SAVE_DATA_CONF,
    ACT_ADD_LOCALSTREAM,
    ACT_HANDLE_MESSAGE_EVENT,
    ACT_TERMINATE_ASISTANT,
    ACT_SET_ACTIVE_CALL,
    CALLS_CONTROLLER,
    ACT_HANDLE_MESSAGE_CHANGE_EVENT,
    ACT_HANDLE_MESSAGE_UPDATE_EVENT,
    ACT_UPDATE_CHAT,
    ACT_EVENT_PHONE,
    ACT_SET_CONNECTION_STATUS,
    ACT_ALARM_SET_PROPS,
    ACT_SOCKET_RECONNECT,
    ACT_ADD_NOTIFICATION,
    ACT_CONF_LIST_UPDATE_ASSISTANT,
    ACT_SEND_SCREENSHOT_EVENT,
    ACT_UPDATE_SEARCHED_CONTACT,
} from '../actionsTypes'

import {
    MUT_CHANGE_BOT_PROP,
    MUT_DELETE_ANSWERED_CALL,
    MUT_SET_CLIENT_DOWNLOAD_CURRENT_SIZE,
    MUT_SET_CLIENT_DOWNLOAD_SIZE,
    MUT_SET_UPDATE_STATUS,
    MUT_SOCKET_CLEAR_RECONNECT_TIMEOUT,
} from '../mutationsTypes'

import {
    LOGIN,
    CONTACTS,
    BOTS,
    CHANNELS,
    TETRA,
    VIDEO_CONF,
    RC_RADIO,
    PHONE_CNTL,
    ASISTANTS,
    CHATS,
    CLIENTDATA,
    PHONE,
    SOCKET,
    USERDATA,
    ALARM,
    NOTIFICATIONS,
    DLP,
} from '../modulesNames'

import { UPDATE_STATUSES } from "../modules/clientdata"
import {SOCKET_STATUSES} from "../modules/socket"
import event_bus from "../../eventBus"

import * as declarations from '../../declarations'
// #if process.env.WEBPACK_BUILD_TARGET === 'electron'
// ipc.on('notice', (event, data) => {
//     store.dispatch('notice/setMsg', data)
// })
// 
// ipc.on('notify-click', (event, options) => {
//     if(options.body === i18n.messages[i18n.locale]['missed-call']) {
//         store.commit('content_manager/setSideType', 'calls-list');
//     } else if(options.unwatchedMsg && options.unwatchedMsg.length) {
//         store.commit('content_manager/setSideType', 'chats')
//         store.commit('acs/setCid', options.data.cid); //TODO: Выпилить к едрене фене
//         store.dispatch('chats/open', {cid: options.data.cid, cidType: options.data.cidType})
//     } else {
//         store.commit('content_manager/setSideType', 'calls-list');
//     }
// })
// 
// ipc.on('contacts/getMergedContactByPhone', (event, phone) => {
//     const returnValue = store.getters['contacts/getMergedContactByPhone'](phone)
//     ipc.send('contacts/getMergedContactByPhone/ok', returnValue)
// })
// 
// ipc.on('contacts/getMergedContactById', (event, cid) => {
//     const returnValue = store.getters['contacts/getMergedContactById'](cid)
//     ipc.send('contacts/getMergedContactById/ok', returnValue)
// })
// ipc.on('contacts/getContactByPhone', (event, number) => {
//     const returnValue = store.getters['contacts/getContactByPhone'](number)
//     ipc.send('contacts/getContactByPhone/ok', returnValue)
// })
// 
// ipc.on('userdata/userInfo', (event) => {
//     const returnValue = store.getters['userdata/userInfo']
//     ipc.send('userdata/userInfo/ok', returnValue)
// })
// 
// ipc.send('clientdata/getClientInfo')
// 
// ipc.on('clientdata/getClientInfo', (event, elClientData) => {
//     store.dispatch('clientdata/setClientInfo', elClientData)
// })
// 
// ipc.on('userdata/getUid', (event) => {
//     const returnValue = store.getters['userdata/getUid']
//     ipc.send('userdata/getUid/ok', returnValue)
// })
// 
// ipc.on('PHONE_CTRL/MUT_DELETE_ANSWERED_CALL', (event, data) =>  store.commit(`${PHONE_CNTL}/${MUT_DELETE_ANSWERED_CALL}`, data, { root: true }))
// 
// // ---------- VideoConf actions -------------------------------
// 
// ipc.on('ACT_SEND_OFFER_CONFERENCE', (event, data) => store.dispatch(`${VIDEO_CONF}/${ACT_SEND_OFFER_CONFERENCE}`, data))
// ipc.on('ACT_CONFERENCE_TERMINATION', (event, data) => store.dispatch(`${VIDEO_CONF}/${ACT_CONFERENCE_TERMINATION}`, data))
// ipc.on('ACT_CONFERENCE_ANSVER', () => store.dispatch(`${VIDEO_CONF}/${ACT_CONFERENCE_ANSVER}`))
// ipc.on('ACT_SAVE_DATA_CONF', (event, data) => store.dispatch(`${VIDEO_CONF}/${ACT_SAVE_DATA_CONF}`, data))
// ipc.on('ACT_ADD_LOCALSTREAM', (event, data) => store.dispatch(`${VIDEO_CONF}/${ACT_ADD_LOCALSTREAM}`, data))
// 
// // ---------- Assistant actions -------------------------------
// 
// ipc.on('ACT_TOGGLE_MICROPHONE', () => store.dispatch(`${ASISTANTS}/${ACT_TOGGLE_MICROPHONE}`))
// ipc.on('ACT_TOGGLE_SPEAKER', () => store.dispatch(`${ASISTANTS}/${ACT_TOGGLE_SPEAKER}`))
// ipc.on('ACT_TRANSIT', () => store.dispatch(`${ASISTANTS}/${ACT_TRANSIT}`))
// ipc.on('ACT_CONFERENCE', () => store.dispatch(`${ASISTANTS}/${ACT_CONFERENCE}`))
// ipc.on('ACT_SET_ACTIVE_CALL', (event, data) => store.dispatch(`${ASISTANTS}/${ACT_SET_ACTIVE_CALL}`, data))
// ipc.on('ACT_PRESS_DIAL_KEY', (event, key) => store.dispatch(`${ASISTANTS}/${ACT_PRESS_DIAL_KEY}`, key))
// ipc.on('chats/open', (event, data) => store.dispatch('chats/open', data))
// ipc.on('ACT_TERMINATE_ASISTANT', (event, data) => store.dispatch(`${ASISTANTS}/${ACT_TERMINATE_ASISTANT}`, data))
// 
// // ------------------ Phone controller actions --------------------------
// 
// ipc.on('phone-call-to-number', (event, number) => {
//     const calls = store.getters[`${PHONE_CNTL}/${GET_ANSWERED_CALLS}`]
//     if (calls && calls.length) return
//     let { cid } = store.getters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](number) || {}
//     let callData = {cid, number, video: false}
//     let assistant_type = store.getters[`${ASISTANTS}/${GET_TYPE_ASISTANT}`]
//     if (assistant_type !== 'none') store.dispatch(`${PHONE_CNTL}/${CALLS_CONTROLLER}`, {type: 'call', data: callData})
// })
// 
// ipc.on('direct-call-by-email', (event, email) => {
//     const calls = store.getters[`${PHONE_CNTL}/${GET_ANSWERED_CALLS}`]
//     if (calls && calls.length) return
//     let contact = store.getters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_EMAIL}`](email) || {}
//     if (contact) {
//         let callData = {cid: contact.cid, number: false, video: false}
//         store.dispatch(`${PHONE_CNTL}/${CALLS_CONTROLLER}`, {type: 'call', data: callData})
//     }
// })
// 
// // data = {cid: this.cid, number: false, video: false};
// // this.$store.dispatch(`${PHONE_CNTL}/${CALLS_CONTROLLER}`, {type: 'call', data})
// 
// // --------------------End Of Phone controller actions -------------------
// 
// // ------------------ ClientData Client Update ---------------------------
// 
// ipc && ipc.on(`${CLIENTDATA}/clientDownloadSize`, (event, size) => {
//     store.commit(`${CLIENTDATA}/${MUT_SET_CLIENT_DOWNLOAD_SIZE}`, size)
// })
// 
// ipc.on(`${CLIENTDATA}/clientDownloadProgress`, (event, size) => {
//     store.commit(`${CLIENTDATA}/${MUT_SET_CLIENT_DOWNLOAD_CURRENT_SIZE}`, size)
// })
// 
// ipc.on(`${CLIENTDATA}/clientDownloadFinish`, (event, error) => {
//     store.commit(`${CLIENTDATA}/${MUT_SET_UPDATE_STATUS}`, !error ? UPDATE_STATUSES.UPDATING : UPDATE_STATUSES.HAVE_NEW_VERSION)
//     store.commit(`${CLIENTDATA}/${MUT_SET_CLIENT_DOWNLOAD_CURRENT_SIZE}`, 0)
//     store.commit(`${CLIENTDATA}/${MUT_SET_CLIENT_DOWNLOAD_SIZE}`, 0)
// })
// 
// ipc.on(`${CLIENTDATA}/clientInstallStart`, (event, error) => {
//     if (error) store.commit(`${CLIENTDATA}/${MUT_SET_UPDATE_STATUS}`, UPDATE_STATUSES.HAVE_NEW_VERSION)
//     else store.commit(`${CLIENTDATA}/${MUT_SET_UPDATE_STATUS}`, UPDATE_STATUSES.UPDATING)
// })
// 
// ipc && ipc.on(`${CLIENTDATA}/clientInstallEnd`, (event, error) => {
//     store.commit(`${CLIENTDATA}/${MUT_SET_UPDATE_STATUS}`, UPDATE_STATUSES.HAVE_NEW_VERSION)
// })
// 
// // --------------------End Of ClientData Client Update -------------------
// 
// #endif

proto.on('connect', socket => {
    store.commit(`${SOCKET}/${MUT_SOCKET_CLEAR_RECONNECT_TIMEOUT}`)
    store.dispatch(`${ALARM}/${ACT_ALARM_SET_PROPS}`, {})
    store.dispatch('clientdata/setSocketId', socket.id)
    //await store.dispatch(`${SOCKET}/${ACT_SET_ENCRYPTION}`)
})

const onDisconnect = (reconnect) => {
    if (reconnect && (!store.getters[`${CLIENTDATA}/${GET_IS_ELECTRON}`] || store.getters[`${USERDATA}/${GET_ROSCHAT_SERVER}`])) {
        store.dispatch(`${ALARM}/${ACT_ALARM_SET_PROPS}`, { msg: i18n.messages[i18n.locale]['no-serv-conn'] })
        store.dispatch(`${SOCKET}/${ACT_SOCKET_RECONNECT}`)
    }
    store.dispatch(`${SOCKET}/${ACT_SET_CONNECTION_STATUS}`, SOCKET_STATUSES.DISCONNECTED)
}

proto.on('disconnect', onDisconnect)

proto.on('connect_error', onDisconnect)

proto.on('qr-login-data-event', data => {
    event_bus.$emit(data)
})

proto.on('tokens-event', (payload) => {
    store.dispatch(`${LOGIN}/${ACT_HANDLE_TOKENS_EVENT}`, payload)
})

proto.on('contact-change-event', ({type, data}) => {
    const {type: eventType, contact, cid} = data
    switch (eventType) {
        case 'add':
            let getter = type === 'global' ? GET_CONTACT_BY_ID : GET_LOCAL_CONTACT_BY_ID
            if (store.getters[`${CONTACTS}/${getter}`](contact.cid).cid) {
                store.commit(`${CONTACTS}/updateContact`, {type, contact})
                store.commit(`${BOTS}/${MUT_CHANGE_BOT_PROP}`, {cid, prop: 'temp', val: false})
            } else {
                store.commit(`${CONTACTS}/addContact`, {type, contact})
            }
            break;
        case 'change':
            if (store.getters[`${CONTACTS}/${GET_IS_ROLES_SUPPORTED}`]) {
                store.dispatch(`${CONTACTS}/updateSingleContact`, { type, cid })
            }
            else store.commit(`${CONTACTS}/updateContact`, {type, contact})
            break;
        case 'delete':
            store.commit(`${CONTACTS}/deleteContact`, {type, cid})
            store.dispatch(`${BOTS}/${ACT_BOT_REMOVE}`, {cid, contactRemoved: true})
            const isRolesModelSupported = store.getters[`${CONTACTS}/${GET_IS_ROLES_SUPPORTED}`]
            if (isRolesModelSupported) {
                    const isFoundContact = store.getters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](cid)
                    if (!isFoundContact.cid) {
                        store.dispatch(`${CONTACTS}/${ACT_UPDATE_SEARCHED_CONTACT}`, {cid})
                    }
            }
            break;
        default:
            console.log('Unknown type of contact-change-event');
    }
});

proto.on('channel-change-event', (payload) => {
    store.dispatch(`${CHANNELS}/${ACT_HANDLE_CHANNEL_CHANGE_EVENT}`, payload)
});

proto.on('publication-event', async data => {
    const { chId, pubId, title } = data
    await store.dispatch('channel/updatePublication', { chId, pubId })
    store.commit('channels/updateChannelLastTitle', { chId, text: title })
    if (store.getters[`${CHANNELS}/${GET_SELECTED_CHANNEL}`] === chId) store.dispatch('channels/watchChannelPublications', { chId })
    store.dispatch(`${NOTIFICATIONS}/${ACT_ADD_NOTIFICATION}`, { type: 'pub', pub: data })
})

proto.on('conference-ringing-event', (data) => {
    data.status = 'ringing'
    store.dispatch(`${VIDEO_CONF}/${ACT_CHANGE_CONTACT_STATUS}`, data)
});

proto.on('conference-answer-event', (data) => {
    data.status = 'joined'
    store.dispatch(`${VIDEO_CONF}/${ACT_CHANGE_CONTACT_STATUS}`, data)
});

proto.on('conference-processing-event', (data) => {
    store.dispatch(`${VIDEO_CONF}/${ACT_SAVE_REMOTE_PROCESSING}`, data)
});

proto.on('conference-termination-event', (data) => {
    store.dispatch(`${VIDEO_CONF}/${ACT_TERMINATION_EVENT}`, data)
});

// --------- ASISTANTS ------------------

proto.on('assistant-ta-info-event', (data) => {
    const theme = store.getters['clientdata/getTheme']
    data.theme = theme
    store.dispatch(`${ASISTANTS}/${ACT_UPDATE_TA}`, data)
})
proto.on('assistant-calls-info-event', (data) => {
    store.dispatch(`${ASISTANTS}/${ACT_UPDATE_CALLS}`, data)
})
proto.on('assistant-call-info-event', (data) => {
    store.dispatch(`${ASISTANTS}/${ACT_UPDATE_CALL}`, data)
})
proto.on('assistant-conference-event', (data) => {
    store.dispatch(`${ASISTANTS}/${ACT_CONF_LIST_UPDATE_ASSISTANT}`, data)
})

// -------- CALLS -----------------------

proto.on('calls-event', (data) => {
    store.dispatch(`${PHONE_CNTL}/${CALLS_EVENTS_CONTROLLER}`, data)
})
proto.on('rtc-call-termination-event', (data) => {
    store.dispatch(`${PHONE}/${ACT_EVENT_PHONE}`, { data, eventName: 'rtc-call-termination-event' })
    store.dispatch(`${PHONE_CNTL}/${ACT_HIDE_INCOMMING_CALL}`, { type: 'phone', id: data.callId })
})
proto.on('rtc-call-conference-event', (data) => {
    store.dispatch(`${PHONE}/${ACT_EVENT_PHONE}`, {eventName: 'rtc-call-conference-event', data})
})
proto.on('history-call-event', data => {
    store.dispatch('calls/updateCallsEvent', data)
})
proto.on('history-call-change-event', data => {
    store.commit('calls/updateCallChangeEvent', data)
})

// -------- RADIO -----------------------

radioproto.on('radio-status-event', (payload) => {
    store.dispatch(`${RC_RADIO}/${ACT_RC_RADIO_HANDLE_STATUS_EVENT}`, payload)
})

radioproto.on('radio-leave-event', (payload) => {
    store.dispatch(`${RC_RADIO}/${ACT_RC_RADIO_HANDLE_STATUS_EVENT}`, payload)
})

radioproto.on('radio-grant-voice-event', (payload) => {
    store.dispatch(`${RC_RADIO}/${ACT_RC_RADIO_HANDLE_GRANT_EVENT}`, payload)
})

radioproto.on('radio-stop-voice-event', (payload) => {
    store.dispatch(`${RC_RADIO}/${ACT_RC_RADIO_HANDLE_GRANT_EVENT}`, payload)
})

radioproto.on('radio-voice-event', (payload) => {
    store.dispatch(`${RC_RADIO}/${ACT_RC_RADIO_HANDLE_VOICE_EVENT}`, payload)
})

radioproto.on('radio-disconnected-event', (payload) => {
    store.dispatch(`${RC_RADIO}/${ACT_RC_RADIO_HANDLE_DISCONNECT_EVENT}`, payload)
})

radioproto.on('radio-message-event', (payload) => {
    store.dispatch(`${RC_RADIO}/${ACT_RC_RADIO_HANDLE_MESSAGE_EVENT}`, payload)
})

radioproto.on('tetra-radio-status-change-event', (payload) => {
    store.dispatch(`${TETRA}/${ACT_TETRA_HANDLE_STATUS_EVENT}`, payload)
})

radioproto.on('tetra-radio-message-event', (payload) => {
    store.dispatch(`${TETRA}/${ACT_TETRA_HANDLE_MSG_EVENT}`, payload)
})



// --------- CHATS ------------------

proto.on('chat-change-event', (payload) => {
    store.dispatch(`${CHATS}/${ACT_UPDATE_CHAT}`, payload)
})

proto.on('message-event', (payload) => {
    store.dispatch(`${CHATS}/${ACT_HANDLE_MESSAGE_EVENT}`, payload)
})

proto.on('message-change-event', payload => {
    store.dispatch(`${CHATS}/${ACT_HANDLE_MESSAGE_CHANGE_EVENT}`, payload)
})

proto.on('message-update-event', payload => {
    store.dispatch(`${CHATS}/${ACT_HANDLE_MESSAGE_UPDATE_EVENT}`, payload)
})