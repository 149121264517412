//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalDialog from '../ModalDialog.vue'
import modal_mixin from '../modal-mixin'
import CustomDropDown from "../../custom/CustomDropDown.vue"
import ModalBtn from "../ModalBtn.vue"

import { INTEGRATIONS, USERDATA } from '../../../store/modulesNames'
import { 
    ACT_SCHEDULE_CREDENTIALS_CHECK,
    ACT_SCHEDULE_UPDATE_CREDENTIALS,
    ACT_SAVE_INTEGRATION_SETTINGS,
}  from "../../../store/actionsTypes"
import { GET_INTEGRATION_SETTINGS } from '../../../store/gettersTypes'
import { mapActions, mapGetters } from '../../../../vuex'

import { INTEGRATION_NAMES } from '../../../constants'
const integrationName = INTEGRATION_NAMES.MS_EXCHANGE

export default {
    name: "AddIntegration",
    components: { ModalDialog, CustomDropDown, ModalBtn },
    mixins: [modal_mixin],
    data() {
        return {
            compModalProps: {
                pivotY: 0.40,
                pivotX: 0.73,
                width: 440,
                height: 240,
                clickToClose: false,
            },
            cancelChange: false,
            login: '',
            password: '',
            isCheckedOK: false,
            notification: { message: '', class: ''}
        }
    },
    mounted() {
        const currentSettings = this[GET_INTEGRATION_SETTINGS](integrationName)
        let { login, password } = currentSettings
        this.login = login
        this.password = password
    },
    computed: {
        isAddBtnDisabled() {
            return !this.selectedVal
        },
        isCheckBtnShow() {
            return this.login && this.login.length && this.password && this.password.length
        },
        ...mapGetters(USERDATA, [GET_INTEGRATION_SETTINGS],)
    },
    methods: {
        async saveBtn() {
            const credentials = { login: this.login, password: this.password }
            let payload = { type: "exchange", credentials }
            let res = await this[ACT_SCHEDULE_UPDATE_CREDENTIALS](payload)
            if (res) {
                this[ACT_SAVE_INTEGRATION_SETTINGS]({ integrationName, settings: credentials })
            }
            this.modalClose()
        },
        cancelBtn() {
            this.cancelChange = true
            this.modalClose()
        },
        selectMSVersion(val) {
            this.selectedMSVersion = val
        },
        selectAuth(val) {
            this.selectedAuth = val
        },
        async checkBtn() {
            this.notification = {}
            const login = this.login, password = this.password
            const payload = { type: "exchange", credentials: { login, password } }
            const isOk = await this[ACT_SCHEDULE_CREDENTIALS_CHECK](payload)
            const successMsg = this.$t('settings.authorization') + this.$t('settings.successfull')
            const failedMsg = this.$t('settings.authorization') + this.$t('settings.failed')
            if (isOk) {
                this.$nextTick(() => this.notification = { message: successMsg, class: 'greenColor'})
                this.isCheckedOK = true
            }
            else this.$nextTick(() => this.notification = { message: failedMsg, class: 'redColor'})
        },
        ...mapActions(INTEGRATIONS, [    
            ACT_SCHEDULE_CREDENTIALS_CHECK,
            ACT_SCHEDULE_UPDATE_CREDENTIALS,
        ]),
        ...mapActions(USERDATA, [ACT_SAVE_INTEGRATION_SETTINGS])            
    },
}
