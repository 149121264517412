//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';
import {mapGetters, mapActions} from 'vuex';
import {ACT_INFO_PUSH} from "../../store/actionsTypes";
import {INFO, CONTACTS, LOGIN, USERDATA} from "../../store/modulesNames";

import IconImage from '../IconImage.vue';
import CallDetailsButton from "./CallDetailsButton.vue"
import { i18n }from '../../../ext/i18n';
import {GET_CONTACT_BY_ID, GET_MY_CONTACT, GET_SERVER_API, GET_USER_PARAMS, GET_PHONE_LIST_BY_ID} from "../../store/gettersTypes";
import {CALLS_CONTROLLER} from "../../store/actionsTypes";
import {PHONE_CNTL} from "../../store/modulesNames";
import modal_mixin from '../modal/modal-mixin'

   export default {
       name: 'call-details',
       props: ['user', 'fio', 'calls'],
       data() {
           return {
               compModalProps: {
                 delay: 200,
                 pivotY: 0.05,
                 width: 350,
                 height: 'auto',
               }
           };
       },
       i18n,
       components: {
           'icon-image': IconImage,
           CallDetailsButton
       },
       mixins: [modal_mixin],
       computed: {
           type: function() {
               let locale = i18n.messages[i18n.locale]
               switch (this.user.class) {
                   case 'FL':
                       return locale.mainPage['voice-call']
                       break;
                   case 'FL-video':
                       return locale.mainPage['video-call']
                       break;
                   case 'number':
                   case 'out_number':
                       return this.user.number;
                       break;
                   case 'pult':
                       return locale.pult
                       break;
                   case 'analog':
                       return locale.analog
                       break;
                   default:
                       return '';
               }
           },
           myContactActions() {
               return this[GET_MY_CONTACT].actions || []
           },
           userActions() {
               let contact = this[GET_CONTACT_BY_ID](this.user.cid)
               return contact.actions || {}
           },
           isChatAllowed() {
               if (this[GET_SERVER_API] < declarations.serverAPILevels.LEVEL_12) return true
               if (!this.isViewProfileAllowed) return false
               let isMyChatAllowed, isUserChatAllowed
               const myActions = this.myContactActions
               isMyChatAllowed = !!myActions["send-message"]
               const userActions = this.userActions
               isUserChatAllowed = !!userActions["send-message"] && !!userActions["receive-message"]
               return isMyChatAllowed && isUserChatAllowed
           },
           isCallAllowed() {
               if (this[GET_SERVER_API] < declarations.serverAPILevels.LEVEL_12) return true
               let isMyCallAllowed
               const myActions = this.myContactActions
               isMyCallAllowed = !!myActions["make-call"]
               if (Object.keys(this.userActions).length) {
                   if (!this.isViewProfileAllowed) return false
                   let isUserCallAllowed
                   const userActions = this.userActions
                   isUserCallAllowed = !!userActions["make-call"] && !!userActions["accept-call"]
                   return isMyCallAllowed && isUserCallAllowed
               } else {
                   return isMyCallAllowed
               }
           },
           isViewProfileAllowed() {
               if (this[GET_SERVER_API] < declarations.serverAPILevels.LEVEL_12) return true
               const userActions = this.userActions
               const isAllowed = !!userActions["view-contact"]
               return isAllowed
           },
           isDisabledDirectCall() {
               const myUserParams = this[GET_USER_PARAMS]
               let isDisabled = myUserParams.hasOwnProperty("disableDirectCall") && myUserParams.disableDirectCall
               return isDisabled
           },
           contactPhone() { 
               let phones
               if(this.user.cid) phones = this[GET_PHONE_LIST_BY_ID](this.user.cid)
               return phones && phones.length && phones[0].value || false
           },
           isClientCallsProhibited() {
               if (localStorage.getItem('assistant') === 'prohibit') return true
               return false
           },            
           isCallBtnEnabled() {
               let isEnabled = this.isCallAllowed
               if (this.isClientCallsProhibited) return false
               let userPhone = this.contactPhone
               if (userPhone) return true
               isEnabled = isEnabled && !this.isDisabledDirectCall
               return isEnabled
           },     
           isAssistantType() {
               const storedType = localStorage.getItem('assistant')
               if (storedType !== 'prohibit' && storedType !== 'none' && storedType !== 'sip') return true
               return false
           },                       
           ...mapGetters(CONTACTS, [GET_MY_CONTACT, GET_CONTACT_BY_ID, GET_PHONE_LIST_BY_ID]),
           ...mapGetters(LOGIN, [GET_SERVER_API]),
           ...mapGetters(USERDATA, [GET_USER_PARAMS]),            
       },
       methods: {
           doClickCall: function(cid, classCall) {
               let data;
               if(['number', 'out_number'].includes(classCall)) data = {cid: cid, number: this.user.number, video: false};
               else if(classCall === ' FL-video') data = {cid: cid, number: false, video: true};
               else data = {cid: cid, number: false, video: false};
               if ((this.isDisabledDirectCall || this.isAssistantType) && this.contactPhone) {
                       data.number = this.contactPhone
               } 
               this.$store.dispatch(`${PHONE_CNTL}/${CALLS_CONTROLLER}`, {type: 'call', data})
               this.$modal.hide('call-details');
           },
           doClickPlus: function(cid) {
               if(this.permission(cid)) {
                   this[ACT_INFO_PUSH]({type: 'contact-info', params: {cid}});
                   this.$emit('update:contact-selected', cid);
                   if (this.noswitch) return;
                   this.$store.dispatch('contacts/selected', cid);
                   this.$modal.hide('call-details');
               }
           },
           doClickChat(cid) {
               if(this.permission(cid)) {
                   this.$store.dispatch('chats/open', {cid, cidType: 'user', isBot: false});
                   this.$modal.hide('call-details');
               }
           },
           permission(cid) {
               let user = app.store.getters['contacts/getMergedContactById'](cid);
               if('cid' in user) return true;
               else return false;
           },
           ...mapActions(INFO, [ACT_INFO_PUSH])
       }
   }
