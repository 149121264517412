
import SelectContact from "./SelectContact.vue"
import {CONTACTS_SEARCH_TYPES} from '../../constants'

export default {
    name: "SelectContactToPrivate",
    extends: SelectContact,
    data() {
        return {
            searchType: CONTACTS_SEARCH_TYPES.SEND_MESSAGE,
        }
    },
    computed: {
        type: () => null
    },
    methods: {
        onOk(contact) {
            this.$store.dispatch('contacts/selected', contact.cid);
            this.modalClose()
        }
    }
}
