//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconImage from '../IconImage.vue';
import {ACT_INFO_PUSH, ACT_UPDATE_SEARCHED_CONTACT} from '../../store/actionsTypes'
import {CONTACTS, INFO, LOGIN} from '../../store/modulesNames'
import ContactProfileEdition from "../ContactProfoleEdition.vue"
import SelectThenEditContact from '../modal/SelectThenEditContact.vue'
import {GET_SERVER_API} from '../../store/gettersTypes'

export default {
    name: 'chat-contact-message',
    props: ['cid', "message"],
    components: {
        'icon-image': IconImage
    },
    created() {
        if (this.globalContactCid && !this.contact.cid) {
            if (this.$store.getters[`${LOGIN}/${GET_SERVER_API}`] >= declarations.serverAPILevels.LEVEL_12) {
                this.$store.dispatch(`${CONTACTS}/${ACT_UPDATE_SEARCHED_CONTACT}`, {cid: this.globalContactCid}).catch()
            }
        }
    },
    computed: {
        id() { return this.message.id },
        globalContactCid() {
            return this.message.msg.cid
        },
        contact() {
            if (this.globalContactCid) {
                return this.$store.getters['contacts/getMergedContactById'](this.globalContactCid)
            }
            else return false
        },
        photo() {
            let msg = this.message, file = msg.msg.file
            if (file) return app.store.getters['userdata/getHost'] + '/' + file
            else return this.$store.getters['contacts/getMergedContactById'](this.globalContactCid).photo
        },
        makeFio() {
            let msg = this.message, fio = this.fioBySurnameName
            if (!fio) {
                fio = msg.msg.fio
            }
            if (!fio || !fio.trim().length) fio = this.fioByAuxFields
            return fio
        },
        fioBySurnameName() {
            const fields = Array.isArray(this.message.fields) && this.message.fields
            let fioArr = fields && fields.filter(f => ['surname', 'name', 'nik'].includes(f.type))
            let fioValsArr = [] 
            if (fioArr) fioValsArr = fioArr.map(f => f.value.trim())
            return fioValsArr.join(" ").trim()
        },
        fioByAuxFields() {
            const fields = Array.isArray(this.message.fields) && this.message.fields
            const phones = fields && fields.filter(f => f.type === 'phone' || f.type === 'workphone')
            const phone = phones && phones.length && phones[0].value
            const mails = fields && fields.filter(f => f.type === 'mail')
            const mail = mails && mails.length && mails[0].value
            return phone || mail
        },
        fio() {
            return this.makeFio || this.contact.fio || this.$t('search-comp.noname-contact')
        },
    },
    methods: {
        makeActions() {
            let msg = this.message
            if (this.globalContactCid) {
                let cid = this.globalContactCid
                if (msg.fields) {
                    //let props = { type: 'edit', cid, newFields: msg.fields }
                    let params = {cid, fio: msg.msg.fio, fields: msg.fields}
                    if (msg.msg.file) params.photo = this.$store.getters['userdata/getHost'] + '/' + msg.msg.file
                    else params.photo = this.$store.getters['userdata/getPhotoUrlbyCid'](this.globalContactCid)
                    this.$store.dispatch(`${INFO}/${ACT_INFO_PUSH}`, {type: 'contact-info', params})
                    // this.modalOpen({
                    //     component: ContactProfileEdition,
                    //     props
                    // })
                } else this.$store.dispatch(`${INFO}/${ACT_INFO_PUSH}`, {type: 'contact-info', params: {cid}})
            } else {
                let params = {fio: msg.msg.fio, fields: msg.fields}
                if (msg.msg.file) params.photo = this.$store.getters['userdata/getHost'] + '/' + msg.msg.file
                else params.photo = this.$store.getters['userdata/getPhotoUrlbyCid'](this.globalContactCid)
                this.$store.dispatch(`${INFO}/${ACT_INFO_PUSH}`, {type: 'contact-info', params});
            }
        },
        fioById(cid) {
            return this.$store.getters['contacts/getMergedContactById'](cid).fio || null
        },
        secondContextMenu() {
            let cid = this.globalContactCid
            let handlers = [], msg = this.message, self = this
            let fields = msg.fields
            if (typeof fields === 'string') {
                try {
                    fields = JSON.parse(fields)
                } catch {
                    fields = []
                }
            }
            handlers.push({
                item_name: this.$t('mainPage.create-new-contact'),
                handler: () => {
                    this.modalOpen({
                        component: ContactProfileEdition,
                        props: { type: 'create', cid: self.globalContactCid, photoLink: self.photo, newFields: fields }
                    })
                }
            })
            handlers.push({
                item_name: this.$t('mainPage.add-to-contact'),
                handler: () => {
                    this.modalOpen({
                        component: SelectThenEditContact,
                        props: { type: 'add-to-contact',  photoLink: self.photo, newFields: fields }
                    })
                }
            })
            this.$emit('secondcontextmenu', handlers)
        }
    },
}
