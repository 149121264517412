import moment from 'moment'
import {mapActions, mapGetters} from 'vuex'
import {AJAX, BLOB} from '../../store/modulesNames'
import {
    GET_AJAXX_CALL_IS_EXIST_BY_FILE,
    GET_AJAXX_CALL_PROGRESS_BY_FILE,
    GET_FILE_BLOB,
} from '../../store/gettersTypes'
import {ACT_ABORT_AJAX_CALL, ACT_AJAX_GET_FILE} from '../../store/actionsTypes'

export default {
    computed: {
        xhrFile() { return this.index > -1 ? this.file : this.data.file },
        xhrId() { return this.xhrFile && this[GET_AJAXX_CALL_IS_EXIST_BY_FILE](this.xhrFile) },
        isPending() { return this[GET_AJAXX_CALL_IS_EXIST_BY_FILE](this.xhrFile) },
        loadProgress() { return this[GET_AJAXX_CALL_PROGRESS_BY_FILE](this.xhrFile) },
        link() { return this[GET_FILE_BLOB](this.xhrFile) },
        ...mapGetters(AJAX, [GET_AJAXX_CALL_IS_EXIST_BY_FILE, GET_AJAXX_CALL_PROGRESS_BY_FILE]),
        ...mapGetters(BLOB, [GET_FILE_BLOB]),
    },
    methods: {
        abortAjaxCall() {
            if (this.xhrId) this[ACT_ABORT_AJAX_CALL](this.xhrId)
        },
        getPreview (data = {}) {
            let preview_file = data.preview_file
            if (!preview_file && data.previewFile) {
                preview_file = declarations.http_post_dirs.CHANNEL_DATA_DIR + data.previewFile
            }
            return this.$store.getters['userdata/getHost'] + '/' + preview_file
        },
        async loadFile (params) {
            let blob_url = this.$store.getters['blob/getFileBlob'](params.file)
            if (blob_url) return
            if (!params.file) return
            await this[ACT_AJAX_GET_FILE]({url: params.file, fileSize: params.size, link: true})
        },
        getDownloadFileName(type, name, extension) {
            let filename = name + (extension ? '.' + extension : '')
            let date = moment().format('YYYY-MM-DD_HH-MM-SS')
            if (!name || !filename) filename = (type + '_' + date) + (extension ? '.' + extension : '')
            return filename
        },
        ...mapActions(AJAX, [ACT_ABORT_AJAX_CALL, ACT_AJAX_GET_FILE])
    }
}