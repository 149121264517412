//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "../../../vuex"
import CustomRadio from '../custom/CustomRadio.vue';
import ModalDialog from "./ModalDialog.vue"
import modal_mixin from "./modal-mixin"

import { i18n } from '../../../ext/i18n'
const locale = i18n.messages[i18n.locale]

import { USERDATA, ASISTANTS, CONTENT_MANAGER } from '../../store/modulesNames';
import { MUT_SET_SIDE_BAR_NOTIFICATION, MUT_DELETE_SIDE_BAR_NOTIFICATION } from "../../store/mutationsTypes"
import { GET_USER_PARAMS, GET_TYPE_ASISTANT, GET_ASSISTANTS, GET_NUMBER_ASSISTANT, GET_SIDE_BAR_NOTIFICATION } from '../../store/gettersTypes';
import { ACT_SELECT_ASSISTANT } from '../../store/actionsTypes';

import { SIDE_TYPES } from "../../store/modules/content-manager"

export default {
    name: 'SelectAssistant',
    components: {ModalDialog, CustomRadio},
    mixins: [modal_mixin],
    data() {
        return {
            title: this.$t('сall-control'),
            compModalProps: {
                delay: 200,
                pivotY: 0.05,
                width: 500,
                height: 'auto'
            },
            booster: ''
        };
    },
    mounted () {
        this.booster = this[GET_TYPE_ASISTANT]
    },
    computed: {
        userParams() {
            return this[GET_USER_PARAMS]
        },
        isDisabledDirectCall() {
            return this.userParams.hasOwnProperty("disableDirectCall") && this.userParams.disableDirectCall
        },
        inputs() {
            let assistants = this[GET_ASSISTANTS]
            
            const isProhibitedExist = assistants.some(ass => ass.value === 'prohibit')
            if (!isProhibitedExist) {
                let prohibitItem = {
                    value: 'prohibit',
                    label: this.$t('assistants.name.prohibit'),
                    description: locale.assistants.description['prohibit'],
                    isDisabled: this.isDisabledDirectCall
                }              
                assistants.unshift(prohibitItem)
            }
            // console.log("🚀 ~ file: SelectAssistant.vue:54 ~ inputs ~ assistants:", assistants)
            const retVal = assistants.map((item, i) => {
                if (!i) return item
                if (i === 1) 
                return {
                    'value': item.type,
                    'label': this.$t(`assistants.name.${item.type}`, { number: item.number ? `(${item.number})` : item.number }),
                    'description': locale.assistants.description[item.type],
                    isDisabled: this.isDisabledDirectCall
                }
                else return {
                    'value': item.type,
                    'label': this.$t(`assistants.name.${item.type}`, { number: item.number ? `(${item.number})` : item.number }),
                    'description': locale.assistants.description[item.type]
                }
            })
            // console.log("🚀 ~ file: SelectAssistant.vue:68 ~ inputs ~ retVal:", retVal)
            return retVal
        },
        ...mapGetters(USERDATA, [GET_USER_PARAMS]),
        ...mapGetters(ASISTANTS, [GET_TYPE_ASISTANT, GET_ASSISTANTS]),
        ...mapGetters(CONTENT_MANAGER, [GET_SIDE_BAR_NOTIFICATION]),
    },
    methods: {
        doClick() {
            if (!this.booster) this.booster = this.defaultBooster()
            if (!this.isDisabledDirectCall && this.booster === 'prohibit') {
                const currentNotification = this[GET_SIDE_BAR_NOTIFICATION]() || ''
                const warningMsg = this.$t('calls-prohibited-long')
                const isExist = currentNotification.indexOf(warningMsg) > -1
                if (!isExist) {
                    const payload = { type: SIDE_TYPES.CALLS_LIST, msg: this.$t('calls-prohibited-long') }
                    this.$store.commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_BAR_NOTIFICATION}`, payload)
                }
            }
            if (!this.isDisabledDirectCall && this.booster !== 'prohibit') {
                const payload = { type: SIDE_TYPES.CALLS_LIST, msg: this.$t('calls-prohibited-long') }
                this.$store.commit(`${CONTENT_MANAGER}/${MUT_DELETE_SIDE_BAR_NOTIFICATION}`, payload)
            }
            this.$store.dispatch(`${ASISTANTS}/${ACT_SELECT_ASSISTANT}`, this.booster)
            this.props.cb && this.props.cb(this.booster)
            this.modalClose()
        },
        getNumber(assistant) {
            const number = this.$store.getters[`${ASISTANTS}/${GET_NUMBER_ASSISTANT}`](assistant)
            return number ? `(${number})` : number
        },
        defaultBooster () {
            if (!this.isDisabledDirectCall && this.booster === '' && this.inputs.length) return this.inputs[0].value
            else if (this.isDisabledDirectCall && this.booster === '' && this.inputs.length) return this.inputs[this.inputs.length - 1].value
            else return this.booster
        }
    }
}
