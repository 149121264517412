//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';
import ContactsFilter from '../modal/ContactsFilter.vue';
import CustomSearch from '../custom/CustomSearch.vue';
import Contacts from './Contacts.vue';
import Channels from './Channels.vue';
import Chats from './Chats.vue';
import Bots from './bots.vue';
import SearchContacts from './SearchContacts.vue';
import myGroups from './myGroups.vue';
import myChannels from './myChannels.vue';
import myBots from './myBots.vue';
import ContentLoader from "../main/body/MainContentLoader.vue"
import { i18n } from '../../../ext/i18n'

import { mapActions, mapMutations, mapGetters } from 'vuex';

import {
    GET_SEARCH_OPENED,
    GET_SEARCHED_CONTACTS,
    GET_BOTS,
    GET_LOCALE,
    GET_IS_ROLES_SUPPORTED,
    GET_SERVER_API,
    GET_MY_CONTACT,
    GET_CHATS,
    GET_CHANNELS,
    GET_IS_SEARCH_FILTER_SUPPORTED,
    GET_CONTACTS_FILTER_BY_NAME,
} from '../../store/gettersTypes'
import {
    CONTENT_MANAGER,
    USERDATA,
    BOTS,
    CONTACTS,
    CLIENTDATA,
    LOGIN,
    CHATS,
    CHANNELS
} from '../../store/modulesNames'

import { MUT_SET_SIDE_TYPE, MUT_SET_SEARCH_OPENED } from '../../store/mutationsTypes';
import {
    ACT_SEARCH_BOTS,
    ACT_SEARCH_CONTACTS,
    ACT_SET_CONTACTS_FILTER
} from '../../store/actionsTypes'

import event_bus from '../../eventBus'

const searchItemsPortion = 50
const inputSearchDelay = 500 // Delay (ms) in input before search starts

export default {
    name: 'universal-search-items',
    components: {
        CustomSearch,
        Contacts,
        Channels,
        Chats,
        Bots,
        SearchContacts,
        myGroups,
        myChannels,
        myBots,
        'group': Chats,
        ContactsFilter,
        ContentLoader,
    },
    props: {
        categories: {
            type: Array,
            required: false,
            default: () => ['contacts', 'group', 'channels', 'bots']
        },
        noswitch: {
            type: Boolean,
            required: false,
            default: false
        }
    },  
    created() {
        this.getContacts = debounce(this.getContacts, inputSearchDelay)
    },
    data() {
        return {
            keys: ['fio','mails', 'workphones'],
            search: '',
            pane: 'contacts',
            open: false,
            readonly: true,
            selectedNode: -1,
            showFilter: false,
            isScrollVisible: false,
            loading: false,
            isLoadPossible: true,
            contacts: [],
            fromId: 0,
        };
    },
    methods: {
        dataSearch (data = []) {
            return data.reduce((prev, cur) => {
                if (prev) prev+=', '
                prev+=cur.value
                return prev
            }, '')
            // let newarr = []
            // let arr = Object.keys(data)
            // arr.forEach(type => {
            //     if (data[type].length >= 2) {
            //         let name = data[type].length + ' ' + this.filterName(type, data[type].length)
            //         newarr.push(name)
            //         return
            //     }
            //     newarr.push(data[type][0])
            // })
            // return newarr.join(', ')
        },
        closeInfoSearch () {
            this[ACT_SET_CONTACTS_FILTER]({})
        },
        filterName (name, count) {
            if (name === 'organization') {
                let text = ''
                if (this[GET_LOCALE] === 'ru') {
                    text = utils.declOfNum(count, [ this.$t('information.one-organization'), this.$t('information.several-organization'), this.$t('information.many-organization') ])
                } else {
                    text = (count === 1) ? this.$t('information.one-organization') : this.$t('information.many-organization')
                }
                return `${text}`
            }
            if (name === 'office') {
                let text = ''
                if (this[GET_LOCALE] === 'ru') {
                    text = utils.declOfNum(count, [ this.$t('information.one-department'), this.$t('information.several-department'), this.$t('information.many-department') ])
                } else {
                    text = (count === 1) ? this.$t('information.one-department') : this.$t('information.many-department')
                }
                return `${text}` 
            }
            if (name === 'post') {
                let text = ''
                if (this[GET_LOCALE] === 'ru') {
                    text = utils.declOfNum(count, [ this.$t('information.one-position'), this.$t('information.several-position'), this.$t('information.many-position') ])
                } else {
                    text = (count === 1) ? this.$t('information.one-position') : this.$t('information.many-position')
                }
                return `${text}`
            }                                                                                                                                                        
        },
        createSearch () {
            this.modalOpen({
                component: ContactsFilter,
                props: {
                    onClose: () => resolve(false)
                }
            })
        },
        makeSearchOpened() {
            if (this.isSearchAllowed) {
                this.$store.commit(`${CONTENT_MANAGER}/${MUT_SET_SEARCH_OPENED}`, true)
            } else this.$store.commit(`${CONTENT_MANAGER}/${MUT_SET_SEARCH_OPENED}`, false)
        },
        doContactSelected(contact_id) {
            this.$emit('update:contact-selected', contact_id);
            this.$store.dispatch('chats/open', {contact_id, cidType: 'user', isBot: false});
            this.$modal.hide('contacts', contact_id);
        },
        doFocusOn(id) {
            this.selectedNode = id;
        },
        gotoItem(val) {
            let selectedNode = this.selectedNode + val;
            if (selectedNode < 0) return
            this.selectedNode = selectedNode;
        },
        selectItem() {
            let node = this.$el.querySelector('.focused');
            if (node) {
                node.click();
                this.close();
            }
        },
        changePane(pane) {
            const input = document.querySelector('.search-input');
            input && input.focus();
            this.pane = pane;
            this.selectedNode = -1;
        },
        close() {
            this.$refs.search.clearSearch()
            this.open = false;
            this.selectedNode = -1;
            this.$store.commit(`${CONTENT_MANAGER}/${MUT_SET_SEARCH_OPENED}`, false)
            event_bus.$emit('close-search')
        },
        closeSearch() {
            this.close();
            this.$store.commit(`${CONTENT_MANAGER}/${MUT_SET_SIDE_TYPE}`, 'chats');
        },
        clearSearch() {
            this.search = ''
        },
        showSearchedContacts() {
            if(!this.open || !this.isSearchAllowed) return
            switch(this.pane) {
                case 'contacts':
                    if (!this.search.length && this.searchContacts.length && !this.contactsExtFilter) this.pane = 'searchContacts'
                    break
                case 'searchContacts':
                    if(this.search.length || this.contactsExtFilter) this.pane = 'contacts'
                    break
                case 'group':
                    if(!this.search.length && this.myGroups.length) this.pane = 'myGroups'
                    break
                case 'myGroups':
                    if(this.search.length) this.pane = 'group'
                    break
                case 'channels':
                    if(!this.search.length && this.myChannels.length) this.pane = 'myChannels'
                    break
                case 'myChannels':
                    if(this.search.length) this.pane = 'channels'
                    break
                case 'bots':
                    if(!this.search.length && this.myBots.length) this.pane = 'myBots'
                    break
                case 'myBots':
                    if(this.search.length) this.pane = 'bots'
                    break       
            }
        },
        doShowFilter() {
            this.showFilter = !this.showFilter
        },
        async getContacts() {
            if (this.pane !== 'contacts' || !(this.search.length >= 2 || this.contactsExtFilter)) return
            let contacts = []
            let isListPage = false
            this.loading = true
            try {
                const searchResult = await this[ACT_SEARCH_CONTACTS]({
                    search: this.search,
                    fromId: this.fromId,
                    count: searchItemsPortion,
                    ...(this.contactsExtFilter && {extFilter: this.contactsExtFilter})
                })
                this.isLoadPossible = searchResult.isLoadPossible
                if (searchResult.lastCid) this.fromId = searchResult.lastCid
                isListPage = searchResult.isListPage
                contacts = searchResult.contacts
            } catch (e) {}
            if (isListPage) {
                this.contacts = this.contacts.concat(contacts)
            } else {
                this.contacts = contacts
            }
            this.loading = false
        },
        onScrolledToBottom() {
            if(!this.open || !this.isSearchAllowed) return
            switch(this.pane) {
                case 'contacts':
                    if (this.isLoadPossible && !this.loading) this.getContacts()
                    break
            }
        },
        clearContacts() {
            this.isLoadPossible = true
            this.contacts = []
            this.fromId = 0
        },
        ...mapActions(CONTACTS, [ACT_SEARCH_CONTACTS, ACT_SET_CONTACTS_FILTER]),
        ...mapActions(BOTS, [ACT_SEARCH_BOTS]),
        ...mapMutations(CONTENT_MANAGER, [MUT_SET_SEARCH_OPENED])
    },
    computed: {
        found() {
            return this[this.current_pane]
        },
        contactsExtFilter() {
            let filter = this[GET_CONTACTS_FILTER_BY_NAME]('site')
            return filter && Object.keys(filter).reduce((extFilter, type) => {
                if (filter[type]?.length) {
                    if (!extFilter) extFilter = []
                    filter[type].forEach((item) => extFilter.push({ type, value: item }))
                }
                return extFilter
            }, undefined)
        },
        isSearchOpened() {
            return this[GET_SEARCH_OPENED]
        },
        current_pane() { return this.pane },
        searchContacts() {
            return this[GET_SEARCHED_CONTACTS]
        },
        isRolesModelSupported() {
            return this[GET_IS_ROLES_SUPPORTED]
        },
        myGroups() {
            return this[GET_CHATS].filter(item => item.cidType === 'group')
        },
        myChannels() {
            return this[GET_CHANNELS]
        },
        myBots() {
            return this.$store.getters[`${BOTS}/${GET_BOTS}`]
        },
        myContactActions() {
            return this[GET_MY_CONTACT].actions || []
        },
        isSearchAllowed() {
            if (!this.isRolesModelSupported) return true
            const actions = this.myContactActions
            const isProperty = actions.hasOwnProperty("search-contacts")
            if (isProperty) return actions["search-contacts"]
            else return true
        },
        isSearchFilterSupported() {
            return this[GET_IS_SEARCH_FILTER_SUPPORTED]
        },
        placeholder() {
            return this.isSearchAllowed ? '' : this.$t('roles.no-search-contacts')
        },
        linePosition() {
            return {[this.current_pane]: true};
        },
        ...mapGetters(CHATS, [GET_CHATS]),
        ...mapGetters(CHANNELS, [GET_CHANNELS]),
        ...mapGetters(CONTENT_MANAGER, [GET_SEARCH_OPENED]),
        ...mapGetters(CLIENTDATA, [GET_LOCALE]),
        ...mapGetters(LOGIN, [GET_SERVER_API]),
        ...mapGetters(USERDATA, [GET_SEARCHED_CONTACTS]),
        ...mapGetters(CONTACTS, [
            GET_IS_ROLES_SUPPORTED,
            GET_MY_CONTACT,
            GET_IS_SEARCH_FILTER_SUPPORTED,
            GET_CONTACTS_FILTER_BY_NAME,
        ]),
    },
    asyncComputed: {
        async channels() {
            this.loading = false
            if(this.pane !== 'channels' || !this.search.length) return []
            let remoteChannels
            try {
                remoteChannels = await this.$store.dispatch('channels/search', this.search);
            } catch(e) {
                remoteChannels = [];
            }
            return remoteChannels;
        },
        async chats() {
            let locale = i18n.messages[i18n.locale]['search-comp']
            this.loading = false
            if(this.pane !== 'group' || !this.search.length) return []
            const all_chats = this[GET_CHATS]
            let chats = JSON.parse(JSON.stringify(all_chats));
            for (let i in all_chats) {
                let contact = this.$store.getters['contacts/getMergedContactById'](all_chats[i].cid) || {fio: locale['unknown-contact']}; //TODO:

                if (chats[i].cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP && !chats[i].name) {chats[i].name = locale['group-chat'];}
                else if (chats[i].cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER) chats[i].name = contact.fio;
            }
            return chats.filter(chat => chat.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1);
        },
        async group() {
            this.loading = false
            if(this.pane !== 'group' || !this.search.length) return []
            return (await this.chats).filter(chat => {return chat.cidType === 'group'});
        },
        async bots() {
            this.loading = false
            if(this.pane !== 'bots' || !this.search.length) return []
            let bots;
            try {
                bots = await this[ACT_SEARCH_BOTS]({'filter': this.search});
            } catch(e) {
                console.log(e);
            }
            return bots || [];
        },
    },
    watch: {
        isSearchOpened(val) {
            this.open = this.isSearchAllowed
            if (val) {
                const input = document.querySelector('.search-input')
                setTimeout(() => { input && input.focus() }, 0)
            } else {
                this.close()
                let tmp = document.createElement("input");
                document.body.appendChild(tmp);
                tmp.focus();
                document.body.removeChild(tmp);
            }
        },
        search() {
            this.loading = true
            this.selectedNode = -1
            this.clearContacts()
            this.getContacts()
            this.showSearchedContacts()
        },
        contactsExtFilter() {
            this.loading = true
            this.selectedNode = -1
            this.clearContacts()
            this.getContacts()
            this.showSearchedContacts()
        },
        channels() {
            this.showSearchedContacts()
        },
        group() {
            this.showSearchedContacts()
        },
        bots() {
            this.showSearchedContacts()
        },
        open() {
            this.showSearchedContacts()
        },
        current_pane() {
            this.clearContacts()
            this.getContacts()
            this.showSearchedContacts()  
        }
    },
    filters: {
        rus(str) {
            let result;
            let locale = i18n.messages[i18n.locale]
            switch (str) {
                case 'contacts':
                    result = locale.people;
                    break;
                case 'group':
                    result = locale.groups;
                    break;
                case 'channels':
                    result = locale.channels;
                    break;
                case 'bots':
                    result = locale.bots;
                    break;
                default:
                    result = locale.all;
            }
            return result;
        }
    }
}

const debounce = (func, delay) => {
    let debounceTimer
    return function() {
        const context = this
        const args = arguments
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(() => { func.apply(context, args) }, delay)
    }
}
