//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomListItem from "../custom/CustomListItem.vue"
import IconImage from "../IconImage.vue"
import ScrollToMessageMixin from "../chat/ScrollToMessageMixin"

export default {
    name: "media-search-links",
    props: ["messages"],
    mixins: [ScrollToMessageMixin],
    components: {
        IconImage,
        CustomListItem
    },
    data() {
        return {};
    },
    methods: {
        makeHeader(item) {
            const firstLink = item.msg[0];
            if (item.linkItemHeader.substr(0, 10) !== firstLink.substr(0, 10))
                return item.linkItemHeader;
            else return false;
        },
        makeTitle(links) {
            let title = "";
            links.forEach(link => {
                title += link + "\n";
            });
            return title;
        },
        // makeFooter(item) {
        //     //return this. + " " + item.date;
        // },

        // makeTitleHighlights(message) {
        //     const titleHighLights = {}
        //     titleHighLights.highlightString = message.msg
        //     return titleHighLights
        // },
        getPhoto(cid) {
            return this.$store.getters["contacts/getMergedContactById"](cid).photo || ""
        },
        getFio(cid) {
            return this.$store.getters["contacts/getMergedContactById"](cid).fio || ""
        },
        onContextMenu(e, message) {
            //console.log("!! -> file: MediaSearchLinks.vue -> line 74 -> onContextMenu -> message", message)
            this.$emit("mediasearch-common-contextmenu", { e, message });
        },
        scrollToMessage(e, id) {
            if (e.type === "click" && id) this.scrollingToElement(id);
        },
        onScroll(e) {
            //console.log("!! -> file: MediaSearchLinks.vue -> line 81 -> onScroll -> e", e)
        },
        async openLinkContextMenu(e) {
            //if (navigator.platform.toUpperCase().indexOf('MAC')>=0) await (m => new Promise((resolve) => setTimeout(resolve, m)))(100)
            let { event, link } = e.detail;
            let handlers = [];
            handlers.push({
                item_name: this.$t("open-link"),
                handler: this.openUrlLink,
                data: link
            });
            handlers.push({
                item_name: this.$t("copy-link"),
                handler: this.copyToClipboard,
                data: link
            });
            this.cmOpen(event, handlers, "right-bottom");
        },
        openUrlLink(link) {
            window.openLink(link);
        },
        copyToClipboard(data = "") {
            this.$copyText(data);
        }
    }
};
