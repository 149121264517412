//
//
//
//
//
//
//
//
//
//
//

import IconImage from '../IconImage.vue';
import ItemListMixin from './ItemListMixin'
import {mapGetters, mapActions} from 'vuex';
import {CONTENT_MANAGER, CHATS, CONTACTS} from '../../store/modulesNames';
import {GET_SELECTED_CHAT, GET_MAIN_TYPE} from '../../store/gettersTypes';

import {ACT_LOAD_BOT_CONTACT} from "../../store/actionsTypes"

export default {
    name: 'search-bots',
    mixins: [ItemListMixin],
    components: {IconImage},
    props: {
        items: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    computed: {
        ...mapGetters(CHATS, [GET_SELECTED_CHAT]),
        ...mapGetters(CONTENT_MANAGER, [GET_MAIN_TYPE])
    },
    methods: {
        async openBot(bot) {
            await this[ACT_LOAD_BOT_CONTACT](bot.cid)
            this.$store.dispatch('chats/update')
            this.$store.dispatch('chats/open', {cid: bot.cid, cidType: 'user'})
        },
        ...mapActions(CONTACTS, [ACT_LOAD_BOT_CONTACT])
    },
}
