var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-item", class: _vm.classes }, [
    _vm.has_media_slot
      ? _c("div", { staticClass: "item-media" }, [_vm._t("media")], 2)
      : _vm._e(),
    _c("div", { staticClass: "item-content" }, [
      _c("div", { staticClass: "item-text" }, [
        _vm.header
          ? _c("span", { staticClass: "item-header" }, [
              _vm._v(_vm._s(_vm.header)),
            ])
          : _vm._e(),
        _vm.withHighlights
          ? _c("div", { staticClass: "htswrapper" }, [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.stringWithHighlights) },
              }),
            ])
          : _c("span", { staticClass: "item-title" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
        _vm.footer
          ? _c("span", { staticClass: "item-footer" }, [
              _vm._v(_vm._s(_vm.footer)),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "item-footer-link" },
          [_vm._t("footer-link")],
          2
        ),
      ]),
      _vm.has_media_after
        ? _c("div", { staticClass: "item-after" }, [_vm._t("after")], 2)
        : _vm._e(),
      _vm.link && _vm.chevron
        ? _c("div", { staticClass: "item-link" }, [
            _vm.close
              ? _c("i", {
                  staticClass: "fa fa-times",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.clear($event)
                    },
                  },
                })
              : _c("i", { staticClass: "fal fa-angle-right" }),
          ])
        : _vm._e(),
      _vm.link && _vm.circles
        ? _c("div", { staticClass: "item-link" }, [
            _c(
              "div",
              {
                staticClass: "right circle-animation",
                on: {
                  click: function ($event) {
                    return _vm.$emit("customevent", $event)
                  },
                },
              },
              [
                _c("svg", { attrs: { width: "10px", height: "24px" } }, [
                  _c("circle", { attrs: { cx: "5", cy: "5", r: "2" } }),
                  _c("circle", { attrs: { cx: "5", cy: "12", r: "2" } }),
                  _c("circle", { attrs: { cx: "5", cy: "19", r: "2" } }),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }