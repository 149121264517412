//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import modal_mixin from '../modal/modal-mixin'
import CustomInputText from '../custom/CustomInputText.vue'
import DateRangePicker from '../settings/DateRangePicker.vue'
import CustomRadio from '../custom/CustomRadio.vue'
import ListItem from '../custom/ListItem.vue'
import ModalBtn from '../modal/ModalBtn.vue'
import ExtConfSettings from './scheduleConferenceSettings.vue'
import {mapActions} from "vuex"
import {VM_CONF} from "../../store/modulesNames"
import {ACT_VM_CONF_DELETE, ACT_VM_CONF_SET_PARAMS} from "../../store/actionsTypes"
import moment from 'moment'

export default {
    name: "vmConferenceEdit",
    components: { CustomInputText, DateRangePicker, CustomRadio, ListItem, ModalBtn/*, ExtConfSettings*/ },
    mixins: [modal_mixin],
    props:  {
        conf: {
            type: Object,
            required: true,
            default: {}
        }
    },
    data() {
        return {
            mainTitle: this.$t('videomost.menu-title'),
            confModes: [{
                    value: true,
                    label: this.$t('videomost.conf-discussion')
                },
                {
                    value: false,
                    label: this.$t('videomost.conf-presentation')
                }
            ],
            compModalProps: {
                delay: 100,
                pivotY: 0.44,
                width: 464,
                height: 404,
                clickToClose: false
            },
            startConfText: this.$t('videomost.conf-run'),
            editConfText: this.$t('videomost.conf-edit'),
            deleteConfText: this.$t('videomost.conf-delete'),
            confSettingsText: this.$t('videomost.conf-settings'),
            placeholder: this.$t('videomost.conf-enterway'),
            chosenEnterWay: null,
            dateTimeFromStr: '',
            dateTimeToStr: '',
            confSettingsText: this.$t('videomost.conf-settings'),
            subjectMissed: false,
            fromMissed: false,
            toMissed: false,
            confIdMissed: false,
            confPasswordMissed: false,
            initialConfId: '',
        }
    },
    watch: {
        startUTime(val) {
            if (val) this.fromMissed = false
        },
        finishUTime(val) {
            if (val) this.toMissed = false
        },
        correctFromTo(val) {
            if (val) this.fromMissed = this.toMissed = false
        }
    },
    mounted() {
        if (!!this.conf.confid) this.initialConfId = this.conf.confid
    },
    computed: {
        startUTime () {
            return +this.conf.startUTime
        },
        finishUTime () {
            return +this.conf.finishUTime
        },
        correctFromTo () {
            const startUTime = +this.conf.startUTime
            const finishUTime = +this.conf.finishUTime
            return startUTime && finishUTime && startUTime < finishUTime
        },
    },
    methods: {
        getStartTime(conf) {
            let mskTime = this.getLocalTime(conf.startUTime)
            return mskTime
        },
        getFinishTime(conf) {
            let mskTime = this.getLocalTime(conf.finishUTime)
            return mskTime
        },
        getLocalTime(dt) {
            return moment.unix(dt).local().format('YYYY.MM.DD HH:mm:ss')
        },
        async confirmConfChange(conf) {
            if (!conf.topic) this.subjectMissed = true
            if (!conf.startUTime) this.fromMissed = true
            if (!conf.finishUTime) this.toMissed = true
            if (conf.startUTime && conf.finishUTime && !this.correctFromTo) this.fromMissed = this.toMissed = true
            if (!conf.password) this.confPasswordMissed = true
            if (this.subjectMissed || this.fromMissed || this.toMissed || this.confPasswordMissed) return
            
            const confid = conf.confid
            // После обновления videoMost меняет только по unix
            // const startTime = moment.unix(conf.startUTime).utc().format("YYYY-MM-DD HH:mm:ss")
            const startUTime = +conf.startUTime
            // const finishTime = moment.unix(conf.finishUTime).utc().format("YYYY-MM-DD HH:mm:ss")
            const finishUTime = +conf.finishUTime

            let params = {
                confid,
                // startTime,
                startUTime,
                // finishTime,
                finishUTime,
                topic: conf.topic,
                password: conf.password
            }

            const initConfId = this.initialConfId
            const changes = {confid: initConfId, params}
            const result = await this[ACT_VM_CONF_SET_PARAMS](changes)
            
            this.modalClose()
            this.$bus.$emit('reload-conf-lists')
        },
        openExtendedSettings(conf) {
            this.modalClose()
            this.modalOpen({
                    component: ExtConfSettings,
                    props: { conf }
            })
        },
        deleteConfConfirm(confID) {

            let close = async () => {
                await this.deleteConf(confID)
                this.$bus.$emit('reload-conf-lists')
                this.modalClose()
            }

            this.modalOpen({
                name: 'confirm',
                props: {
                    text: this.$t('videomost.conf-delete-confirm'),
                    btnOk: {
                        cb: () => close()
                    }
                }
            })
            
        },
        async deleteConf(confid) {
            let result = await this[ACT_VM_CONF_DELETE]({confid})
        },
        ...mapActions(VM_CONF, [ACT_VM_CONF_SET_PARAMS, ACT_VM_CONF_DELETE])
    },
}
