//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions} from 'vuex'
import {GET_SCROLL_TO_PUBLICATION} from '../../store/gettersTypes'
import {MUT_SCROLL_TO_PUBLICATION} from '../../store/mutationsTypes'
import {ACT_INFO_PUSH, ACT_INFO_REPLACE} from '../../store/actionsTypes'
import {INFO, CHANNEL} from '../../store/modulesNames'
import Publication from './Publication.vue'
import ChannelInvite from './ChannelInvite.vue'

export default {
    components: {
        Publication,
        ChannelInvite
    },
    data() {
        return {
            visible: false,
            firstPubsLoad: true,
            loadingPublication: false,
            pubLoadPossible: false,
            pubLoadStepSize: 20,
        }
    },
    updated() {
        this.doScrollToPublication()
    },
    mounted() {
        let elem = document.getElementById('publications-list')
        if(elem) elem.scrollTop = 0
    },
    watch: {
        chId() {
            let node = this.$el
            node = node && node.querySelector && node.querySelector('#publications-list')
            if (node) node.scrollTop = 0
            this.firstPubsLoad = true
            this.pubLoadPossible = false
        },
        publications(publications) {
            if (this.firstPubsLoad) {
                this.visible = true
                this.firstPubsLoad = false
                this.pubLoadPossible = publications.length === this.pubLoadStepSize
            }
        },
        scrollToPublication() {
            this.doScrollToPublication()
        }
    },
    computed: {
        opened() { return this.$store.getters['channel/visible'] },
        joined() { return this.$store.getters['channels/joinedP'] },
        chId() { return this.$store.getters['channel/chId'] },
        publications() { return this.$store.getters['channel/getPublications'] },
        channel() { return this.$store.getters['channels/getChannel']({chId: this.chId}) },
        can_join() { return this.channel && Channel.prototype.canJoin(this.channel.status) || !this.joined },
        is_banned() { return this.channel && Channel.prototype.isBanned(this.channel.status) },
        admin() { return this.channel && Channel.prototype.hasAdminRights(this.channel.privilege) },
        selected() { return this.$store.getters['channels/selected'] },
        scrollToPublication() { return this.$store.getters[`${CHANNEL}/${GET_SCROLL_TO_PUBLICATION}`] },
        invited() { return this.channel && this.channel.status === declarations.channel_user_statuses.INVITED },
    },
    methods: {
        doScrollToPublication() {
            if(this.scrollToPublication && this.scrollToPublication.chId === this.chId && this.scrollToPublication.pubId && this.publications.length) {
                let containsPublication = this.publications.some(item => {
                    return item.pubId === this.scrollToPublication.pubId
                })
                if(containsPublication) {
                    let element = document.querySelector('#publication' + this.scrollToPublication.pubId)
                    element && element.scrollIntoView({behavior: 'smooth', block: 'center'})
                    element && this.$store.commit(`${CHANNEL}/${MUT_SCROLL_TO_PUBLICATION}`, { chId: this.chId, pubId: null})
                } else {
                    this.$store.commit('channel/clearPublications')
                    this.$store.dispatch('channel/updatePublication', {chId: this.chId, pubId: this.scrollToPublication.pubId})
                }
            }
        },
        doContextMenu(event, type) {
            let handlers = []
            let open = this.cmOpen
            switch (type) {
                case 'channelOptions':
                    handlers.push({item_name: this.$t('mainPage.channel-info'), handler: () => {
                        this.channel && this[ACT_INFO_PUSH]({type: 'channel-info', params: {chId: this.chId}})
                    }})
                    break
            }
            open(event, handlers)
        },
        async doJoinChannel() {
            await this.$store.dispatch('channels/changeChannelUsers', {
                chId: this.selected,
                contacts: [{
                    cid: app.getUid(),
                    privilege: declarations.channel_user_privilege.USER,
                    status: declarations.channel_user_statuses.JOINED}]
            })
        },
        /*doLikePublication(pubId, like_state) {
            this.$store.dispatch('channel/publicationLike', {pubId: pubId, chId: this.chId, state: !like_state})
        },*/
        doOnScroll(e) {
            let wrapper = e.target
            let list = wrapper.firstElementChild
            let scrollTop = wrapper.scrollTop
            let wrapperHeight = wrapper.offsetHeight
            let listHeight = list.offsetHeight
            let diffHeight = listHeight - wrapperHeight

            if(diffHeight <= scrollTop && !this.loading) this.doLoadPublication()
        },
        async doLoadPublication() {
            if(!this.pubLoadPossible) return
            this.loadingPublication = true
            let result = await this.$store.dispatch('channel/update', {
                chId: this.chId,
                fromPubId: (this.publications[this.publications.length - 1].pubId),
                count: this.pubLoadStepSize
            })
            this.loadingPublication = false
            this.pubLoadPossible = result.length === this.pubLoadStepSize
        },
        ...mapActions(INFO, [ACT_INFO_PUSH, ACT_INFO_REPLACE])
    },
}
