//
//
//
//
//
//
//
//

"use strict"

import ChatRegularFileMessage from "../chat/ChatRegularFileMessage.vue"

export default {
    name: "media-search-files",
    props: ["messages"],
    components: {
        ChatRegularFileMessage
    },
    data() {
        return {}
    },
    methods: {
        prepareMessages(messages) {
            let res = []
            messages.forEach(m => {
                const { msg, ...rest } = m
                if (msg.constructor === Array) {
                    msg.forEach(msg => {
                        if (msg.type === 'file') res.push({ msg, ...rest})
                    })
                }
            })
            return res.length ? res : messages
        },
        onContextMenu(e, message) {
            this.$emit("mediasearch-common-contextmenu", { e, message })
        }
    }
}
