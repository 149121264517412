//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconImage from "../IconImage.vue";
import { mapActions } from "vuex";
import { GET_UID } from "../../store/gettersTypes";
import {
    ACT_ADD_PUBLICATION_COMMENT,
    ACT_INFO_PUSH,
} from '../../store/actionsTypes'
import { USERDATA, INFO, CHANNEL } from '../../store/modulesNames'
import proto from "../../protocol";
import ReferenceMsg from "../chat/ReferenceMsg.vue";
import { i18n } from "../../../ext/i18n";
import modal_mixin from "../modal/modal-mixin";
import moment from 'moment'

export default {
    name: "channel-comments",
    components: {
        "icon-image": IconImage,
        "reference-msg": ReferenceMsg
    },
    props: ["chId", "publId"],
    mixins: [modal_mixin],
    data() {
        return {
            input: "",
            textareaInFocus: false,
            placeholder: true,
            opacity: false,
            opacityFirst: true,
            compModalProps: {
                delay: 200,
                pivotY: 0.05,
                width: 600,
                height: "auto"
            }
        };
    },
    mounted() {
        this.$store.dispatch("channel/publicationComments", {
            pubId: this.publId,
            chId: this.chId,
            count: 100
        });
    },
    beforeDestroy() {
        this.$store.commit("channel/deleteComments");
    },
    watch: {
        input() {
            if (this.input.length > 0) this.placeholder = false;
            else this.placeholder = true;
        },
        comments() {
            if (this.comments.length > 0) {
                setTimeout(() => {
                    this.opacity = true;
                    if (this.opacity === true) this.opacityFirst = true;
                }, 0);
            }
        }
    },
    computed: {
        user() {
            let uid = this.$store.getters[`${USERDATA}/${GET_UID}`];
            return this.$store.getters["contacts/getMergedContactById"](uid);
        },
        comments() {
            let comments = this.$store.getters[
                "channel/getPublicationComments"
            ];
            comments.forEach((comment, i) => {
                let user = this.$store.getters["contacts/getMergedContactById"](
                    comment.cid
                );
                comments[i].userPhoto = user.photo;
                comments[i].userFio = user.fio;
                comments[i].time = formatTime(comment.time);
                let { outText, entities } = this.extractInputTextFormat(comment.text)
                outText = outText.replace(/</g,'\x7F').replace(/>/g,'\x8F')
                let formattedText = this.messageTextFormat(entities, outText)
                formattedText = formattedText.replace(/\x7F/g,'&lt;').replace(/\x8F/g,'&gt;')
                comments[i].textComment = formattedText
            });
            return comments;

            function formatTime(timeCall) {
                let channels = i18n.messages[i18n.locale]["channel-comp"];
                let result;
                let timeCallFormat = moment().subtract(timeCall, "seconds");
                let today = moment().startOf("day");
                let yesterday = moment()
                    .subtract(1, "days")
                    .startOf("day");
                let twoDays = moment()
                    .subtract(2, "days")
                    .startOf("day");
                if (timeCallFormat.isAfter(today)) {
                    result = moment()
                        .subtract(timeCall, "seconds")
                        .format("HH:mm");
                } else if (
                    timeCallFormat.isBefore(today) &&
                    timeCallFormat.isAfter(yesterday)
                ) {
                    result = channels.yesterday;
                } else if (
                    timeCallFormat.isBefore(yesterday) &&
                    timeCallFormat.isAfter(twoDays)
                ) {
                    result = channels["before-yest"];
                } else {
                    result = moment()
                        .subtract(timeCall, "seconds")
                        .format("DD MMM YYYY");
                }
                return result;
            }
        }
    },
    methods: {
        getTextEntities(text) {
            if (!text) return { outText: "", entities: [] };
            return this.extractInputTextFormat(text);
        },
        onChangeInput() {
            this.input = this.$refs.textarea.innerText;
        },
        async addComment() {
            if (this.input.length === 0) return;
            await this[ACT_ADD_PUBLICATION_COMMENT]({
                pubId: this.publId,
                chId: this.chId,
                text: this.input
            })
            this.$refs.textarea.innerText = "";
            this.input = "";
            this.$refs.commentList.scrollTo(0, this.$refs.commentList.scrollHeight)
            //if (this.opacity === true) this.opacityFirst = false;
        },
        close() {
            this.$modal.hide("channel-comments");
        },
        firstChildAnimation() {
            if (!this.opacity) return "";
            else if (this.opacityFirst) return "show-first";
            else return "hide-first";
        },
        openInfo(cid) {
            if (cid)
                this[ACT_INFO_PUSH]({
                    type: "chat-info",
                    params: { cid: +cid }
                });
            this.close();
        },
        doContextMenu(e, user) {
            let channels = i18n.messages[i18n.locale]["channel-comp"];
            if (user.cid) {
                e.preventDefault();
                e.stopPropagation();
                let handlers = [];
                let open = this.cmOpen;
                let data;
                let tagsRE = /(\<(\/?[^>]+)>)/g;

                if (e.target.className === "e-mail") data = e.target.innerText;
                else if (tagsRE.test(user.text))
                    data = user.text.replace(tagsRE, "");
                else data = user.text;

                handlers.push({
                    item_name: channels.copy,
                    handler: this.copy,
                    data: data
                });
                if (user.cid)
                    handlers.push({
                        item_name: channels["open-profile"],
                        handler: this.openInfo,
                        data: user.cid
                    });
                open(event, handlers);
            }
        },
        copy(text) {
            this.$copyText(text);
        },
        checkReference(text) {
            if (text && text.search(/^@\[|\s@\[/) > -1) return true;
        },
        ...mapActions(INFO, [ACT_INFO_PUSH]),
        ...mapActions(CHANNEL, [ACT_ADD_PUBLICATION_COMMENT]),
    }
};
