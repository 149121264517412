var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "EmojiPickerWrapper" } },
    [
      _vm.isEmojiPickerVisible
        ? _c("Picker", {
            style: _vm.style,
            attrs: {
              data: _vm.index,
              perLine: 7,
              set: "apple",
              showPreview: false,
              emojiSize: 24,
              i18n: _vm.translation,
            },
            on: {
              select: function ($event) {
                return _vm.addEmoji($event)
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "searchTemplate",
                  fn: function (slotProps) {
                    return [
                      _c("input", {
                        attrs: {
                          type: "text",
                          placeholder: _vm.translation.search,
                        },
                        on: {
                          input: function ($event) {
                            return slotProps.onSearch($event.target.value)
                          },
                        },
                      }),
                    ]
                  },
                },
              ],
              null,
              false,
              426513171
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }