var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-entrance" }, [
    _c("div", { staticClass: "component-header text-center" }),
    _c("div", { staticClass: "component-section" }, [
      _c(
        "form",
        {
          staticClass: "input-block",
          on: {
            submit: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.doLogin($event)
            },
          },
        },
        [
          _c("custom-input-text", {
            directives: [{ name: "focus", rawName: "v-focus" }],
            ref: "input_mail",
            staticClass: "input",
            attrs: {
              default_text: _vm.login,
              input_text: _vm.login,
              label: _vm.$t("loginPage.email"),
              tabindex: "1",
            },
            on: {
              "update:input_text": function ($event) {
                _vm.login = $event
              },
            },
          }),
          _c("custom-input-text", {
            ref: "input_pass",
            staticClass: "input",
            attrs: {
              default_text: _vm.password,
              input_text: _vm.password,
              type: "password",
              label: _vm.$t("loginPage.password"),
              tabindex: "2",
            },
            on: {
              "update:input_text": function ($event) {
                _vm.password = $event
              },
            },
          }),
          false
            ? _c("custom-checkbox", {
                staticClass: "input",
                attrs: {
                  default: _vm.remember_me,
                  checkbox: _vm.remember_me,
                  label: _vm.$t("loginPage.logged-in"),
                  tabindex: 3,
                },
                on: {
                  "update:checkbox": function ($event) {
                    _vm.remember_me = $event
                  },
                },
              })
            : _vm._e(),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: {
                id: "login-btn",
                tabindex: "4",
                disabled: _vm.disableLogin,
              },
            },
            [_vm._v(_vm._s(_vm.$t("loginPage.to-log-in")))]
          ),
        ],
        1
      ),
    ]),
    _vm.avanpostEnabled
      ? _c("div", { staticClass: "oauth2-section" }, [
          _c("div", { staticClass: "alt-entrace-btns" }, [
            _c(
              "div",
              {
                staticClass: "alt-entrace-btn avanpost-entrace",
                on: {
                  click: function ($event) {
                    return _vm.oauth("avanpost")
                  },
                },
              },
              [
                _c("img", { attrs: { src: "img/avanpost_logo.png" } }),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("loginPage.entrance-with-avanpost"))),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _vm.adEnabled
      ? _c("div", { staticClass: "oauth2-section" }, [
          _c("div", { staticClass: "alt-entrace-btns" }, [
            _c(
              "div",
              {
                staticClass: "alt-entrace-btn ad-entrace",
                on: {
                  click: function ($event) {
                    return _vm.oauth("ad")
                  },
                },
              },
              [
                _c("img", { attrs: { src: "img/microsoft_logo.png" } }),
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("loginPage.entrance-with-ad"))),
                ]),
              ]
            ),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "component-footer" }, [
      _c("div", { staticClass: "pass-restore-block text-center" }, [
        _c(
          "a",
          {
            attrs: { id: "pass-restore", tabindex: "5" },
            on: {
              click: function ($event) {
                return _vm.doOpenEntrance("pass-restore")
              },
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.doOpenEntrance("pass-restore")
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("loginPage.forgot-pass")))]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }