//
//
//
//
//
//
//
//
//
//
//
//

'use strict';

import { i18n } from '../../../ext/i18n'

export default {
   name: 'custom-input',
   props: ['contenteditable', 'text', 'idTextarea', 'typeField'],
   data() {
       return {
           locale: i18n.messages[i18n.locale],
           placeholder: false,
           focused: false,
       }
   },
   mounted() {        
       this.render()
       if(typeof this.text === 'undefined') {
           document.getElementById(this.idTextarea).innerText = ''
           this.showPlaceholder()       
       }
   },
   watch: {
       text() {
           this.checkText()
           this.showPlaceholder()
       },
       focused() {
           this.showPlaceholder()
       }
   },
   computed: {
       placeholderName() {
           let multipleFieldsPhones = ['workphone', 'homephone', 'mobilephone', 'phone', 'fax']
           let multipleFieldsAddress = ['workaddress', 'homeaddress', 'address']

           switch(this.typeField) {
               case 'workphone':
               case 'homephone':
               case 'mobilephone':
               case 'phone':
               case 'fax':
                   return this.locale.loginPage.remote
               case 'workaddress':
               case 'homeaddress':
               case 'address':
                   return this.locale.address
               case 'icq':
                   return this.locale.information.icq
               case 'site':
                   return this.locale.information.website
               case 'children':
                   return this.locale.information.children
               case'mail':
                   return this.locale.mail
           }
       },
   },
   methods: {
       update: function (event) {
           this.$emit('update:text', event.target.innerText);
       },
       render() {
           if(document.getElementById(this.idTextarea).innerText !== this.text) document.getElementById(this.idTextarea).innerText = this.text;
       },
       checkText() {
           if(this.text === this.locale['your-var']) {
               document.getElementById(this.idTextarea).innerText = ''
               this.focusInput()    
           } else this.render()
       },
       showPlaceholder() {
           if(!this.focused && document.getElementById(this.idTextarea).innerText === '') this.placeholder = true
           else this.placeholder = false
       },
       focusInput() {
           setTimeout(() => {
               let elem = document.getElementById(this.idTextarea)
               elem.focus();
           }, 0);
       },
       onFocus() {
           this.focused = true
       },
       onBlur() {
           this.focused = false
       }
   }    
}
