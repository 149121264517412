//
//
//
//
//
//
//
//
//
//
//


const minSymbolsToSearch = 2, maxRequestCount = 50

import InfoMainBlock from "./InfoMainBlock.vue"
import IconImage from "../IconImage.vue"
import ListItem from "../custom/ListItem.vue"
import CustomTabs from "../custom/CustomTabs.vue"
import { mapGetters } from "vuex"
import { USERDATA, CHAT } from "../../store/modulesNames"
import { GET_UID, GET_CHAT_MEDIA_SEARCH_STRING } from "../../store/gettersTypes"
import moment from "moment"

import ContentLoader from "../main/body/MainContentLoader.vue"
import ScrollToMessageMixin from "../chat/ScrollToMessageMixin"
import checkMessageDataExist from "../chat/checkMessageDataExist"

import SelectChatToForward from  '../modal/SelectChatToForward.vue'
import DelChatMessage from "../modal/DelChatMessage.vue"
import MediaSearchImages from "../mediaViewer/MediaSearchImages.vue"
import MediaSearchVideos from "../mediaViewer/MediaSearchVideos.vue"
import MediaSearchAudio from "../mediaViewer/MediaSearchAudio.vue"
import MediaSearchFiles from "../mediaViewer/MediaSearchFiles.vue"
import MediaSearchLinks from "../mediaViewer/MediaSearchLinks.vue"

const mediaTypeComponents = [
    { type: "image", componentName: "media-search-images" },
    { type: "video", componentName: "media-search-videos" },
    { type: "audio", componentName: "media-search-audio" },
    { type: "file", componentName: "media-search-files" },
    { type: "link", componentName: "media-search-links" }
]

const checkIsScrollVisible = () => {
    let isVisible = false
    return new Promise((resolve) => {
        setTimeout(() => {
            const elem = document.getElementById("media-search-area")
            if (elem) {
                isVisible = elem.scrollHeight > elem.clientHeight
                resolve(isVisible)
            }
        }, 0)
    })
}

export default {
    name: "MediaSearch",
    components: {
        IconImage,
        ListItem,
        CustomTabs,
        InfoMainBlock,
        ContentLoader,
        MediaSearchImages,
        MediaSearchVideos,
        MediaSearchFiles,
        MediaSearchLinks,
        MediaSearchAudio,
        DelChatMessage
    },
    props: ["params", "canBack"],
    mixins: [ScrollToMessageMixin, checkMessageDataExist],
    mounted() {
        this.chosenTab = this.defaultTabValue
        this.isScrollVisible = checkIsScrollVisible()
    },
    data() {
        return {
            chats: [],
            foundChats: [],
            searchString: "",
            chunkStartId: 0,
            firstSearch: true,
            noRecordsItem: { title: this.$t("media-search.messages-not-found") },
            isLookingFor: false,
            itemSize: 71,
            tabs: [
                { key: 0, value: this.$t("media-search.image") },
                { key: 1, value: this.$t("media-search.video") },
                { key: 2, value: this.$t("media-search.audio") },
                { key: 3, value: this.$t("media-search.file") },
                { key: 4, value: this.$t("media-search.link") }
            ],
            chosenTab: -1,
            isTabSwitched: false,
            isScrollVisible: false,
            isShowSearchMore: true,
        }
    },
    computed: {
        cid() {
            return this[GET_UID]
        },
        isChannel() {
            return !!this.params.chId
        },
        getTabs() {
            if (this.params.chId) return this.tabs.filter(t => t.key < 4)
            else return this.tabs
        },
        bufferSize() {
            return this.itemSize * 10
        },
        defaultTabValue() {
            return this.params && this.params.tabIndexValue
        },
        scrollerStyle() {
            return this.foundChats.length ? "height: 100%" : ""
        },
        getSearchString() {
            return this[GET_CHAT_MEDIA_SEARCH_STRING]
        },
        mediaType() {
            let retValue = ""
            switch (this.chosenTab) {
                case 0:
                    retValue = "image"
                    break
                case 1:
                    retValue = "video"
                    break
                case 2:
                    retValue = "audio"
                    break
                case 3:
                    retValue = "file"
                    break
                case 4:
                    retValue = "link"
                    break
            }
            return retValue
        },
        mediaSearchComponent() {
            const component = mediaTypeComponents.find(c => c.type === this.mediaType)
            return component && component.componentName
        },
        showContentLoader() {
            return this.isLoader || this.isLookingFor
        },
        isLoader() {
            return (
                this.firstSearch && this.searchString && !this.foundChats.length
            )
        },
        isNoResults() {
            return (
                !this.firstSearch && !this.isLookingFor &&
                !this.foundChats.length && this.searchString.length >= 2
            )
        },
        grouppedMessages() {
            const messagesOrganizedByMonth = this.foundChats.reduce(
                (group, message) => {
                    const { month } = message
                    group[month] = group[month] ?? []
                    group[month].push(message)
                    return group
                },
                {}
            )
            return messagesOrganizedByMonth
        },
        showSearchMore() {
            return this.isScrollVisible && this.isShowSearchMore
        },
        readOnly() {

        },
        ...mapGetters(USERDATA, [GET_UID]),
        ...mapGetters(CHAT, [GET_CHAT_MEDIA_SEARCH_STRING])
    },
    methods: {
        getPhoto(cid) {
            return (
                this.$store.getters["contacts/getMergedContactById"](cid)
                    .photo || ""
            )
        },
        getFio(cid) {
            return (
                this.$store.getters["contacts/getMergedContactById"](cid).fio ||
                ""
            )
        },
        getMessageItem(message) {
            let out = message.type === "out" || app.getUid() === message.senderId
            if (message.data.pollId) message.data.type = "poll"
            return {
                id: message.id,
                chId: '',
                cid: message.cid,
                cidType: message.cidType,
                senderId: message.senderId,
                author_tmp: message.author,
                type: message.dataType,
                sub_type: message.data && message.data.type,
                date: this.formatTime(message.time),
                month: this.formatTimeAsMonth(message.time),
                //time: date_helper.secondsLeftToTimeFormat(message.time),
                time: message.time,
                msg: this.getItemText(message), // message.id ? this.getItemText(message) : message.data,
                linkItemHeader: this.mediaType === "link" ? message.data.text.substr(0, 40) : null,
                text: message.data.text,
                ownMsg: out
            }
        },
        getChannelMessageItem(message) {
            if (!Object.keys(message).length) return {}
            let out = message.type === "out" || app.getUid() === message.senderId
            if (message.data.pollId) message.data.type = "poll"
            return {
                chId: message.chId,
                pubId: message.pubId,
                cidType: message.cidType,
                date: this.formatTime(message.time),
                month: this.formatTimeAsMonth(message.time),
                //time: date_helper.secondsLeftToTimeFormat(message.time),
                time: message.time,
                msg: message.data,
                title: message.hasOwnProperty("title") ? message.title : '', 
                info: message.hasOwnProperty("info") ? message.info : '',
                ownMsg: out
            }
        },
        getItemText(message) {
            let text = ""
            switch (message.dataType) {
                case declarations.msgDataTypes.MSG_DATA_TYPE_TEXT:
                    text = message.data
                    break
                case declarations.msgDataTypes.MSG_DATA_TYPE_DATA:
                    if (message.data.pollId) message.data.type = "poll"
                    if (this.mediaType === "link") {
                        text = getDataText(message.data, true)
                    } else text = getDataText(message.data)
                    break
                case declarations.msgDataTypes.MSG_DATA_TYPE_UNSTORED:
                    text = message.data
                    break
                case declarations.msgDataTypes.MSG_DATA_TYPE_SYSTEM:
                    text = ""
                    break
            }
            return text
        },
        formatTime(timeCall) {
            let result
            let timeCallFormat = moment().subtract(timeCall, "seconds")
            let today = moment().startOf("day")
            let yesterday = moment()
                .subtract(1, "days")
                .startOf("day")
            let twoDays = moment()
                .subtract(2, "days")
                .startOf("day")
            if (timeCallFormat.isAfter(today)) {
                result = moment()
                    .subtract(timeCall, "seconds")
                    .format("HH:mm")
            } else if (
                timeCallFormat.isBefore(today) &&
                timeCallFormat.isAfter(yesterday)
            ) {
                result = this.$t("channel-comp.yesterday")
            } else if (
                timeCallFormat.isBefore(yesterday) &&
                timeCallFormat.isAfter(twoDays)
            ) {
                result = this.$t("channel-comp.before-yest")
            } else {
                result = moment()
                    .subtract(timeCall, "seconds")
                    .format("DD MMM YYYY")
            }
            return result
        },
        formatTimeAsMonth(timeCall) {
            const timeWithoutSec = moment().subtract(timeCall, "seconds")
            const nowTimeYear = moment().format("YYYY")
            const isCurrentYear = timeWithoutSec.format("YYYY") === nowTimeYear
            return isCurrentYear
                ? timeWithoutSec.format("MMMM")
                : timeWithoutSec.format("MMMM YYYY")
        },
        async addMessages() {
            let params = { count: maxRequestCount, type: this.mediaType }
            let chatsChunk = [], filter = ''
            if (this.searchString && this.searchString.length >= minSymbolsToSearch) filter = this.searchString
            if (filter.length) params.filter = filter

            const cid = this.params.cid
            const chId = this.params.chId

            let oldestMessage = this.chats.pop()

            if (cid) {
                this.chunkStartId = oldestMessage ? oldestMessage.id : 0
                params.cid = this.params.cid,
                params.cidType = this.params.cidType
                params.startId = this.chunkStartId
                chatsChunk = await this.$store.dispatch("chat/getMessagesMedia", params)
            }
            else if (chId) {
                this.chunkStartId = oldestMessage ? oldestMessage.pubId : 0
                params.chId = this.params.chId
                params.fromPubId = this.chunkStartId
                chatsChunk = await this.$store.dispatch("chat/getPublicationsMedia", params)
            }

            let concatArr = this.chats.concat(chatsChunk)
            oldestMessage = concatArr[concatArr.length - 1]

            if (this.chunkStartId === oldestMessage.id || this.chunkStartId === oldestMessage.pubId) {
                this.isShowSearchMore = false
                return
            } else {
                this.isShowSearchMore = true
                this.chats = concatArr
                this.chunkStartId = oldestMessage.id
                await this.searchMessages()
            }
        },
        async searchMessages() {
            let filter = ''
            if (this.searchString && this.searchString.length >= minSymbolsToSearch) filter = this.searchString
            this.isLookingFor = true
            this.foundChats.splice(0, this.foundChats.length)

            let params = { count: maxRequestCount, type: this.mediaType }
            if (filter.length) params.filter = filter

            const cid = this.params.cid
            const chId = this.params.chId

            if (!this.chats.length || this.isTabSwitched) {
                if (cid) {
                    if (this.isTabSwitched) this.isTabSwitched = false
                    params.cid = this.params.cid,
                    params.cidType = this.params.cidType
                    this.chats = await this.$store.dispatch("chat/getMessagesMedia", params)
                }
                else if (chId) {
                    if (this.isTabSwitched) this.isTabSwitched = false
                    params.chId = this.params.chId
                    this.chats = await this.$store.dispatch("chat/getPublicationsMedia", params)
                }
            }

            this.chats.forEach(chMsg => {
                let msgItem
                if (chMsg.id) msgItem = this.getMessageItem(chMsg)
                if (chMsg.chId) msgItem = this.getChannelMessageItem(chMsg)
                if (filter.length) {
                    let index = -1, fileIndex = -1, text = ''
                    if (msgItem && !msgItem.chId) {
                        text = msgItem.text || ''
                        if (typeof text === "string")
                            index = text.toLowerCase().indexOf(filter.toLowerCase())
                        if (msgItem.sub_type === this.mediaType) {
                            const msg = msgItem.msg
                            if (msg.text) text += msg.text
                            text +=  ' ' + msg.name + '.' + msg.extension 
                            if (typeof text === "string")
                                fileIndex = text.toLowerCase().indexOf(filter.toLowerCase())
                            } 
                        if (index > -1 || fileIndex > -1) this.foundChats.push(msgItem)
                    }
                    else if (msgItem && msgItem.chId) {
                        if (Array.isArray(msgItem.msg)) {
                            text = msgItem.title + ' ' + msgItem.info
                            index = text.toLowerCase().indexOf(filter.toLowerCase())
                            let { msg, ...modMsgItem } = msgItem    
                            let newMsgArr = []
                            const isIVType = ['image', 'video'].includes(this.mediaType)
                            msgItem.msg.forEach(m => {
                                if (m.type === this.mediaType) {
                                    let newText = text + ' ' + m.name
                                    if (this.mediaType === 'file') newText +=  '.' + m.extension
                                    fileIndex = newText.toLowerCase().indexOf(filter.toLowerCase())
                                    if (fileIndex > -1) {
                                        if (index === -1 || isIVType) newMsgArr.push(m)
                                        modMsgItem.msg = newMsgArr
                                        if (!isIVType) this.foundChats.push(modMsgItem)
                                    }
                                }
                            })
                            if (isIVType && newMsgArr.length) msgItem.msg = newMsgArr
                            if ((!newMsgArr.length && index > -1) || (isIVType && newMsgArr.length)) this.foundChats.push(msgItem)
                        }
                    }
                } else if (msgItem && msgItem.chId) {
                    let { msg, ...modMsgItem } = msgItem    
                    let newMsgArr = []
                    msgItem.msg.forEach(m => {
                        if (m.type === this.mediaType) {
                            newMsgArr.push(m)
                            modMsgItem.msg = newMsgArr
                        }
                    })
                    if (newMsgArr.length) this.foundChats.push(modMsgItem)
                }
                else this.foundChats.push(msgItem)
            })
            this.firstSearch = false
            this.isLookingFor = false
            this.isScrollVisible = await checkIsScrollVisible()
        },
        onTabClick(filter) {
            this.chosenTab = filter
        },
        onContextMenu({ e, message }) {
            let handlers = []
            if (message.id) {
                handlers.push({
                    item_name: this.$t('media-search.goto-chat'),
                    handler: this.scrollToMessageContext,
                    data: message.id
                })
            } else if (message.chId && message.pubId) {
                handlers.push({                                                                         
                    item_name: this.$t('media-search.goto-publication'),
                    handler: this.showPublication,
                    data: message
                })
            }
            if (message.id) {
                if (!(message.msg && message.msg.readOnly)) {
                    handlers.push({
                        item_name: this.$t('media-search.forward'),
                        handler: this.forwardMessage,
                        data: message
                    })
                }
                handlers.push({
                    item_name: this.$t('media-search.delete'),
                    handler: this.removeMsg,
                    data: message
                })
            }
            this.cmOpen(e, handlers, "right-bottom")
        },
        showPublication(msg) {
            this.checkPublicationExist(msg.chId, msg.pubId)
        },
        scrollToMessageContext(id) {
            if (id) this.scrollingToElement(id)
        },
        forwardMessage(message) {
            message = JSON.parse(JSON.stringify(message))
            let data = message.msg
            let entities = message.entities
            let dataType, author = message.author_tmp ? message.author : message.senderId
            if (message.type === 'text') {
                dataType = declarations.msgDataTypes.MSG_DATA_TYPE_TEXT
            } else {
                if (message.sub_type === 'text') {
                    data = {
                        type: message.sub_type,
                        text: data,
                    }
                    if (!entities) entities = this.extractInputTextFormat(data.text).entities || []
                    data.entities = entities
                } else {
                    data.type = message.sub_type
                }
                if (data.file) {
                    data.file = data.file.split('/').pop();
                }
                if (message.sub_type === 'location') {
                    let { type, latitude, longitude } = data
                    data = { type, latitude, longitude }
                }
                if (message.sub_type === 'contact') {
                    data.fields = message.fields
                }

                data = JSON.stringify(data)
                dataType = declarations.msgDataTypes.MSG_DATA_TYPE_DATA
            }
            this.modalOpen({
                component: SelectChatToForward,
                props: {
                    msg: { dataType, data, author },
                }
            })
        },
        removeMsg(message) {
            this.modalOpen({
                component: DelChatMessage,
                props: {
                    msg: message,
                    btnOk: {
                        cb: () => { 
                            this.isTabSwitched = true
                            this.searchMessages()
                        }
                    }
                }
            })
        },
    },
    watch: {
        getSearchString(val) {
            this.searchString = val
        },
        searchString(filter) {
            if (!filter.length || filter.length >= 2) this.searchMessages()
        },
        chosenTab() {
            this.isTabSwitched = true
            this.isScrollVisible = checkIsScrollVisible()
            this.isShowSearchMore = true
            this.searchMessages()
        },
    }
}

function getDataText(data, withLinks = false) {
    let text = ""
    switch (data.type) {
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT:
            const fields = Array.isArray(data.fields) && data.fields
            const phones =
                fields &&
                fields.filter(f => f.type === "phone" || f.type === "workphone")
            const phone = phones && phones.length && phones[0].value
            const mails = fields && fields.filter(f => f.type === "mail")
            const mail = mails && mails.length && mails[0].value
            const fio = (fields && fields.fio) || phone || mail
            text = fio
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CHANNEL:
            text = data
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_PUBLICATION:
            text = data.text
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_LOCATION:
            text = data.longitude + " " + data.latitude
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE:
            text = {
                size: data.size,
                extension: data.extension,
                file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file,
                preview: data.preview,
                name: data.name,
                text: data.text,
                previewFile: data.previewFile,
                previewSize: data.previewSize
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO:
            text = {
                size: data.size,
                extension: data.extension,
                duration: data.duration,
                file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file,
                preview: data.preview,
                name: data.name,
                text: data.text,
                previewFile: data.previewFile,
                previewSize: data.previewSize
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_AUDIO:
            text = {
                size: data.size,
                extension: data.extension,
                duration: data.duration,
                file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file,
                name: data.name,
                text: data.text
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_FILE:
            text = {
                size: data.size,
                extension: data.extension,
                file: declarations.http_post_dirs.CHAT_DATA_DIR + data.file,
                name: data.name,
                text: data.name + '.' + data.extension,
                readOnly: data.readOnly,
                key: data.key,
            }
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_POLL:
            text = data.topic
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CALL_AVAILABILITY:
            text = data
            break
        case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_TEXT:
            if (withLinks) {
                let links = []
                const linksEntities = data.entities.filter(
                    ent => ent.type === "url" || ent.type === "mail" || ent.type === "textUrl"
                )
                linksEntities.forEach(entity => {
                    if (entity.type !== "textUrl") {
                        let link = data.text.substr(entity.offset, entity.length)
                        if (entity.length) links.push(`${link}`)
                    } else {
                        const textPart = data.text.substr(entity.offset, entity.length)
                        const urlPart = entity.url
                        let textUrlLink = `@[${textPart}][url:${urlPart}]`
                        if (entity.length) links.push(textUrlLink)
                    }
                })
                text = links
            } else text = data.text
            break
        default:
            text = ""
    }
    return text
}
