var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      attrs: {
        id: _vm.modalName,
        name: _vm.modalName,
        "modal-props": _vm.compModalProps,
        title: _vm.$t("integrations.settings"),
      },
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "modal-body" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("integrations.user")))]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.login,
                  expression: "login",
                },
              ],
              staticClass: "input",
              domProps: { value: _vm.login },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.login = $event.target.value
                },
              },
            }),
          ]),
          _c("div", { staticClass: "item" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("integrations.password")))]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.password,
                  expression: "password",
                },
              ],
              staticClass: "input",
              domProps: { value: _vm.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.password = $event.target.value
                },
              },
            }),
          ]),
          _vm.notification.message && _vm.notification.message.length
            ? _c(
                "span",
                { staticClass: "notification", class: _vm.notification.class },
                [_vm._v(_vm._s(_vm.notification.message))]
              )
            : _vm._e(),
        ]),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.$t("cancel") },
            on: { click: _vm.cancelBtn },
          }),
          _c("modal-btn", {
            attrs: {
              text: _vm.$t("integrations.check"),
              disabled: !_vm.isCheckBtnShow,
            },
            on: { click: _vm.checkBtn },
          }),
          _c("modal-btn", {
            attrs: { text: _vm.$t("save"), disabled: !_vm.isCheckedOK },
            on: {
              click: function ($event) {
                return _vm.saveBtn()
              },
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }