import Vue from 'vue'

import {
    GET_CONF,
    GET_CONFS,
    GET_CONF_OWN_MEMBER,
    GET_CONF_MIC_STATUS,
    GET_CONF_CAM_STATUS,
    GET_CONF_SPEAKER_STATUS,
    GET_CONF_INFO,
    GET_CONF_MEMBERS,
    GET_CONF_MEMBERS_COUNT,
    IS_CONF_MODERATOR,
    IS_CONF_OWNER,
    GET_MERGED_CONTACT_BY_ID,
    GET_CONF_SHARE,
    IS_OWN_SHARE,
    GET_CONF_STATUS,
    IS_BANNED,
    GET_CONF_DIALOG_OPENED,
    GET_CONF_HANDUP,
    GET_CONF_MEDIA_DEVICE_BY_KIND,
} from '../gettersTypes'

import {
    ACT_CONF_JOIN,
    ACT_CONF_JOIN_BY_LINK,
    ACT_CONF_FINISH,
    ACT_CONF_LEAVE,
    ACT_CONF_MINIMIZE,
    ACT_CONF_CAMERA_TOGGLE,
    ACT_CONF_MICROPHONE_TOGGLE,
    ACT_CONF_SPEAKER_TOGGLE,
    ACT_CONF_SET_MEMBER_MODERATOR,
    ACT_CONF_SET_MEMBER_MIC,
    ACT_CONF_SET_MEMBER_VIDEO,
    ACT_CONF_BAN_MEMBER,
    ACT_CONF_KICK_OUT_MEMBER,
    ACT_CONF_SHARE,
    ACT_CONF_WANT_SPEAK,
    ACT_CONF_SEND_CID,
    ACT_CONF_ANSWER_CID,
    ACT_CONF_HAND_UP,
    ACT_CONF_REACTION,
    ACT_CONF_SET_DEVICE,
    ACT_CONF_SEND_INVITE,
    ACT_CONF_SET_DEVICES,
} from '../actionsTypes'

import {
    MUT_CONF_ADD,
    MUT_UPDATE_CONF,
    MUT_CONF_ADD_MEMBER,
    MUT_CONF_UPDATE_MEMBER,
    MUT_CONF_REMOVE_MEMBER,
    MUT_SET_CONF_INFO,
    MUT_SET_CONF_SHARE,
    MUT_CONF_REMOVE,
    MUT_CONF_SET_DIALOG_OPENED,
    MUT_SET_CONF_DEVICES,
} from "../mutationsTypes"
import {CONTACTS} from "../modulesNames"
import ipc from "../../../electron/ipc"
import {MEDIA_DEVICES} from "../../constants"

export const CONF_PROPS = {
    SPEAKER_STATUS: 'speakerStatus',
    MEMBERS: 'members',
    INFO: 'info',
    SHARE_MEMBER: 'share',
    DEVICES: 'devices',
    STATUS: 'status',
    DIALOGS: 'dialogs',
    END_REASON: 'endReason',
    ERROR_TEXT: 'errorText',
}

export const STATUS = {
    CONNECTING: 'connecting',
    ACTIVE: 'active',
    HOLD: 'hold',
    BANNED: 'banned',
    ENDED: 'ended',
    ERROR: 'error'
}

export const END_REASONS = {
    REMOTE_TERMINATE: 'remote-terminate',
    UNKNOWN: 'unknown'
}

export const MIC_STATUS = {
    ENABLE: 'enable',
    DISABLE: 'disable',
    MUTED: 'muted'
}

export const CAM_STATUS = {
    ENABLE: 'enable',
    DISABLE: 'disable',
    MUTED: 'muted',
}

export const MEMBER_PROPS = {
    ID: 'id', // идентификатор участника
    CID: 'cid', // идентификатор контакта
    HANDUP: 'handup', // рука поднята true/false
    REACTION: 'reaction', // массив реакций ['U1F603']
    ME: 'me', // я true/false
    NAME: 'name', // отображаемое имя участника
    BANNED: 'banned', // забанен 0/1/2
    MODERATOR: 'moderator', // модератор true/false
    HIDDEN: 'hidden', // ???
    OWNER: 'owner', // создатель true/false
    PRESENTER: 'presenter', // докладчик
    PRIORITY: 'priority', // ???
    READER: 'reader', // ???
    CAMERA_GLOBAL: 'camera_global', // камера в конфе true/false
    CAMERA: 'camera', // камера true/false
    MICROPHONE_GLOBAL: 'microphone_global', // микрофон в конфе true/false
    MICROPHONE: 'microphone', // микрофон true/false
    WANT_SHARE: 'want_share', // ???
    WANT_SPEAK: 'want_speak', // запрашивает слово true/false
    WRITER: 'writer', // чат true/false
}

export const INFO_PROPS = {
    TOPIC: 'topic',
    OWNER: 'owner',
    OWNER_CID: 'ownerCid',
    OWNER_NAME: 'ownerName',
    CONF_ID: 'confId',
    PASSWORD: 'password',
    LINK: 'link',
    START_TIME: 'startTime',
    FINISH_TIME: 'finishTime',
    // START_UTIME: 'startUTime',
    // FINISH_UTIME: 'finishUTime',
    SIP_ENABLED: 'sip_enabled',
    SIP_TYPE: 'sip_type',
    SIP: 'sip',
    SIP_PROXY: 'sip_proxy',
    SIP_MIXED_TYPE: 'sip_mixed_type',
    SIP_MIXED_PROFILE: 'sip_mixed_profile',
}

const state = {
    /**
     * @param {object} confs
     * @param {String} confs.status - состояние конференции
     * @param {String} confs.errorText - текст ошибки
     * @param {String} confs.micStatus - состояние микрофона
     * @param {String} confs.camStatus - состояние камеры
     * @param {Boolean} confs.speakerStatus - состояние динамика
     * @param {String} confs.viewScheme - схема отображения
     * @param {object} confs.info
     * @param {string} confs.info.topic - Тема
     * @param {string} confs.info.owner - Организатор
     * @param {string|number} confs.info.owner - Идентификатор
     * @param {string} confs.info.password - Пароль
     * @param {string} confs.info.link - Ссылка
     * @param {Array.<{status: String, myString: String, myArray: Array}>} confs.members
     *             banned: 0
     hidden: 0
     moderator: 1
     owner: 1
     presenter: 1
     priority: 0
     reader: 1
     speaker: 1
     visible: 1
     wantshare: 0
     wantspeak: 0
     writer: 1
     **/
    confs: {}
}

const getters = {
    [GET_CONF]: (state) => (id) => {
        return state.confs[id] || {}
    },
    [GET_CONFS]: (state) => Object.entries(state.confs).map(([id, value]) => ({...value, ...{id}})),
    [GET_CONF_STATUS]: (state, getters) => (id) => {
        return getters[GET_CONF](id)[CONF_PROPS.STATUS]
    },
    [GET_CONF_MEMBERS]: (state, getters) => (id) => {
        return getters[GET_CONF](id).members || []
    },
    [GET_CONF_OWN_MEMBER]: (state, getters) => (id) => {
        return getters[GET_CONF_MEMBERS](id).find(({me}) => me)
    },
    [GET_CONF_MIC_STATUS]: (state, getters) => (id) => {
        let ownMember = getters[GET_CONF_OWN_MEMBER](id)
        let status = MIC_STATUS.DISABLE
        if (ownMember) {
            if (!ownMember[MEMBER_PROPS.MICROPHONE_GLOBAL]) status = MIC_STATUS.MUTED
            else if (ownMember[MEMBER_PROPS.MICROPHONE]) status = MIC_STATUS.ENABLE
        }
        return status
    },
    [GET_CONF_CAM_STATUS]: (state, getters) => (id) => {
        let ownMember = getters[GET_CONF_OWN_MEMBER](id)
        let status = CAM_STATUS.DISABLE
        if (ownMember) {
            if (!ownMember[MEMBER_PROPS.CAMERA_GLOBAL]) status = CAM_STATUS.MUTED
            else if (ownMember[MEMBER_PROPS.CAMERA]) status = CAM_STATUS.ENABLE
        }
        return status
    },
    [GET_CONF_SPEAKER_STATUS]: (state, getters) => (id) => {
        let conf = getters[GET_CONF](id)
        return conf[CONF_PROPS.SPEAKER_STATUS]
    },
    [GET_CONF_INFO]: (state, getters, rootState, rootGetters) => (id) => {
        let conf = getters[GET_CONF](id)
        let confInfo = conf[CONF_PROPS.INFO]
        if (!confInfo) return {}
        let owner = ''
        let cid = confInfo[INFO_PROPS.OWNER_CID]
        if (cid) {
            owner = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](cid).fio
        } else {
            owner = confInfo[INFO_PROPS.OWNER_NAME]
        }
        return {
            [INFO_PROPS.TOPIC]: confInfo[INFO_PROPS.TOPIC],
            [INFO_PROPS.OWNER]: owner,
            [INFO_PROPS.CONF_ID]: confInfo[INFO_PROPS.CONF_ID],
            [INFO_PROPS.PASSWORD]: confInfo[INFO_PROPS.PASSWORD],
            [INFO_PROPS.LINK]: confInfo[INFO_PROPS.LINK],
            [INFO_PROPS.FINISH_TIME]: confInfo[INFO_PROPS.FINISH_TIME],
            //[INFO_PROPS.FINISH_UTIME]: confInfo[INFO_PROPS.FINISH_UTIME],
            [INFO_PROPS.START_TIME]: confInfo[INFO_PROPS.START_TIME],
            //[INFO_PROPS.START_UTIME]: confInfo[INFO_PROPS.START_UTIME],
        }
    },
    [GET_CONF_MEMBERS_COUNT]: (state) => (id) => {
        return state.confs[id] && state.confs[id].members.length
    },
    [IS_CONF_MODERATOR]: (state, getters) => (id) => {
        let ownMember = getters[GET_CONF_OWN_MEMBER](id)
        return Boolean(ownMember && ownMember[MEMBER_PROPS.MODERATOR])
    },
    [IS_CONF_OWNER]: (state, getters) => (id) => {
        let ownMember = getters[GET_CONF_OWN_MEMBER](id)
        return Boolean(ownMember && ownMember[MEMBER_PROPS.OWNER])
    },
    [GET_CONF_SHARE]: (state, getters) => id => {
        let conf = getters[GET_CONF](id)
        return Boolean(conf && conf[CONF_PROPS.SHARE_MEMBER])
    },
    [IS_OWN_SHARE]: (state, getters) => id => {
        let conf = getters[GET_CONF](id)
        let ownMember = getters[GET_CONF_OWN_MEMBER](id)
        return Boolean(conf && ownMember && conf[CONF_PROPS.SHARE_MEMBER] === ownMember[MEMBER_PROPS.ID])
    },
    [GET_CONF_HANDUP]: (state, getters) => id => {
        let conf = getters[GET_CONF](id)
        let ownMember = getters[GET_CONF_OWN_MEMBER](id)
        return Boolean(conf && ownMember && ownMember[MEMBER_PROPS.HANDUP])
    },
    [IS_BANNED]: (state, getters) => id => {
        let ownMember = getters[GET_CONF_OWN_MEMBER](id)
        return Boolean( ownMember && ownMember[MEMBER_PROPS.BANNED] > 0)
    },
    [GET_CONF_MEDIA_DEVICE_BY_KIND]: (state, getters) => (id, kind) => {
        let conf = getters[GET_CONF](id)
        return conf && conf[CONF_PROPS.DEVICES] && conf[CONF_PROPS.DEVICES][kind]
    },
    [GET_CONF_DIALOG_OPENED]: (state, getters) => (id, dialog) => {
        let conf = getters[GET_CONF](id)
        return Boolean(conf[CONF_PROPS.DIALOGS] && conf[CONF_PROPS.DIALOGS].find(d => d === dialog))
    }
}
const actions = {
    async [ACT_CONF_JOIN]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_JOIN_BY_LINK]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_FINISH]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_LEAVE]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_MINIMIZE]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_CAMERA_TOGGLE]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_MICROPHONE_TOGGLE]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_SPEAKER_TOGGLE]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_WANT_SPEAK]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_SET_MEMBER_MODERATOR]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_SET_MEMBER_MIC]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_SET_MEMBER_VIDEO]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_BAN_MEMBER]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_BAN_MEMBER]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_KICK_OUT_MEMBER]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_SHARE]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_SEND_CID]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_ANSWER_CID]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_HAND_UP]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_REACTION]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_SET_DEVICE]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_SEND_INVITE]() {
        throw new Error('abstract-action-called')
    },
    async [ACT_CONF_SET_DEVICES]({ commit, dispatch, getters }, { id, devices = {} }) {
        if (!devices[MEDIA_DEVICES.AUDIO_INPUT] && getters[GET_CONF_MIC_STATUS](id) !== MIC_STATUS.DISABLE) dispatch(ACT_CONF_MICROPHONE_TOGGLE, {id})
        if (!devices[MEDIA_DEVICES.AUDIO_OUTPUT] && getters[GET_CONF_SPEAKER_STATUS](id)) dispatch(ACT_CONF_SPEAKER_TOGGLE, {id})
        if (!devices[MEDIA_DEVICES.VIDEO_INPUT] && getters[GET_CONF_CAM_STATUS](id) !== CAM_STATUS.DISABLE) dispatch(ACT_CONF_CAMERA_TOGGLE, {id})
        commit(MUT_SET_CONF_DEVICES, { id, devices })
    },
}

const mutations = {
    [MUT_CONF_ADD](state, {id, conf = {}}) {
        if (!conf[CONF_PROPS.MEMBERS]) conf[CONF_PROPS.MEMBERS] = []
        if (!conf[CONF_PROPS.DIALOGS]) conf[CONF_PROPS.DIALOGS] = []
        if (!conf[CONF_PROPS.SHARE_MEMBER]) conf[CONF_PROPS.SHARE_MEMBER] = ''
        if (!conf[CONF_PROPS.DEVICES]) conf[CONF_PROPS.DEVICES] = {}
        if (!(CONF_PROPS.SPEAKER_STATUS in conf)) conf[CONF_PROPS.SPEAKER_STATUS] = true
        if (!(CONF_PROPS.STATUS in conf)) conf[CONF_PROPS.STATUS] = STATUS.CONNECTING
        Vue.set(state.confs, id, conf)
    },
    [MUT_CONF_REMOVE](state, {id}) {
        Vue.delete(state.confs, id)
    },
    [MUT_UPDATE_CONF](state, {id, props}) {
        let conf = state.confs[id]
        if (!conf || [STATUS.ENDED, STATUS.ERROR].includes(conf[CONF_PROPS.STATUS])) return
        for (let i in props) {
            if (conf[i] !== props[i]) {
                conf[i] = props[i]
            }
        }
    },
    [MUT_SET_CONF_INFO](state, {id, topic, ownerCid, ownerName, password, confId, link}) {
        let conf = state.confs[id]
        if (!conf) return
        Vue.set(conf, CONF_PROPS.INFO, {topic, ownerCid, ownerName, password, confId, link})
    },
    [MUT_CONF_ADD_MEMBER](state, {id, member}) {
        let conf = state.confs[id]
        if (!(MEMBER_PROPS.HANDUP in member)) member[MEMBER_PROPS.HANDUP] = false
        if (!(MEMBER_PROPS.REACTION in member)) member[MEMBER_PROPS.REACTION] = null
        if (!conf) return
        let index = conf.members.findIndex(({id}) => member.id === id)
        if (index >= 0) {
            conf.members.splice(index, 1, member)
        } else {
            //Vue.set(conf.members, conf.members.length, member)
            conf.members.push(member)
        }
    },
    [MUT_CONF_UPDATE_MEMBER](state, {id, member}) {
        let conf = state.confs[id]
        if (!conf) return
        let memberOld = conf.members.find(({id}) => member.id === id)
        for (let i in member) {
            if (i === MEMBER_PROPS.REACTION) {
                memberOld[i] = member[i]
                if (memberOld.reactionTimeout) clearTimeout(memberOld.reactionTimeout)
                memberOld.reactionTimeout = setTimeout(() => memberOld[i] = null,10000)
            } else {
                if (memberOld[i] !== member[i]) {
                    memberOld[i] = member[i]
                }
            }
        }
    },
    [MUT_CONF_REMOVE_MEMBER](state, {id, member}) {
        let conf = state.confs[id]
        if (!conf) return
        let memberIndex = conf.members.findIndex(({id}) => member.id === id)
        if (memberIndex >= 0) conf.members.splice(memberIndex, 1)
    },
    [MUT_SET_CONF_SHARE](state, {id, userId = ''}) {
        let conf = state.confs[id]
        if (!conf) return
        Vue.set(conf, CONF_PROPS.SHARE_MEMBER, userId)
    },
    [MUT_SET_CONF_DEVICES](state, {id, devices = {}}) {
        let conf = state.confs[id]
        if (!conf) return
        Vue.set(conf, CONF_PROPS.DEVICES, devices)
    },
    [MUT_CONF_SET_DIALOG_OPENED](state, {id, dialog, opened = false}) {
        let conf = state.confs[id]
        if (!conf) return
        let dialogs = conf[CONF_PROPS.DIALOGS]
        let dialogIndex = dialogs.indexOf(dialog)
        if (dialogIndex > -1 && !opened) dialogs.splice(dialogIndex, 1)
        else if (opened && dialogIndex === -1) dialogs.push(dialog)
        ipc.send('toggle-phone-on-top', !dialogs.length)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
