//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import {
    USERDATA,
    CONTACTS,
    VM_CONF,
    VIDEO_CONF_NEW,
} from "../../store/modulesNames"
import {
    GENERATE_VC_CONF_PROPS,
    GET_MERGED_CONTACT_BY_ID,
    GET_UID,
    GET_VM_CONF_CONNECTION_PARAMS,
} from '../../store/gettersTypes'

import modal_mixin from '../modal/modal-mixin'
import CustomInputText from '../custom/CustomInputText.vue'
import DateRangePicker from '../settings/DateRangePicker.vue'
import CustomRadio from '../custom/CustomRadio.vue'
import ListItem from '../custom/ListItem.vue'
import ModalBtn from '../modal/ModalBtn.vue'
import CustomSelect from '../custom/CustomSelect.vue'
import CustomCheckbox from "../custom/CustomCheckboxRound.vue"
import VideoMostConfMenu from './mainContentVideoConfs.vue'

import { ACT_VC_CONF_ADD } from "../../store/actionsTypes"
import { CONF_TYPES } from "../../constants"

export default {
    name: "enterConference",
    components: {
        CustomInputText,
        DateRangePicker,
        CustomRadio,
        CustomSelect,
        CustomCheckbox,
        ListItem,
        ModalBtn,
        VideoMostConfMenu
    },
    mixins: [modal_mixin],
    props: {
        vmLink: {
            type: String,
            default: '',
            required: false
        }
    },
    mounted () {
        if (this.vmLink && this.vmLink.length) {
            const linkData = (this[GENERATE_VC_CONF_PROPS](CONF_TYPES.VM, { link: this.vmLink }) || {})
            if (this.integrationServer && linkData.server && this.integrationServer === linkData.server) {
                this.confID = linkData.confId
                this.confPassword = linkData.pass
            } else {
                this.chosenEnterWay = 1
            }
        }
    },
    data () {
        return {
            enterWays: [
                {
                    name: this.$t('videomost.conf-enterway-id'),
                },
                {
                    name: this.$t('videomost.conf-enterway-link'),
                }
            ],
            confMode: {},
            confID: null,
            confPassword: '',
            confLink: '',
            compModalProps: {
                delay: 150,
                pivotY: 0.33,
                width: 458,
                height: 'auto',
                clickToClose: false
            },
            okText: this.$t('videomost.conf-plan'),
            placeholder: this.$t('videomost.conf-enterway'),
            chosenEnterWay: 0,
            participantName: '',
            defTextID: '',
            defTextPassword: '',
            defTextLink: this.vmLink ? this.vmLink : '',
            defText: '',
            isNoCamera: false,
            isNoMicrophone: false,
        }
    },
    computed: {
        ...mapGetters(CONTACTS, {[GET_MERGED_CONTACT_BY_ID]: GET_MERGED_CONTACT_BY_ID}),
        ...mapGetters(USERDATA, {[GET_UID]: GET_UID}),
        ...mapGetters(VM_CONF, [GET_VM_CONF_CONNECTION_PARAMS]),
        ...mapGetters(VIDEO_CONF_NEW, [GENERATE_VC_CONF_PROPS]),
        contact () {
            return this[GET_MERGED_CONTACT_BY_ID](this[GET_UID])
        },
        fio () {
            return this.contact.fio
        },
        integrationServer() {
            let { server } = this[GET_VM_CONF_CONNECTION_PARAMS]
            return server
        }
    },
    methods: {
        chosenWayOptionClick (way) {
            this.chosenEnterWay = way
        },
        enterConference () {
            let props = {
                noCamera: this.isNoCamera,
                noMicrophone: this.isNoMicrophone,
            }
            let userName = this.participantName || this.fio
            if (userName) props.userName = userName
            const confProps = { }
            if (this.chosenEnterWay) confProps.link = this.confLink
            else Object.assign(confProps, { server: this.integrationServer, confId: this.confID, pass: this.confPassword })
            Object.assign(props, this[GENERATE_VC_CONF_PROPS](CONF_TYPES.VM, confProps))
            this.modalClose()
            this[ACT_VC_CONF_ADD]({type: CONF_TYPES.VM, data: props})
        },
        ...mapActions(VIDEO_CONF_NEW, [ACT_VC_CONF_ADD])
    },
}
