//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import modal_mixin from './modal-mixin'
import ListItem from '../custom/ListItem.vue'
import CustomSearch from "../custom/CustomSearch.vue"
import { mapGetters, mapActions } from 'vuex'
import { RC_RADIO, CHATS } from '../../store/modulesNames'
import {
    GET_RC_RADIO_ACTIVE_GROUP,
    GET_CHATS,
    GET_RC_RADIO_CONNECTION_STATUS,
} from "../../store/gettersTypes"
import {
    ACT_RC_RADIO_CONNECT,
    ACT_RC_RADIO_DISCONNECT,
    ACT_RC_RADIO_GET_STATUS,
} from "../../store/actionsTypes"

import {CONNECTION_STATUS} from '../../store/modules/rc-radio'

export default {
    name: "SelectRadioGroup",
    mixins: [modal_mixin],
    components: {
        ListItem,
        CustomSearch,
    },
    data: () => {
        return {
            compModalProps: {
                delay: 250,
                pivotY: 0.05,
                width: 400,
                height: 'auto',
                clickToClose: false
            },
            search: '',
        }
    },
    computed: {
        cancelText () {
            return this.$t('cancel')
        },
        title() {
            return this.$t('group-selecion')
        },
        groups() {
            let groups = this[GET_CHATS].filter(({cidType, name}) => cidType === 'group' && name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
            .sort(utils.chatAndChannelSortFn)

            let systemGroup = {
                cidType: "group",
                cid: 0,
                name: this.$t('system-radio'),
                contacts: [],
            }
            groups.unshift(systemGroup)
            
            return groups
        },
        getGroupFooterText() {
            return function(cid) {
                let text = this.$t('updating')
                let status = (this.statuses || {})[cid]
                if (status && cid) {
                    let activeCount = (status.contacts || []).filter(({status}) => status === 'online').length
                    if (activeCount) text = `${this.$t('active-members-from-upper')}${activeCount}`
                    else text = this.$t('no-active-members')
                }
                if (status && !cid) text = this.$t('speech-quality-test')
                return text
            }
        },
        ...mapGetters(RC_RADIO, [GET_RC_RADIO_ACTIVE_GROUP, GET_RC_RADIO_CONNECTION_STATUS]),
        ...mapGetters(CHATS, [GET_CHATS]),
    },
    asyncComputed: {
        async statuses() {
            let statuses = {}
            for (let i = 0, count = this.groups.length; i < count;i++) {
                let { cid:channelId } = this.groups[i]
                try {
                    statuses[channelId] = await this[ACT_RC_RADIO_GET_STATUS]({channelId})
                } catch (e) {}
            }
            return statuses
        }
    },
    methods: {
        isGroupActive(cid) {
            return cid === this[GET_RC_RADIO_ACTIVE_GROUP]
        },
        connect(channelId) {
            this[ACT_RC_RADIO_CONNECT]({channelId})
            this.modalClose()
        },
        onClosed() {
            if (this[GET_RC_RADIO_CONNECTION_STATUS] === CONNECTION_STATUS.GROUP_NOT_SET) this[ACT_RC_RADIO_DISCONNECT]()
        },
        ...mapActions(RC_RADIO, [ACT_RC_RADIO_CONNECT, ACT_RC_RADIO_GET_STATUS, ACT_RC_RADIO_DISCONNECT])
    },
}
