'use strict';

import ModalDialog from './ModalDialog.vue';
import ModalBtn from "./ModalBtn.vue"

const mixin = {
    props: {
        modalName: {
            type: String,
            required: true
        },
        props: {
            type: Object,
            required: false,
            default: () => ({})
        },
        modalProps: {
            type: Object,
            required: false,
            default: () => ({})
        },
    },
    components: { ModalDialog, ModalBtn },
    computed: {
        sumModalProps() { return { ...(this.compModalProps || {}), ...this.modalProps } }
    },
    methods: {
        modalClose() {
            this.$refs.modal.close();
        },
    }
};

export default mixin;