//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex'
import {MODAL, USERDATA, INFO} from "../../store/modulesNames"
import {GET_UID} from "../../store/gettersTypes"
import {ACT_MODAL_OPEN, ACT_INFO_PUSH} from "../../store/actionsTypes"

import InfoMainBlock from './InfoMainBlock.vue'
import IconImage from '../IconImage.vue'
import ActionsContainer from "./ActionsContainer.vue"
import InfoIcon from "./InfoIcon.vue"
import ListItem from "../custom/ListItem.vue"
import MediaSearchInfoItems from './MediaSearchInfoItems.vue'
import ChatPinSwitch from "./ChatPinSwitch.vue"

export default {
    name: "NoteInfo",
    props: ['canBack'],
    components: {
        'icon-image': IconImage,
        ActionsContainer,
        ListItem,
        InfoIcon,
        InfoMainBlock,
        MediaSearchInfoItems,
        ChatPinSwitch,
    },
    data() {
        return {
            cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER,
            // iconPhoto: 'img/note.png',
            iconText: this.$t('notes-chat-name'),
        }
    },
    computed: {
        iconPhoto() {
            const isDarkTheme = this.$store.getters['clientdata/getTheme'] === 'dark'
            return isDarkTheme ? 'img/notes_dark.png' : 'img/note.png'
        },
        cid() { return this[GET_UID] },
        ...mapGetters(USERDATA, [GET_UID]),
    },
    methods: {
        doDeleteChat() {
            this.modalOpen({
                name: 'remove-chat',
                props: {
                    cid: this.cid,
                    cidType: this.cidType
                }
            })
        },
        openSearchMedia(tabIndexValue) {
            this[ACT_INFO_PUSH]({type: 'media-search', params: {cid: this.cid, cidType: this.cidType, tabIndexValue}})
        },
        ...mapActions(MODAL, [ACT_MODAL_OPEN]),
        ...mapActions(INFO, [ACT_INFO_PUSH]),
    }
}
