var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "select-contact-modal dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.title,
      },
    },
    [
      _c(
        "template",
        { slot: "default" },
        [
          _c("custom-search", {
            staticClass: "search-contact search-input-wrapper",
            attrs: { search: _vm.search, focus: true },
            on: {
              "update:search": function ($event) {
                _vm.search = $event
              },
            },
          }),
          _c(
            "div",
            { staticClass: "select-contact" },
            [
              _c("selected-items-line", {
                attrs: { selected: _vm.selectedItemsLine },
                on: { remove: _vm.unselectContact },
              }),
              _c("recycle-contacts-list", {
                ref: "scroller",
                attrs: { contactsList: _vm.contactsList },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        !_vm.isExcluded(item)
                          ? _c("contacts-list-item", {
                              attrs: {
                                contact: item,
                                subTitle: item.mails[0].value,
                                noswitch: true,
                                marked: _vm.isMarked(item),
                                noSelection: true,
                              },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.selectContact(item)
                                },
                              },
                            })
                          : _vm._e(),
                        _c("div", { staticClass: "hor-line" }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.cancelText },
            on: { click: _vm.modalClose },
          }),
          _c("modal-btn", {
            attrs: { text: _vm.okText, disabled: !_vm.selected.length },
            on: {
              click: function ($event) {
                return _vm.onOk(_vm.selected)
              },
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }