'use strict'

import { CLIENTDATA } from '../modulesNames';

import { GET_DND, GET_EXP_TIME, GET_CURRENT_TIME } from '../gettersTypes';

import { ACT_SET_USER_PROFILE,ACT_SET_USER_PROFILE_REVISION, ACT_SET_DND } from '../actionsTypes';

import { MUT_SET_USER_PROFILE, MUT_SET_REV, MUT_SET_DND } from "../mutationsTypes";

import proto from "../../protocol";

const KEY = "dnd";
const REV = 0;
const DEF_EXP_TIME = 0;

const state = {
    profile: {
        rev: REV,
        dnd: { expirationTime: DEF_EXP_TIME }
    },
};

const getters = {
    [GET_DND]: (state, getters, rootState, rootGetters) => {
        const currentTime = rootGetters[`${CLIENTDATA}/${GET_CURRENT_TIME}`];
        const dnd = state.profile.dnd.expirationTime;
        return dnd && dnd > currentTime;
    },

    [GET_EXP_TIME]: (state) => {
        return state.profile.dnd.expirationTime;
    },
};

const actions = {
    async [ACT_SET_USER_PROFILE]({ commit }, payload) {
        const {rev, dnd} = payload;
        if (!!rev && !!dnd) {
            if (rev > state.profile.rev)
                commit(MUT_SET_USER_PROFILE, payload);
            return;
        }
        if (!!rev && rev > state.profile.rev) {
            let dndVal = { expirationTime: DEF_EXP_TIME };
            commit(MUT_SET_REV, rev);
            commit(MUT_SET_DND, dndVal);
        }
    },

    async [ACT_SET_USER_PROFILE_REVISION]({commit}, revServer) {
        if (!!revServer && revServer > state.profile.rev) {
            try {
                let getProfile = await proto.getProfile();
                const {rev, dnd, ...rest} = getProfile; // rest = { other props to be added in future }
            commit(MUT_SET_REV, rev);
            if (!!dnd) {
                commit(MUT_SET_DND, dnd)
            } else {
                commit(MUT_SET_DND, DEF_EXP_TIME);
            }
            return;
            } catch(err) {
                console.log("error: ", err)
                throw err
            }
        }
    },

    async [ACT_SET_DND]({ commit }, dndSet) {
        if (!dndSet && dndSet !== 0) return;
        let dndVal = { expirationTime: DEF_EXP_TIME };
        dndSet.expirationTime ?
            (dndVal.expirationTime = dndSet.expirationTime) :
            (dndVal.expirationTime = dndSet);
        let requestData = { key: KEY, value: dndVal };
        try {
            let serverReply = await proto.setProfile(requestData);
            let { rev } = serverReply;
            if (!!rev && rev!==state.profile.rev) {
                commit(MUT_SET_REV, rev);
                commit(MUT_SET_DND, dndVal);
            } 
        } catch(err) { 
            console.log("error: ", err)
            throw err
        }
    },
};

const mutations = {
    [MUT_SET_USER_PROFILE](state, profile) {
        state.profile = profile;
    },
    [MUT_SET_REV](state, rev) {
        state.profile.rev = rev;
    },
    [MUT_SET_DND](state, dnd) {
        state.profile.dnd = dnd;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
