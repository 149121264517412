//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex';
import {USERDATA, INFO, CHANNELS, MODAL, AJAX} from '../../store/modulesNames'
import {GET_UID, GET_CHANNEL} from "../../store/gettersTypes";
import {ACT_INFO_PUSH, ACT_CHANGE_CHANNEL, ACT_MODAL_OPEN, ACT_AJAX_SEND_FILE} from '../../store/actionsTypes'

import IconImage from '../IconImage.vue';
import CustomCheckboxSlider from "../custom/CustomCheckboxSlider.vue";
import ActionsContainer from "./ActionsContainer.vue"
import InfoMainBlock from "./InfoMainBlock.vue"
import InfoIcon from "./InfoIcon.vue"
import ListItem from "../custom/ListItem.vue"
import ChannelMuteSwitch from "./ChannelMuteSwitch.vue"
import ChannelPinSwitch from "./ChannelPinSwitch.vue"
import SelectChatToShare from "../modal/SelectChatToShare.vue"
import MediaSearchInfoItems from './MediaSearchInfoItems.vue'
import { i18n } from '../../../ext/i18n'

import {DLP_SOURCE_TYPES} from '../../store/modules/dlp'

export default {
    name: "ChannelInfo",
    components: { IconImage, CustomCheckboxSlider, ActionsContainer, InfoMainBlock, 
                  InfoIcon, ListItem, ChannelMuteSwitch, ChannelPinSwitch, MediaSearchInfoItems},
    props: ['params', 'canBack'],
    data() {
        return {
            title: i18n.messages[i18n.locale].mainPage['channel-info'],
            info_max_unextended_length: 75,
            info_extend: false
        };
    },
    computed: {
        chId() {
            return this.params.chId
        },
        channel() {
            let x = this.$store.getters['channels/getChannel']({chId: this.chId});
            return x;
        },
        joined() { return this.$store.getters['channels/joinedP'] },
        canJoin() {
            return this.channel && Channel.prototype.canJoin(this.channel.status) || !this.joined;
        },
        isBanned() {
            return this.channel && Channel.prototype.isBanned(this.channel.status);
        },
        isOwner() {
            return this.channel && Channel.prototype.isOwner(this.channel.privilege);
        },
        hasAdminRights() {
            return this.channel && Channel.prototype.hasAdminRights(this.channel.privilege);
        },
        type() {
            let info = i18n.messages[i18n.locale].information
            return this.channel.chType === 'private' ? info.private : info.public;
        },
        photo() {
            return this.channel && app.getChannelPhotoUrl(this.channel.icon);
        },
        subscribers() {
            return this.channel.subscribersCount
        },
        admins() {
            return this.channel.adminsCount
        },
        membersCountText() {
            const count = this.subscribers + this.admins
            return isNaN(count) ? '' : `(${count})`
        },
        verifed() {
            return this.$store.getters[`${CHANNELS}/${GET_CHANNEL}`]({chId: this.chId}).verified
        },
        reference() {
            return `@[channel\\${this.chId}:${this.channel.name}]`
        },
        refNewFormat() {
            return `@[${this.channel.name}][channel:${this.chId}]`
        },
        newFormatData() {
            let data = {}
            const entities = [{ type: "channel", offset: 0, length: this.channel.name.length, channelId: this.chId}]
            Object.assign(data, { dataType: declarations.msgDataTypes.MSG_DATA_TYPE_DATA, 
                data: { type: declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_TEXT, text: this.channel.name, entities } })
            return data
        },
        ...mapGetters(USERDATA, [GET_UID])
    },
    methods: {
        _buildMenu(cid, noAdmins = false) {
            let handlers = []
            handlers.push({item_name: this.$t('mainPage.contact-info'), handler: this.doOpenContactInfo, data: cid})
            const isParticipantAdmin = this.admin_members.find(member => member.cid === cid)
            if ((this.isOwner || this.hasAdminRights) && cid !== this.uid || noAdmins) {
                if (!isParticipantAdmin) handlers.push({item_name: this.$t('information.promote-to-adm'), handler: this._setMemberAdminPrivilege, data: cid});
                handlers.push({item_name: this.$t('information.remove-member'), handler: this._delMember, data: cid})
            }
            return handlers;
        },
        doLoadImage(input) {
            const cb = (status, file_name) => {
                    const fr = new FileReader();

                    fr.readAsDataURL(input.files[0]);
                    console.log(status, file_name);
                    let params = {
                        chId: this.chId,
                        cidType: this.channel.cidType,
                        icon: file_name
                    };
                    this[ACT_CHANGE_CHANNEL](params);
                };

            app.store.dispatch(`${AJAX}/${ACT_AJAX_SEND_FILE}`, {
                url: declarations.http_post_dirs.CHANNELS_ICONS,
                file: input.files[0],
                cb(err, file_name) {
                    cb && cb(err, file_name)
                }
            })
        },
        doChangeChannelIcon() {
            let input = document.createElement('input');
            input.setAttribute('accept', "image/*");
            input.setAttribute('type', 'file');
            input.onchange = () => this.doLoadImage(input);
            input.click();
        },
        doEditChannel() {
            this[ACT_MODAL_OPEN]({
                name: 'channel-edit',
                props: {channel: this.channel}
            })
        },
        async doJoinChannel () {
            let privilege;
            if (this.channel.privilege === 'admin') privilege = declarations.channel_user_privilege.ADMIN;
            else privilege = declarations.channel_user_privilege.USER;
            await this.$store.dispatch('channels/changeChannelUsers', {
                chId: this.channel.chId,
                contacts: [{
                    cid: this[GET_UID],
                    privilege: privilege, //@todo
                    status: declarations.channel_user_statuses.JOINED
                }]
            });
        },
        doLeaveChannel() {
            this.modalOpen({
                name: 'leave-channel',
                props: {chId: this.chId}
            })
        },
        doDelChannel() {
            this.modalOpen({
                name: 'delete-channel',
                props: {chId: this.chId}
            })
        },
        openChannelMembersInfo() {
            this[ACT_INFO_PUSH]({type: 'channel-members', params: {chId: this.chId}});
        },
        doCopyReference() {
            this.$copyText(this.refNewFormat, undefined, {
                sourceType: DLP_SOURCE_TYPES.CHANNEL,
                sourceData: {
                    chId: this.chId,
                }
            })
        },
        doShare() {
            this.modalOpen({
                component: SelectChatToShare,
                props: {
                    'type': 'data',
                    'params': this.newFormatData //this.refNewFormat // this.reference
                }
            })
        },
        doShowViewer() {
            if (!this.channel.icon) return
            this.$modal.show(IconViewer, {
                'src': this.photo,
                'type': 'image',
                'iconType': 'channel',
                'id': this.chId
            }, {
                name: 'viewer',
                delay: 200,
                width: '100%',
                height: '100%'
            });
        },
        openSearchMedia(tabIndexValue) {
            this[ACT_INFO_PUSH]({type: 'media-search', params: {chId: this.chId, tabIndexValue}})
        },
        ...mapActions(INFO, [ACT_INFO_PUSH]),
        ...mapActions(CHANNELS, [ACT_CHANGE_CHANNEL]),
        ...mapActions(MODAL, [ACT_MODAL_OPEN])

    }
}
