var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-entrance" }, [
    _c("div", { staticClass: "component-header text-center" }),
    _c("div", { staticClass: "component-section" }),
    _c("div", { staticClass: "component-footer" }, [
      _c("div", { staticClass: "alt-entrace-btns" }, [
        _c(
          "div",
          {
            staticClass: "alt-entrace-btn qr-entrace",
            on: {
              click: function ($event) {
                return _vm.doOpenEntrance("qr")
              },
            },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                  viewBox: "0 0 16 16",
                },
              },
              [
                _c(
                  "g",
                  {
                    attrs: {
                      transform: "scale(.5,.5)",
                      fill: "none",
                      "stroke-linecap": "butt",
                    },
                  },
                  [
                    _c("path", {
                      attrs: { id: "A", d: "M3,3 h8 v8 h-8 v-8 z M6,7 h2" },
                    }),
                    _c("use", {
                      attrs: { "xlink:href": "#A", x: "18", y: "0" },
                    }),
                    _c("use", {
                      attrs: { "xlink:href": "#A", x: "0", y: "18" },
                    }),
                    _c("path", { attrs: { d: "M18,3 h-3 v4 h2 v4 h-2 v3" } }),
                    _c("path", {
                      attrs: { d: "M2,15 h2 m2,0 h4 m6,0 h2 m2,0 h4 m4,0 h2" },
                    }),
                    _c("path", {
                      attrs: { d: "M4,17 h2 m2,0 h8 m6,0 h2 m2,0 h4" },
                    }),
                    _c("path", { attrs: { d: "M14,19 h2 m2,0 h2 m6,0 h2" } }),
                    _c("path", { attrs: { d: "M16,21 h2 m2,0 h8" } }),
                    _c("path", {
                      attrs: { d: "M14,23 h2 m2,0 h2 m4,0 h2 m2,0 h2" },
                    }),
                    _c("path", { attrs: { d: "M14,25 h4 m2,0 h2 m2, 0 h4" } }),
                    _c("path", { attrs: { d: "M14,27 h2 m6,0 h2 m4,0 h2" } }),
                    _c("path", { attrs: { d: "M16,29 h8 m2,0 h2" } }),
                  ]
                ),
              ]
            ),
          ]
        ),
        _c("span", [_vm._v(_vm._s(_vm.$t("loginPage.qr")))]),
        _c(
          "div",
          {
            staticClass: "alt-entrace-btn pencil-entrace",
            on: {
              click: function ($event) {
                return _vm.goToNext("mobile")
              },
            },
          },
          [_c("i", { staticClass: "fal fa-mobile" })]
        ),
        _c("span", [_vm._v(_vm._s(_vm.$t("loginPage.mobile")))]),
        _c(
          "div",
          {
            staticClass: "alt-entrace-btn pencil-entrace",
            on: {
              click: function ($event) {
                return _vm.goToNext("login")
              },
            },
          },
          [_c("i", { staticClass: "fal fa-pencil" })]
        ),
        _c("span", [_vm._v(_vm._s(_vm.$t("loginPage.manually")))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }