//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions} from 'vuex';
import {AJAX, CHANNELS} from '../../store/modulesNames'
import {ACT_CREATE_CHANNEL, ACT_CHANGE_CHANNEL, ACT_AJAX_SEND_FILE} from '../../store/actionsTypes'
import IconImage from '../IconImage.vue';
import modal_mixin from './modal-mixin';
import CustomRadio from "../custom/CustomRadio.vue"
import { i18n } from '../../../ext/i18n'

const locale = i18n.messages[i18n.locale]

export default {
    name: 'ChannelEdit',
    components: {IconImage, CustomRadio},
    mixins: [modal_mixin],
    data() {
        return {
            compModalProps: {
                delay: 200,
                pivotY: 0.05,
                width: 500,
                height: 'auto',
                clickToClose: false,
            },
            public: !this.props.channel || this.props.channel.chType === declarations.channel_types.PUBLIC,
            name: this.props.channel && this.props.channel.name || '',
            info: this.props.channel && this.props.channel.info || '',
            icon: '',
            image: '',
            oldIcon: '',
            oldImage: '',
            warning: false,
            publicInputs: [
                {
                    value: true,
                    label: locale.information.public,
                    description: locale['can-be-found'] +
                        locale['can-sub']
                }, {
                    value: false,
                    label: locale.information.private,
                    description: locale['by-inv']
                }
            ]
        }
    },
    computed: {
        newChannel() { return !this.props.channel },
        iconChanged() { return this.icon !== this.oldIcon },
        iconRemove() { return Boolean(this.icon && !this.iconChanged) },
        iconRollback() { return !this.iconRemove && this.iconChanged },
        title() { return this.newChannel ? i18n.messages[i18n.locale].modal['new-channel'] : i18n.messages[i18n.locale].modal['edit-channel'] },
        okText() { return this.newChannel ? i18n.messages[i18n.locale].modal.create : i18n.messages[i18n.locale].save  },
    },
    methods: {
        checkInput () {
            this.warning = false;
        },
        doLoadImage: function (e) {
            const cb = (status, file_name) => {
                if (status) return console.log(status, file_name);
                const fr = new FileReader();

                fr.addEventListener("load", (e) => {
                    this.image = e.target.result
                    this.icon = file_name
                });

                fr.readAsDataURL(e.target.files[0]);
                console.log(status, file_name);
            };

            app.store.dispatch(`${AJAX}/${ACT_AJAX_SEND_FILE}`, {
                url: declarations.http_post_dirs.CHANNELS_ICONS,
                file: e.target.files[0],
                cb(err, file_name) {
                    cb && cb(err, file_name)
                }
            })
        },
        doFileSelect: function () {
            let input = this.$refs.fileLoader;
            input.value = '';
            input.click();
        },
        onOkClicked() {
            if(!this.name) return this.warning = true;
            const data = {
                chType: this.public ? declarations.channel_types.PUBLIC : declarations.channel_types.PRIVATE,
                name: this.name,
                info: this.info,
            }
            if (this.iconChanged) data.icon = this.icon
            if (!this.newChannel) data.chId = this.props.channel.chId
            this[this.newChannel ? ACT_CREATE_CHANNEL : ACT_CHANGE_CHANNEL](data)
            this.modalClose();
        },
        doRemoveImage() {
            this.icon = this.image = ''
        },
        doRollbackImage() {
            this.image = this.oldImage
            this.icon = this.oldIcon
        },
        ...mapActions(CHANNELS, [ACT_CREATE_CHANNEL, ACT_CHANGE_CHANNEL])
    },
    created () {
        let icon = this.props.channel && this.props.channel.icon || ''
        let image = icon && app.getChannelPhotoUrl(icon) || ''
        if (icon) this.icon = this.oldIcon = icon
        if (image) this.image = this.oldImage = image
    }
}

