var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return false
    ? _c(
        "div",
        { staticClass: "change-favourites-wrapper" },
        [
          _c("div", { staticClass: "change-favourites-header" }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("contacts-comp.change-fav")))]),
            _c(
              "div",
              {
                staticClass: "change-favourites-close",
                on: {
                  click: function ($event) {
                    return _vm.changeFavouritesMode(false)
                  },
                },
              },
              [_c("i", { staticClass: "fas fa-times" })]
            ),
          ]),
          _c("div", { staticClass: "hor-line light-line" }),
          _c(
            "div",
            { staticClass: "change-favourites-list" },
            [
              _c(
                "draggable",
                _vm._b(
                  {
                    staticClass: "list-group",
                    attrs: { tag: "ul" },
                    on: {
                      start: function ($event) {
                        _vm.isDragging = true
                      },
                      end: function ($event) {
                        _vm.isDragging = false
                      },
                    },
                    model: {
                      value: _vm.favourites,
                      callback: function ($$v) {
                        _vm.favourites = $$v
                      },
                      expression: "favourites",
                    },
                  },
                  "draggable",
                  _vm.dragOptions,
                  false
                ),
                [
                  _c(
                    "transition-group",
                    { attrs: { type: "transition", name: "'flip-list'" } },
                    _vm._l(_vm.favourites, function (favourite, i) {
                      return _c(
                        "div",
                        {
                          key: "a" + i,
                          staticClass: "change-favourites-list-item",
                        },
                        [
                          _c(
                            "contacts-list-item",
                            { attrs: { contact: favourite } },
                            [
                              _c("div", { staticClass: "menu" }, [
                                _c("div", { staticClass: "move" }, [
                                  _c("i", { staticClass: "fal fa-bars" }),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "item-menu",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.doContextMenu(
                                          $event,
                                          favourite,
                                          "bottom-right"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fal fa-ellipsis-v",
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "add-favourite-button",
                      attrs: { slot: "footer" },
                      on: { click: _vm.addFavourite },
                      slot: "footer",
                    },
                    [
                      _c("a", { attrs: { href: "#" } }, [
                        _vm._v(_vm._s(_vm.$t("contacts-comp.add-to-fav"))),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("rawDisplayer", {
            staticClass: "col-3",
            attrs: { value: _vm.favourites, title: "List" },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }