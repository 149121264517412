var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog hoy",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.$t("videomost.new-conference"),
      },
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "conference-settings" }, [
          _c(
            "div",
            { staticClass: "subject conf-item" },
            [
              _c("custom-input-text", {
                attrs: {
                  default_text: _vm.conf.topic,
                  input_text: _vm.conf.topic,
                  label: _vm.$t("videomost.conf-subject"),
                  warning: _vm.subjectMissed,
                },
                on: {
                  "update:input_text": function ($event) {
                    return _vm.$set(_vm.conf, "topic", $event)
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    _vm.subjectMissed = false
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "schedule conf-item" },
            [
              _c("date-range-picker", {
                key: "2",
                attrs: {
                  default_from: _vm.getStartTime(_vm.conf),
                  from: _vm.conf.startUTime,
                  default_to: _vm.getFinishTime(_vm.conf),
                  to: _vm.conf.finishUTime,
                  required_from: _vm.fromMissed,
                  required_to: _vm.toMissed,
                  minute_step: 5,
                  clearable: false,
                },
                on: {
                  "update:from": function ($event) {
                    return _vm.$set(_vm.conf, "startUTime", $event)
                  },
                  fromClick: function ($event) {
                    _vm.fromMissed = false
                  },
                  "update:to": function ($event) {
                    return _vm.$set(_vm.conf, "finishUTime", $event)
                  },
                  toClick: function ($event) {
                    _vm.toMissed = false
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "conf-auth conf-item" },
            [
              _c("custom-input-text", {
                key: "1",
                staticStyle: { "margin-right": "4px" },
                attrs: {
                  default_text: _vm.conf.confid,
                  input_text: _vm.conf.confid,
                  label: _vm.$t("videomost.conf-id"),
                  warning: _vm.confIdMissed,
                },
                on: {
                  "update:input_text": function ($event) {
                    return _vm.$set(_vm.conf, "confid", $event)
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    _vm.confIdMissed = false
                  },
                },
              }),
              _c("custom-input-text", {
                key: "2",
                staticStyle: { "margin-left": "22px" },
                attrs: {
                  default_text: _vm.conf.password,
                  input_text: _vm.conf.password,
                  label: _vm.$t("videomost.conf-password"),
                  warning: _vm.confPasswordMissed,
                },
                on: {
                  "update:input_text": function ($event) {
                    return _vm.$set(_vm.conf, "password", $event)
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    _vm.confPasswordMissed = false
                  },
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "video-quality" }, [
            _c(
              "div",
              { staticClass: "video-quality-item" },
              [
                _c("span", { staticClass: "subheader" }, [
                  _vm._v(_vm._s(_vm.$t("videomost.video-quality"))),
                ]),
                _c("custom-select", {
                  attrs: {
                    selected_option: _vm.chosenVideoQuality,
                    options: _vm.videoQuality,
                  },
                  on: {
                    "update:selected_option": function ($event) {
                      _vm.chosenVideoQuality = $event
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "video-quality-item" },
              [
                _c("span", { staticClass: "subheader" }, [
                  _vm._v(_vm._s(_vm.$t("videomost.quality-client"))),
                ]),
                _c("custom-select", {
                  attrs: {
                    selected_option: _vm.chosenVideoQualityClient,
                    options: _vm.videoQualityClient,
                  },
                  on: {
                    "update:selected_option": function ($event) {
                      _vm.chosenVideoQualityClient = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "conf-modes conf-item" },
            [
              _c("span", { staticClass: "subheader" }, [
                _vm._v(_vm._s(_vm.$t("videomost.conf-modes"))),
              ]),
              _c("custom-select", {
                attrs: {
                  selected_option: _vm.show_only_owner,
                  options: _vm.confModes,
                },
                on: {
                  "update:selected_option": function ($event) {
                    _vm.show_only_owner = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "conf-modes-options conf-item subheader" },
            [
              _c("custom-checkbox", {
                attrs: {
                  default: _vm.isAddTerminals,
                  checkbox: _vm.isAddTerminals,
                  label: this.$t("videomost.settings-addterminals"),
                },
                on: {
                  "update:checkbox": function ($event) {
                    _vm.isAddTerminals = $event
                  },
                },
              }),
              _vm.isAddTerminals
                ? _c("div", { staticClass: "terminals subheader" }, [
                    _c(
                      "div",
                      { staticClass: "item" },
                      [
                        _c("custom-radio", {
                          attrs: {
                            inputs: _vm.SIPServerItems,
                            default_data: _vm.SIPServerValue,
                            value_change: _vm.SIPServerValue,
                            isHorizontal: "",
                          },
                          on: {
                            "update:value_change": function ($event) {
                              _vm.SIPServerValue = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.SIPServerValue.toString() === "1"
                      ? _c("div", { staticClass: "sipserver item" }, [
                          _c(
                            "div",
                            { staticClass: "sipitem" },
                            [
                              _c("custom-input-text", {
                                key: "1",
                                attrs: {
                                  input_text: _vm.SIPServer,
                                  default_text: _vm.SIPServer,
                                  label: "SIP Account (123@domain)",
                                },
                                on: {
                                  "update:input_text": function ($event) {
                                    _vm.SIPServer = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "sipitem" },
                            [
                              _c("custom-input-text", {
                                key: "2",
                                attrs: {
                                  input_text: _vm.SIPProxy,
                                  default_text: _vm.SIPProxy,
                                  label: "SIP Proxy IP (1.2.3.4)",
                                },
                                on: {
                                  "update:input_text": function ($event) {
                                    _vm.SIPProxy = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "items" }, [
                      _c(
                        "div",
                        { staticClass: "item" },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.SIPPartiesMixerLabel)),
                          ]),
                          _c("custom-select", {
                            attrs: {
                              selected_option: _vm.SIPPartiesMixerValue,
                              options: _vm.SIPPartiesMixerItems,
                            },
                            on: {
                              "update:selected_option": function ($event) {
                                _vm.SIPPartiesMixerValue = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "item",
                          staticStyle: { height: "38px" },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.SIPPartiesVideoQualityLabel)),
                          ]),
                          _c("custom-select", {
                            attrs: {
                              selected_option: _vm.SIPPartiesVideoQualityValue,
                              options: _vm.SIPPartiesVideoQualityItems,
                            },
                            on: {
                              "update:selected_option": function ($event) {
                                _vm.SIPPartiesVideoQualityValue = $event
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "periodicity subheader" },
            [
              _c("custom-checkbox", {
                attrs: {
                  default: _vm.rhythm_pattern_enabled,
                  checkbox: _vm.rhythm_pattern_enabled,
                  label: this.$t("videomost.settings-period"),
                },
                on: {
                  "update:checkbox": function ($event) {
                    _vm.rhythm_pattern_enabled = $event
                  },
                },
              }),
              _vm.rhythm_pattern_enabled
                ? _c(
                    "div",
                    { staticClass: "options" },
                    [
                      _c("custom-select", {
                        attrs: {
                          selected_option: _vm.periodItemValue,
                          options: _vm.periodItems,
                        },
                        on: {
                          "update:selected_option": function ($event) {
                            _vm.periodItemValue = $event
                          },
                        },
                      }),
                      _c("div", { staticClass: "counterwrapper" }, [
                        _c("span", { staticClass: "words-left" }, [
                          _vm._v(_vm._s(_vm.$t("videomost.periods.repeat"))),
                        ]),
                        _c("span", { staticClass: "words-left" }, [
                          _vm._v(_vm._s(_vm.repeatPhrase.eachWord)),
                        ]),
                        _c("span", { staticClass: "words-right" }, [
                          _vm._v(_vm._s(_vm.repeatPhrase.periodWord)),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.rhythm_pattern_enabled && _vm.periodItemValue === "weekly"
                ? _c(
                    "div",
                    { staticClass: "weekdays" },
                    [
                      _c("span", { staticClass: "words" }, [
                        _vm._v(
                          _vm._s(_vm.$t("videomost.periods.days")) +
                            " " +
                            _vm._s(
                              _vm.$t("videomost.periods.weeks").toLowerCase()
                            )
                        ),
                      ]),
                      _c("custom-checkbox", {
                        staticClass: "ccb",
                        attrs: {
                          label: this.$t("videomost.weekdays.monday"),
                          checkbox: _vm.isMo,
                          default: _vm.isMo,
                        },
                        on: {
                          "update:checkbox": function ($event) {
                            _vm.isMo = $event
                          },
                        },
                      }),
                      _c("custom-checkbox", {
                        staticClass: "ccb",
                        attrs: {
                          label: this.$t("videomost.weekdays.tuesday"),
                          checkbox: _vm.isTu,
                          default: _vm.isTu,
                        },
                        on: {
                          "update:checkbox": function ($event) {
                            _vm.isTu = $event
                          },
                        },
                      }),
                      _c("custom-checkbox", {
                        staticClass: "ccb",
                        attrs: {
                          label: this.$t("videomost.weekdays.wednesday"),
                          checkbox: _vm.isWe,
                          default: _vm.isWe,
                        },
                        on: {
                          "update:checkbox": function ($event) {
                            _vm.isWe = $event
                          },
                        },
                      }),
                      _c("custom-checkbox", {
                        staticClass: "ccb",
                        attrs: {
                          label: this.$t("videomost.weekdays.thursday"),
                          checkbox: _vm.isTh,
                          default: _vm.isTh,
                        },
                        on: {
                          "update:checkbox": function ($event) {
                            _vm.isTh = $event
                          },
                        },
                      }),
                      _c("custom-checkbox", {
                        staticClass: "ccb",
                        attrs: {
                          label: this.$t("videomost.weekdays.friday"),
                          checkbox: _vm.isFr,
                          default: _vm.isFr,
                        },
                        on: {
                          "update:checkbox": function ($event) {
                            _vm.isFr = $event
                          },
                        },
                      }),
                      _c("custom-checkbox", {
                        staticClass: "ccb",
                        attrs: {
                          label: this.$t("videomost.weekdays.saturday"),
                          checkbox: _vm.isSa,
                          default: _vm.isSa,
                        },
                        on: {
                          "update:checkbox": function ($event) {
                            _vm.isSa = $event
                          },
                        },
                      }),
                      _c("custom-checkbox", {
                        staticClass: "ccb",
                        attrs: {
                          label: this.$t("videomost.weekdays.sunday"),
                          checkbox: _vm.isSu,
                          default: _vm.isSu,
                        },
                        on: {
                          "update:checkbox": function ($event) {
                            _vm.isSu = $event
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.$t("videomost.cancel") },
            on: { click: _vm.modalClose },
          }),
          _c("modal-btn", {
            attrs: { text: _vm.$t("videomost.save") },
            on: {
              click: function ($event) {
                return _vm.saveConf(_vm.conf)
              },
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }