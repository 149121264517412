var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notify-body", on: { click: _vm.doOnClickNotification } },
    [
      _vm.img
        ? _c("icon-image", { attrs: { src: _vm.img, text: _vm.title } })
        : _vm.type === "alert"
        ? _c("div", { staticClass: "icon" }, [
            _c("i", { staticClass: "fas fa-exclamation" }),
          ])
        : _vm._e(),
      _c("div", { staticClass: "info-section" }, [
        _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        _c("span", { staticClass: "subtitle" }, [_vm._v(_vm._s(_vm.subtitle))]),
      ]),
      _c("i", { staticClass: "fas fa-times", on: { click: _vm.hide } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }