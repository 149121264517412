var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title:
          _vm.type === "edit"
            ? _vm.$t("contact-editing")
            : _vm.$t("create-contact"),
      },
    },
    [
      _c("template", { slot: "default" }, [
        _c("div", { staticClass: "contact-edition" }, [
          _c("div", { staticClass: "block" }, [
            _c(
              "div",
              {
                directives: [{ name: "focus", rawName: "v-focus" }],
                staticClass: "edition-user-cont",
              },
              [
                _c("div", { staticClass: "edition-user-main" }, [
                  _c(
                    "div",
                    { staticClass: "icon-wrapper edition-user-foto" },
                    [
                      _c("icon-image", {
                        staticClass: "medium-big editable",
                        attrs: {
                          src: _vm.photo,
                          remove: _vm.iconCanBeRemoved,
                          rollBack: _vm.iconCanBeRollback,
                        },
                        on: {
                          loaded: _vm.doOnImageLoad,
                          remove: _vm.doRemovePhoto,
                          rollBack: _vm.doRollbackPhoto,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.doFileSelect($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "edition-user-name" }, [
                    _c("div", { staticClass: "edition-user-name-input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.contact.surname.value,
                            expression: "contact.surname.value",
                          },
                        ],
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("information.surname") },
                        domProps: { value: _vm.contact.surname.value },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.contact.surname,
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("div", { staticClass: "global-value-btn" }, [
                        _vm.globalValueBtn("surname")
                          ? _c("i", {
                              staticClass: "fas fa-times",
                              on: {
                                click: function ($event) {
                                  return _vm.doGetGlobalValue("surname")
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "edition-user-name-input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.contact.name.value,
                            expression: "contact.name.value",
                          },
                        ],
                        staticClass: "input",
                        attrs: { placeholder: _vm.$t("information.name") },
                        domProps: { value: _vm.contact.name.value },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.contact.name,
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("div", { staticClass: "global-value-btn" }, [
                        _vm.globalValueBtn("name")
                          ? _c("i", {
                              staticClass: "fas fa-times",
                              on: {
                                click: function ($event) {
                                  return _vm.doGetGlobalValue("name")
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _c("div", { staticClass: "edition-user-name-input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.contact.secondname.value,
                            expression: "contact.secondname.value",
                          },
                        ],
                        staticClass: "input",
                        attrs: {
                          placeholder: _vm.$t("information.secondname"),
                        },
                        domProps: { value: _vm.contact.secondname.value },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.contact.secondname,
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("div", { staticClass: "global-value-btn" }, [
                        _vm.globalValueBtn("secondname")
                          ? _c("i", {
                              staticClass: "fas fa-times",
                              on: {
                                click: function ($event) {
                                  return _vm.doGetGlobalValue("secondname")
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm.contact.nik
                      ? _c("div", { staticClass: "edition-user-name-input" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.contact.nik.value,
                                expression: "contact.nik.value",
                              },
                            ],
                            staticClass: "input",
                            attrs: { placeholder: _vm.$t("nick") },
                            domProps: { value: _vm.contact.nik.value },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.contact.nik,
                                  "value",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _c("div", { staticClass: "global-value-btn" }, [
                            _vm.globalValueBtn("nik")
                              ? _c("i", {
                                  staticClass: "fas fa-times",
                                  on: {
                                    click: function ($event) {
                                      return _vm.doGetGlobalValue("nik")
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("label", { staticClass: "edition-user-section-single" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("information.organization"))),
                  ]),
                  _c("div", { staticClass: "edition-user-section-input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.contact.organization.value,
                          expression: "contact.organization.value",
                        },
                      ],
                      staticClass: "input",
                      attrs: {
                        placeholder: _vm.$t("information.organization"),
                      },
                      domProps: { value: _vm.contact.organization.value },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.contact.organization,
                            "value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("div", { staticClass: "global-value-btn" }, [
                      _vm.globalValueBtn("organization")
                        ? _c("i", {
                            staticClass: "fas fa-times",
                            on: {
                              click: function ($event) {
                                return _vm.doGetGlobalValue("organization")
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _c("label", { staticClass: "edition-user-section-single" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("information.department"))),
                  ]),
                  _c("div", { staticClass: "edition-user-section-input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.contact.office.value,
                          expression: "contact.office.value",
                        },
                      ],
                      staticClass: "input",
                      attrs: { placeholder: _vm.$t("information.department") },
                      domProps: { value: _vm.contact.office.value },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.contact.office,
                            "value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("div", { staticClass: "global-value-btn" }, [
                      _vm.globalValueBtn("office")
                        ? _c("i", {
                            staticClass: "fas fa-times",
                            on: {
                              click: function ($event) {
                                return _vm.doGetGlobalValue("office")
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _c("label", { staticClass: "edition-user-section-single" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("information.position")))]),
                  _c("div", { staticClass: "edition-user-section-input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.contact.post.value,
                          expression: "contact.post.value",
                        },
                      ],
                      staticClass: "input",
                      attrs: { placeholder: _vm.$t("information.position") },
                      domProps: { value: _vm.contact.post.value },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.contact.post,
                            "value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _c("div", { staticClass: "global-value-btn" }, [
                      _vm.globalValueBtn("post")
                        ? _c("i", {
                            staticClass: "fas fa-times",
                            on: {
                              click: function ($event) {
                                return _vm.doGetGlobalValue("post")
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]),
                ]),
                _vm.contact.room
                  ? _c(
                      "label",
                      { staticClass: "edition-user-section-single" },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("information.room"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "edition-user-section-input" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.contact.room.value,
                                  expression: "contact.room.value",
                                },
                              ],
                              staticClass: "input",
                              attrs: {
                                placeholder: _vm.$t("information.room"),
                              },
                              domProps: { value: _vm.contact.room.value },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact.room,
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", { staticClass: "global-value-btn" }, [
                              _vm.globalValueBtn("room")
                                ? _c("i", {
                                    staticClass: "fas fa-times",
                                    on: {
                                      click: function ($event) {
                                        return _vm.doGetGlobalValue("room")
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.contact.workstart
                  ? _c(
                      "label",
                      { staticClass: "edition-user-section-single" },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("work-hours")))]),
                        _c(
                          "div",
                          { staticClass: "edition-user-section-input" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.contact.workstart.value,
                                  expression: "contact.workstart.value",
                                },
                              ],
                              staticClass: "input",
                              attrs: { placeholder: _vm.$t("work-start") },
                              domProps: { value: _vm.contact.workstart.value },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact.workstart,
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", { staticClass: "global-value-btn" }, [
                              _vm.globalValueBtn("workstart")
                                ? _c("i", {
                                    staticClass: "fas fa-times",
                                    on: {
                                      click: function ($event) {
                                        return _vm.doGetGlobalValue("workstart")
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.contact.workfinish
                  ? _c(
                      "label",
                      { staticClass: "edition-user-section-single" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.contact.workstart ? "" : _vm.$t("work-hours")
                            )
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "edition-user-section-input" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.contact.workfinish.value,
                                  expression: "contact.workfinish.value",
                                },
                              ],
                              staticClass: "input",
                              attrs: { placeholder: _vm.$t("work-finish") },
                              domProps: { value: _vm.contact.workfinish.value },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact.workfinish,
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", { staticClass: "global-value-btn" }, [
                              _vm.globalValueBtn("workfinish")
                                ? _c("i", {
                                    staticClass: "fas fa-times",
                                    on: {
                                      click: function ($event) {
                                        return _vm.doGetGlobalValue(
                                          "workfinish"
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.contact.leader
                  ? _c(
                      "label",
                      { staticClass: "edition-user-section-single" },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("information.head"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "edition-user-section-input" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.contact.leader.value,
                                  expression: "contact.leader.value",
                                },
                              ],
                              staticClass: "input",
                              attrs: {
                                placeholder: _vm.$t("information.head"),
                              },
                              domProps: { value: _vm.contact.leader.value },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact.leader,
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", { staticClass: "global-value-btn" }, [
                              _vm.globalValueBtn("leader")
                                ? _c("i", {
                                    staticClass: "fas fa-times",
                                    on: {
                                      click: function ($event) {
                                        return _vm.doGetGlobalValue("leader")
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.contact.helper
                  ? _c(
                      "label",
                      { staticClass: "edition-user-section-single" },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("information.helper"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "edition-user-section-input" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.contact.helper.value,
                                  expression: "contact.helper.value",
                                },
                              ],
                              staticClass: "input",
                              attrs: {
                                placeholder: _vm.$t("information.helper"),
                              },
                              domProps: { value: _vm.contact.helper.value },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact.helper,
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", { staticClass: "global-value-btn" }, [
                              _vm.globalValueBtn("helper")
                                ? _c("i", {
                                    staticClass: "fas fa-times",
                                    on: {
                                      click: function ($event) {
                                        return _vm.doGetGlobalValue("helper")
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._l(_vm.contact.phone, function (phone, index) {
                  return _c("div", { staticClass: "edition-user-section" }, [
                    _c("div", { staticClass: "edition-user-section-label" }, [
                      _c("div", { staticClass: "edition-user-btn-set" }, [
                        !_vm.isDisabled("phone", index)
                          ? _c("i", {
                              staticClass: "fas fa-cog",
                              on: {
                                click: function ($event) {
                                  return _vm.doMenu(
                                    $event,
                                    index,
                                    "bottom-right",
                                    "phone"
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "edition-user-label-name" },
                        [
                          _c("custom-input", {
                            attrs: {
                              contenteditable: _vm.contenteditable(
                                "phone",
                                index
                              ),
                              text: _vm.contact.phone[index].name,
                              idTextarea: "div-textarea-phone-" + index,
                              typeField: _vm.contact.phone[index].type,
                            },
                            on: {
                              "update:text": function ($event) {
                                return _vm.$set(
                                  _vm.contact.phone[index],
                                  "name",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "edition-user-section-input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.contact.phone[index].value,
                            expression: "contact.phone[index].value",
                          },
                        ],
                        staticClass: "input",
                        attrs: {
                          disabled: _vm.isDisabled("phone", index),
                          placeholder: _vm.$t("loginPage.remote"),
                        },
                        domProps: { value: _vm.contact.phone[index].value },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.contact.phone[index],
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("div", { staticClass: "global-value-btn" }, [
                        _vm.globalValueBtn("phone", index)
                          ? _c("i", {
                              staticClass: "fas fa-times",
                              on: {
                                click: function ($event) {
                                  return _vm.doGetGlobalValue("phone", index)
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                }),
                _vm._l(_vm.contact.mail, function (mail, index) {
                  return _c("div", { staticClass: "edition-user-section" }, [
                    _c("div", { staticClass: "edition-user-section-label" }, [
                      _c("div", { staticClass: "edition-user-btn-set" }, [
                        !_vm.isDisabled("mail", index)
                          ? _c("i", {
                              staticClass: "fas fa-cog",
                              on: {
                                click: function ($event) {
                                  return _vm.doMenu(
                                    $event,
                                    index,
                                    "bottom-right",
                                    "mail"
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "edition-user-label-name" },
                        [
                          _c("custom-input", {
                            attrs: {
                              contenteditable: _vm.contenteditable(
                                "mail",
                                index
                              ),
                              text: _vm.contact.mail[index].name,
                              idTextarea: "div-textarea-mail-" + index,
                              typeField: _vm.contact.mail[index].type,
                            },
                            on: {
                              "update:text": function ($event) {
                                return _vm.$set(
                                  _vm.contact.mail[index],
                                  "name",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "edition-user-section-input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.contact.mail[index].value,
                            expression: "contact.mail[index].value",
                          },
                        ],
                        staticClass: "input",
                        attrs: { placeholder: "e-mail" },
                        domProps: { value: _vm.contact.mail[index].value },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.contact.mail[index],
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("div", { staticClass: "global-value-btn" }, [
                        _vm.globalValueBtn("mail", index)
                          ? _c("i", {
                              staticClass: "fas fa-times",
                              on: {
                                click: function ($event) {
                                  return _vm.doGetGlobalValue("mail", index)
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                }),
                _vm._l(_vm.contact.icq, function (icq, index) {
                  return _c("div", { staticClass: "edition-user-section" }, [
                    _c("div", { staticClass: "edition-user-section-label" }, [
                      _c("div", { staticClass: "edition-user-btn-set" }, [
                        !_vm.isDisabled("icq", index)
                          ? _c("i", {
                              staticClass: "fas fa-cog",
                              on: {
                                click: function ($event) {
                                  return _vm.doMenu(
                                    $event,
                                    index,
                                    "bottom-right",
                                    "icq"
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "edition-user-label-name" },
                        [
                          _c("custom-input", {
                            attrs: {
                              contenteditable: _vm.contenteditable(
                                "icq",
                                index
                              ),
                              text: _vm.contact.icq[index].name,
                              idTextarea: "div-textarea-icq-" + index,
                              typeField: _vm.contact.icq[index].type,
                            },
                            on: {
                              "update:text": function ($event) {
                                return _vm.$set(
                                  _vm.contact.icq[index],
                                  "name",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "edition-user-section-input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.contact.icq[index].value,
                            expression: "contact.icq[index].value",
                          },
                        ],
                        staticClass: "input",
                        attrs: {
                          disabled: _vm.isDisabled("icq", index),
                          placeholder: "icq",
                        },
                        domProps: { value: _vm.contact.icq[index].value },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.contact.icq[index],
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("div", { staticClass: "global-value-btn" }, [
                        _vm.globalValueBtn("icq", index)
                          ? _c("i", {
                              staticClass: "fas fa-times",
                              on: {
                                click: function ($event) {
                                  return _vm.doGetGlobalValue("icq", index)
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                }),
                _vm._l(_vm.contact.site, function (site, index) {
                  return _c("div", { staticClass: "edition-user-section" }, [
                    _c("div", { staticClass: "edition-user-section-label" }, [
                      _c("div", { staticClass: "edition-user-btn-set" }, [
                        !_vm.isDisabled("site", index)
                          ? _c("i", {
                              staticClass: "fas fa-cog",
                              on: {
                                click: function ($event) {
                                  return _vm.doMenu(
                                    $event,
                                    index,
                                    "bottom-right",
                                    "site"
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "edition-user-label-name" },
                        [
                          _c("custom-input", {
                            attrs: {
                              contenteditable: _vm.contenteditable(
                                "site",
                                index
                              ),
                              text: _vm.contact.site[index].name,
                              idTextarea: "div-textarea-site-" + index,
                              typeField: _vm.contact.site[index].type,
                            },
                            on: {
                              "update:text": function ($event) {
                                return _vm.$set(
                                  _vm.contact.site[index],
                                  "name",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "edition-user-section-input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.contact.site[index].value,
                            expression: "contact.site[index].value",
                          },
                        ],
                        staticClass: "input",
                        attrs: {
                          disabled: _vm.isDisabled("site", index),
                          placeholder: _vm.$t("information.website"),
                        },
                        domProps: { value: _vm.contact.site[index].value },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.contact.site[index],
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("div", { staticClass: "global-value-btn" }, [
                        _vm.globalValueBtn("site", index)
                          ? _c("i", {
                              staticClass: "fas fa-times",
                              on: {
                                click: function ($event) {
                                  return _vm.doGetGlobalValue("site", index)
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                }),
                _vm._l(_vm.contact.address, function (address, index) {
                  return _c("div", { staticClass: "edition-user-section" }, [
                    _c("div", { staticClass: "edition-user-section-label" }, [
                      _c("div", { staticClass: "edition-user-btn-set" }, [
                        !_vm.isDisabled("address", index)
                          ? _c("i", {
                              staticClass: "fas fa-cog",
                              on: {
                                click: function ($event) {
                                  return _vm.doMenu(
                                    $event,
                                    index,
                                    "bottom-right",
                                    "address"
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "edition-user-label-name" },
                        [
                          _c("custom-input", {
                            attrs: {
                              contenteditable: _vm.contenteditable(
                                "address",
                                index
                              ),
                              text: _vm.contact.address[index].name,
                              idTextarea: "div-textarea-address-" + index,
                              typeField: _vm.contact.address[index].type,
                            },
                            on: {
                              "update:text": function ($event) {
                                return _vm.$set(
                                  _vm.contact.address[index],
                                  "name",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "edition-user-section-input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.contact.address[index].value,
                            expression: "contact.address[index].value",
                          },
                        ],
                        staticClass: "input",
                        attrs: {
                          disabled: _vm.isDisabled("address", index),
                          placeholder: _vm.$t("address"),
                        },
                        domProps: { value: _vm.contact.address[index].value },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.contact.address[index],
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("div", { staticClass: "global-value-btn" }, [
                        _vm.globalValueBtn("address", index)
                          ? _c("i", {
                              staticClass: "fas fa-times",
                              on: {
                                click: function ($event) {
                                  return _vm.doGetGlobalValue("address", index)
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                }),
                _vm.contact.birthday
                  ? _c(
                      "label",
                      { staticClass: "edition-user-section-single" },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("information.birthday"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "edition-user-section-input" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.contact.birthday.value,
                                  expression: "contact.birthday.value",
                                },
                              ],
                              staticClass: "input",
                              attrs: {
                                placeholder: _vm.$t("information.birthday"),
                              },
                              domProps: { value: _vm.contact.birthday.value },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact.birthday,
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", { staticClass: "global-value-btn" }, [
                              _vm.globalValueBtn("birthday")
                                ? _c("i", {
                                    staticClass: "fas fa-times",
                                    on: {
                                      click: function ($event) {
                                        return _vm.doGetGlobalValue("birthday")
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.contact.partner
                  ? _c(
                      "label",
                      { staticClass: "edition-user-section-single" },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("information.spouse"))),
                        ]),
                        _c(
                          "div",
                          { staticClass: "edition-user-section-input" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.contact.partner.value,
                                  expression: "contact.partner.value",
                                },
                              ],
                              staticClass: "input",
                              attrs: {
                                placeholder: _vm.$t("information.spouse"),
                              },
                              domProps: { value: _vm.contact.partner.value },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact.partner,
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", { staticClass: "global-value-btn" }, [
                              _vm.globalValueBtn("partner")
                                ? _c("i", {
                                    staticClass: "fas fa-times",
                                    on: {
                                      click: function ($event) {
                                        return _vm.doGetGlobalValue("partner")
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._l(_vm.contact.children, function (children, index) {
                  return _c("div", { staticClass: "edition-user-section" }, [
                    _c("div", { staticClass: "edition-user-section-label" }, [
                      _c("div", { staticClass: "edition-user-btn-set" }, [
                        !_vm.isDisabled("children", index)
                          ? _c("i", {
                              staticClass: "fas fa-cog",
                              on: {
                                click: function ($event) {
                                  return _vm.doMenu(
                                    $event,
                                    index,
                                    "bottom-right",
                                    "children"
                                  )
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                      _c(
                        "div",
                        { staticClass: "edition-user-label-name" },
                        [
                          _c("custom-input", {
                            attrs: {
                              contenteditable: _vm.contenteditable(
                                "children",
                                index
                              ),
                              text: _vm.contact.children[index].name,
                              idTextarea: "div-textarea-children-" + index,
                              typeField: _vm.contact.children[index].type,
                            },
                            on: {
                              "update:text": function ($event) {
                                return _vm.$set(
                                  _vm.contact.children[index],
                                  "name",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "edition-user-section-input" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.contact.children[index].value,
                            expression: "contact.children[index].value",
                          },
                        ],
                        staticClass: "input",
                        attrs: {
                          disabled: _vm.isDisabled("children", index),
                          placeholder: _vm.$t("information.children"),
                        },
                        domProps: { value: _vm.contact.children[index].value },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.contact.children[index],
                              "value",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _c("div", { staticClass: "global-value-btn" }, [
                        _vm.globalValueBtn("children", index)
                          ? _c("i", {
                              staticClass: "fas fa-times",
                              on: {
                                click: function ($event) {
                                  return _vm.doGetGlobalValue("children", index)
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                }),
                _vm.contact.note
                  ? _c(
                      "label",
                      { staticClass: "edition-user-section-single" },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.$t("notes")))]),
                        _c(
                          "div",
                          { staticClass: "edition-user-section-input" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.contact.note.value,
                                  expression: "contact.note.value",
                                },
                              ],
                              staticClass: "input",
                              attrs: { placeholder: _vm.$t("notes") },
                              domProps: { value: _vm.contact.note.value },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact.note,
                                    "value",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _c("div", { staticClass: "global-value-btn" }, [
                              _vm.globalValueBtn("note")
                                ? _c("i", {
                                    staticClass: "fas fa-times",
                                    on: {
                                      click: function ($event) {
                                        return _vm.doGetGlobalValue("note")
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _c("input", {
                  staticStyle: { display: "none" },
                  attrs: { id: "inputFotoUpload", type: "file" },
                  on: { change: _vm.doAddImage },
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
      _c("template", { slot: "footer" }, [
        _c("div", { staticClass: "btns-service" }, [
          !_vm.localUser && _vm.cid !== _vm.uid
            ? _c(
                "div",
                {
                  staticClass: "btn reset-local",
                  attrs: { title: _vm.$t("reset-initial") },
                  on: { click: _vm.backToGlobal },
                },
                [_c("i", { staticClass: "fas fa-undo-alt" })]
              )
            : _vm._e(),
          _vm.localUser
            ? _c(
                "div",
                {
                  staticClass: "btn delete-local",
                  attrs: { title: _vm.$t("delete-contact") },
                  on: { click: _vm.deleteLocalUser },
                },
                [_c("i", { staticClass: "fa fa-trash-alt" })]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "btn add-field",
              attrs: { title: _vm.$t("add-field") },
              on: { click: _vm.addField },
            },
            [_c("i", { staticClass: "fas fa-plus" })]
          ),
        ]),
        _c(
          "div",
          { staticClass: "btns-func" },
          [
            _c("modal-btn", {
              attrs: { text: _vm.$t("cancel") },
              on: { click: _vm.cancel },
            }),
            _c("modal-btn", {
              attrs: { text: _vm.$t("save") },
              on: { click: _vm.save },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }