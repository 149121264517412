let canvasId = 1

class VideoPlug {
    constructor ({text, textCb}) {
        this.updateFn = this.getVideoDummy({text, textCb})
        this.updateInt = null
        this.canvas = null
    }

    start() {
        let canvas = document.createElement('canvas')
        canvas.setAttribute('id', (canvasId++).toString())
        canvas.setAttribute('width', '480')
        canvas.setAttribute('height', '360')
        //canvas.style.position = 'absolute'
        //canvas.style.left = '-99999px'
        //canvas.style.top = '-99999px'
        //document.body.appendChild(canvas)

        this.canvas = canvas
        this._setUpdateInterval()
        return canvas.captureStream()
    }

    _setUpdateInterval() {
        if (this.updateInt) clearInterval(this.updateInt)
        this._update()
        setInterval(() => this._update(), 1000)
    }

    _update() {
        this.updateFn(this.canvas)
    }

    /**
     * Заглушка для видео
     * @param {Object} [options]
     * @param {String} [options.text]
     * @param {Function} [options.textCb]
     * @return {function(...[*]=)}
     */
    getVideoDummy ({text, textCb}) {
        return function (canvas) {
            let ctx = canvas.getContext('2d')
            ctx.fillStyle = "#262626"
            ctx.fillRect(0, 0, canvas.width, canvas.height)
            ctx.font = "20px Arial"
            ctx.fillStyle = "rgba(255, 255, 255, 0.6)"
            let userName = text || (textCb && textCb()) || ''
            let textMetr = ctx.measureText(userName)
            if (textMetr.width > canvas.width) {
                ctx.textAlign = "left"
                ctx.fillText(userName, 0, canvas.height / 2)
            } else {
                ctx.fillText(userName, canvas.width / 2 - textMetr.width / 2, canvas.height / 2)
            }
        }
    }

    stop() {
        if (this.updateInt) clearInterval(this.updateInt)
        //if (this.canvas) document.body.removeChild(this.canvas)
    }
}

export default VideoPlug