//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import modal_mixin from "./modal-mixin"
    import ModalBtn from "./ModalBtn.vue"
    import RecycleContactsList from "./RecycleContactsList.vue"
    import ContactsListItem from "../listItems/ContactsListItem.vue"
    import SelectedItemsLine from "./SelectedItemsLine.vue"
    import CustomSearch from "../custom/CustomSearch.vue"
    import { mapActions } from 'vuex'
    import { CONTACTS } from '../../store/modulesNames'
    import { ACT_SEARCH_CONTACTS, ACT_UPDATE_SEARCHED_CONTACT } from '../../store/actionsTypes'

    export default {
        name: "SelectContactsToScheduler",
        components: { ModalBtn, CustomSearch, RecycleContactsList, ContactsListItem, SelectedItemsLine },
        mixins: [modal_mixin],
        created() {
            this.searchContact = debounce(this.searchContact, 500)
        },        
        data() {
            return {
                compModalProps: {
                    delay: 350,
                    pivotY: 0.05,
                    width: 400,
                    height: 'auto',
                    clickToClose: false
                },
                selecter: true,
                selected: [],
                search: '',
                selected: [],
                contactsList: [],
                fromId: 0,
                searchStep: 50,
                isLoadPossible: true,
                loading: false
            }
        },
        beforeDestroy() {
            this.props.cancel && this.props.cancel()
        },
        async mounted() {
            await this.searchContact()
        },        
        computed: {
            cancelText() { return this.props.cancelText ||  this.$t('cancel') },
            okText() { return this.props.okText || this.$t('loginPage.ok') },
            multiple() { return !!this.props.multiple },
            title() { return this.multiple ? this.$t('chat.contacts-selection') : this.$t('chat.contact-selection') },
            excludeMembers() { 
                return this.props.excludeMembers || [] 
            },
            selectedItemsLine() {
                return this.selected.map(({fio, photo}) => ({iconPhoto: photo, iconText: fio, text: fio}))
            },            
        },
        watch: {
            selected() {
                this.maxHeight = this.selected.length !== 0
            },
            search() {
                this.newSearch()
            },
            unselectedContact(delCid) {
                let index = this.selected.findIndex(({cid}) => cid === delCid)
                if (index > -1) this.unselectContact(index)
            },
        },
        methods: {
            selectContact(contact) {
                let add = true
                for(let i = 0; i < this.selected.length; i++) {
                    if(this.selected[i].cid === contact.cid) {
                        add = false
                        break
                    }
                }
                if (add) {
                    this.selected.push(contact)
                }
            },
            unselectContact(index) {
                this.selected.splice(index, 1)
            },
            isMarked(item) {
                return this.selected.includes(item)
            },
            isExcluded({cid}) {
                return this.excludeMembers.includes(cid)
            },
            async searchContact() {
                let contacts = [], contactsWithEmails = []
                let isListPage = false
                try {
                    this.loading = true
                    const searchResult = await this[ACT_SEARCH_CONTACTS]({
                        search: this.search,
                        searchBots: false,
                        fromId: this.fromId,
                        count: this.searchStep
                    })
                    this.isLoadPossible = searchResult.isLoadPossible
                    if (searchResult.lastCid) this.fromId = searchResult.lastCid
                    isListPage = searchResult.isListPage
                    contacts = searchResult.contacts
                    for (let index = 0; index < contacts.length; index++) {
                        let contact = contacts[index]
                        // await this[ACT_UPDATE_SEARCHED_CONTACT]({ cid: contact.cid })
                        const mails = contact.fields.filter(f => f.type === 'mail') //this.$store.getters['contacts/getMailsListById'](contact.cid, true)
                        if (mails.length) {
                            contact.mails = mails
                            console.log("🚀 ~ file: SelectContactsToScheduler.vue:140 ~ searchContact ~ contact:", contact)
                            contactsWithEmails.push(contact)
                        }
                    }
                } catch (e) {}
                this.loading = false
                if (isListPage) {
                    this.contactsList = this.contactsList.concat(contactsWithEmails)
                } else {
                    this.contactsList = contactsWithEmails
                }
            },
            async newSearch() {
                this.fromId = 0
                this.isLoadPossible = true
                this.contactsList = []
                await this.searchContact()
                if (this.$refs.scroller && this.$refs.scroller.$el) this.$refs.scroller.$el.scrollTop = 0
            },
            onOk(contacts) {
                this.modalClose()
                this.props.cb && this.props.cb(contacts)
            },
            ...mapActions(CONTACTS, [ACT_SEARCH_CONTACTS, ACT_UPDATE_SEARCHED_CONTACT])
        }
    }

const debounce = (func, delay) => {
    let debounceTimer
    return function() {
        const context = this
        const args = arguments
        clearTimeout(debounceTimer)
        debounceTimer = setTimeout(() => { func.apply(context, args) }, delay)
    }
}
