//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import modal_mixin from "./modal-mixin"
import ListItem from '../custom/ListItem.vue'
import {mapGetters, mapActions} from 'vuex'
import {RADIO} from '../../store/modulesNames'
import {
    GET_RADIO_PARAMS,
    GET_CURRENT_RADIO,
} from '../../store/gettersTypes'
import {
    ACT_SET_CURRENT_RADIO,
    ACT_TETRA_ACTIVATE_GROUP,
} from '../../store/actionsTypes'

import {RADIO_TYPE} from '../../store/modules/radio'

export default {
    name: "RadioSelect",
    components: {ListItem},
    mixins: [modal_mixin],
    data() {
        return {
            compModalProps: {
                pivotY: 0.05,
                width: 450,
                height: 'auto'
            }
        }
    },
    computed: {
        title() { return this.$t('radio-select') },
        isActive() {
            return (radioItem) =>  radioItem === this.currentRadio
        },
        ...mapGetters(RADIO, {radioList: GET_RADIO_PARAMS, currentRadio: GET_CURRENT_RADIO})
    },
    methods: {
        selectRadio(radio) {
            this[ACT_SET_CURRENT_RADIO](radio)
            this.modalClose()
        },
        getTitleText(radio) {
            let text = this.$t('unknown-type')
            switch (radio.type) {
                case RADIO_TYPE.ROSCHAT:
                    text = this.$t('roschat-radio')
                    break
                case RADIO_TYPE.TETRA:
                    text = `${radio.description || radio.name}`
                    break
            }
            return text
        },
        getFooterText(radio) {
            let text = ''
            switch (radio.type) {
                case RADIO_TYPE.ROSCHAT:
                    text = this.$t('integrated-radio')
                    break
                case RADIO_TYPE.TETRA:
                    text = `${radio.name} (ssi ${radio.ssi})`
                    break
            }
            return text
        },
        ...mapActions(RADIO, [ACT_SET_CURRENT_RADIO])
    },
}
