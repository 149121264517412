//
//
//
//
//

import {GET_VC_CONF} from "../../store/gettersTypes"
import {CONF_PROPS, END_REASONS, STATUS} from "../../store/modules/video-conf-abstract"
import {mapGetters} from "vuex"
import {VIDEO_CONF_NEW} from "../../store/modulesNames"

import videoConfSectionMixin from "./videoConfSectionMixin"

export default {
    name: "videoConfStatus",
    mixins: [videoConfSectionMixin],
    computed: {
        conf() {
            return this.confIdGetterData && this[GET_VC_CONF](...this.confIdGetterData) || {}
        },
        /*status() {
            return this.conf[CONF_PROPS.STATUS]
        },*/
        errorText() {
            return this.conf[CONF_PROPS.ERROR_TEXT]
        },
        endReason() {
            return this.conf[CONF_PROPS.END_REASON]
        },
        statusText() {
            let text = ''
            switch (this.status) {
                case STATUS.CONNECTING:
                    text = this.$t('videomost.status.connecting')
                    break
                case STATUS.ACTIVE:
                    if (this.isBanned) text = this.$t('videomost.status.temp-ban')
                    break
                case STATUS.ERROR:
                    if (this.errorText) text = this.errorText
                    else text = this.$t('videomost.status.unknown-error')
                    break
                case STATUS.ENDED:
                    switch (this.endReason) {
                        case END_REASONS.REMOTE_TERMINATE:
                        default:
                            text = this.$t('videomost.status.ended')
                    }
                    break
            }
            return text
        },
        ...mapGetters(VIDEO_CONF_NEW, [GET_VC_CONF]),
    }
}
