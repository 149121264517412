//
//
//
//
//
//
//
//
//
//
//

import ModalDialog from './ModalDialog.vue';
import modal_mixin from './modal-mixin';
import { i18n } from '../../../ext/i18n'

export default {
    name: "Location",
    mixins: [modal_mixin],
    props: [ 'latitude', 'longitude', 'withText' ],
    components: {ModalDialog},
    data() {
      return {
          compModalProps: {
              delay: 0,
              pivotY: 0.05,
              width: 648,
              height: 550
          },
          map: null,
          placemark: null,
          interval: null
      }
    },
    computed: {
        //title() { return this.props.title || i18n.messages[i18n.locale]['action-confirm'] },
        okText() { return this.props.btnOk && this.props.btnOk.text || i18n.messages[i18n.locale].loginPage.ok },
        cancelText() { return this.props.btnCancel && this.props.btnCancel.text || i18n.messages[i18n.locale].cancel}
    },
    methods: {
        async onOk() {
            this.modalClose()
            let coordinates = this.placemark.geometry.getCoordinates()
            let dataCoordinates = {latitude: coordinates[0], longitude: coordinates[1]}
            if (this.withText) {
                let text = await this.getAddressByCoordinates(coordinates)
                if (!text) text = [dataCoordinates.latitude, dataCoordinates.longitude].map(val => val.toString().substr(0,9)).join(',')
                dataCoordinates.text = text
            }
            this.$nextTick(() => {
                this.btnOkCb && this.btnOkCb()
                this.props.btnOk && this.props.btnOk.cb && this.props.btnOk.cb(dataCoordinates)
            })
        },
        onCancel() {
            this.modalClose()
            this.props.onClose && this.props.onClose()
            this.props.btnCancel && this.props.btnCancel.cb && this.props.btnCancel.cb()
        },
        showMap () {
            if (this.$refs.map) {
                ymaps.ready(this.initMap)
                clearInterval(this.interval) 
            } 
        },
        initMap () {
            ymaps.geolocation.get().then( (res) => {
                let bounds = res.geoObjects.get(0).properties.get('boundedBy')
                    // Рассчитываем видимую область для текущей положения пользователя.
                let mapState = ymaps.util.bounds.getCenterAndZoom(
                        bounds,
                        [mapContainer.width(), mapContainer.height()]
                    );
                this.createMap(mapState);
            }, (e) => {
                // Если местоположение невозможно получить, то просто создаем карту.
                this.createMap({
                    center: [55.751574, 37.573856],
                    zoom: 16
                });
            });

        },
        createMap (state) {
            //if (center) state.center = [center.latitude, center.longitude];
            this.map = new ymaps.Map('map', state)
            this.placemark = new ymaps.Placemark(this.map.getCenter())
            this.map.geoObjects.add(this.placemark)

            this.map.events.add(['actionbegin'], (e) => {
                this.map.geoObjects.removeAll()
                document.querySelector("#map .cross-control").style.display = 'block'
            });
            this.map.events.add(['actionend'], (e) => {
                this.placemark = new ymaps.Placemark(this.map.getCenter())
                this.map.geoObjects.add(this.placemark)
                document.querySelector("#map .cross-control").style.display = 'none'
            });
        },
        async getAddressByCoordinates(coordinates) {
            let text = ''
            if (ymaps) {
                try {
                    text = await ymaps.geocode([coordinates.latitude, coordinates.longitude])
                } catch (e) {
                    console.log('getAddressByCoordinates error', e)
                }
            }
            return text
        },
        async loadMaps() {
            const script = document.createElement('script')
            script.src = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU'
            script.async = true
            document.body.appendChild(script)
            return new Promise(resolve => script.onload = resolve)
        },
    },
    async created () {

        //await this.loadMaps()
    },
    async mounted() {
        if (!window.ymaps) await this.loadMaps()
        this.interval = setInterval(() => this.showMap(), 50)
    }
}
