import {
    GET_VC_CONF,
    GET_CONF,
    GET_VC_CONF_STATUS,
    GET_VC_CONF_MIC_STATUS,
    GET_CONF_MIC_STATUS,
    GET_VC_CONF_CAM_STATUS,
    GET_CONF_CAM_STATUS,
    GET_CONF_INFO,
    GET_VC_CONF_INFO,
    GET_VC_CONF_SPEAKER_STATUS,
    GET_CONF_SPEAKER_STATUS,
    GET_VC_CONF_MEMBERS,
    GET_CONF_MEMBERS,
    IS_VC_CONF_MODERATOR,
    IS_CONF_MODERATOR,
    IS_VC_CONF_OWNER,
    IS_CONF_OWNER,
    GET_VC_CONF_SHARE,
    GET_CONF_SHARE,
    IS_OWN_SHARE,
    IS_VC_OWN_SHARE,
    GET_CONF_STATUS,
    IS_VC_BANNED,
    IS_BANNED,
    GENERATE_VC_CONF_PROPS,
    GENERATE_CONF_PROPS,
    GET_VC_CONF_HANDUP,
    GET_CONF_HANDUP,
    GET_CONF_OWN_MEMBER,
    GET_VC_CONF_OWN_MEMBER,
    GET_CONF_DIALOG_OPENED,
    GET_VC_CONF_DIALOG_OPENED,
    GET_CONF_MEDIA_DEVICE_BY_KIND,
    GET_VC_CONF_MEDIA_DEVICE_BY_KIND,
    GET_VC_CONF_INVITE_TEXT,
    GET_CONF_INVITE_TEXT,
    GET_VC_CONFS,
    IS_VC_MINIMIZED,
    IS_VC_IN_FULLSCREEN,
    GET_MINUTES_RELATIVE_TO_TIME,
    GET_VC_CONF_TALK_TIME,
    GET_VC_ACTIVE_CONF,
} from '../gettersTypes'

import {
    ACT_VC_CONF_JOIN,
    ACT_VC_CONF_JOIN_BY_LINK,
    ACT_VC_CONF_FINISH,
    ACT_VC_CONF_LEAVE,
    ACT_VC_CONF_MINIMIZE,
    ACT_VC_CONF_CAMERA_TOGGLE,
    ACT_VC_CONF_MICROPHONE_TOGGLE,
    ACT_VC_CONF_SPEAKER_TOGGLE,
    ACT_VC_CONF_SET_MEMBER_MODERATOR,
    ACT_VC_CONF_SET_MEMBER_PROPERTY,
    ACT_VC_CONF_SET_MEMBER_MIC,
    ACT_VC_CONF_SET_MEMBER_VIDEO,
    ACT_VC_CONF_BAN_MEMBER,
    ACT_VC_CONF_KICK_OUT_MEMBER,
    ACT_CONF_JOIN,
    ACT_CONF_JOIN_BY_LINK,
    ACT_CONF_CAMERA_TOGGLE,
    ACT_CONF_MICROPHONE_TOGGLE,
    ACT_CONF_SPEAKER_TOGGLE,
    ACT_CONF_SET_MEMBER_PROPERTY,
    ACT_CONF_SET_MEMBER_MODERATOR,
    ACT_CONF_SET_MEMBER_MIC,
    ACT_CONF_SET_MEMBER_VIDEO,
    ACT_CONF_BAN_MEMBER,
    ACT_CONF_KICK_OUT_MEMBER,
    ACT_CONF_LEAVE,
    ACT_CONF_SHARE,
    ACT_VC_CONF_SHARE,
    ACT_VC_CONF_SET_DIALOG_OPENED,
    ACT_MODAL_OPEN,
    ACT_VC_CONF_OPEN_DIALOG,
    ACT_CONF_WANT_SPEAK,
    ACT_VC_CONF_WANT_SPEAK,
    ACT_CONF_FINISH,
    ACT_CONF_HAND_UP,
    ACT_CONF_REACTION,
    ACT_VC_CONF_HAND_UP,
    ACT_VC_CONF_REACTION,
    ACT_VC_CONF_SET_DEVICE,
    ACT_CONF_SET_DEVICE,
    ACT_VC_CONF_SEND_INVITE,
    ACT_CONF_SEND_INVITE,
    ACT_VC_CONF_ADD,
    ACT_VC_CONF_REMOVE,
    ACT_VC_TOGGLE_MINIMIZE,
    ACT_VC_TOGGLE_FULLSCREEN,
    ACT_VC_SET_FULLSCREEN,
    ACT_VC_MINIMIZE,
    ACT_REQUEST_MEDIA_DEVICES_ACCESS,
} from '../actionsTypes'

import {
    MUT_CONF_SET_DIALOG_OPENED,
    MUT_CONF_UPDATE_MEMBER,
    MUT_VC_CONF_ADD,
    MUT_VC_CONF_REMOVE,
    MUT_VC_SET_CONF_FULLSCREEN,
    MUT_VC_SET_CONF_MINIMIZED,
} from '../mutationsTypes'

import {
    CLIENTDATA,
    MODAL, USERDATA,
    VM_CONF,
} from '../modulesNames'

import moment from "moment"

import { MEMBER_PROPS } from './video-conf-abstract'
import { CONF_TYPES } from "../../constants"

import ipc from "../../../electron/ipc"

export const getRootPathByType = function (type, localFnText) {
    switch (type) {
        case CONF_TYPES.VM:
            return `${VM_CONF}/${localFnText}`
        default: throw new Error('no-such-type')
    }
}

const state = {
    confs: [],
    minimized: false,
    fullScreen: false,
}

const getters = {
    [GET_VC_CONFS]: (state) => state.confs,
    [IS_VC_MINIMIZED]: state => state.minimized,
    [IS_VC_IN_FULLSCREEN]: state => state.fullScreen,
    [GET_VC_ACTIVE_CONF]: state => state.confs[state.confs.length - 1],
    [GET_VC_CONF_TALK_TIME]: (state, getters, rootState, rootGetters) => (type, id) => {
        const conf = findConf(state, {type, id})
        if (conf) return rootGetters[`${CLIENTDATA}/${GET_MINUTES_RELATIVE_TO_TIME}`](conf.talkStartTime)
    },
    [GENERATE_VC_CONF_PROPS]: (state, getters, rootState, rootGetters) => (type, ...payload) => {
        return rootGetters[getRootPathByType(type, GENERATE_CONF_PROPS)](...payload)
    },
    [GET_VC_CONF]: (state, getters, rootState, rootGetters) => (type, ...payload) => {
        return rootGetters[getRootPathByType(type, GET_CONF)](...payload)
    },
    [GET_VC_CONF_STATUS]: (state, getters, rootState, rootGetters) => (type, ...payload) => {
        return rootGetters[getRootPathByType(type, GET_CONF_STATUS)](...payload)
    },
    [GET_VC_CONF_MIC_STATUS]: (state, getters, rootState, rootGetters) => (type, ...payload) => {
        return rootGetters[getRootPathByType(type, GET_CONF_MIC_STATUS)](...payload)
    },
    [GET_VC_CONF_CAM_STATUS]: (state, getters, rootState, rootGetters) => (type, ...payload) => {
        return rootGetters[getRootPathByType(type, GET_CONF_CAM_STATUS)](...payload)
    },
    [GET_VC_CONF_SPEAKER_STATUS]: (state, getters, rootState, rootGetters) => (type, ...payload) => {
        return rootGetters[getRootPathByType(type, GET_CONF_SPEAKER_STATUS)](...payload)
    },
    [GET_VC_CONF_MEMBERS]: (state, getters, rootState, rootGetters) => (type, ...payload) => {
        return rootGetters[getRootPathByType(type, GET_CONF_MEMBERS)](...payload)
    },
    [GET_VC_CONF_OWN_MEMBER]: (state, getters, rootState, rootGetters) => (type, ...payload) => {
        return rootGetters[getRootPathByType(type, GET_CONF_OWN_MEMBER)](...payload)
    },
    [IS_VC_CONF_MODERATOR]: (state, getters, rootState, rootGetters) => (type, ...payload) => {
        return rootGetters[getRootPathByType(type, IS_CONF_MODERATOR)](...payload)
    },
    [IS_VC_CONF_OWNER]: (state, getters, rootState, rootGetters) => (type, ...payload) => {
        return rootGetters[getRootPathByType(type, IS_CONF_OWNER)](...payload)
    },
    [GET_VC_CONF_INFO]: (state, getters, rootState, rootGetters) => (type, ...payload) => {
        return rootGetters[getRootPathByType(type, GET_CONF_INFO)](...payload)
    },
    [GET_VC_CONF_SHARE]: (state, getters, rootState, rootGetters) => (type, ...payload) => {
        return rootGetters[getRootPathByType(type, GET_CONF_SHARE)](...payload)
    },
    [IS_VC_OWN_SHARE]: (state, getters, rootState, rootGetters) => (type, ...payload) => {
        return rootGetters[getRootPathByType(type, IS_OWN_SHARE)](...payload)
    },
    [GET_VC_CONF_HANDUP]: (state, getters, rootState, rootGetters) => (type, ...payload) => {
        return rootGetters[getRootPathByType(type, GET_CONF_HANDUP)](...payload)
    },
    [IS_VC_BANNED]: (state, getters, rootState, rootGetters) => (type, ...payload) => {
        return rootGetters[getRootPathByType(type, IS_BANNED)](...payload)
    },
    [GET_VC_CONF_MEDIA_DEVICE_BY_KIND]: (state, getters, rootState, rootGetters) => (type, ...payload) => {
        return rootGetters[getRootPathByType(type, GET_CONF_MEDIA_DEVICE_BY_KIND)](...payload)
    },
    [GET_VC_CONF_INVITE_TEXT]: (state, getters, rootState, rootGetters) => (type, ...payload) => {
        return rootGetters[getRootPathByType(type, GET_CONF_INVITE_TEXT)](...payload)
    },
    [GET_VC_CONF_DIALOG_OPENED]: (state, getters, rootState, rootGetters) => (type, ...payload) => {
        return rootGetters[getRootPathByType(type, GET_CONF_DIALOG_OPENED)](...payload)
    },
}
const actions = {
    // ------------------ Abstract actions --------------------------
    // --------------------------------------------------------------
    async [ACT_VC_CONF_ADD]({ state, getters, commit, dispatch }, payload) {
        const {type, data} = payload
        const containerOpened = Boolean(state.confs.length)
        await dispatch(`${USERDATA}/${ACT_REQUEST_MEDIA_DEVICES_ACCESS}`, { audio: true, video: true }, { root: true })
        commit(MUT_VC_CONF_ADD, {type, talkStartTime: moment().unix(), ...data})
        if (!containerOpened && state.confs.length) {
            dispatch(ACT_VC_MINIMIZE, false)
            dispatch(ACT_VC_SET_FULLSCREEN, false)
            ipc.send('conf-container-show')
        }
    },
    async [ACT_VC_CONF_REMOVE]({ commit }, payload) {
        commit(MUT_VC_CONF_REMOVE, payload)
    },
    [ACT_VC_TOGGLE_MINIMIZE]({ state, commit }, payload) {
        let minimize = (payload && 'minimize' in payload) ? payload.minimize : !state.minimized
        if (minimize) commit(MUT_VC_SET_CONF_FULLSCREEN, false)
        commit(MUT_VC_SET_CONF_MINIMIZED, minimize)
    },
    [ACT_VC_MINIMIZE]({commit}, payload) {
        commit(MUT_VC_SET_CONF_MINIMIZED, payload)
    },
    [ACT_VC_TOGGLE_FULLSCREEN]({ state, commit }) {
        commit(MUT_VC_SET_CONF_FULLSCREEN, !state.fullScreen)
    },
    [ACT_VC_SET_FULLSCREEN]({commit}, payload) {
        commit(MUT_VC_SET_CONF_FULLSCREEN, payload)
    },
    async [ACT_VC_CONF_JOIN]({ dispatch }, {type, ...payload}) {
        return dispatch(getRootPathByType(type, ACT_CONF_JOIN), payload, { root: true })
    },
    async [ACT_VC_CONF_JOIN_BY_LINK]({ dispatch }, {type, ...payload}) {
        return dispatch(getRootPathByType(type, ACT_CONF_JOIN_BY_LINK), payload, { root: true })
    },
    async [ACT_VC_CONF_FINISH]({ dispatch }, {type, ...payload}) {
        dispatch(ACT_VC_CONF_REMOVE, {type, ...payload})
        return dispatch(getRootPathByType(type, ACT_CONF_FINISH), payload, { root: true })
    },
    async [ACT_VC_CONF_LEAVE]({ dispatch }, {type, ...payload}) {
        dispatch(ACT_VC_CONF_REMOVE, {type, ...payload})
        return dispatch(getRootPathByType(type, ACT_CONF_LEAVE), payload, { root: true })
    },
    async [ACT_VC_CONF_MINIMIZE]({ dispatch }, {type, ...payload}) {

    },
    async [ACT_VC_CONF_CAMERA_TOGGLE]({ dispatch }, {type, ...payload}) {
        return dispatch(getRootPathByType(type, ACT_CONF_CAMERA_TOGGLE), payload, { root: true })
    },
    async [ACT_VC_CONF_MICROPHONE_TOGGLE]({ dispatch }, {type, ...payload}) {
        return dispatch(getRootPathByType(type, ACT_CONF_MICROPHONE_TOGGLE), payload, { root: true })
    },
    async [ACT_VC_CONF_SPEAKER_TOGGLE]({ dispatch }, {type, ...payload}) {
        return dispatch(getRootPathByType(type, ACT_CONF_SPEAKER_TOGGLE), payload, { root: true })
    },
    async [ACT_VC_CONF_WANT_SPEAK]({ dispatch }, {type, ...payload}) {
        return dispatch(getRootPathByType(type, ACT_CONF_WANT_SPEAK), payload, { root: true })
    },
    async [ACT_VC_CONF_SET_MEMBER_PROPERTY]({ dispatch, getters }, {type, ...payload}) {
        let moderator = getters[IS_VC_CONF_MODERATOR]
        if (!moderator) throw new Error('no-permission')
        return dispatch(getRootPathByType(type, ACT_CONF_SET_MEMBER_PROPERTY), payload, { root: true })
    },
    async [ACT_VC_CONF_SET_MEMBER_MODERATOR]({ dispatch }, {type, ...payload}) {
        let moderator = getters[IS_VC_CONF_MODERATOR]
        if (!moderator) throw new Error('no-permission')
        return dispatch(getRootPathByType(type, ACT_CONF_SET_MEMBER_MODERATOR), payload, { root: true })
    },
    async [ACT_VC_CONF_SET_MEMBER_MIC]({ dispatch }, {type, ...payload}) {
        let moderator = getters[IS_VC_CONF_MODERATOR]
        if (!moderator) throw new Error('no-permission')
        return dispatch(getRootPathByType(type, ACT_CONF_SET_MEMBER_MIC), payload, { root: true })
    },
    async [ACT_VC_CONF_SET_MEMBER_VIDEO]({ dispatch }, {type, ...payload}) {
        let moderator = getters[IS_VC_CONF_MODERATOR]
        if (!moderator) throw new Error('no-permission')
        return dispatch(getRootPathByType(type, ACT_CONF_SET_MEMBER_VIDEO), payload, { root: true })
    },
    async [ACT_VC_CONF_BAN_MEMBER]({ dispatch }, {type, ...payload}) {
        let moderator = getters[IS_VC_CONF_MODERATOR]
        if (!moderator) throw new Error('no-permission')
        return dispatch(getRootPathByType(type, ACT_CONF_BAN_MEMBER), payload, { root: true })
    },
    async [ACT_VC_CONF_KICK_OUT_MEMBER]({ dispatch }, {type, ...payload}) {
        let moderator = getters[IS_VC_CONF_MODERATOR]
        if (!moderator) throw new Error('no-permission')
        return dispatch(getRootPathByType(type, ACT_CONF_KICK_OUT_MEMBER), payload, { root: true })
    },
    async [ACT_VC_CONF_SHARE]({ dispatch }, {type, ...payload}) {
        return dispatch(getRootPathByType(type, ACT_CONF_SHARE), payload, { root: true })
    },
    [ACT_VC_CONF_HAND_UP]: ({ dispatch, commit, getters }, {type, ...payload} ) => {
        dispatch(getRootPathByType(type, ACT_CONF_HAND_UP), payload, { root: true })
        let { id } = getters[GET_VC_CONF_OWN_MEMBER](type, payload.id)
        commit(getRootPathByType(type, MUT_CONF_UPDATE_MEMBER), {
            id: payload.id,
            member: {
                id,
                [MEMBER_PROPS.HANDUP]: payload[MEMBER_PROPS.HANDUP]
            }
        }, { root: true })
    },
    [ACT_VC_CONF_REACTION]: ({ dispatch, commit, getters }, {type, ...payload} ) => {
        dispatch(getRootPathByType(type, ACT_CONF_REACTION), payload, { root: true })
        let { id } = getters[GET_VC_CONF_OWN_MEMBER](type, payload.id)
        commit(getRootPathByType(type, MUT_CONF_UPDATE_MEMBER), {
            id: payload.id,
            member: {
                id,
                [MEMBER_PROPS.REACTION]: payload[MEMBER_PROPS.REACTION]
            }
        }, { root: true })
    },
    [ACT_VC_CONF_OPEN_DIALOG]: ({ dispatch }, payload ) => {
        dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, payload, {root: true})
    },
    async [ACT_VC_CONF_SET_DEVICE]({ dispatch }, {type, ...payload}) {
        return dispatch(getRootPathByType(type, ACT_CONF_SET_DEVICE), payload, { root: true })
    },
    async [ACT_VC_CONF_SEND_INVITE]({ dispatch }, {type, ...payload}) {
        return dispatch(getRootPathByType(type, ACT_CONF_SEND_INVITE), payload, { root: true })
    },
    async [ACT_VC_CONF_SET_DIALOG_OPENED]({ commit }, {type, ...payload}) {
        return commit(getRootPathByType(type, MUT_CONF_SET_DIALOG_OPENED), payload, { root: true })
    },
}

const mutations = {
    [MUT_VC_CONF_ADD](state, payload) {
        if (!findConf(state, payload)) state.confs.push(payload)
    },
    [MUT_VC_CONF_REMOVE](state, payload) {
        const index = findConfIndex(state, payload)
        if (index >= 0) state.confs.splice(index, 1)
    },
    [MUT_VC_SET_CONF_MINIMIZED](state, minimized) {
       state.minimized = minimized
    },
    [MUT_VC_SET_CONF_FULLSCREEN](state, fullScreen) {
       state.fullScreen = fullScreen
    },
}

function findConfIndex(state, {type, id}) {
    return state.confs.findIndex(conf => conf.type === type && conf.id === id)
}

function findConf(state, {type, id}) {
    const index = findConfIndex(state, {type, id})
    return index >= 0 ? state.confs[index] : undefined
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
