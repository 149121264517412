//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import modal_mixin from '../modal/modal-mixin'
import CustomInputText from '../custom/CustomInputText.vue'
import DateRangePicker from '../settings/DateRangePicker.vue'
import CustomRadio from '../custom/CustomRadio.vue'
import ListItem from '../custom/ListItem.vue'
import ModalBtn from '../modal/ModalBtn.vue'
import EditConf from './editConference.vue'
import ConfSettings from './scheduleConferenceSettings.vue'
import AddConfMembers from '../modal/AddConfMembers.vue'
import EnterConf from "./enterConference.vue"
import {CHATS, VM_CONF} from "../../store/modulesNames"
import {ACT_SEND_MESSAGE, ACT_VM_CONF_DELETE, ACT_VM_CONF_SET_PARAMS} from "../../store/actionsTypes"

import {mapGetters} from 'vuex'
import {USERDATA, CONTACTS} from "../../store/modulesNames"
import {GET_MERGED_CONTACT_BY_ID, GET_UID, GET_VM_CONF_CONNECTION_PARAMS} from "../../store/gettersTypes"
import moment from 'moment'

export default {
    name: "vmConferenceInfo",
    components: { CustomInputText, DateRangePicker, CustomRadio, ListItem, ModalBtn, ConfSettings },
    mixins: [modal_mixin],
    props:  {
        conf: {
            type: Object,
            required: true,
            default: {}
        }
    },
    data() {
        return {
            mainTitle: this.$t('videomost.menu-title'),
            confModes: [{
                    value: true,
                    label: this.$t('videomost.conf-discussion')
                },
                {
                    value: false,
                    label: this.$t('videomost.conf-presentation')
                }
            ],
            compModalProps: {
                delay: 100,
                pivotY: 0.44,
                width: 464,
                height: 'auto',
                clickToClose: false
            },
            startConfText: this.$t('videomost.conf-run'),
            editConfText: this.$t('videomost.conf-edit'),
            confSettingsText: this.$t('videomost.conf-settings'),
            placeholder: this.$t('videomost.conf-enterway'),
            chosenEnterWay: null,
            readonly: true,
            dateTimeFromStr: '',
            dateTimeToStr: '',
        }
    },
    computed: {
        ...mapGetters(CONTACTS, {[GET_MERGED_CONTACT_BY_ID]: GET_MERGED_CONTACT_BY_ID}),
        ...mapGetters(USERDATA, {[GET_UID]: GET_UID}),
        ...mapGetters(VM_CONF, [GET_VM_CONF_CONNECTION_PARAMS]),
        contact() {
            return this[GET_MERGED_CONTACT_BY_ID](this[GET_UID])
        },
        fio() {
            return this.contact.fio
        },
    },
    methods: {
        closeModal() {
            this.modalClose()
        },
        getStartTime(conf) {
            return this.getLocalTime(conf.startUTime)
        },
        getFinishTime(conf) {
           return this.getLocalTime(conf.finishUTime)
        },
        getLocalTime(dt) {
            return moment.unix(dt).local().format('YYYY.MM.DD HH:mm:ss')
        },
        startConf() {
            let conf = this.conf
            let vmLink = this.getConfLink(conf)
            this.modalOpen({ component: EnterConf, props: { vmLink } })
            this.modalClose()
        },
        getConfLink(conf) {
            let { server } = this[GET_VM_CONF_CONNECTION_PARAMS]
            return `https://${server}/service/join/?confid=${conf.confid}&confpass=${conf.password}` //@todo брать сервер из userdata
        },
        openEditConf(conf) {
            this.modalClose()
            let propConf = {...conf}
            this.modalOpen({
                component: EditConf,
                props: { conf: propConf }
            })
        },
        openInviteContextMenu(conf) {
            let handlers = []
            handlers.push({
                item_name: this.$t('videomost.conf-members-choose'),
                handler: () => {
                    this.modalOpen({
                        component: AddConfMembers,
                        props: {
                            msg: {dataType: 'text', data: this.makeInvitationMessage(conf)}
                        }
                    })
                }
            })
            handlers.push({
                item_name: this.$t('videomost.conf-members-sendinvitemail'),
                handler: () => { this.sendInvitationMail(conf) }
            })
            handlers.push({
                item_name: this.$t('videomost.conf-members-copyconflink'),
                handler: () => {
                    utils.copyTextToClipboard(null, this.getConfLink(conf))
                }
            })
            this.cmOpen(event, handlers)
        },
        sendInvitationMail(conf) {
            const mailSubject = this.$t('videomost.conf-invitemail-subject')
            const mailBody = encodeURIComponent(this.makeInvitationMessage(conf))
            let mailRef = `mailto:?subject=${mailSubject}&body=${mailBody}`
            window.location.href = mailRef
        },
        sendMessage(contact, message) {
            if (!message) return
            let msg = {
                cid: contact.cid,
                cidType: contact.cidType,
                dataType: declarations.msgDataTypes.MSG_DATA_TYPE_TEXT,
                data: message
            }
            this[ACT_SEND_MESSAGE](msg)
        },
        makeInvitationMessage(conf) {
            let vmLink = this.getConfLink(conf)
            let startConfTime =  moment.unix(conf.startUTime).local().format('DD.MM.YY HH:mm')
            return `${this.fio} ${this.$t('videomost.conf-invitemail-bodyfirstrow')}

${this.$t('videomost.conf-invitemail-bodyconfsubj')}: ${conf.topic}
${this.$t('videomost.conf-invitemail-bodyconftime')}: ${startConfTime}

${this.$t('videomost.conf-invitemail-bodyconflink')}
${vmLink}

${this.$t('videomost.conf-invitemail-bodyconfid')}: ${conf.confid}
${this.$t('videomost.conf-password')}: ${conf.password}`
        },
        deleteConfConfirm(confID) {

            let close = async () => {
                await this.deleteConf(confID)
                this.$bus.$emit('reload-conf-lists')
                this.modalClose()
            }

            this.modalOpen({
                name: 'confirm',
                props: {
                    text: this.$t('videomost.conf-delete-confirm'),
                    btnOk: {
                        cb: () => close()
                    }
                }
            })

        },
        async deleteConf(confid) {
            let result = await this[ACT_VM_CONF_DELETE]({confid})
        },
        ...mapActions(VM_CONF, [ACT_VM_CONF_SET_PARAMS, ACT_VM_CONF_DELETE]),
        ...mapActions(CHATS, [ACT_SEND_MESSAGE])
    },
}
