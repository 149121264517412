//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {USERDATA, CONTACTS, VM_CONF} from "../../store/modulesNames"
import {GET_MERGED_CONTACT_BY_ID, GET_UID} from "../../store/gettersTypes"
import {mapActions, mapGetters} from 'vuex'
import modal_mixin from '../modal/modal-mixin'
import CustomInputText from '../custom/CustomInputText.vue'
import DateRangePicker from '../settings/DateRangePicker.vue'
import CustomRadio from '../custom/CustomRadio.vue'
import ListItem from '../custom/ListItem.vue'
import ModalBtn from '../modal/ModalBtn.vue'
//import ConfSettings from './scheduleConferenceSettings.vue'
import CustomCheckbox from "../custom/CustomCheckboxRound.vue"
import event_bus from '../../eventBus'
import {ACT_VM_CONF_CREATE} from "../../store/actionsTypes"

export default {
    name: "NewVMConference",
    components: { CustomInputText, DateRangePicker, CustomRadio, CustomCheckbox, ListItem, ModalBtn, /*ConfSettings*/ },
    mixins: [modal_mixin],
    data() {
        return {
            subjectName: '',
            subjectMissed: false,
            participantName: '',
            confModes: [{
                    value: true,
                    label: this.$t('videomost.conf-discussion')
                },
                {
                    value: false,
                    label: this.$t('videomost.conf-presentation')
                }
            ],
            newCreatedConf: {},
            confMode: {},
            confID: '',
            confPassword: '',
            compModalProps: {
                delay: 150,
                pivotY: 0.4,
                width: 356,
                height: 317,
                clickToClose: false
            },
            runConferenceText: this.$t('videomost.conf-run'),
            cancelText: this.$t('videomost.cancel'),
            confSettingsText: this.$t('videomost.conf-settings'),
            isNoCamera: false,
            isNoMicrophone: false,
        }
    },
    computed: {
        ...mapGetters(CONTACTS, {[GET_MERGED_CONTACT_BY_ID]: GET_MERGED_CONTACT_BY_ID}),
        ...mapGetters(USERDATA, {[GET_UID]: GET_UID}),
        contact() {
            return this[GET_MERGED_CONTACT_BY_ID](this[GET_UID])
        },
        fio() {
            return this.contact.fio
        },
    },
    methods: {
        cancelCreateNewConf() {
            this.modalClose()
        },
        async createNewConf() {
            if (!this.subjectName) this.subjectMissed = true
            if (this.subjectMissed) return

            const newConfSubject = this.subjectName
            const participantName = this.contact.fio
            let newConfParams = {topic: newConfSubject, username: participantName}
            this.newCreatedConf = await this[ACT_VM_CONF_CREATE](newConfParams)
            this.modalClose()
            event_bus.$emit('reload-conf-lists')
        },
        ...mapActions(VM_CONF, [ACT_VM_CONF_CREATE])
    },
}
