//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex';
import {AJAX, CHATS} from '../../store/modulesNames'
import {IS_CHAT_ADMIN} from "../../store/gettersTypes";
import {ACT_ADD_CHAT, ACT_CHANGE_CHAT, ACT_AJAX_SEND_FILE} from '../../store/actionsTypes'
import IconImage from '../IconImage.vue';
import modal_mixin from './modal-mixin';
import CustomRadio from "../custom/CustomRadio.vue"
import { i18n } from '../../../ext/i18n';

const locale = i18n.messages[i18n.locale]

export default {
    name: 'ChatEdit',
    components: {IconImage, CustomRadio},
    mixins: [modal_mixin],
    data() {
        return {
            compModalProps: {
                delay: 200,
                pivotY: 0.05,
                width: 500,
                height: 'auto',
                clickToClose: false,
            },
            icon: '',
            oldIcon: '',
            image: '',
            oldImage: '',
            name: this.props.chat && this.props.chat.name || '',
            imageText: '',
            warning: false,
        }
    },
    computed: {
        namePlaceholder() { return this.warning ? locale.modal['enter-channel-name'] : locale['chat-name']},
        newChat() { return !this.props.chat },
        admin() { return this[IS_CHAT_ADMIN](this.props.chat) },
        globalIcon() { return this.oldIcon.indexOf('_') === -1 },
        nameChanged() { return this.name !== this.oldName },
        iconChanged() { return this.icon !== this.oldIcon },
        iconRemove() { return Boolean(this.icon && !this.iconChanged && (this.admin || !this.globalIcon)) },
        iconRollback() { return !this.iconRemove && this.iconChanged },
        title() { return this.newChat ? locale['new-chat'] : locale['chat-editing'] },
        okText() { return this.newChannel ? locale.modal.create : locale.save },
        ...mapGetters(CHATS, [IS_CHAT_ADMIN])
    },
    methods: {
        checkInput () {
            this.warning = false;
        },
        doLoadImage(e) {
            const cb = (status, file_name) => {
                const fr = new FileReader()

                fr.addEventListener('load', (e) => {
                    this.image = e.target.result
                    this.icon = file_name
                })

                fr.readAsDataURL(e.target.files[0])
                console.log(status, file_name)
            }

            app.store.dispatch(`${AJAX}/${ACT_AJAX_SEND_FILE}`, {
                url: declarations.http_post_dirs.CHATS_ICONS,
                file: e.target.files[0],
                cb(err, file_name) {
                    cb && cb(err, file_name)
                }
            })
        },
        doFileSelect() {
            let input = this.$refs.fileLoader;
            input.value = '';
            input.click();
        },
        onOkClicked() {
            if(!this.name) return this.warning = true;
            const data = { }
            if (this.nameChanged) data.name = this.name
            if (this.iconChanged) data.icon = this.icon
            if (this.newChat) data.contacts = this.props.contacts.map((cur) => ({ cid: cur.cid, privilege: 'user' }))
            else  {
                //if (this.icon !== '') data.icon = this.icon
                data.cid = this.props.chat.cid
                data.cidType = 'group'
            }
            let { cid, cidType, ...props } = data
            if (Object.keys(props).length) this[this.newChat ? ACT_ADD_CHAT : ACT_CHANGE_CHAT](data)
            this.modalClose();
        },
        doRemoveImage() {
            this.icon = this.image = ''
        },
        doRollbackImage() {
            this.image = this.oldImage
            this.icon = this.oldIcon
        },
        ...mapActions(CHATS, [ACT_ADD_CHAT, ACT_CHANGE_CHAT])
    },
    created () {
        let icon = this.props.chat && this.props.chat.icon
        let image = this.props.chat && this.props.chat.photo
        let name = this.props.chat && this.props.chat.name
        if (icon) this.icon = this.oldIcon = icon
        if (image) this.image = this.oldImage = image
        if (name) this.name = this.oldName = name
    }
}
