var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.$t("integrations.meeting-scheduler"),
      },
    },
    [
      _c("template", { slot: "default" }, [
        _c(
          "div",
          { staticClass: "scheduler" },
          [
            _c(
              "div",
              { staticClass: "scheduler-item custom-input" },
              [
                _c("custom-input", {
                  ref: "eventSubject",
                  attrs: {
                    input_text: _vm.eventName,
                    default_text: _vm.eventName,
                    label: _vm.$t("integrations.event-name"),
                  },
                  on: {
                    "update:input_text": function ($event) {
                      _vm.eventName = $event
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "scheduler-item custom-input" },
              [
                _c("custom-input", {
                  ref: "eventLocation",
                  attrs: {
                    input_text: _vm.eventLocation,
                    default_text: _vm.eventLocation,
                    label: _vm.$t("integrations.location"),
                  },
                  on: {
                    "update:input_text": function ($event) {
                      _vm.eventLocation = $event
                    },
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "scheduler-item people" }, [
              _c("div", { staticClass: "people-label" }, [
                _c("span", [_vm._v("E-mails")]),
                _vm.attendees.length
                  ? _c("div", { staticClass: "lbl-chosen-people" }, [
                      _vm._v(
                        "(" +
                          _vm._s(_vm.attendees.length) +
                          " " +
                          _vm._s(_vm.attendeesStr) +
                          ")"
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "people-choose" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mailsStr,
                      expression: "mailsStr",
                    },
                  ],
                  staticClass: "ctipeople",
                  attrs: { label: "" },
                  domProps: { value: _vm.mailsStr },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.mailsStr = $event.target.value
                    },
                  },
                }),
                _c("i", {
                  staticClass: "fa fa-user-plus lblBtn",
                  on: { click: _vm.selectContacts },
                }),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "scheduler-item drpicker" },
              [
                _c("date-range-picker", {
                  attrs: {
                    from: _vm.from,
                    default_from: _vm.defaultFrom,
                    to: _vm.to,
                    default_to: _vm.defaultTo,
                    minute_step: 5,
                    clearable: false,
                    popUpStyleFrom: { left: 0, top: "36px" },
                    popUpStyleTo: { left: "-50px", top: "36px" },
                  },
                  on: {
                    "update:from": function ($event) {
                      _vm.from = $event
                    },
                    "update:to": function ($event) {
                      _vm.to = $event
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "scheduler-item checkBoxes" },
              [
                _c("custom-checkbox", {
                  staticClass: "checkBoxItem",
                  attrs: {
                    checkbox: _vm.isAllDay,
                    default: _vm.isAllDay,
                    label: _vm.$t("integrations.all-day"),
                  },
                  on: {
                    "update:checkbox": function ($event) {
                      _vm.isAllDay = $event
                    },
                  },
                }),
                _c("custom-checkbox", {
                  staticClass: "checkBoxItem right",
                  attrs: {
                    checkbox: _vm.isPrivate,
                    default: _vm.isPrivate,
                    label: _vm.$t("integrations.private"),
                  },
                  on: {
                    "update:checkbox": function ($event) {
                      _vm.isPrivate = $event
                    },
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "scheduler-item repeat-n-reminder" }, [
              _c(
                "div",
                { staticClass: "repeat" },
                [
                  _c("div", { staticClass: "repeat-label" }, [
                    _vm._v(_vm._s(_vm.$t("integrations.repeat"))),
                  ]),
                  _c("custom-drop-down", {
                    key: "1",
                    staticClass: "ddrepeat",
                    attrs: {
                      options: _vm.repeatList,
                      default: _vm.selectedRepeat,
                      firstOption: "true",
                      styleItems: { item_max_width: 270 + "px" },
                    },
                    on: { "dd-selected-value": _vm.setRepeatValue },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "reminder" },
                [
                  _c("div", { staticClass: "reminder-label" }, [
                    _vm._v(_vm._s(_vm.$t("integrations.reminder"))),
                  ]),
                  _c("custom-drop-down", {
                    key: "2",
                    staticClass: "ddreminder",
                    attrs: {
                      options: _vm.reminderList,
                      default: _vm.selectedReminder,
                      firstOption: "true",
                    },
                    on: { "dd-selected-value": _vm.setReminderValue },
                  }),
                ],
                1
              ),
            ]),
            _vm.showRepeatOptions || _vm.showRepeatOptionsExt
              ? _c("div", { staticClass: "scheduler-item repeat-intervals" }, [
                  _c("div", { staticClass: "label-repeat-intervals" }, [
                    _vm._v(_vm._s(_vm.$t("integrations.repeat-intervals"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "repeat-calendar" },
                    [
                      _c("date-range-picker", {
                        attrs: {
                          from: _vm.repeatFrom,
                          default_from: _vm.defaultRepeatFrom,
                          custom_type: "date",
                          date_format: "DD.MM.YYYY",
                          to: _vm.repeatTo,
                          default_to: _vm.defaultRepeatTo,
                          clearable: false,
                          popUpStyleFrom: { left: 0, top: "-6px" },
                          popUpStyleTo: { left: "-50px", top: "-6px" },
                        },
                        on: {
                          "update:from": function ($event) {
                            _vm.repeatFrom = $event
                          },
                          "update:to": function ($event) {
                            _vm.repeatTo = $event
                          },
                        },
                      }),
                      _vm.showRepeatOptions
                        ? _c("div", { staticClass: "repeat-options" }, [
                            _c("div", { staticClass: "interval-value-label" }, [
                              _vm._v(
                                _vm._s(_vm.$t("integrations.interval")) +
                                  "                                "
                              ),
                            ]),
                            _vm.showRepeatOptions
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.repeatIntervalVal,
                                      expression: "repeatIntervalVal",
                                    },
                                  ],
                                  staticClass: "interval-input",
                                  domProps: { value: _vm.repeatIntervalVal },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.repeatIntervalVal =
                                        $event.target.value
                                    },
                                  },
                                })
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.showRepeatOptionsExt
              ? _c("repeat-options", {
                  staticClass: "repeat-options-ext",
                  attrs: { recurrence: _vm.recurrence },
                  on: { changerecurrence: _vm.onRecurrenceChangeOther },
                })
              : _vm._e(),
            _c("div", { staticClass: "scheduler-item" }, [
              _c("div", { staticClass: "note" }, [
                _vm._v(_vm._s(_vm.$t("integrations.note"))),
              ]),
            ]),
            _c("div", { staticClass: "scheduler-item" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.eventNote,
                    expression: "eventNote",
                  },
                ],
                attrs: { rows: "9", cols: "50" },
                domProps: { value: _vm.eventNote },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.eventNote = $event.target.value
                  },
                },
              }),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("modal-btn", {
            attrs: { text: _vm.$t("cancel") },
            on: {
              click: function ($event) {
                return _vm.modalClose()
              },
            },
          }),
          _c("modal-btn", {
            attrs: {
              text:
                _vm.type === "edit"
                  ? _vm.$t("save")
                  : _vm.$t("integrations.create"),
            },
            on: {
              click: function ($event) {
                return _vm.saveEvent()
              },
            },
          }),
          _vm.type === "edit"
            ? _c("modal-btn", {
                attrs: { text: _vm.$t("chat.delete") },
                on: {
                  click: function ($event) {
                    return _vm.deleteEvent()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }