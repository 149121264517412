'use strict';
import Vue from 'vue';
import proto from '../../protocol';
import ipc from '../../../electron/ipc';
import { ACT_ADD_NOTIFICATION } from '../actionsTypes';
import {NOTIFICATIONS} from "../modulesNames";
import { i18n } from '../../../ext/i18n'
import event_bus from '../../eventBus';
import moment from 'moment';

export default  {
    namespaced: true,
    state: {
        calls: null,
        unwatchedCalls: null,
        watchedCallsStatus: false,
        timerChangeUnwatchedCalls: null
    },
    getters: {
        getCalls(state) {
            return state.calls && state.calls.map(call => {
                return {
                    ...call,
                    ...{
                        date: formatTime(call.time),
                        dateDetails: formatTimeDetails(call.time)
                    }
                }
            });
        },
        getUnwatchedCallsCount(state) {
            if(state.unwatchedCalls) return state.unwatchedCalls.length;
        },
        getUnwatchedCalls(state) {
            return state.unwatchedCalls;
        },
        getWatchedCallsStatus(state) {
            return state.watchedCallsStatus;
        }
    },
    actions: {
        updateCalls:({commit, rootGetters}) => new Promise((resolve, reject) =>  {
            proto.getHistoryCalls({ count: 100 }, function(items) {
                let arr = [];
                let unwatched = [];
                items.forEach(function(item) {
                    arr.push(addInfoOnCall(item, rootGetters))                    
                    if(item.type !== "out" && !item.duration && !item.status) unwatched.push(item)
                });
                commit('updateCalls', arr);
                commit('updateUnwatchedCalls', unwatched);
                resolve();
            });
        }),
        updateStatus({dispatch}, params) {
            proto.setHistoryCallStatus(params, data => {
                if(data && data.rev) dispatch('updateCalls')
            })
        },
        updateCallsEvent({dispatch, commit, rootGetters}, params) {
            params = addInfoOnCall(params, rootGetters)
            params.date = formatTime(params.time);            
            commit('addCall', params);
            dispatch('updateCalls');
        },
        updateCallContactInfo({commit, rootGetters}, {cid, contact}) {
            if (cid && !contact) contact = rootGetters['contacts/getMergedContactById'](cid)
            const params = { cid, contact }
            if (params) commit('updateCall', params)
        },
        updateCallsAfterContactAdded({commit}, params) {
            commit('updateCallsAfterAdded', params)
        },
        updateCallsAfterContactDeleted({commit}, params) {
            commit('updateCallsAfterDeleted', params)
        },        
    },
    mutations: {
        addCall: (state, call) => {
            state.calls.unshift(call);    
        },
        updateCalls: function (state, calls) {
            Vue.set(state, 'calls', calls);
        },
        updateUnwatchedCalls: function(state, params) {
            if(Array.isArray(state.unwatchedCalls) && state.unwatchedCalls.length !== params.length) {
                app.store.dispatch(`${NOTIFICATIONS}/${ACT_ADD_NOTIFICATION}`, {type: 'call'});
            }
            Vue.set(state, 'unwatchedCalls', params);
        },
        updateCallChangeEvent: function(state, params) {
            console.log('updateCallChangeEvent');
            for(let i = 0; i < state.calls.length; i++) {
                if(state.calls[i].id === params.id) {
                    if(params.status === 'deleted') state.calls.splice(i, 1);
                    else Vue.set(state.calls[i], 'status', params.status);
                }
            }
            let changeUnwatchedCalls = false;
            for(let i = 0; i < state.unwatchedCalls.length; i++) {
                if(state.unwatchedCalls[i].id === params.id) {
                    state.unwatchedCalls.splice(i, 1);
                    changeUnwatchedCalls = true;
                }
            }
            if(changeUnwatchedCalls) app.store.dispatch(`${NOTIFICATIONS}/${ACT_ADD_NOTIFICATION}`, {type: 'update'});
        },
        updateWatchedCallsStatus: function(state, status) {
            state.watchedCallsStatus = status;
        },
        updateCall: (state, params) => {
            const { cid, contact } = params
            for(let i = 0; i < state.calls.length; i++) {
                if(state.calls[i].cid === cid) {
                    state.calls[i].fio = contact.fio
                }
            }
        },
        updateCallsAfterAdded: (state, params) => {
            const { contact } = params
            const cid = contact.cid
            const fio = contact.fio
            const { fields } = contact
            const phoneField = fields && fields.find(f => f.type === 'phone')
            const phoneNumber = phoneField && phoneField.value
            for(let i = 0; i < state.calls.length; i++) {
                if (!phoneNumber) break
                if(state.calls[i].number === phoneNumber) {
                    state.calls[i].cid = cid
                    state.calls[i].fio = fio
                }
            }
        },
        updateCallsAfterDeleted: (state, params) => {
            const { cid } = params
            for(let i = 0; i < state.calls.length; i++) {
                if(state.calls[i].cid === cid) {
                    state.calls[i].cid = undefined
                    state.calls[i].fio = state.calls[i].number
                }
            }
        },
    }
};

function addInfoOnCall(call, rootGetters) {
    if(call.cid) {
        let user = rootGetters['contacts/getMergedContactById'](call.cid);
        call.fio = user.fio;
        call.photo = user.photo;
    } else {
        let user = rootGetters['contacts/getMergedContactByPhone'](call.number);
        if(user) {
            call.fio = user.fio;
            call.photo = user.photo;
        } else {
            call.fio = call.number;
            call.class = 'out_number';
            call.photo = i18n.messages[i18n.locale].unknown;
        }
    }
    if(!call.class) call.class = 'number';
    return call
}

let locale = i18n.messages[i18n.locale]
let localeFormatTime = locale['channel-comp']

function formatTime(timeCall) {
    let locale = localeFormatTime
    let result;
    let timeCallFormat = moment().subtract(timeCall, 'seconds');
    let today = moment().startOf('day');
    let yesterday = moment().subtract(1, 'days').startOf('day');
    let twoDays = moment().subtract(2, 'days').startOf('day');
    if(timeCallFormat.isAfter(today)) {
        result = moment().subtract(timeCall, 'seconds').format('HH:mm');
    } else if(timeCallFormat.isBefore(today) && timeCallFormat.isAfter(yesterday)) {
        result = locale.yesterday;
    } else if(timeCallFormat.isBefore(yesterday) && timeCallFormat.isAfter(twoDays)) {
        result = locale['before-yest'];
    } else {
        result = moment().subtract(timeCall, 'seconds').format('DD MMM YYYY');
    }
    return result;
}
function formatTimeDetails(timeCall) {
    let result = {
        data: null,
        time: null
    };
    let timeCallFormat = moment().subtract(timeCall, 'seconds');
    let today = moment().startOf('day');
    let yesterday = moment().subtract(1, 'days').startOf('day');
    let twoDays = moment().subtract(2, 'days').startOf('day');
    if(timeCallFormat.isAfter(today)) {
        result.data = locale.settings.today;
        result.time = moment().subtract(timeCall, 'seconds').format('HH:mm');
    } else if(timeCallFormat.isBefore(today) && timeCallFormat.isAfter(yesterday)) {
        result.data = locale['channel-comp'].yesterday;
        result.time = moment().subtract(timeCall, 'seconds').format('HH:mm');
    } else if(timeCallFormat.isBefore(yesterday) && timeCallFormat.isAfter(twoDays)) {
        result.data = locale['channel-comp']['before-yest'];
        result.time = moment().subtract(timeCall, 'seconds').format('HH:mm');
    } else {
        result.data = moment().subtract(timeCall, 'seconds').format('DD MMM YYYY');
        result.time = moment().subtract(timeCall, 'seconds').format('HH:mm');
    }
    return result;
}
