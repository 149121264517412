//
//
//
//
//
//

'use strict';
export default {
    name: 'custom-input-text',
    props:  {
        default_text: {
            type: [String, Number],
            required: false,
            default: ''
        },
        label: {
            type: String,
            required: false,
            default: ''
        },
        warning: {
            type: Boolean,
            required: false,
            default: false
        },
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        tabindex: {
            type: [String, Number],
            required: false
        },
        autocomplete: {
            type: String,
            required: false,
            default: /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor) ? 'none' : 'off'
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        blurOnApply: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            input_text: '' 
        };
    },
    computed: {
        lbl() {
            return this.warning ? '* ' + this.label : this.label
        }
    },
    mounted() {
        if (this.default_text) this.input_text = this.default_text
    },
    methods: {
        reset() {
            this.input_text = '';
        },
        getValue() {
            return this.input_text
        },
        applyChanges() {
            this.$emit('apply', this.getValue())

            const input = this.$el.querySelector('input')

            if (this.blurOnApply && input === document.activeElement) input.blur()
        }
    },
    watch: {
        input_text() {
            this.$emit('update:input_text', this.getValue());
        }
    }
}
