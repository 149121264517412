export default (text = '') => {
    if (typeof text !== 'string') return
    const RE_VM_CONF_LINK = /\S*?\/service\/join\/\?confid=\S*/g
    const checkVmConfLink = text.match(RE_VM_CONF_LINK)
    let vmLink = ''
    if (checkVmConfLink) vmLink = checkVmConfLink[0]
    const vm_conf_subst = '<a draggable="false" href="#" oncontextmenu="window.openVmConfContext(event,\''+ vmLink + '\')" onclick="window.openVmConfByLink(\''+ vmLink + '\')">$&</a>'
    return `<span>${text.replace(reBotCommands, substBotCommands).replace(reLinks, substLinks).replace(reTextUrl, substTextUrls).replace(reEmail, substEmail).replace(rePhoneNumber, substPhoneNumber).replace(RE_VM_CONF_LINK, vm_conf_subst)}</span>`
}

window.doEmail = email => window.location.href = `mailto:  ${email}`

// #if process.env.WEBPACK_BUILD_TARGET === 'web'
window.openLink = url => {
    const isProto = url.search(/^(?:https?:\/\/|ftps?:\/\/)/i)
    if (isProto === -1) url = `https://${url}`
    window.open(url, '_blank')
}
// #endif

const reEmail = /(^|[^\S\/])[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*/g
const substEmail = (a,b) => {
   return [b, `<a draggable="false" href="mailto: ${a}" oncontextmenu="window.openEmailContext(event,'${a}')">${a}</a>`].join('')
}

const rePhoneNumber = /(^|[^\S\/])[\+]?[\d][ -\.]?[(]?(\d{3})?[)]?[ -\.]?\d{2,3}[ -\.]?((\d{4})|(\d{2}[ -\.]?\d{2}))(?!\S)/g
const substPhoneNumber = (a,b) => {
    return [b, `<a draggable="false" href="#" oncontextmenu="window.openPhoneNumberContext(event,'${a}')" onclick="window.openPhoneNumberByLink(event, '${a}')">${a}</a>`].join('')
}

const reBotCommands = /(^|[^\S\/])(?<command>\/(?!\w+\/)(?:\w+))/g
const substBotCommands = (a,b,c) => [b, `<a draggable="false" href="#" onclick="window.openBotCommandLink(event,'${c}')">${c}</a>`].join('')

const reLinks = /(?:((http(s)?)|(ftp(s)?)):\/\/)?(www\.)?(?!\S*\/service\/join\/\?confid=\S*)(?:[-\w:%._\+~#=]{2,256}\.(([a-z]{2,4})|((\d\d\.)|(\d\d\d\.)){2})\b([-\w@:%_\+.~#?&\/=]*))/g
const substLinks = (m, p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, index, text) => {
    let res = m
    if ((index && text[index-1] !=='@' && text[index-1] !=='[') || (!index && text[0] !== '@' && text[0] !=='['))
        res = `<a draggable="false" href="#" target="_blank" oncontextmenu="window.openLinkContext(event,'${m}')" onclick="window.openLink('${m}')">${m}</a>`
    return res
}

const reTextUrl = /@\[(?<title>[^@]+?)\]\[url\:(?<url>[^\s\]]+)\]/g
const substTextUrls = (m, a, b) => `<a draggable="false" href="#" target="_blank" oncontextmenu="window.openLinkContext(event,'${b}')" onclick="window.openLink('${b}')">${a}</a>`


window.openEmailContext = (e, email) => {
    e.stopPropagation()
    e.preventDefault()
    const event = new CustomEvent('emailcontextmenu', { 'bubbles': true, detail: { event: e, email } })
    e.target.dispatchEvent(event)
}

window.openVmConfContext = (e, vmLink) => {
    e.stopPropagation()
    e.preventDefault()
    const event = new CustomEvent('vmcontextmenu', { 'bubbles': true, detail: { event: e, vmLink } })
    e.target.dispatchEvent(event)
}

window.openVmConfByLink = (vmLink) => {
    const infoEl = document.getElementById('info')
    const vueInstance = infoEl.__vue__
    vueInstance.$bus.$emit('enter-conference', vmLink)
}

window.openBotCommandLink = (e, command) => {
    e.stopPropagation()
    e.preventDefault()
    const event = new CustomEvent('botcommand', { 'bubbles': true, detail: { event: e, command } })
    e.target.dispatchEvent(event)
}

window.openLinkContext = (e, link) => {
    e.stopPropagation()
    e.preventDefault()
    const event = new CustomEvent('linkcontextmenu', { 'bubbles': true, detail: { event: e, link } })
    e.target.dispatchEvent(event)
}

window.openPhoneNumberContext = (e, number) => {
    e.stopPropagation()
    e.preventDefault()
    const event = new CustomEvent('phonenumbercontextmenu', { 'bubbles': true, detail: { event: e, number } })
    e.target.dispatchEvent(event)
}

window.openPhoneNumberByLink = (e, number) => {
    e.stopPropagation()
    e.preventDefault()
    const event = new CustomEvent('phonenumberlink', { 'bubbles': true, detail: { event: e, number } })
    e.target.dispatchEvent(event)
}