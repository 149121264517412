//
//
//
//
//
//
//
//
//
//
//

"use strict"

import ChatAudioMessage from "../chat/ChatAudioMessage.vue"
import PublicationAudioItemSearch from "../channels/PublicationAudioItemSearch.vue"

export default {
    name: "media-search-audio",
    props: { 
        messages: {
            type: Object,
            default: []
        }, 
        isChannel: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ChatAudioMessage,
        PublicationAudioItemSearch
    },
    // watch: {
    //     messages(val) {
    //       console.log("!! -> file: MediaSearchAudio.vue -> line 36 -> messages -> val", val)
    //     },
    // },
    methods: {
        prepareMessages(messages) {
            let res = []
            messages.forEach(m => {
                const { msg, ...rest } = m
                if (msg.constructor === Array) {
                    msg.forEach(msg => {
                        res.push({ msg, ...rest})
                    })
                }
            })
            return res.length ? res : messages
        },
        onContextMenu(e, message) {
            this.$emit("mediasearch-common-contextmenu", { e, message })
        }
    }
}
