//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import { MODAL } from "../../store/modulesNames"
import CustomSearch from "../custom/CustomSearch.vue"
import FilterTypeList from "./FilterTypeList.vue"
import modal_mixin from "./modal-mixin"
import { ACT_MODAL_CLOSE } from "../../store/actionsTypes"


export default {
    name: "FilterContactType",
    components: { CustomSearch, FilterTypeList },
    mixins: [modal_mixin],
    props: {
        filter: {
            type: Object,
            required: false,
            default: () => ({})
        },
        itemType: {
            type: String
        },
        calbackItem: {
            type: Function
        },
        calbackNewData: {
            type: Function
        }
    },
    data: () => {
        return {
            compModalProps: {
                delay: 350,
                pivotY: 0.05,
                width: 400,
                height: 'auto',
                clickToClose: false
            },
            selected: [],
            search: '',
            settingsFilter: {},
            mapSettings: {
                organization: [],
                office: [],
                post: []
            },
            newDataSettings: {
                organization: [],
                office: [],
                post: []
            },
        }
    },
    computed: {
        title () {
            if (this.itemType === 'organization') return this.$t('information.organizations')
            if (this.itemType  === 'office') return this.$t('information.departments')
            if (this.itemType  === 'post') return this.$t('information.positions')
        },
        searchList () {
            return this.filter[this.itemType]
        }
    },
    methods: {
        onContactSelected(contact) {
            let type = this.itemType
            if (this.filter[type].includes(contact)) {
                this.filter[type].splice(this.filter[type].indexOf(contact), 1)
                this.newDataSettings[type].splice(this.newDataSettings[type].indexOf(contact), 1)
                return
            }
            this.filter[type].push(contact)
            this.newDataSettings[type].push(contact)
        },
        onOk() {
            this.calbackItem(this.filter)
            this.calbackNewData(this.newDataSettings)
            this[ACT_MODAL_CLOSE]({name: "FilterContactType"})
        },
        onCancel() {
            this[ACT_MODAL_CLOSE]({name: "FilterContactType"})
        },
        ...mapActions(MODAL, [ACT_MODAL_CLOSE])
    }
}

