//
//
//
//
//
//
//
//

import IconImage from "../IconImage.vue"

export default {
    name: "SelectedItemsLine",
    components: { IconImage },
    props: {
        selected: {
            type: Array,
            required: true,
        },
    }
}
