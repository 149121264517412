import { i18n } from '../../../ext/i18n'
let locale = i18n.messages[i18n.locale]
import moment from 'moment'

import {
    GET_CONF,
    GET_CONF_MIC_STATUS,
    GET_CONF_CAM_STATUS,
    GET_CONF_INFO,
    GET_CONF_SPEAKER_STATUS,
    GET_CONF_OWN_MEMBER,
    GET_CONF_MEMBERS,
    GET_USER_PARAMS,
    GET_VM_CONF_CONNECTION_PARAMS,
    GENERATE_CONF_PROPS,
    GET_UID,
    GET_ACTIVE_MEDIA_DEVICES,
    GET_CONF_INVITE_TEXT,
    GET_MERGED_CONTACT_BY_ID,
    GET_IS_ELECTRON,
    GET_VM_SERVER_MANAGEMENT_HOST,
} from '../gettersTypes'

import {
    ACT_VM_CONF_UPDATE_DEVICES,
    ACT_CONF_JOIN,
    ACT_CONF_JOIN_BY_LINK,
    ACT_CONF_FINISH,
    ACT_CONF_LEAVE,
    ACT_CONF_CAMERA_TOGGLE,
    ACT_CONF_SPEAKER_TOGGLE,
    ACT_CONF_SET_MEMBER_MODERATOR,
    ACT_CONF_SET_MEMBER_MIC,
    ACT_CONF_SET_MEMBER_VIDEO,
    ACT_CONF_BAN_MEMBER,
    ACT_CONF_KICK_OUT_MEMBER,
    ACT_CONF_MICROPHONE_TOGGLE,
    ACT_VM_CONF_FINISH,
    ACT_CONF_SHARE,
    ACT_CONF_WANT_SPEAK,
    ACT_VM_CONF_SEARCH_CONFERENCE,
    ACT_VM_CONF_DELETE,
    ACT_VM_CONF_SET_PARAMS,
    ACT_VM_CONF_CREATE,
    ACT_CONF_SEND_CID,
    ACT_CONF_ANSWER_CID,
    ACT_CONF_HAND_UP,
    ACT_CONF_REACTION,
    ACT_VM_CONF_HANDLE_MEMBER_DATA,
    ACT_VM_GET_CONFERENCE_INFO,
    ACT_CONF_SET_DEVICE,
    ACT_CONF_SEND_INVITE,
    ACT_MODAL_OPEN,
    ACT_CONF_SET_DEVICES,
} from '../actionsTypes'

import {
    MUT_CONF_ADD,
    MUT_UPDATE_CONF,
    MUT_CONF_ADD_MEMBER,
    MUT_CONF_REMOVE_MEMBER,
    MUT_CONF_UPDATE_MEMBER,
    MUT_SET_CONF_SHARE,
    MUT_CONF_REMOVE,
} from '../mutationsTypes'

import module, {
    MEMBER_PROPS as MEMBER_PROPS_BASE,
    CAM_STATUS,
    CONF_PROPS,
    MIC_STATUS,
    INFO_PROPS,
    STATUS,
    END_REASONS,
} from './video-conf-abstract'

import VideomostClient, { CONF_SETTINGS_GLOBAL_PROPS } from "../../sdk/videomost/VideomostClient"

import VideoMostXml from "../../api/VideoMostXml"

const CORS_IGNORED_PORT = 8080

const xmlRpc = {
    _instance: null,
    getInstance({getters}) {
        if (!this._instance) {
            const params = getters[GET_VM_CONF_CONNECTION_PARAMS]
            const managementHost = getters[GET_VM_SERVER_MANAGEMENT_HOST](params.server)
            window.videoMostXml = this._instance = new VideoMostXml(managementHost, params.login, params.password)
        }
        return this._instance
    }
}

import {
    EVENTS,
    MEMBER_PROPS,
    MEMBER_ATTRS_PROPS,
    MEMBER_STATES_PROPS,
    CONF_SETTINGS_PROPS,
    CONF_SETTINGS_INFO_PROPS,
} from "../../sdk/videomost/VideomostClient"

import {CLIENTDATA, CONTACTS, MODAL, USERDATA} from "../modulesNames"
import event_bus from "../../eventBus"

const LINK_REG = /\w+:\/\/(?<server>[^\/]+)\/service\/join\/\?confid=(?<confId>[^\&]+)&confpass=(?<pass>[^($|&)]+)/gm

const clients = {}

const RCH_COMMANDS = {
    CONTACT: 'rch_contact',
    ANSWER_CONTACT: 'rch_answer_contact',
    HANDUP: 'rch_handup',
    REACTION: 'rch_reaction',
}

const state = {
    server: '',
    login: '',
    password: '',
    ice: [],
    confs: {},
    inited: false,
}

const getters = {
    [GENERATE_CONF_PROPS]: (state, getters) => ({confId, pass, server, link}) => {
        if (link) {
            let temp
            let groups = null
            while ((temp = LINK_REG.exec(link)) !== null) groups = temp.groups
            let { confId: _confId, server: _server, pass: _pass } = groups || {}
            server = _server
            confId = _confId
            pass = _pass
        } else {
            if (!server) server = (getters[GET_VM_CONF_CONNECTION_PARAMS]).server
        }
        return { id: `${confId}(conf.vm)@${server}`, confId, server, pass }
    },
    [GET_VM_CONF_CONNECTION_PARAMS]: (state, getters, rootState, rootGetters) => {
        let ud = rootGetters[`${USERDATA}/${GET_USER_PARAMS}`]

        let params = { }
        if (ud.videomostUrl)  {
            let url = new URL(ud.videomostUrl)
            params.server = url.host
        }
        let vcService = (ud.services || []).find(({type}) => type === 'videomost')
        if (vcService) {
            params.login = vcService.login
            params.password = vcService.password
        }
        return params
    },
    [GET_CONF_INVITE_TEXT]: (state, getters, rootState, rootGetters) => (id) => {
        let cid = rootGetters[`${USERDATA}/${GET_UID}`]
        let { fio } = rootGetters[`${CONTACTS}/${GET_MERGED_CONTACT_BY_ID}`](cid)
        let confInfo = getters[GET_CONF_INFO](id)
        if (!Object.keys(confInfo).length) return ''
        return `${fio} ${locale.videomost['conf-invitemail-bodyfirstrow']}

${locale.videomost['conf-invitemail-bodyconfsubj']}: ${confInfo[INFO_PROPS.TOPIC]}

${locale.videomost['conf-invitemail-bodyconflink']}
${confInfo[INFO_PROPS.LINK]}

${locale.videomost['conf-invitemail-bodyconfid']}: ${confInfo[INFO_PROPS.CONF_ID]}
${locale.videomost['conf-password']}: ${confInfo[INFO_PROPS.PASSWORD]}`
    },
    [GET_VM_SERVER_MANAGEMENT_HOST]: (state, getters, rootState, rootGetters) => (server) => {
        const isElectron = rootGetters[`${CLIENTDATA}/${GET_IS_ELECTRON}`]
        let port = isElectron ? `` : `:${CORS_IGNORED_PORT}`
        return `${server}${port}`
    },
}

const actions = {
    // ------------------ XML-RPC --------------------------
    async [ACT_VM_CONF_FINISH](obj, {confid}) {
        await xmlRpc.getInstance(obj).setConferenceParamsArray({confid, params: {finishTime: moment().utc().format('YYYY-MM-DD HH:mm:ss')} })
        await xmlRpc.getInstance(obj).stopConference({confid})
        event_bus.$emit('reload-conf-lists')
    },
    async [ACT_VM_CONF_CREATE](obj, payload) {
        return xmlRpc.getInstance(obj).createConferenceFull(payload)
    },
    async [ACT_VM_CONF_SEARCH_CONFERENCE](obj, payload) {
        return xmlRpc.getInstance(obj).searchConference(payload)
    },
    async [ACT_VM_GET_CONFERENCE_INFO](obj, {confid}) {
        return xmlRpc.getInstance(obj).getConferenceInfo({confid})
    },
    async [ACT_VM_CONF_SET_PARAMS](obj, payload) {
        return xmlRpc.getInstance(obj).setConferenceParamsArray(payload)
    },
    async [ACT_VM_CONF_DELETE](obj, payload) {
        try {
           await xmlRpc.getInstance(obj).stopConference(payload)
        } catch (e) {}
        return xmlRpc.getInstance(obj).deleteConference(payload)
    },
    // -----------------------------------------------------
    [ACT_VM_CONF_UPDATE_DEVICES](obj, {id}) {
        let client = getClient(id)
        if (client) client.updateDevices()
    },
    async [ACT_CONF_JOIN](obj, {username, confId, pass, server, ...payload }) {
        let { getters, commit, rootGetters } = obj
        let id = `${confId}(conf.vm)@${server}`
        let defDevices = rootGetters[`${USERDATA}/${GET_ACTIVE_MEDIA_DEVICES}`]
        let managementHost = getters[GET_VM_SERVER_MANAGEMENT_HOST](server)
        let client = new VideomostClient({ id, server, username, defDevices, managementHost })
        window.testClient = client
        let joinPayload = { confId, confPass: pass, ...payload }
        let connectionParams = getters[GET_VM_CONF_CONNECTION_PARAMS]
        if (server === connectionParams.server && connectionParams.login && connectionParams.password) {
            Object.assign(joinPayload, { login: connectionParams.login, password: connectionParams.password })
        }
        commit(MUT_CONF_ADD, { id })
        client.joinConference(joinPayload).catch(e => {
            commit(MUT_UPDATE_CONF, { id, props: { [CONF_PROPS.STATUS]: STATUS.ERROR, [CONF_PROPS.ERROR_TEXT]: e.message } })
            client.leaveConference()
        })
        clients[id] = client
        subscribeOnClient(obj, client)
        return id
    },
    async [ACT_CONF_JOIN_BY_LINK]({dispatch, commit}, {link, ...payload}) {
        let temp
        let groups = null
        while ((temp = LINK_REG.exec(link)) !== null) groups = temp.groups
        let { confId, pass, server } = groups || {}
        return dispatch(ACT_CONF_JOIN, { confId, pass, server, ...payload })
    },
    async [ACT_CONF_FINISH]({getters, commit, dispatch}, {id}) {
        let confInfo = getters[GET_CONF_INFO](id)
        let confid = confInfo[INFO_PROPS.CONF_ID]
        await dispatch(ACT_CONF_LEAVE, {id})
        if (confid) await dispatch(ACT_VM_CONF_FINISH, {confid})
    },
    async [ACT_CONF_LEAVE]({getters, commit}, {id}) {
        let client = getClient(id)
        if (client) {
            await client.leaveConference()
            commit(MUT_CONF_REMOVE, {id})
        }
    },
    [ACT_CONF_CAMERA_TOGGLE]({getters}, {id}) {
        let status = getters[GET_CONF_CAM_STATUS](id)
        let val
        if (status === CAM_STATUS.DISABLE) val = false
        else if (status === CAM_STATUS.ENABLE) val = true
        else return
        let client = getClient(id)
        if (client) client.disableConfCamera(val)
    },
    [ACT_CONF_MICROPHONE_TOGGLE]({getters}, {id}) {
        let status = getters[GET_CONF_MIC_STATUS](id)
        let val
        if (status === MIC_STATUS.DISABLE) val = false
        else if (status === MIC_STATUS.ENABLE) val = true
        else return
        let client = getClient(id)
        if (client) client.muteConfMicrophone(val)
    },
    [ACT_CONF_SPEAKER_TOGGLE]({getters, commit}, {id}) {
        let status = getters[GET_CONF_SPEAKER_STATUS](id)
        let newStatus = !status
        let client = getClient(id)
        if (client) {
            client.muteConfSpeaker(!newStatus)
            commit(MUT_UPDATE_CONF, {id, props: {[CONF_PROPS.SPEAKER_STATUS]: newStatus}})
        }
    },
    [ACT_CONF_WANT_SPEAK](obj, {id, value}) {
        let { getters } = obj
        let ownMember = getters[GET_CONF_OWN_MEMBER](id)
        if (ownMember) {
            changeMemberProps(obj, {id, memberId: ownMember[MEMBER_PROPS_BASE.ID], prop: MEMBER_PROPS_BASE.WANT_SPEAK, value})
        }
    },
    [ACT_CONF_SET_MEMBER_MODERATOR](obj, {id, memberId, value}) {
        changeMemberProps(obj, {id, memberId, prop: MEMBER_PROPS_BASE.MODERATOR, value})
    },
    [ACT_CONF_SET_MEMBER_MIC](obj, {id, memberId, value}) {
        changeMemberProps(obj, {id, memberId, prop: MEMBER_PROPS_BASE.MICROPHONE_GLOBAL, value})
    },
    [ACT_CONF_SET_MEMBER_VIDEO](obj, {id, memberId, value}) {
        changeMemberProps(obj, {id, memberId, prop: MEMBER_PROPS_BASE.CAMERA_GLOBAL, value})
    },
    [ACT_CONF_BAN_MEMBER](obj, {id, memberId, value}) {
        changeMemberProps(obj, {id, memberId, prop: MEMBER_PROPS_BASE.BANNED, value})
    },
    [ACT_CONF_KICK_OUT_MEMBER](obj, {id, memberId}) {
        changeMemberProps(obj, {id, memberId, prop: MEMBER_PROPS_BASE.BANNED, value: 2})
    },
    [ACT_CONF_SHARE](obj, {id}) {
        let client = getClient(id)
        if (client) client.shareToggle()
    },
    [ACT_CONF_SEND_CID](obj, { id }) {
        let { rootGetters } = obj
        let cid = rootGetters[`${USERDATA}/${GET_UID}`]
        sendData(obj, {id, name: RCH_COMMANDS.CONTACT, data: { cid }})
    },
    [ACT_CONF_ANSWER_CID](obj, { id, to }) {
        let { rootGetters } = obj
        let cid = rootGetters[`${USERDATA}/${GET_UID}`]
        sendData(obj, {id, name: RCH_COMMANDS.ANSWER_CONTACT, data: { cid }, to})
    },
    [ACT_CONF_HAND_UP](obj, { id, handup }) {
        sendData(obj, {id, name: RCH_COMMANDS.HANDUP, data: { handup }})
    },
    [ACT_CONF_REACTION](obj, { id, reaction }) {
        sendData(obj, {id, name: RCH_COMMANDS.REACTION, data: { reaction }})
    },
    [ACT_CONF_SET_DEVICE](obj, {id, kind, deviceId}) {
        let client = getClient(id)
        if (!client) return
        client.setDevice(kind, deviceId)
    },
    [ACT_CONF_SEND_INVITE]({getters, dispatch}, {id, inviteType = 'chat'}) {
        let inviteText = getters[GET_CONF_INVITE_TEXT](id)
        if (inviteType === 'chat') {
            if (inviteText) {
                dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, {
                    name: 'add-conf-members',
                    props: {
                        msg: {dataType: 'text', data: inviteText}
                    },
                }, {root: true})
            }
        } else if (inviteType === 'mail') {
            const mailSubject = locale.videomost['conf-invitemail-subject']
            const mailBody = encodeURIComponent(inviteText)
            let mailRef = `mailto:?subject=${mailSubject}&body=${mailBody}`
            window.location.href = mailRef
        }
    },
    [ACT_VM_CONF_HANDLE_MEMBER_DATA]({ commit, dispatch }, { id, from, name, data }) {
        let member = { [MEMBER_PROPS_BASE.ID]: from }
        switch (name) {
            case RCH_COMMANDS.CONTACT:
                dispatch(ACT_CONF_ANSWER_CID, { id, to: from })
            case RCH_COMMANDS.ANSWER_CONTACT:
                commit(MUT_CONF_UPDATE_MEMBER, { id, member: { ...member, [MEMBER_PROPS_BASE.CID]: data[MEMBER_PROPS_BASE.CID] }})
                break
            case RCH_COMMANDS.HANDUP:
                commit(MUT_CONF_UPDATE_MEMBER, { id, member: { ...member, [MEMBER_PROPS_BASE.HANDUP]: data[MEMBER_PROPS_BASE.HANDUP] }})
                break
            case RCH_COMMANDS.REACTION:
                commit(MUT_CONF_UPDATE_MEMBER, { id, member: { ...member, [MEMBER_PROPS_BASE.REACTION]: data[MEMBER_PROPS_BASE.REACTION] }})
                break
        }
    },
}

function subscribeOnClient({ commit, dispatch, getters }, client) {
    let id = client.getActiveConfId()
    client.on(EVENTS.CONF_JOINED, (id, confInfo) => {
        let info = prepareVmInfo(confInfo)
        commit(MUT_UPDATE_CONF, { id, props: { [CONF_PROPS.INFO]: info } })
    })
    client.on(EVENTS.CONF_TERMINATED, (reason) => {
        if (!(reason in END_REASONS)) reason = END_REASONS.UNKNOWN
        commit(MUT_UPDATE_CONF, { id, props: { [CONF_PROPS.END_REASON]: reason, [CONF_PROPS.STATUS]: STATUS.ENDED } })
    })
    client.on(EVENTS.REBUILD_LAYOUT, () => {
        commit(MUT_UPDATE_CONF, { id, props: { [CONF_PROPS.STATUS]: STATUS.ACTIVE } })
    })
    client.on(EVENTS.USERS_INITED, (users) => {
        // console.log('EVENTS.USERS_INITED', users)
        users.forEach((user) => commit(MUT_CONF_ADD_MEMBER, { id, member: prepareVmMember(user) }))
        dispatch(ACT_CONF_SEND_CID, { id })
    })
    client.on(EVENTS.USER_ADDED, (user) => {
        let member = prepareVmMember(user)
        commit(MUT_CONF_ADD_MEMBER, { id, member})
        dispatch(ACT_CONF_SEND_CID, { id, to: member[MEMBER_PROPS_BASE.ID] })
    })
    client.on(EVENTS.USER_CHANGED, (user, attr, newVal) => {
        let member = prepareVmMember(user)
        commit(MUT_CONF_UPDATE_MEMBER, { id, member})
    })
    client.on(EVENTS.USER_STATES_CHANGED, (user, attr, newVal) => {
        let member = prepareVmMember(user)
        // если нам включили камеру или микрофон, то снимаем WANT_SPEAK
        if (member[MEMBER_PROPS_BASE.ME]) {
            const oldVal = getters[GET_CONF_OWN_MEMBER](id)
            if (oldVal[MEMBER_PROPS_BASE.WANT_SPEAK]) {
                if (member[MEMBER_PROPS_BASE.CAMERA_GLOBAL] || member[MEMBER_PROPS_BASE.MICROPHONE_GLOBAL]) {
                    dispatch(ACT_CONF_WANT_SPEAK, {id, value: false})
                }
            }
        }
        commit(MUT_CONF_UPDATE_MEMBER, { id, member})
    })
    client.on(EVENTS.USER_REMOVED, (user) => {
        commit(MUT_CONF_REMOVE_MEMBER, {id, member: prepareVmMember(user)})
    })
    client.on(EVENTS.SHARE_STARTED, (user) => {
        let userId = ''
        if (user) {
            userId = user[MEMBER_PROPS.LOGIN]
        } else {
            let ownMember = getters[GET_CONF_OWN_MEMBER](id)
            userId = ownMember && ownMember[MEMBER_PROPS_BASE.ID]
        }
        commit(MUT_SET_CONF_SHARE, {id, userId})
    })
    client.on(EVENTS.SHARE_ENDED, () => {
        commit(MUT_SET_CONF_SHARE, {id})
    })
    client.on(EVENTS.SHARE_ENDED, () => {
        commit(MUT_SET_CONF_SHARE, {id})
    })
    client.on(EVENTS.ON_DEVICES_CHANGED, ( devices ) => {
        dispatch(ACT_CONF_SET_DEVICES, { id, devices })
    })
    client.on(EVENTS.ON_DATA, ( user, name, data ) => {
        if (user) dispatch(ACT_VM_CONF_HANDLE_MEMBER_DATA, { id, from: user[MEMBER_PROPS.LOGIN], name, data: JSON.parse(data) })
    })
}

function prepareVmInfo(confSettings) {
    let confInfo = confSettings[CONF_SETTINGS_PROPS.CONF_INFO]
    let global = confSettings[CONF_SETTINGS_PROPS.GLOBAL]
    let ownerName = [confInfo[CONF_SETTINGS_INFO_PROPS.LASTNAME], confInfo[CONF_SETTINGS_INFO_PROPS.FIRSTNAME]].filter((val) => val).join(' ')
    let id = confInfo[CONF_SETTINGS_INFO_PROPS.CONF_ID]
    let password = confInfo[CONF_SETTINGS_INFO_PROPS.PASSWORD]
    let server = global[CONF_SETTINGS_GLOBAL_PROPS.SERVER]
    let link = `https://${server}/service/join/?confid=${id}&confpass=${password}`
    let startTime = confInfo[CONF_SETTINGS_INFO_PROPS.START_TIME]
    let finishTime = confInfo[CONF_SETTINGS_INFO_PROPS.FINISH_TIME]
    let sipEnabled = confInfo[CONF_SETTINGS_INFO_PROPS.SIP_ENABLED]
    let sipType = confInfo[CONF_SETTINGS_INFO_PROPS.SIP_TYPE]
    let sip = confInfo[CONF_SETTINGS_INFO_PROPS.SIP]
    let sipProxy = confInfo[CONF_SETTINGS_INFO_PROPS.SIP_PROXY]
    let sipMixedType = confInfo[CONF_SETTINGS_INFO_PROPS.SIP_MIXED_TYPE]
    let sipMixedProfile = confInfo[CONF_SETTINGS_INFO_PROPS.SIP_MIXED_PROFILE]
    return {
        [INFO_PROPS.TOPIC]: confInfo[CONF_SETTINGS_INFO_PROPS.TOPIC],
        [INFO_PROPS.OWNER_CID]: null,
        [INFO_PROPS.OWNER_NAME]: ownerName,
        [INFO_PROPS.CONF_ID]: id,
        [INFO_PROPS.PASSWORD]: password,
        [INFO_PROPS.LINK]: link,
        [INFO_PROPS.START_TIME]: startTime,
        [INFO_PROPS.FINISH_TIME]: finishTime,
        [INFO_PROPS.SIP_ENABLED]: sipEnabled,
        [INFO_PROPS.SIP_TYPE]: sipType,
        [INFO_PROPS.SIP]: sip,
        [INFO_PROPS.SIP_PROXY]: sipProxy,
        [INFO_PROPS.SIP_MIXED_TYPE]: sipMixedType,
        [INFO_PROPS.SIP_MIXED_PROFILE]: sipMixedProfile
    }
}

function prepareVmMember(user) {
    let cam = (MEMBER_STATES_PROPS.CAM in user[MEMBER_PROPS.STATES]) ? Boolean(user[MEMBER_PROPS.STATES][MEMBER_STATES_PROPS.CAM]) : true
    let mic = (MEMBER_STATES_PROPS.MIC in user[MEMBER_PROPS.STATES]) ? Boolean(user[MEMBER_PROPS.STATES][MEMBER_STATES_PROPS.MIC]) : true
    return {
        [MEMBER_PROPS_BASE.ID]: user[MEMBER_PROPS.LOGIN],
        [MEMBER_PROPS_BASE.NAME]: user[MEMBER_PROPS.NAME],
        [MEMBER_PROPS_BASE.ME]: user[MEMBER_PROPS.IM],
        [MEMBER_PROPS_BASE.BANNED]: user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.BANNED],
        [MEMBER_PROPS_BASE.MODERATOR]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.MODERATOR]),
        [MEMBER_PROPS_BASE.HIDDEN]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.HIDDEN]),
        [MEMBER_PROPS_BASE.OWNER]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.OWNER]),
        [MEMBER_PROPS_BASE.PRESENTER]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.PRESENTER]),
        [MEMBER_PROPS_BASE.PRIORITY]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.PRIORITY]),
        [MEMBER_PROPS_BASE.READER]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.READER]),
        [MEMBER_PROPS_BASE.CAMERA_GLOBAL]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.VISIBLE]),
        [MEMBER_PROPS_BASE.CAMERA]: cam,
        [MEMBER_PROPS_BASE.MICROPHONE_GLOBAL]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.SPEAKER]),
        [MEMBER_PROPS_BASE.MICROPHONE]: mic,
        [MEMBER_PROPS_BASE.WANT_SHARE]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.WANTSHARE]),
        [MEMBER_PROPS_BASE.WANT_SPEAK]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.WANTSPEAK]),
        [MEMBER_PROPS_BASE.WRITER]: Boolean(user[MEMBER_PROPS.ATTRIBS][MEMBER_ATTRS_PROPS.WRITER]),
    }
}

function changeMemberProps({getters}, {id, memberId, prop, value}) {
    let client = getClient(id)
    if (client) {
        let props = { }
        switch (prop) {
            case MEMBER_PROPS_BASE.MODERATOR:
                props[MEMBER_ATTRS_PROPS.MODERATOR] = +value
                break
            case MEMBER_PROPS_BASE.CAMERA_GLOBAL:
                props[MEMBER_ATTRS_PROPS.VISIBLE] = +value
                break
            case MEMBER_PROPS_BASE.MICROPHONE_GLOBAL:
                props[MEMBER_ATTRS_PROPS.SPEAKER] = +value
                if (value) props[MEMBER_ATTRS_PROPS.WANTSPEAK] = 0
                break
            case MEMBER_PROPS_BASE.BANNED:
                props[MEMBER_ATTRS_PROPS.BANNED] = +value
                break
            case MEMBER_PROPS_BASE.WANT_SPEAK:
                props[MEMBER_ATTRS_PROPS.WANTSPEAK] = +value
                break
        }
        if (Object.keys(props).length) {
            props = { [memberId]: props }
            client.setMembersProps(props)
        }
    }
}

function sendData({getters}, {id, to, name, data}) {
    let client = getClient(id)
    if (!client) return
    data = JSON.stringify(data)
    if (to) {
        client.sendData(name, data, to)
    } else {
        let members = getters[GET_CONF_MEMBERS](id) || []
        members.forEach((member) => {
            if (member[MEMBER_PROPS_BASE.ME]) return
            client.sendData(name, data, member[MEMBER_PROPS_BASE.ID])
        })
    }
}

function getClient(id) {
    return clients[id]
}

const mutations = {
}

Object.assign(module.state, state)
Object.assign(module.getters, getters)
Object.assign(module.actions, actions)
Object.assign(module.mutations, mutations)

export default module