//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "MainHeaderContainer",
    props: {
        iconSrc: {
            type: String,
            required: false,
        },
        iconText: {
            type: String,
            required: false,
        },
        title: {
            type: [String, Number],
            required: false,
            default: ''
        },
        titleFooter: {
            type: [String, Number],
            required: false,
            default: ''
        },
        searchBtn: {
            type: Boolean,
            required: false,
            default: false
        },
        infoToggleBtn: {
            type: Boolean,
            required: false,
            default: true
        },
        menu: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    computed: {
        showTitleFooter() {
            return Boolean(this.titleFooter || this.$slots['title-footer-before'] || this.$slots['title-footer-after'])
        },
    },
}
