//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import {CHAT, CONTACTS, USERDATA} from "../../store/modulesNames"
import { ACT_CHAT_REMOVE_MSG } from "../../store/actionsTypes"
import modal_mixin from "./modal-mixin"
import ModalDialog from "./ModalDialog.vue"
import CustomCheckbox from "../custom/CustomCheckboxRound.vue"
import {GET_MERGED_CONTACT_BY_ID, GET_UID} from "../../store/gettersTypes"

export default {
    name: "DelChatMessage",
    mixins: [modal_mixin],
    components: { ModalDialog, CustomCheckbox },
    props: {
        msg: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            compModalProps: {
                delay: 200,
                pivotY: 0.05,
                width: 450,
                height: 'auto',
            },

            title: this.$t('action-confirm'),
            text: this.$t('modal.delete-message-confirm'),
            everyone: false,
        }
    },
    computed: {
        isGroupChat() {
            return this.msg.cidType === 'group'
        },
        ownChat() {
            return !this.isGroupChat && this.msg.cid === this[GET_UID]
        },
        ownMsg() {
            return this.msg.senderId === this[GET_UID]
        },
        canDeleteForAll() {
            return this.ownMsg && !this.ownChat
        },
        allText() {
            if (this.isGroupChat) {
                return this.$t('modal.delete-message-for-all')
            } else {
                return this.$t('modal.delete-message-for') + this[GET_MERGED_CONTACT_BY_ID](this.msg.cid).fio
            }
        },
        ...mapGetters(USERDATA, [GET_UID]),
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_ID]),
    },
    methods: {
        onOk() {
            this[ACT_CHAT_REMOVE_MSG]({ msg: this.msg, everyone: this.everyone })
            this.modalClose()
            this.$nextTick(() => {
                this.btnOkCb && this.btnOkCb()
                this.props.btnOk && this.props.btnOk.cb && this.props.btnOk.cb()
            })
        },
        onCancel() {
            this.modalClose();
        },
        ...mapActions(CHAT, [ACT_CHAT_REMOVE_MSG])
    }
}
