//
//
//
//
//
//
//
//
//
//
//

import ModalDialog from './ModalDialog.vue';
import modal_mixin from './modal-mixin';
import CustomRadio from '../custom/CustomRadio.vue';

import { ACT_SET_BIRTHDAY_NOTIFY, ACT_UPDATE_CONTACTS_BIRTHDAYS } from "../../store/actionsTypes";
import { GET_BIRTHDAY_NOTIFY, GET_SERVER_API } from "../../store/gettersTypes";
import { USERDATA, LOGIN, CONTACTS } from "../../store/modulesNames";

export default {
    name: "SetBirthday",
    components: {ModalDialog, CustomRadio},
    mixins: [modal_mixin],
    props: {
        modalName: {
            type: String,
            required: true
        },
        props: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    data() {
        return {
            compModalProps: {
                delay: 200,
                pivotY: 0.40,
                pivotX: 0.73,
                width: 350,
                height: 'auto'
            },
            birthdayNotify: 3,
            setUnwatched: false,
        }
    },
    mounted() {
        this.birthdayNotify = this.$store.getters[`${USERDATA}/${GET_BIRTHDAY_NOTIFY}`]
    },
    computed: {
        title() {
           return this.$t('bday-notifications')
        },
        isRolesSupported() {
            return this.$store.getters[`${LOGIN}/${GET_SERVER_API}`] > declarations.serverAPILevels.LEVEL_11
        },
        inputs() {
            return [
                {
                    value: 10, 
                    label: this.$t('not-notify')
                }, {
                    value: 0,
                    label: this.$t('notify-on-same-day')
                }, {
                    value: 1,
                    label: this.$t('day-before')
                }, {
                    value: 3,
                    label: this.$t('3days-before')
                }
            ]
        }
    },
    watch: {
        birthdayNotify(newVal, oldVal) {
            if (newVal !== 10) this.setUnwatched = true
        }
    },
    methods: {
        okBtn() {
            this.$store.dispatch(`${USERDATA}/${ACT_SET_BIRTHDAY_NOTIFY}`, this.birthdayNotify)
            if (this.isRolesSupported) this.$store.dispatch(`${CONTACTS}/${ACT_UPDATE_CONTACTS_BIRTHDAYS}`)
            if (this.setUnwatched) this.$store.commit("contacts/setBirthdaysUnwatched")
            this.cancelBtn()
        },
        cancelBtn() {
            this.setUnwatched = false
            this.modalClose();
        }
    },
}
