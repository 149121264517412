//
//
//
//
//
//
//
//
//
//

import modal_mixin from './modal-mixin';
import { i18n } from '../../../ext/i18n';
import store from '../../store/main/store'
import customRadio from '../custom/CustomRadio.vue'

export default {
  name: 'LanguageSelect',
  mixins: [modal_mixin],
  components: { customRadio },
  data() {
    return {
      compModalProps: {
        delay: 200,
        pivotY: 0.40,
        pivotX: 0.73,
        width: 500,
        height: 'auto',
        clickToClose: false,
      },
      selectedlocale: store.getters['clientdata/getLocale'],
      newLocale: store.getters['clientdata/getLocale'],
      locales: [
        {
          value: 'ru',
          label: 'Русский'
        },
        {
          value: 'en',
          label: 'English'
        }
      ],
      title: i18n.messages[i18n.locale]['language-select']
    }
  },
  methods: {
    changeLocale() {
      this.modalClose()
      this.modalOpen({
        name: 'Confirm',
        props: {
          text: i18n.messages[i18n.locale]['for-locale-change'],
          btnOk: {
            cb: () => store.dispatch('clientdata/changeLocale', this.newLocale)
          }
        }
      })
    }
  }
}
