import EncrypterWorker from 'comlink-loader!../webworkers/Encrypter.worker.comlink.js'
export const {
    decryptText,
    encryptText,
    generateHexString,
    decryptArrayBuffer,
    encryptArrayBuffer,
    ab2str,
    str2ab,
} = new EncrypterWorker()

const getClearPemKey = (pem) => {
    return pem.replace(/((-----(BEGIN|END) (RSA )?PUBLIC KEY-----)|\r\n|\n|\r)/gm, '')
}

export const importRsaKey = async (pem) => {
    let pemContents = getClearPemKey(pem)
    // base64 декодирует строку, чтобы получить двоичные данные
    const binaryDerString = atob(pemContents)
    // преобразование из двоичной строки в ArrayBuffer
    const binaryDer = await str2ab(binaryDerString)

    try {
        return await window.crypto.subtle.importKey(
            "spki",
            binaryDer,
            {
                name: "RSA-OAEP",
                hash: "SHA-256"
            },
            true,
            ["encrypt"]
        )
    } catch (e) {
        console.log(e)
    }
}

export const encryptTextByPublicKey = async (key, text) => {
    let textBuff = new TextEncoder().encode(text)
    let encrypted = await window.crypto.subtle.encrypt(
        {
            name: "RSA-OAEP"
        },
        key,
        textBuff
    )
    return btoa(String.fromCharCode.apply(null, new Uint8Array(encrypted)))
}