import { render, staticRenderFns } from "./ModalDialog.vue?vue&type=template&id=07b7246c&scoped=true&lang=pug&"
import script from "./ModalDialog.vue?vue&type=script&lang=js&"
export * from "./ModalDialog.vue?vue&type=script&lang=js&"
import style0 from "./ModalDialog.vue?vue&type=style&index=0&id=07b7246c&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07b7246c",
  null
  
)

export default component.exports