import axios from "axios"

import EventEmitter from '../../common/EventEmitter'
import Logger from "../../common/Logger"
import VideoPlug from "../../common/VideoPlug"
const logger = new Logger('VideoMostClient')

import { MEDIA_DEVICES } from '../../constants'

let videoMostInited = false

const DEFAULT_CONF_OPTIONS = {
    video_maxparticipants: 25,
    bitrate_limit: 4500,
    reordering: false,
    localVideoInRemotes: true,
    localVideoInRemotesAtNum: 0,
}

export const EVENTS = {
    CONF_JOINED: 'conf_joined',
    CONF_TERMINATED: 'conf_terminated',
    REBUILD_LAYOUT: 'rebuild_layout',
    SHARE_STARTED: 'share_started',
    SHARE_ENDED: 'share_ended',
    USERS_INITED: 'users_inited',
    USER_ADDED: 'user_add',
    USER_REMOVED: 'user_removed',
    USER_CHANGED: 'user_changed',
    USER_STATES_CHANGED: 'user_states_changed',
    ON_DATA: 'on_data',
    ON_DEVICES_CHANGED: 'on_devices_changed',
}
//SetAttributes
//Включить видео и звук
/*{
    "58599975(u.vm)@vmotsp.ros.chat": {
    "wantspeak": 0,
        "speaker": 1,
        "visible": 1
}
}*/
//отключить видео и звук
/*{
    "17512868(u.vm)@vmotsp.ros.chat": {
    "speaker": 0,
        "visible": 0
}
}*/
//отключить докладчика
/*{
    "90256083(u.vm)@vmotsp.ros.chat": {
    "presenter": 0,
        "wantshare": 0
}
}*/
//сделать докладчика
/*{
    "88084380(u.vm)@vmotsp.ros.chat": {
    "wantshare": 0,
        "presenter": 1
}
}*/
//разбанить
    /*
     {
     "71157917(u.vm)@vmotsp.ros.chat": {
     "banned": 0
     }
     }
     */

//user.sa
//"moderator" 0/1
//"banned" 0/1/2 отключить участника + SendCustomData временно отключить/удалить + SendCustomData Удалить
//"speaker" 0/1 звук
//"visible" 0/1 видео
//"writer" 0/1 чат

//SendCustomData
//Отключить
/*Siq: undefined
Squery: undefined
callback: undefined
data: "1"
errback: undefined
name: "hangup"
sendto: undefined
this: WM
timeout: undefined
to: "58685409(u.vm)@vmotsp.ros.chat"*/
//временно отключить
/*Siq: undefined
Squery: undefined
callback: undefined
data: "pid:42212482(u.vm)@vmotsp.ros.chat;attr:banned;val:1"
errback: undefined
name: "party-attributes"
sendto: undefined
this: WM
timeout: undefined
to: undefined*/
//Удалить + user.sa banned 2
/*Siq: undefined
Squery: undefined
callback: undefined
data: "pid:71157917(u.vm)@vmotsp.ros.chat;attr:banned;val:2"
errback: undefined
name: "party-attributes"
sendto: undefined
this: WM
timeout: undefined
to: undefined*/

export const MEMBER_PROPS = {
    ATTRIBS: 'Attribs', // свойства конфы для участника
    IM: 'Im', // я
    LOGIN: 'Login', // логин
    NAME: 'Name', // отображаемое имя
    STATUS: 'Status', // ???
    ID: '_id', // ???
    STATES: '_states', // состояния устройств
}

export const MEMBER_ATTRS_PROPS = {
    VISIBLE: 'visible', // камера в конфе 0/1
    PRESENTER: 'presenter', // докладчик 0/1
    BANNED: 'banned', // забанен 0/1/2
    MODERATOR: 'moderator', // модератор 0/1
    OWNER: 'owner', // создатель 0/1
    SPEAKER: 'speaker', // микрофон в конфе 0/1
    WANTSPEAK: 'wantspeak', // запрашивает слово 0/1
    WANTSHARE: 'wantshare', // ???
    HIDDEN: 'hidden', // ???
    WRITER: 'writer', // чат 0/1
    READER: 'reader', // ???
    PRIORITY: 'priority', // ???
}

export const CONF_SETTINGS_PROPS = {
    CONF_INFO: 'confinfo',
    GLOBAL: 'global',
}

export const CONF_SETTINGS_INFO_PROPS = {
    TOPIC: 'topic',
    LOGIN: 'login',
    FIRSTNAME: 'firstname',
    LASTNAME: 'lastname',
    CONF_ID: 'confid',
    PASSWORD: 'password',
    START_TIME: 'startTime',
    FINISH_TIME: 'finishTime',
    SIP_ENABLED: 'sip_enabled',
    SIP_TYPE: 'sip_type',
    SIP: 'sip',
    SIP_PROXY: 'sip_proxy',
    SIP_MIXED_TYPE: 'sip_mixed_type',
    SIP_MIXED_PROFILE: 'sip_mixed_profile',
}

export const CONF_SETTINGS_GLOBAL_PROPS = {
    SERVER: 'xmpp_server',
    STUN: 'adm_wrtc_stun_servers',
}

export const MEMBER_STATES_PROPS = {
    MIC: 'mic', // состояние микрофона
    CAM: 'cam', // состояние камеры
}



class VideomostClient extends EventEmitter {
    /**
     * @param {Object} payload
     * @param {String} payload.id
     * @param {String} payload.server
     * @param {String} payload.username
     * @param {String} payload.managementHost
     */
    constructor (payload = {}) {
        super()
        window.videoMostClient = this
        let {id, server, username, defDevices = {}, managementHost} = payload
        logger.info(`VideomostClient constructor payload ${JSON.stringify(payload)}`)
        this._client = null
        this._confId = id
        this._confConn = null
        this._server = server
        this._username = username
        this._defDevices = defDevices
        this._defDevicesSettet = false
        this._lastDevices = null
        this._permisionRequeredDiveces = new Set()
        this._managementHost = managementHost
    }

    async _init(iceServers) {
        if (!videoMostInited) {
            try {
                const $ = (await import(/* webpackChunkName: "jquery" */'jquery')).default
                window.jQuery = window.$ = $
                await import(/* webpackChunkName: "jquery" */'jquery-ui')
                await import(/* webpackChunkName: "videomost" */'../../../ext/vm.bundle.js')
            } catch (e) {
                logger.error(e, 'VideoMost init error')
                throw e
            }
            //let rawModule = await import('../../../ext/vm.bundle.js?raw')
            //eval.call(null, rawModule.default)
            VideoMost.init({ debug: true })
            videoMostInited = true
            logger.info('VideoMost inited')
        }
        this._client = VideoMost.getClient(false)

        let initOptions = {
            username: this._username,
            SignallingServer: this._server,
            service: `wss://${this._server}:7443/ws/`,
        }
        if (iceServers) initOptions.iceServers = iceServers
        logger.info(`VideoMost._client init: payload - ${JSON.stringify(initOptions)}`)
        this._client.init(initOptions);
        this._confConn = this._client.makeConfConnection()
        this._subscribeOnClientEvents()
        this._subscribeOnConfEvents()
        this._subscribeOnUsersEvents()
    }

    _subscribeOnClientEvents() {
        this._client.on('OnDisconnected', () => {
            logger.info(`OnDisconnected`)
        })
        this._client.on('OnAuthfail OnConnfail OnConnError', (e) => {
            logger.info(`OnAuthfail OnConnfail OnConnError`)
        })
        this._client.on('OnMediaDevices', () => {
            logger.info(`OnMediaDevices`)
            if (!this._defDevicesSettet) {
                //window.videoMostClient._confConn._jmate._wm._wrtc.showPermissionBox()
                let kinds = [MEDIA_DEVICES.AUDIO_INPUT, MEDIA_DEVICES.VIDEO_INPUT]
                if (!(navigator.userAgent.toLowerCase().indexOf('firefox') > -1)) kinds.push(MEDIA_DEVICES.AUDIO_OUTPUT)
                kinds.forEach(kind => {
                    // меняем дефолтное устройство на уставленное в наших настройках
                    const selectedInVideomost = this._client.getDevice(kind)
                    if (selectedInVideomost) {
                        const selectedInRoschat = this._defDevices && this._defDevices[kind] && this._defDevices[kind]
                        if (selectedInRoschat !== selectedInVideomost) {
                            if ((this._client.getDeviceList(kind) || []).find(({id}) => id === selectedInRoschat))
                                this.setDevice(kind, this._defDevices[kind])
                        }
                    } else {
                        this._permisionRequeredDiveces.add(kind)
                    }
                })
                this._defDevicesSettet = true
            }
            this._onDeviceChanged()
        })
    }

    _subscribeOnConfEvents() {
        let selfVideoHack = true
        this._confConn.on('OnMessage', (e, msg) => {
            console.log('OnMessage', ...arguments)
        })
        this._confConn.on('OnTerminate', (e, reason) => {
            logger.info(`OnTerminate: reason - ${reason}`)
            this.emit(EVENTS.CONF_TERMINATED, reason)
        })
        this._confConn.on('OnSharingReceive', (e, user)=>{
            logger.info('User started sharing: '+this._prepareUserForLog(user))
            this.emit(EVENTS.SHARE_STARTED, user)
        })
        this._confConn.on('OnSharingHangup', (e)=>{
            logger.info('User ended sharing')
            this.emit(EVENTS.SHARE_ENDED)
        })
        this._confConn.on('OnSharingStarted', (e)=>{
            logger.info('Sharing started')
            this.emit(EVENTS.SHARE_STARTED)
        })
        this._confConn.on('OnSharingEnded', (e)=>{
            logger.info('Sharing ended')
            this.emit(EVENTS.SHARE_ENDED)
        })
        this._confConn.on('OnData', (e, user, name, data)=>{
            logger.info(`onData: user - ${this._prepareUserForLog(user)}, name - ${name}, data - ${data}`)
            this.emit(EVENTS.ON_DATA, user, name, data)
        })
        this._confConn._jmate.on('OnRebuildLayout', (e)=>{
            logger.info(`OnRebuildLayout`)
            this.emit(EVENTS.REBUILD_LAYOUT)
            if (selfVideoHack) {
                let myVideo = document.querySelector('.view_self video')
                if (myVideo) myVideo.play()
                selfVideoHack = false
            }
        })
    }

    _subscribeOnUsersEvents() {
        this._confConn.Users.on('OnAddUser', (e, user) => {
            logger.info(`OnAddUser: user - ${this._prepareUserForLog(user)}`)
            this.emit(EVENTS.USER_ADDED, user)
        });
        this._confConn.Users.on('OnDelUser', (e, user) => {
            logger.info(`OnDelUser: user - ${this._prepareUserForLog(user)}`)
            this.emit(EVENTS.USER_REMOVED, user)
        });
        this._confConn.Users.on('OnInit', (e) => {
            let count = this && this._confConn && this._confConn.Users && this._confConn.Users._users && this._confConn.Users._users.length || 0
            logger.info(`OnInit: users count - ${count}`)
            this.emit(EVENTS.USERS_INITED, this._confConn.Users._users)
        });
        this._confConn.Users.on('OnPartyAttrChanged', (e, user, attr, newVal, oldVal) => {
            logger.info(`OnPartyAttrChanged: attr - ${attr}, newVal - ${newVal}, user - ${this._prepareUserForLog(user)}`)
            this.emit(EVENTS.USER_CHANGED, user, attr, newVal, oldVal)
        });
        this._confConn.Users.on('OnStateChanged', (e, user, attr, newVal, oldVal) => {
            logger.info(`OnStateChanged: attr - ${attr}, newVal - ${newVal}, user - ${this._prepareUserForLog(user)}`)
            this.emit(EVENTS.USER_STATES_CHANGED, user, attr, newVal)
        });
    }

    login(login, password) {
        logger.info(`login: login - ${login}, password - ${password}`)
        return new Promise(async (resolve, reject) => {
            const connectedCb = () => {
                logger.info(`login: success`)
                resolve()
                clear()
            }
            const errorCb = (e) => {
                logger.info(`login: error`)
                reject(e)
                clear()
            }
            const clear = () => {
                this._client.off('OnConnected', connectedCb)
                this._client.off('OnAuthfail OnConnfail OnConnError', errorCb)
            }
            this._client.on('OnConnected', connectedCb)
            this._client.on('OnAuthfail OnConnfail OnConnError', errorCb)
            this._client.Login(login, password)
        })
    }

    logout() {
        try {
            this._client.Logout()
        } catch (e) {
            logger.error(e)
        }
    }

    async joinConference({el, share, login, password, confId, confPass, noCamera, noMicrophone, options = {}}) {
        logger.info(`joinConference: login - ${login}, password - ${password}, confId  - ${confId}, confPass  - ${confPass}, noCamera  - ${noCamera}, noMicrophone - ${noMicrophone}, options - ${JSON.stringify(options)}`)
        let confSettings = await this._getConfSettings(this._server, confId, confPass)
        let majorVersion = (confSettings && confSettings.version || '').split('.').shift()
        majorVersion = majorVersion ? +majorVersion : 0

        let id = `${confId}(conf.vm)@${this._server}`
        if (login && password) {
            if (majorVersion === 8) login = password = `${login}.videomost(u.vm)`
            else login = `${login}(u.vm)`
        } else {
            // #if process.env.WEBPACK_BUILD_TARGET === 'web'
            if (majorVersion >= 9) {
                confSettings = await this._getConfSettings(this._server, confId, confPass, true)
            }
            // #endif
            login = password = confSettings.login
        }
        let iceServers = confSettings.global && confSettings.global.adm_wrtc_stun_servers
        if (iceServers) {
            try {
                iceServers = JSON.parse(iceServers)
            } catch (e) {
                iceServers = null
            }
        }
        await this._init(iceServers)
        await this.login(login, password)
        await this._joinConference(el, share, id, options)
        const devices = this.getActiveDevices()
        let noSpeaker = false
        if (!devices[MEDIA_DEVICES.AUDIO_INPUT]) noMicrophone = true
        if (!devices[MEDIA_DEVICES.AUDIO_OUTPUT]) noSpeaker = true
        if (!devices[MEDIA_DEVICES.VIDEO_INPUT]) noCamera = true
        if (noCamera) this._confConn._jmate.Mute('Camera', true)
        if (noSpeaker) this._confConn._jmate.Mute('Speaker', true)
        if (noMicrophone) this._confConn._jmate.Mute('Microphone', true)
        this.emit(EVENTS.CONF_JOINED, this._confId, confSettings)
        if (noMicrophone) this.muteConfMicrophone(true)
        if (noSpeaker) this.muteConfSpeaker(true)
        if (noCamera) this.disableConfCamera(true)
        return confSettings
    }

    async _getConfSettings(server, confId, confPass, withCredentials) {
        let config = { }
        // #if process.env.WEBPACK_BUILD_TARGET === 'electron'
//         config = { withCredentials: true }
        // #endif
        if (withCredentials) config = { withCredentials: true }
        let resp = await axios.get(`https://${this._managementHost}/service/ext/settings_a/?confid=${confId}&confpass=${confPass}`, config)
        if (!resp || !resp.data) throw new Error('invalid settings_a response')
        if (typeof resp.data === 'string') throw new Error(resp.data)
        if (resp.data.Error) throw new Error(resp.data.Error)
        return resp.data
    }

    _joinConference(el, share, options) {
        return new Promise((resolve, reject) => {
            this._confConn.on('OnCallFailed', (e, reason) => {
                logger.info(`OnConferenceJoin: error reason - ${reason}`)
                reject(e)
            })
            this._confConn.on('OnConferenceJoin', (e) => {
                if (!e) {
                    logger.info(`OnConferenceJoin: error - no event`)
                    reject('no event')
                } else {
                    logger.info(`OnConferenceJoin: success`)
                    resolve()
                }
            })

            this._confConn.setView({ remote: el, sharing: share })
            this._confConn.JoinConference({ roomJid: this._confId })
            let camDummy = VideoPlug.prototype.getVideoDummy({textCb: () => this._username})
            this._confConn.SetOptions({
                ...DEFAULT_CONF_OPTIONS, ...options, camDummy
            })

        })
    }

    async leaveConference() {
        logger.info(`leaveConference`)
        return new Promise((resolve, reject) => {
            try {
                this._confConn.on('OnTerminate', (e) => {
                    this.logout()
                    this._client = null
                    this._confConn = null
                    this.clearListeners()
                })

                this._dispose()
                this._confConn.ExitConference()
            } catch (e) {
                try { this._dispose() } catch (e) { }
                logger.info(`leaveConference error: ${e}`)
            }
            resolve()
        })
    }

    muteConfMicrophone(val) {
        logger.info(`muteConfMicrophone: val - ${val}`)
        this._confConn.MuteMicrophone(val)
    }

    muteConfSpeaker(val) {
        logger.info(`muteConfSpeaker: val - ${val}`)
        this._confConn.MuteSpeaker(val)
    }

    disableConfCamera(val) {
        logger.info(`disableConfCamera: val - ${val}`)
        this._confConn.DisableCamera(val)
    }

    setMembersProps(val) {
        logger.info(`setMembersProps: val - ${JSON.stringify(val)}`)
        this._confConn._jmate.SetAttributes(val)
    }

    shareToggle() {
        if (this._confConn.sharingIsStarted()) {
            logger.info(`shareToggle: stop`)
            this._confConn.sharingStop()
        } else {
            logger.info(`shareToggle: start`)
            this._confConn.sharingStart()
        }
    }

    sendData(name, data, to = '') {
        logger.info(`sendData: name - ${name}, data - ${data}, to - ${to}`)
        if (to && typeof to === 'string' && to.includes('(u.vm)@')) {
            this._confConn.sendData({Login: to}, name, data)
        } else {
            logger.info(`sendData: to - ${to} ignored`)
        }
    }

    getActiveDevices() {
        return [MEDIA_DEVICES.AUDIO_INPUT, MEDIA_DEVICES.AUDIO_OUTPUT, MEDIA_DEVICES.VIDEO_INPUT].reduce((prev, cur) =>
            Object.assign(prev, {[cur]: this._client.getDevice(cur)}), {})
    }

    setDevice(kind, id) {
        logger.info(`setDevice: kind - ${kind}, id - ${id}`)
        this._client.setDevice(kind, id)
        // Videomost bug сброс костыля
        if (this._lastDevices && this._lastDevices[kind] === null) {
            this._lastDevices[kind] = false
        }
        this._onDeviceChanged()
    }

    _dispose() {
        try {
            logger.info(`dispose`)
            this._confConn.dispose()
        } catch (e) {
            logger.error(e)
        }
    }

    getActiveConfId() {
        return this._confId
    }

    _prepareUserForLog(user = {}) {
        let userString
        try {
            userString = JSON.stringify({
                [MEMBER_PROPS.LOGIN]: user[MEMBER_PROPS.LOGIN],
                [MEMBER_PROPS.NAME]: user[MEMBER_PROPS.NAME],
            })
        } catch (e) {
            userString = '{}'
        }
        return userString
    }

    updateDevices() {
        this._client && this._client.collectDevices(() => {
            this._onDeviceChanged()
        })
    }

    _onDeviceChanged() {
        let activeDevices = this.getActiveDevices()
        if (this._lastDevices) {
            // Videomost bug если при подключении к конфе не было устройств для типа, нужно дергать this._confConn._jmate._wm._wrtc.showPermissionBox() для их работы
            if (this._permisionRequeredDiveces.size) {
                let triggerPermission = false
                for (let device in this._lastDevices) {
                    let prevSelected = this._lastDevices[device]
                    let curSelected = activeDevices[device]
                    if (curSelected && !prevSelected && this._permisionRequeredDiveces.has(device)) {
                        this._permisionRequeredDiveces.delete(device)
                        triggerPermission = true
                    }
                }
                triggerPermission && this._confConn._jmate._wm._wrtc._showPermissionBox()
            }

            // Videomost bug убираем галочку текущего устройства т.к. оно выбрано, но не работает, нужно повторно выбирать
            for (let kind in activeDevices) {
                if (this._lastDevices[kind] === null) activeDevices[kind] = null
            }
        }

        this._lastDevices = activeDevices
        this.emit(EVENTS.ON_DEVICES_CHANGED, activeDevices)
    }
}

export default VideomostClient