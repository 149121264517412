import {
    CLIENTDATA,
    LOGIN,
    USERDATA,
} from '../../modulesNames'
import {
    GET_IS_ELECTRON,
    GET_ROSCHAT_SERVER,
} from '../../gettersTypes'
import {
    ACT_SOCKET_INIT,
    ACT_SOCKET_RECONNECT,
    ACT_GET_SERVER_CONFIG,
    ACT_SET_ROSCHAT_SERVER_CONFIG,
    ACT_CLEAR_RELOGIN_INTERVAL,
    ACT_SOCKET_CONNECT,
    ACT_SET_CONNECTION_STATUS,
} from '../../actionsTypes'
import {
    MUT_SOCKET_CLEAR_RECONNECT_TIMEOUT,
    MUT_SOCKET_SET_ENCRYPTION,
    MUT_SOCKET_SET_RECONNECT_TIMEOUT
} from '../../mutationsTypes'
import proto from '../../../protocol'

import module, {SOCKET_STATUSES} from '../../modules/socket'

const logger = new Logger('socket.main')

import { generateHexString, importRsaKey, encryptTextByPublicKey } from '../../../common/Encrypter'
import Logger from "../../../common/Logger"


const actions = {
    async [ACT_SOCKET_INIT] ({state, dispatch, commit, rootGetters}, repeat = true) {
        return new Promise(async (resolve, reject) => {
            try {
                resolve(await dispatch(ACT_SOCKET_CONNECT, { repeat }))
            } catch (e) {
                logger.error(e)
                reject('connection-refused')
            }
        })
    },
    [ACT_SOCKET_RECONNECT] ({ commit, dispatch }, payload = {}) {
        /*commit(MUT_SOCKET_SET_CONNECTION_STATUS, 'reconnecting')
        proto.reconnect()*/
        let { try_count = 0, quiet } = payload

        let timeout = 5000
        if (try_count >= 6) timeout = 30000
        else if (try_count >= 3) timeout = 10000
        else if (try_count === 0) timeout = 0

        commit(MUT_SOCKET_CLEAR_RECONNECT_TIMEOUT)
        dispatch(`${LOGIN}/${ACT_CLEAR_RELOGIN_INTERVAL}`, null, { root: true })
        const timeOutId = setTimeout(async () => {
            logger.info(`Trying to reconnect count ${try_count}`)
            commit(MUT_SOCKET_CLEAR_RECONNECT_TIMEOUT)

            try {
                await dispatch(ACT_SOCKET_CONNECT)
            } catch (e) {
                logger.error(e)
                dispatch(ACT_SOCKET_RECONNECT, {try_count: ++try_count, quiet})
            }
        }, timeout)
        commit(MUT_SOCKET_SET_RECONNECT_TIMEOUT, timeOutId)
    },
    async [ACT_SOCKET_CONNECT] ({ commit, dispatch, state, rootGetters }, payload = {}) {
        const { repeat = false } = payload
        // let repeat = false
        // адрес сервера
        let hostPort
        if (rootGetters[`${CLIENTDATA}/${GET_IS_ELECTRON}`]) {
            let selectedServer = rootGetters[`${USERDATA}/${GET_ROSCHAT_SERVER}`]
            hostPort = `http://${selectedServer}`
        } else {
            hostPort = utils.getPageProtoHostPort()
        }
        let serverUrl
        try {
            serverUrl = new URL(hostPort)
        } catch (e) {
            return console.log(`${ACT_SOCKET_INIT}: invalid hostPort: ${hostPort}`)
        }

        // запрашиваем websocket порт
        const config = await dispatch(`${USERDATA}/${ACT_GET_SERVER_CONFIG}`, {httpServer: serverUrl.origin, repeat}, {root: true})
        const respOrigin = config.redirectedServer
        commit(MUT_SOCKET_SET_ENCRYPTION, config.APILevel >= declarations.serverAPILevels.LEVEL_11)
        dispatch(`${USERDATA}/${ACT_SET_ROSCHAT_SERVER_CONFIG}`, config, {root: true})
        serverUrl.href = respOrigin

        // формируем websocket адрес
        let wsUrl = new URL(serverUrl.href)
        wsUrl.port = config.webSocketsPort
        // всегда https
        wsUrl.protocol = 'https'
        let wsServer = wsUrl.origin

        dispatch('userdata/setHost', {host: serverUrl.origin}, {root: true})

        logger.info(`${ACT_SOCKET_CONNECT}: wsServer: ${wsServer}`)

        const socket_id = await proto.connect(wsServer)
        if (state.encryption) {
            let publicKey = await proto.getPublicKey()
            publicKey = await importRsaKey(publicKey)
            let sessionKey = await generateHexString()
            await proto.setSessionKey(await encryptTextByPublicKey(publicKey, sessionKey))
            proto.setProtoSessionKey(sessionKey)
        }
        dispatch(ACT_SET_CONNECTION_STATUS, SOCKET_STATUSES.CONNECTED)

        return socket_id
    },

}

Object.assign(module.actions, actions)

export default module
