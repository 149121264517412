'use strict'

import {
    GET_USER_PARAMS,
    GET_TETRA_PARAMS,
    GET_TETRA_CONNECTION_PARAMS,
    TETRA_IS_TURN_ON,
    GET_TETRA_CONNECT_STATUS,
    GET_TETRA_CONNECT_ERROR,
    GET_TETRA_LINK_STATUS,
    GET_TETRA_CELL,
    GET_TETRA_GROUPS,
    GET_TETRA_ACTIVE_GROUP,
    GET_TETRA_PTT_PUSHED,
    GET_TETRA_CALL,
    GET_IS_ELECTRON,
    GET_CLIENT_VERSION,
    GET_TETRA_ECHO,
    GET_TETRA_TOKEN,
} from '../gettersTypes'
import {
    ACT_TETRA_CONNECT,
    ACT_TETRA_DISCONNECT,
    ACT_TETRA_CONNECT_STATE_TOGGLE,
    ACT_TETRA_GET_STATUS,
    ACT_TETRA_LINK_CREATE,
    ACT_TETRA_START_LINK_TIMER,
    ACT_TETRA_STOP_LINK_TIMER,
    ACT_TETRA_LINK_PING,
    ACT_TETRA_LOCATE,
    ACT_TETRA_GET_ATTACH_GROUPS,
    ACT_TETRA_GET_GROUPS_LIST,
    ACT_TETRA_ACTIVATE_GROUP,
    ACT_TETRA_PUSH_PTT,
    ACT_TETRA_POP_PTT,
    ACT_TETRA_EXIT_BTN,
    ACT_TETRA_HANDLE_MSG_EVENT,
    ACT_TETRA_HANDLE_STATUS_EVENT,
    ACT_TETRA_SEND_SPEECH,
    ACT_TETRA_SET_ECHO,
    ACT_TETRA_REQUEST_TOKEN,
} from '../actionsTypes'
import {
    MUT_TETRA_SET_CLIENT_PARAMS,
    MUT_TETRA_SET_CONNECT_STATUS,
    MUT_TETRA_SET_LINK_STATUS,
    MUT_TETRA_SET_LINK_CREATED,
    MUT_TETRA_SET_LOCATED,
    MUT_TETRA_SET_REJECTED,
    MUT_TETRA_SET_GROUPS_ATTACHED,
    MUT_TETRA_SET_CALL,
    MUT_TETRA_SET_TX_GRANT,
    MUT_TETRA_SET_GROUPS,
    MUT_TETRA_SET_ACTIVE_GROUP,
    MUT_TETRA_SET_PTT_STATUS,
    MUT_TETRA_SET_CONNECT_ERROR,
    MUT_TETRA_SET_TOKEN,
} from '../mutationsTypes'

import {
    USERDATA,
    CLIENTDATA,
} from '../modulesNames'

import event_bus from "../../eventBus"
import mainproto from '../../protocol'
import radioproto from '../../radioprotocol'
let proto = null

const linkDefines = {
        ssiStates: {
            unknown: "unknown",
            opened: "opened"
        },
        readyMsgs: {
            ready: "ready",
            notValidSystem: "notValidSystem",
            readyAck: "readyAck"
        },
        linkCloseCodes: {
            sockedClosed: {code: 1, text: "socket closed"},
            linkTestTimeout: {code: 2, text: "link test timeout"},
            systemNotServed: {code: 3, text: "system not served"},
            linkToSystemAbsent: {code: 4, text: "link to system absent"},
            noActiveSystemServer: {code: 5, text: "no link to active system"},
            invalidSSI: {code: 6, text: "invalid ssi for system"},
            invalidSystemOrSSI: {code: 7, text: "invalid system or ssi"},
            ssiIsAlreadyBusy: {code: 8,text: "ssi is already busy with another client"},
            invalidLife: {code: 9,text: "invalid life"},
            waitingLinkAnswer:    {code: 10, text: "waiting create link answer"},
            linkIsClosed: {code: 11,text: "link is closed"}
        }
    }

const ssiDefines = {
        codecType: {  //for ct in speech messages
            g711: 0,        //g711 (a-law, 8000, 8-bit)
            tetra: 1
        },
        callDir: {
            outgoing: "outgoing",
            incoming: "incoming"
        },
        callType: {
            group: "group",
            duplex: "duplex",
            simplex: "simplex"
        },
}

export const CONNECT_STATUS = {
    OFFLINE:    'offline',
    CONNECTING: 'connecting',
    READY:      'ready',
    ERROR:      'error',
}

export const CONNECT_ERROR = {
    BUSY:                   'busy',
    INVALID_SERVER_NAME:    'invalid-server-name',
    INVALID_SSI:            'invalid-ssi',
    DISCONNECTED:           'tsystem-disconnected',
    TOKEN_EXPIRED:          'token-expired',
    UNKNOWN:                'unknown',
}

const STATUS = {
    READY:          'ready',
    DISCONNECTED:   'tsystem-disconnected',
    DROPPED:        'connection-dropped',
}

const MSG_LEVELS = {
    LINK:   'link',
    SSI:    'ssi',
    READY:  'ready',
}

const LINK_MSGS = {
    CREATE: "create",  //in parms - userId, clientAgent: {type,name}, allSSIEventsTrace
                       //        userId - string
                       //        clientAgent type -  string. Recommended: "HTML","App","Electron",...
                       //        clientAgent name -  string. Recommended: various with version
                       //        allSSIEventsTrace - true or false
    TEST:   "test",    //in parms - allSSIEventsTrace
    CLOSED: "closed",  //in parms - code,text from linkCloseCodes
    OPENED: "opened"   //in parms acp - audit parms about location
                       //and call state. Only in answer of test.
}

export const LINK_STATUS = {
    DISCONNECTED:   'disconnected',
    CREATING:       'creating',
    CLOSED:         'closed',
    OPENED:         'opened',
    ERROR:          'error',
}

const SSI_MSGS = {
    //commands:
    LOCATE:             "locate",           //*** after link created - necessarily,
                                            //answers: located, and then groupsAttached with
                                            //groups list, or groupsNotAttached with group list
    ATTACH_GROUPS:      "attachGroups",     //*** after located and not null group list
    ACTIVE_GROUPS:      "activateGroup",    //parms: group - group for calling with PTT
                                            //*** answer with groupsList,located and then -
                                            //groupAttached or groupsNotAttached event
    GET_GROUP_LIST:     "getGroupList",     //any tyme
    PUSH_PTT:           "pushPTT",          //parms: pingIndex (for calculate ping time from first answer)
                                            //*** ptt button pushed
    POP_PTT:            "popPTT",           //*** ptt button poped
    EXIT_BTN:           "exitButton",       //***or "red button", exit,escape,close...
    SPEECH:             "speech",           //parms: ct - codec type
                                            //       sd - speech data (symbols)
}

const SSI_EVENTS = {
                                                    //events:
    TRACE:                  "trace",                //parms: data - trace string for printf
    LOCATED:                "located",              //parms: cell - located cell if loacted in system, answer after locate
                                                    //       request, or any time from system. Then may be groupsAttached or
                                                    //       groupsNotAttached
    REJECTED:               "rejected",             //***locate rejected in system
    UNLOCATED:              "unLocated",            //temporary if some broken, no need command "locate",
                                                    //only wait events "located","rejected"
    GROUPS_ATTACHED:        "groupsAttached",       //ok after locate or "attachGroups"
                                                    //parms: l - groups list
    GROUPS_NOT_ATTACHED:    "groupsNotAttached",    //nok after locate or "attachGroups"
                                                    //parms: l - group list
    GROUP_LIST:             "groupList",            //l:
                                                    // cnt    - groups cnt or 0
                                                    // actual - actual group number, if 0 - absent
                                                    // list{} - list of group numbers (group number - index):
                                                    //           cofu - group scanning and selection parameter
                                                    //           name - name of group
    GROUP_LIST_CHANGED:     "groupListChanged",     //l: as in groupList, relocate needed after this...
    CALL_EVENT:             "callEvent",            // cp - call parms:
                                                    //   callEvent      - call event from callEvents
                                                    //   dir            - direction  from callDir
                                                    //   callType       - type of call from callType
                                                    //   groupSSI       - called,calling group SSI
                                                    //   txSSI          - current granted SSI
                                                    //   txSSIName      - current granted SSI Name
                                                    //   txGrant        - grant from txGrant
                                                    //   pttAllowed      - true, if ptt alowed in call
                                                    //   releaseCause   - release cause from releaseCause
                                                    //   pingIndex
                                                    //speech:
    SPEECH:                 "speech"                //parms: ct - codec type
                                                    //       sd - speech data (symbols)
}

export const CALL_EVENTS = {
    STARTING:           "starting",
    RELEASE:            "release",
    TX_GRANT:           "txGrant",
    TX_INTERRUPT:       "txInterrupt",
    CEASING:            "ceasing"
}

export const TX_GRANT = {
    GRANTED:            0,
    NOT_GRANTED:        1,
    REQUEST_QUEUED:     2,
    TO_ANOTHER_USER:    3
}

export const RELEASE_CAUSE = {
    USER:                   1,
    SERVICE_NOT_AVAILABLE:  8,
    TIMEOUT:                3,
    SYSTEM:                 4,
}

export const COFU =  { //group scanning and selection
    NON_SCANNED_SELECTABLE: 1,
    SCANNED_SELECTABLE:     5,
    SCANNED_NON_SELECTABLE: 7
}

const state = {
    name: null,
    life: null,
    ssi: null,
    connectStatus: CONNECT_STATUS.OFFLINE,
    connectError: null,
    linkStatus: LINK_STATUS.DISCONNECTED,
    linkCreated: false,
    located: false,
    cell: 0,
    rejected: false,
    call: null,
    txGrant: -1,
    groupsAttached: false,
    activeGroup: null,
    groups: {},
    pttPushed: false,
    pingInterval: null,
    echo: 0,
    token: '',
}

const getters = {
    getRadioServer: (state, getters, rootState, rootGetters) =>  {
        return rootGetters[`${USERDATA}/${GET_USER_PARAMS}`].radioServer || ''
    },
    [GET_TETRA_PARAMS]: (state, getters, rootGetters) => {
        const params = rootGetters[USERDATA][GET_USER_PARAMS]
        return params && params.phones && params.phones.tetra
    },
    [GET_TETRA_CONNECTION_PARAMS]: (state) => {
        return {
            name: state.name,
            life: state.life,
            ssi: state.ssi
        }
    },
    [TETRA_IS_TURN_ON]: (state) => {
        return state.connectStatus !== CONNECT_STATUS.OFFLINE
    },
    [GET_TETRA_CONNECT_STATUS]: (state) => {
        return state.connectStatus
    },
    [GET_TETRA_CELL]: (state) => {
        return state.cell
    },
    [GET_TETRA_CONNECT_ERROR]: (state) => {
        return state.connectError
    },
    [GET_TETRA_LINK_STATUS]: (state) => {
        return state.linkStatus
    },
    [GET_TETRA_GROUPS]: (state) => {
        return state.groups
    },
    [GET_TETRA_ACTIVE_GROUP]: (state) => {
        return state.activeGroup && state.groups[state.activeGroup] && {...{gssi: state.activeGroup}, ...state.groups[state.activeGroup]}
    },
    [GET_TETRA_PTT_PUSHED]: (state) => {
        return state.pttPushed
    },
    [GET_TETRA_CALL]: (state) => {
        return (!state.pttPushed && state.call && state.call.callEvent === CALL_EVENTS.RELEASE) ? null : state.call
    },
    [GET_TETRA_ECHO]: (state) => {
        return state.echo
    },
    [GET_TETRA_TOKEN]: (state) => {
        return state.token
    },
}

const actions = {
    [ACT_TETRA_CONNECT]: async ({state, dispatch, commit, getters}, payload = {}) => {
        const life = (new Date()).getTime()
        const connectionData = {...payload, ...{life}}
        let { ssi, name } = connectionData
        try {
            const isConnected = proto && proto.isConnected
            if (!isConnected) {
                if (!proto) proto = radioproto
                const radioServer = getters['getRadioServer']
                await proto.connect(radioServer)
            }
            const { channelId } = payload
            if (channelId !== state.channelId || !state.token) {
                const params = { ssi, name }
                await dispatch(ACT_TETRA_REQUEST_TOKEN, params)
            }
            commit(MUT_TETRA_SET_CONNECT_STATUS, CONNECT_STATUS.CONNECTING)
            const token = state.token
            const status = await proto.tetraRadioConnect({...connectionData, token})
            commit(MUT_TETRA_SET_CLIENT_PARAMS, connectionData)
            if (status !== CONNECT_STATUS.READY) throw status
            commit(MUT_TETRA_SET_CONNECT_STATUS, status)
            dispatch(ACT_TETRA_LINK_CREATE)
        } catch (e) {
            if (e === CONNECT_ERROR.TOKEN_EXPIRED) {
                const params = { ssi, name }
                await dispatch(ACT_TETRA_REQUEST_TOKEN, params)
                dispatch(ACT_TETRA_CONNECT, payload)
            } else {
                commit(MUT_TETRA_SET_CONNECT_STATUS, CONNECT_STATUS.ERROR)
                commit(MUT_TETRA_SET_CONNECT_ERROR, e)
            }
        }
    },
    [ACT_TETRA_DISCONNECT]: async ({state, commit}) => {
        const {name, ssi} = state
        await proto.tetraRadioDisconnect({name, ssi})
        commit(MUT_TETRA_SET_LOCATED, {})
        commit(MUT_TETRA_SET_CLIENT_PARAMS, {})
        commit(MUT_TETRA_SET_CONNECT_STATUS)
        commit(MUT_TETRA_SET_LINK_CREATED)
        commit(MUT_TETRA_SET_GROUPS, {})
        commit(MUT_TETRA_SET_CALL)
    },
    [ACT_TETRA_CONNECT_STATE_TOGGLE]: async ({getters, dispatch}, payload) => {
        if (getters[TETRA_IS_TURN_ON]) dispatch(ACT_TETRA_DISCONNECT)
        else dispatch(ACT_TETRA_CONNECT, payload)
    },
    [ACT_TETRA_GET_STATUS]: async ({state, commit}) => {
        const {name, ssi} = state
        commit(MUT_TETRA_SET_CONNECT_STATUS, await proto.tetraRadioGetStatus({name, ssi}))
    },
    [ACT_TETRA_LINK_CREATE]: async ({state, dispatch, commit, rootGetters}, payload) => {
        commit(MUT_TETRA_SET_LINK_STATUS, LINK_STATUS.CREATING)
        dispatch(ACT_TETRA_START_LINK_TIMER)
        const parms = {
            userId: state.ssi,
            clientAgent: {
                type: rootGetters[`${CLIENTDATA}/${GET_IS_ELECTRON}`] ? 'Electron' : 'HTML',
                name: (rootGetters[`${CLIENTDATA}/${GET_IS_ELECTRON}`] ? '' : 'RosChat ') + rootGetters[`${CLIENTDATA}/${GET_CLIENT_VERSION}`]
            }
        }
        sendLinkCmd({state, msgType: LINK_MSGS.CREATE, parms})
    },
    [ACT_TETRA_LINK_PING]: async ({state, commit}, payload) => {
        sendLinkCmd({state, msgType: LINK_MSGS.TEST})
    },
    [ACT_TETRA_START_LINK_TIMER]: async ({state, dispatch}) => {
        if (state.pingInterval) clearInterval(state.pingInterval)
        if (state.connectStatus !== CONNECT_STATUS.READY) return
        state.pingInterval = setInterval(() => {
            switch (state.linkStatus) {
                case LINK_STATUS.OPENED:
                    dispatch(ACT_TETRA_LINK_PING)
                    break
                case LINK_STATUS.CLOSED:
                case LINK_STATUS.ERROR:
                    dispatch(ACT_TETRA_LINK_CREATE)
            }
        },10000)
    },
    [ACT_TETRA_STOP_LINK_TIMER]: async ({state}) => {
        clearInterval(state.pingInterval)
    },
    [ACT_TETRA_LOCATE]: async ({state, commit}, payload) => {
        sendSSICmd({state, msgType: SSI_MSGS.LOCATE})
    },
    [ACT_TETRA_GET_ATTACH_GROUPS]: async ({state, commit}, payload) => {
        sendSSICmd({state, msgType: SSI_MSGS.ATTACH_GROUPS})
    },
    [ACT_TETRA_GET_GROUPS_LIST]: async ({state, commit}, payload) => {
        sendSSICmd({state, msgType: SSI_MSGS.GET_GROUP_LIST})
    },
    [ACT_TETRA_ACTIVATE_GROUP]: async ({state, commit}, payload) => {
        sendSSICmd({state, msgType: SSI_MSGS.ACTIVE_GROUPS, parms: payload})
    },
    [ACT_TETRA_PUSH_PTT]: async ({state, commit}) => {
        commit(MUT_TETRA_SET_PTT_STATUS, true)
        sendSSICmd({state, msgType: SSI_MSGS.PUSH_PTT})
    },
    [ACT_TETRA_POP_PTT]: async ({state, commit}) => {
        commit(MUT_TETRA_SET_PTT_STATUS, false)
        sendSSICmd({state, msgType: SSI_MSGS.POP_PTT})
    },
    [ACT_TETRA_EXIT_BTN]: async ({state, commit}, payload) => {
        sendSSICmd({state, msgType: SSI_MSGS.EXIT_BTN})
    },
    [ACT_TETRA_SEND_SPEECH]: async ({state, commit}, payload) => {
        sendSSICmd({state, msgType: SSI_MSGS.SPEECH, parms: payload})
    },
    [ACT_TETRA_SET_ECHO]: async ({state, commit}, payload) => {
        state.echo = Math.random()
    },
    [ACT_TETRA_HANDLE_STATUS_EVENT]: async ({state, dispatch, commit, getters}, {status}) => {
        switch (status) {
            case STATUS.READY:
                if (state.connectStatus === status) return
                commit(MUT_TETRA_SET_CONNECT_STATUS, CONNECT_STATUS.READY)
                dispatch(ACT_TETRA_LINK_CREATE)
                break
            case STATUS.DROPPED:
                commit(MUT_TETRA_SET_CONNECT_STATUS, CONNECT_STATUS.OFFLINE)
            case STATUS.DISCONNECTED:
                if (status === STATUS.DISCONNECTED) {
                    commit(MUT_TETRA_SET_CONNECT_STATUS, CONNECT_STATUS.ERROR)
                    commit(MUT_TETRA_SET_CONNECT_ERROR, status)
                }
                commit(MUT_TETRA_SET_LINK_CREATED)
                commit(MUT_TETRA_SET_LINK_STATUS)
                commit(MUT_TETRA_SET_GROUPS, {})
                commit(MUT_TETRA_SET_CALL)
                break
        }
    },
    [ACT_TETRA_HANDLE_MSG_EVENT]: async ({state, dispatch, commit}, {a, level, msgType, parms}) => {
        switch (level) {
            case MSG_LEVELS.LINK:
                if (state.linkStatus !== msgType) commit(MUT_TETRA_SET_LINK_STATUS, msgType)
                switch (msgType) {
                    case LINK_MSGS.OPENED:
                        const {lp, isCall} = parms.acp || {}
                        const { located, cell } = lp || {}
                        if (located) commit(MUT_TETRA_SET_LOCATED, {state: located, cell})
                        else dispatch(ACT_TETRA_LOCATE)
                        break
                    case LINK_MSGS.CLOSED:
                        break
                }
                break
            case MSG_LEVELS.SSI:
                if (msgType !== SSI_EVENTS.SPEECH) console.log(JSON.stringify({a, level, msgType, parms}))
                switch (msgType) {
                    case SSI_EVENTS.TRACE:
                        console.log(parms.data)
                        break
                    case SSI_EVENTS.LOCATED:
                        commit(MUT_TETRA_SET_LOCATED, {state: true, cell: parms.cell})
                        break
                    case SSI_EVENTS.REJECTED:
                        commit(MUT_TETRA_SET_REJECTED, true)
                        break
                    case SSI_EVENTS.UNLOCATED:
                        if (state.located && !state.rejected) commit(MUT_TETRA_SET_LOCATED, {state: false})
                        break
                    case SSI_EVENTS.GROUPS_ATTACHED:
                        commit(MUT_TETRA_SET_GROUPS_ATTACHED, true)
                        commit(MUT_TETRA_SET_GROUPS, parms.l)
                        break
                    case SSI_EVENTS.GROUPS_NOT_ATTACHED:
                        commit(MUT_TETRA_SET_GROUPS_ATTACHED)
                        break
                    case SSI_EVENTS.GROUP_LIST:
                        commit(MUT_TETRA_SET_GROUPS, parms.l)
                        break
                    case SSI_EVENTS.GROUP_LIST_CHANGED:
                        if (state.located) dispatch(ACT_TETRA_LOCATE)
                        commit(MUT_TETRA_SET_LOCATED, {state: false})
                        commit(MUT_TETRA_SET_GROUPS, parms.l)
                        break
                    case SSI_EVENTS.CALL_EVENT:
                        const { cp } = parms
                        const {dir, callEvent} = cp || {}
                        if (dir && callEvent) commit(MUT_TETRA_SET_CALL, parms.cp)
                        else commit(MUT_TETRA_SET_CALL, null)
                        break
                    case SSI_EVENTS.SPEECH:
                        const {ct: codec, sd: speech} = parms
                        event_bus.$emit('radio-speech', {ssi: a.ssi, codec, speech})
                        break
                }
                break
            case MSG_LEVELS.READY:
                break
        }
    },
    [ACT_TETRA_REQUEST_TOKEN]: async ({commit}, params) => {
        try {
            const replyObj = await mainproto.radioGetToken({type: 'tetra', params})
            const isToken = replyObj.hasOwnProperty('token')
            const token = isToken ? replyObj.token : ''
            commit(MUT_TETRA_SET_TOKEN, token) }
        catch {}
    }
}

const mutations = {
    [MUT_TETRA_SET_CLIENT_PARAMS]: (state, {name = null, ssi = null, life = null}) => {
        state.name = name
        state.ssi = ssi
        state.life = life
    },
    [MUT_TETRA_SET_CONNECT_STATUS]: (state, status = CONNECT_STATUS.OFFLINE) => {
        state.connectStatus = status
    },
    [MUT_TETRA_SET_CONNECT_ERROR]: (state, error = null) => {
        state.connectError = error
    },
    [MUT_TETRA_SET_LINK_STATUS]: (state, status = LINK_STATUS.CLOSED) => {
        state.linkStatus = status
    },
    [MUT_TETRA_SET_LOCATED]: (state, {status = false, cell = 0}) => {
        state.located = status
        state.rejected = false
        state.groupsAttached = false
        state.cell = cell
    },
    [MUT_TETRA_SET_REJECTED]: (state, rejected = false) => {
        state.rejected = rejected
        if (rejected) {
            state.located = false
            state.groupsAttached = false
            state.cell = 0
        }
    },
    [MUT_TETRA_SET_GROUPS_ATTACHED]: (state, groupsAttached = false) => {
        if (groupsAttached && !state.located) return
        state.groupsAttached = groupsAttached
    },
    [MUT_TETRA_SET_CALL]: (state, call = null) => {
        state.call = call
    },
    [MUT_TETRA_SET_TX_GRANT]: (state, txGrant = -1) => {
        state.txGrant = txGrant
    },
    [MUT_TETRA_SET_GROUPS]: (state, {actual = null, list = {}}) => {
        state.groups = list
        state.activeGroup = actual
    },
    [MUT_TETRA_SET_ACTIVE_GROUP]: (state, group) => {
        state.activeGroup = group
    },
    [MUT_TETRA_SET_PTT_STATUS]: (state, status) => {
        state.pttPushed = status
    },
    [MUT_TETRA_SET_TOKEN]: (state, token) => {
        state.token = token
    },
}

function sendLinkCmd (payload) {
    return sendCmd({...payload, ...{level: MSG_LEVELS.LINK}})
}

function sendSSICmd (payload) {
    return sendCmd({...payload, ...{level: MSG_LEVELS.SSI}})
}

function sendCmd ({state, level, msgType, parms = {}}) {
    const {name, ssi, life} = state
    const cmd = {
        a: {system: name, ssi, life},
        level,
        msgType,
        parms
    }
    return proto.tetraRadioSendCmd({name, ssi, cmd})
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
