//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import { GET_ACS_NAME, GET_MAIN_TYPE, GET_MAIN_TYPE_CAN_BACK } from '../../../store/gettersTypes'

import { CONTENT_MANAGER, USERDATA } from '../../../store/modulesNames'
import MainHeaderChannel from './MainHeaderChannel.vue'
import MainHeaderContact from './MainHeaderContact.vue'
import MainHeaderGroup from './MainHeaderGroup.vue'
import MainHeaderNote from './MainHeaderNote.vue'
import MainHeaderTitleOnly from './MainHeaderTitleOnly.vue'

import { MAIN_TYPES } from '../../../store/modules/content-manager'
import { ACT_MAIN_TYPE_BACK } from '../../../store/actionsTypes'


export default {
    name: 'MainHeader',
    data: () => {
        return {
            component: null,
            componentProps: {}
        }
    },
    computed: {
        canBack() {
            return this[GET_MAIN_TYPE_CAN_BACK]
        },
        ...mapGetters(CONTENT_MANAGER, [GET_MAIN_TYPE, GET_MAIN_TYPE_CAN_BACK]),
        ...mapGetters(USERDATA, [GET_ACS_NAME]),
    },
    watch: {
        [GET_MAIN_TYPE](new_val) {
            this.componentProps = {}
            switch (new_val) {
                case MAIN_TYPES.CONTACT:
                    this.component = MainHeaderContact
                    break
                case MAIN_TYPES.CHAT:
                    this.component = MainHeaderGroup
                    break
                case MAIN_TYPES.NOTE:
                    this.component = MainHeaderNote
                    break
                case MAIN_TYPES.CHANNEL:
                    this.component = MainHeaderChannel
                    break
                case MAIN_TYPES.ACS:
                    this.componentProps = { title: this[GET_ACS_NAME] }
                    this.component = MainHeaderTitleOnly
                    break
                case MAIN_TYPES.SETTINGS:
                    this.componentProps = { title: this.$t('mainPage.settings') }
                    this.component = MainHeaderTitleOnly
                    break
                case MAIN_TYPES.PROFILE:
                    this.componentProps = { title: this.$t('mainPage.profile') }
                    this.component = MainHeaderTitleOnly
                    break
                case MAIN_TYPES.SESSIONS:
                    this.componentProps = { title: this.$t('mainPage.sessions') }
                    this.component = MainHeaderTitleOnly
                    break
                case MAIN_TYPES.ABOUT:
                    this.componentProps = { title: this.$t('mainPage.about') }
                    this.component = MainHeaderTitleOnly
                    break
                case MAIN_TYPES.SUPPORT:
                    this.componentProps = { title: this.$t('mainPage.support') }
                    this.component = MainHeaderTitleOnly
                    break
                case MAIN_TYPES.DECORATION:
                    this.componentProps = { title: this.$t('settings.theme') }
                    this.component = MainHeaderTitleOnly
                    break
                case MAIN_TYPES.HOTKEYS:
                    this.componentProps = { title: this.$t('settings.hotkeys') }
                    this.component = MainHeaderTitleOnly
                    break
                case MAIN_TYPES.INTEGRATIONS:
                    this.componentProps = { title: this.$t('settings.integrations') }
                    this.component = MainHeaderTitleOnly
                    break
                case MAIN_TYPES.MEETINGCALENDAR:
                    this.componentProps = { title: this.$t('integrations.calendar') }
                    this.component = MainHeaderTitleOnly
                    break                    
                default:
                    this.component = null
            }
        }
    },
    methods: {
        goBack() {
            this[ACT_MAIN_TYPE_BACK]()
        },
        ...mapActions(CONTENT_MANAGER, [ACT_MAIN_TYPE_BACK])
    }
}
