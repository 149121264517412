var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "media-search-links",
      attrs: { id: "media-search-area" },
      on: { scroll: _vm.onScroll },
    },
    _vm._l(Object.keys(_vm.messages), function (key) {
      return _c("div", { staticClass: "months-group" }, [
        _c("span", { staticClass: "months-name" }, [_vm._v(_vm._s(key))]),
        _c(
          "div",
          { staticClass: "links-wrapper" },
          _vm._l(_vm.messages[key], function (item) {
            return _c(
              "custom-list-item",
              {
                key: item.id,
                staticClass: "list-item-custom",
                attrs: {
                  topAccountId: _vm.getFio(item.senderId),
                  header: _vm.makeHeader(item),
                  title: _vm.makeTitle(item.msg),
                  footer: item.date,
                  link: false,
                  chevron: false,
                  dynamicHeight: true,
                  htmlTitle: true,
                },
                on: {
                  linkcontextmenu: function ($event) {
                    return _vm.openLinkContextMenu($event)
                  },
                },
                nativeOn: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.scrollToMessage($event, item.id)
                  },
                  contextmenu: function ($event) {
                    $event.preventDefault()
                    return _vm.onContextMenu($event, item)
                  },
                },
              },
              [
                _c(
                  "template",
                  { slot: "media" },
                  [
                    _c("icon-image", {
                      attrs: {
                        src: _vm.getPhoto(item.senderId),
                        text: _vm.getFio(item.senderId),
                      },
                    }),
                  ],
                  1
                ),
              ],
              2
            )
          }),
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }