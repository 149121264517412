//
//
//
//
//
//
//
//
//
//
//
//

"use strict";

import ChatImageMessage from "../chat/ChatImageMessage.vue"
import PublicationImageItem from "../channels/PublicationImageItem.vue"

export default {
    name: "media-search-images",
    props: { 
        messages: {
            type: Object,
        }, 
        isChannel: {
            type: Boolean,
            default: false
        }
    },
    components: {
        ChatImageMessage,
        PublicationImageItem,
    },
    data() {
        return {};
    },
    computed: {
        style() {
            const size = "110px"
            const previewSize = "108px"
            const styles = {}
            Object.assign(styles, {
                "--image-width": `${size}`,
                "--image-height": `${size}`,
                "--preview-block-max-width": `${previewSize}`,
                "--image-max-width": "100%",
                "--image-max-height": `${size}`
            })
            return styles
        },
    },
    methods: {
        onContextMenu(e, message) {
            this.$emit("mediasearch-common-contextmenu", { e, message });
        },
    },
    // watch: {
    //     messages(val) {
    //         console.log(
    //             "!! -> file: MediaSearchImages.vue -> line 43 -> messages -> val",
    //             val
    //         );
    //     }
    // }
};
