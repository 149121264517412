//
//
//
//
//
//
//
//

import ModalDialog from './ModalDialog.vue';
import modal_mixin from './modal-mixin';
import { i18n } from '../../../ext/i18n'

export default {
    name: "Alert",
    mixins: [modal_mixin],
    components: {ModalDialog},
    data() {
        return {
            compModalProps: {
                delay: 200,
                pivotY: 0.05,
                width: 450,
                height: 'auto'
            }
        }
    },
    computed: {
        title() { return this.props.title || i18n.messages[i18n.locale].modal.attention },
        text() { return this.props.text || '' },
        okText() { return this.props.btnOk && this.props.btnOk.text || i18n.messages[i18n.locale].loginPage.ok },
    },
    methods: {
        onOk() {
            this.modalClose();
            this.$nextTick(() => this.props.btnOk && this.props.btnOk.cb && this.props.btnOk.cb())

        },
        onCancel() {
            this.modalClose();
            this.props.onClose && this.props.onClose();
        }
    }
}
