//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

"use strict";
import { mapGetters } from "../../vuex"
import {CONTACTS, LOGIN} from '../store/modulesNames'
import { GET_SERVER_API, GET_IS_ROLES_SUPPORTED } from "../store/gettersTypes"

import IconImage from "./IconImage.vue";
import { i18n } from "../../ext/i18n";
import {ACT_UPDATE_SEARCHED_CONTACT} from '../store/actionsTypes'

export default {
	name: "birthdays",
	components: {
		"icon-image": IconImage,
	},
	props: ["contact_id"],
	computed: {
		text() {
			return i18n.messages[i18n.locale].birthdays;
		},
		isRolesModelSupported() {
			return this[GET_IS_ROLES_SUPPORTED]
		},
        birthdays() {
            //let testBirthDays = this.$store.getters["contacts/getBirthdays"]
            //console.log("🚀 ~ file: Birthdays.vue:38 ~ birthdays ~ testBirthDays", testBirthDays)
            return this.$store.getters["contacts/getBirthdays"]
        },
		...mapGetters(LOGIN, [GET_SERVER_API]),
        ...mapGetters(CONTACTS, [GET_IS_ROLES_SUPPORTED]),
	},
	data() {
		return {
			birthdaysList: null,
			opened: true,
			shrink: false,
			grow: true,
			photo: "",
		};
	},
	methods: {
		doWatched() {
			this.$store.commit("contacts/setBirthdaysWatched");
		},
		async doOnSelected(cid) {
            this.$store.dispatch("contacts/selected", cid);
		},
	},
};
