//
//
//
//
//
//
//
//
//
//

export default {
    name: "CustomTabs",
    props:  {
        tabItems: {
            type: Array,
            required: true,
            default: () => []
        },
        defaultTabValue: {
            type: Number,
            required: false,
            default: 0
        },
        railBottomBorder: {
            type: Boolean, 
            default: false
        }
    },
    data() {
        return {
            chosenTab: this.defaultTabValue,
        }
    },
    computed: {
        tabsCss() {
            const tabsCss = {}
            const tabWidth = (340 / this.tabItems.length) + "px"
            Object.assign(tabsCss, {
                "--tab-width": tabWidth,
            })

            if (this.railBottomBorder) {
                Object.assign(tabsCss, {
                    "--tabs-border": "none",
                    "--tabs-border-radius": "0px"
                })
            } else {
                Object.assign(tabsCss, {
                    "--tabs-border": "1px solid",
                    "--tabs-border-radius": "46px"
                })
            }
            return tabsCss
        },
        railCss() {
            const railCss = {}
            const tabWidth = (340 / this.tabItems.length) + "px"
            Object.assign(railCss, {
                "--rail-width": tabWidth,
            })
            
            if (this.tabItems.length > 4) {
                Object.assign(railCss, {
                    "--marginLeft0": "3px",
                    "--marginLeft1": "69px",
                    "--marginLeft2": "136px",
                    "--marginLeft3": "202px",
                    "--marginLeft4": "269px",
                })
            } else {
                Object.assign(railCss, {
                    "--marginLeft0": "9px",
                    "--marginLeft1": "93px",
                    "--marginLeft2": "176px",
                    "--marginLeft3": "261px",
                })
            }

            return railCss
        },
    },
    methods: {
        tabClick(tab) {
            this.chosenTab = tab.key
            this.$emit('tabClick', tab.key)
        },
        tabName(key) {
            return this.tabItems.find(t => t.key === key).value
        }
    },
}
