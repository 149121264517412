//
//
//
//
//
//
//
//

import {mapGetters} from 'vuex';
import {INFO} from "../../store/modulesNames";
import {GET_INFO_CAN_BACK, GET_INFO_HISTORY_LENGTH, GET_INFO_LAST_ACTION} from "../../store/gettersTypes";
import {ACT_INFO_REPLACE} from "../../store/actionsTypes";
import InfoBody from './InfoBody.vue';
import InfoHeader from './InfoHeader.vue';

export default {
    name: "InfoWrapper",
    components: {InfoBody, InfoHeader},
    props: {
        currentInfo: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            transitionName: 'none',
            key: 1
        }
    },
    computed: {
        ...mapGetters(INFO, {canBack: GET_INFO_CAN_BACK, historyLength: GET_INFO_HISTORY_LENGTH, lastAction: GET_INFO_LAST_ACTION})
    },
    watch: {
        historyLength(newVal, oldVal) {
            if (!oldVal || !newVal || this.lastAction === ACT_INFO_REPLACE) this.transitionName = 'none'
            else if (newVal > oldVal) this.transitionName = 'info-switch-new'
            else this.transitionName = 'info-switch-back'
            if (this.transitionName !== 'none') this.key = Math.random()
        }
    }
}
