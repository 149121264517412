//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import draggable from 'vuedraggable';
import ContactsListItem from '../listItems/ContactsListItem.vue';
import {mapGetters, mapActions} from 'vuex';
import {CONTACTS} from '../../store/modulesNames';
import {ACT_CHANGE_FAVOURITES_MODE, ACT_SET_FAVOURITES, ACT_PUSH_FAVOURITES} from '../../store/actionsTypes';
import {GET_FAVOURITES, CHANGE_FAVOURITES_MODE_P} from '../../store/gettersTypes';
import SelectContactsToFavorites from '../modal/SelectContactsToFavorites.vue'
import { i18n } from '../../../ext/i18n';
export default { //@todo не используется
    name: 'change-favourites',
    components: {ContactsListItem, draggable},
    display: 'Transition',
    props: {},
    data() {
        return {
            isDragging: false
        }
    },
    computed: {
        ...mapGetters(CONTACTS, [CHANGE_FAVOURITES_MODE_P]),
        favourites: {
            get() {return this.$store.getters[`${CONTACTS}/${GET_FAVOURITES}`]},
            set(list) {
                let cids = [];
                for (let i in list) cids.push(list[i].cid);
                this.$store.dispatch(`${CONTACTS}/${ACT_SET_FAVOURITES}`, cids);
            }
        },
        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    },
    methods: {
        ...mapActions(CONTACTS, [ACT_CHANGE_FAVOURITES_MODE, ACT_PUSH_FAVOURITES]),
        addFavourite() {
            this.modalOpen({
                component: SelectContactsToFavorites
            })
        },
        removeFavourite(contact) {
            const index = this.favourites.indexOf(contact);
            const favourites = this.favourites;
            favourites.splice(index, 1);
            this.favourites = favourites;
        },
        doContextMenu(event, cid, position="bottom-right") {
            let locale = i18n.messages[i18n.locale]
            const handlers = [];
            handlers.push({
                item_name: locale['contacts-comp']['del-from-fav'],
                handler: () => {this.removeFavourite(cid)}
            });
            this.cmOpen(event, handlers, position);
        }
    }
}
