//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

'use strict';

import event_bus from '../../eventBus';
import RangeSlider from 'vue-range-slider'
import { USERDATA } from "../../store/modulesNames.js";
import { GET_ACTIVE_MICROPHONE, GET_VOLUME_MICROPHONE } from "../../store/gettersTypes.js";
import { ACT_ACTIVE_MICROPHONE, ACT_VOLUME_MICROPHONE } from "../../store/actionsTypes.js";
import CustomDropDown from "../custom/CustomDropDown.vue"
import { i18n } from '../../../ext/i18n';
const locale = i18n.messages[i18n.locale];
import { getAudioContext } from "../../common/Audio"

export default {
    name: 'microphones-settings',
    props:['microphones'],
    components: {
        RangeSlider,
        CustomDropDown
    },
    data() {
        return {
            activeMicrophone: null,
            activeMicrophoneName: '',
            elements: 20,
            activeElements: 0,
            sliderValue: 100,
            sliderValueTimer: null,
            sliderDisabled: false,
            visualizerTimer: null,
            stream: null,
            streamNode: null,
            audioCtx: null,
            processorNode: null,
            analyserNode: null,
            gainNode: null,
            gain: 0.9,
        }
    },
    mounted() {
        this.setOrRestoreValues();
    },
    computed: {
        placeholder() {
            let placeHolder = locale["choose-microphone"];
            if (!!this.microphones && this.microphones.length > 0 && this.activeMicrophoneName.length) {
                placeHolder = '';
            } else if (!!this.microphones && this.microphones.length === 0) {
                placeHolder = locale["no-microphones-found"];
            }
            return placeHolder;
        },
        microphonesList() {
            let microphonesArr = [], selectedItem
            this.microphones.map((mic, index) => {
                let arrItem = { value: mic.label, name: mic.label, selected: false };
                if (index === 0) {
                    arrItem.selected = true
                    selectedItem = arrItem
                }
                if (index > 0 && arrItem.name === this.activeMicrophoneName) {
                    microphonesArr[0].selected = false
                    arrItem.selected = true
                    selectedItem = arrItem
                }
                microphonesArr.push(arrItem);
            });
            return microphonesArr;
        }, 
        selectedMic() {
            return this.activeMicrophoneName ? { value: this.activeMicrophoneName, name: this.activeMicrophoneName } : { value: '', name: '' }
        }
    },
    watch: {
        activeMicrophoneName(val) {
            if (val) {
                this.microphones.forEach(item => {
                    if (item.label === val) {
                        this.$store.dispatch(`${USERDATA}/${ACT_ACTIVE_MICROPHONE}`, item);
                        this.startStream(item.deviceId);
                        event_bus.$emit('change-input-audio-device', item);
                    }
                })
            } else this.sliderDisabled = true;
        },
        sliderValue() {
            this.changeVolume();
            if (this.sliderValueTimer) clearTimeout(this.sliderValueTimer);
            this.sliderValueTimer = setTimeout(() => {
                this.$store.dispatch(`${USERDATA}/${ACT_VOLUME_MICROPHONE}`, this.sliderValue);
                clearTimeout(this.sliderValueTimer);
            }, 500)
        },
    },
    methods: {
        setActiveMic(val) {
            this.activeMicrophoneName = val
        },
        setOrRestoreValues() {
            if (!this.microphones || this.microphones.length === 0) {
                this.$store.dispatch(`${USERDATA}/${ACT_ACTIVE_MICROPHONE}`, null);
                this.sliderDisabled = true;
                return this.activeMicrophoneName = '';
            }
            this.activeMicrophone = this.$store.getters[`${USERDATA}/${GET_ACTIVE_MICROPHONE}`];
            this.sliderValue = this.$store.getters[`${USERDATA}/${GET_VOLUME_MICROPHONE}`];
            this.sliderDisabled = false;
            if (this.activeMicrophone && this.activeMicrophone.label)
                this.activeMicrophoneName = this.activeMicrophone.label;
            else if (this.microphones && this.microphones[0])
                this.activeMicrophoneName = this.microphones[0].label;
        },
        changeVolume() {
            this.gain = this.sliderValue / 100;
        },
        async startStream(deviceId) {
            let constraints = {
                audio: true,
                video: false
            };
            if (deviceId.toLowerCase() === 'default')
                constraints.audio = {
                    deviceId: deviceId ? {
                        exact: deviceId
                    } : undefined
                }
            try {
                this.stream = await navigator.mediaDevices.getUserMedia(constraints);
            } catch(error) {
                const errorMessage = 'navigator.MediaDevices.getUserMedia error: ' + error.message + ' ' + error.name;
                console.log(errorMessage);
                return;
            }
            clearInterval(this.visualizerTimer);
            let ctx = getAudioContext()
            this.streamNode = ctx.createMediaStreamSource(this.stream)
            this.analyserNode = ctx.createAnalyser()
            this.processorNode = ctx.createScriptProcessor(256, 1, 1)
            this.gainNode = ctx.createGain()

            this.streamNode.connect(this.analyserNode);
            this.streamNode.connect(this.processorNode);
            this.gainNode.connect(this.processorNode);
            this.processorNode.connect(ctx.destination);

            this.gainNode.gain.value = this.gain;

            let frequencyData = new Uint8Array(this.analyserNode.frequencyBinCount);
            this.visualizerTimer = setInterval(() => {
                this.activeElements = Math.ceil(this.gain*frequencyData.reduce((sum, item) => sum + item, 0) / frequencyData.length);
            }, 50);

            this.processorNode.onaudioprocess = () => { this.analyserNode.getByteFrequencyData(frequencyData); }
        },
        stopStream() {
            if (this.stream) {
                this.gainNode.disconnect()
                this.analyserNode.disconnect()
                this.processorNode.disconnect()
                this.streamNode.disconnect()
                this.stream.getTracks().forEach(track => track.stop())
            }
        }
    },
    beforeDestroy() {
        clearTimeout(this.sliderValueTimer);
        clearInterval(this.visualizerTimer);
        this.stopStream();
    }
}
