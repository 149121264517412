//
//
//
//
//
//

import videomostConf from "../videoConfNew/videomostConf.vue"

import { CONF_TYPES } from '../../constants'

export default {
    name: 'ConfContainer',
    // props: ['call', 'showPhone'],
    props: {
        call: {
            type: Object,
            required: true
        },
        showPhone: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        component() {
            if (!this.call) return
            switch (this.call.type) {
                case CONF_TYPES.VM:
                    return videomostConf

            }
        },
    },
}
