//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex'
import IconImage from '../IconImage.vue'
import {
    CONTENT_MANAGER,
    BOTS,
    CHATS,
    CHAT,
    INFO,
    MODAL,
    USERDATA,
    CONTACTS,
} from '../../store/modulesNames'
import {
    GET_MAIN_TYPE,
    GET_BOT_BY_ID,
    GET_SELECTED_CHAT,
    GET_IS_CHAT_OPENED,
    GET_CHAT_DRAFT,
    GET_CHAT_TYPINGS,
    GET_UID,
    GET_CHAT_IS_SYSTEM_MESSAGE,
    IS_CHAT_PINNED,
    GET_CHAT_ITEM_TEXT,
    GET_CONTACT_BY_ID,
} from '../../store/gettersTypes'
import {
    ACT_INFO_PUSH,
    ACT_OPEN_CHAT,
    ACT_MODAL_OPEN,
    ACT_WATCH_MESSAGES,
    ACT_SET_CHAT_MUTED,
    ACT_LEAVE_FROM_CHAT,
    ACT_SET_CHAT_MARKED,
    ACT_SET_CHAT_PINED,
    ACT_ADD_BOT_CONTACT,
} from '../../store/actionsTypes'
import ListItemMixin from './ListItemMixin'
import TypingStatus from './TypingStatus.vue'

import LoaderFiles from '../../components/LoaderFiles.vue'
import ChatItemRightBlock from './ChatItemRightBlock.vue'

export default {
    name: 'chat-item',
    mixins: [ListItemMixin],
    components: {
        IconImage,
        TypingStatus,
        ChatItemRightBlock,
    },
    props: ['chat', 'focused'],
    data() {
        return {
            showTitle: false,
        }
    },
    computed: {
        cid() {
            return this.chat.cid
        },
        cidType() {
            return this.chat.cidType
        },
        getChat() { return this.$store.getters['chats/getChat']({cid: this.cid, cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP}) || {} },
        isChatMember() {
            if (!this.getChat) return null
            return this.getChat.contacts && !!this.getChat.contacts.find((member) => member.cid === this[GET_UID])
        },
        isNote() {
            return this.cid === this[GET_UID]
        },
        chatTitle() {
            let title = this.showTitle ? this.chat.name : ''
            if (this.isBot) title = this.bot.fio ? this.bot.fio : this.bot.title
            return title
        },
        bot() {
            let bot = this.$store.getters[`${BOTS}/${GET_BOT_BY_ID}`](this.cid)
            const contact = this.$store.getters[`${CONTACTS}/${GET_CONTACT_BY_ID}`](this.cid)
            const isBot = contact && contact.isBot
            if (!bot && isBot) bot = contact
            const isBotExit = this.$store.getters[`${BOTS}/${GET_BOT_BY_ID}`](this.cid)
            if (isBot && !isBotExit) {
                this.$store.dispatch(`${CONTACTS}/${ACT_ADD_BOT_CONTACT}`, contact)
            }
            return bot
        },
        isBot() {
            return !!this.bot
        },
        user() {
            const bot = this.bot
            const isBot = this.isBot
            if (isBot) return { photo: bot.photo, fio: bot.fio }
            if (this.cid === 0) {
                let result = this.$store.getters['chats/getChat']({cid: this.cid, cidType: 'user'})
                return {photo: result.photo, fio: this.$t('roschat-caps')}
            }
            if (this.isNote) {
            return { photo: this.noteImg, fio: this.$t('notes-chat-name') }
            }
            if (this.cid && this.cidType === 'user') return this.$store.getters['contacts/getMergedContactById'](this.cid)
            else {
                let result = this.$store.getters['chats/getChat']({cid: this.cid, cidType: 'group'})
                if (result) {
                    result.fio = result.name
                    return result
                } else return {photo: '', fio: ''}
            }
        },
        noteImg() {
            const isDarkTheme = this.$store.getters['clientdata/getTheme'] === 'dark'
            return isDarkTheme ? 'img/notes_dark.png' : 'img/note.png'
        },
        typing() {
            let { cid, cidType } = this.chat
            return Boolean(this[GET_CHAT_TYPINGS]({cid, cidType}).length)
        },
        draft() {
            let { cidType, cid } = this.chat
            return this[GET_CHAT_DRAFT]({cidType, cid})
        },
        isSystemDataType() {
            let { cid, cidType } = this.chat
            return this[GET_CHAT_IS_SYSTEM_MESSAGE]({ cid, cidType })
        },
        isOpened() {
            return this.chat && this[GET_IS_CHAT_OPENED](this.chat)
        },
        isSelected() {
            return (!this.noSelection && this.isOpened)
        },
        text() {
            let returnedText = ''
            if (this.draft) {
                returnedText = this.draft.text || ''
                returnedText = returnedText.replace(/</g,'\x7F').replace(/>/g,'\x8F')
            } else {
                try {
                    let refs = this.chat && this.chat.text ? this.lookForRefs(this.chat.text) : ''
                    if (refs) returnedText = refs
                    else returnedText = this.chat.lastMessage && this.getTextMsg(this.chat.lastMessage)
                    returnedText = returnedText.replace(/</g,'\x7F').replace(/>/g,'\x8F')
                } catch(e) {
                    console.log(e)
                    console.log('!! -> file: ChatItem.vue -> text -> this.chat.text', this.chat.text)
                }
            }
            returnedText = this.getFormattedText(returnedText)
            returnedText = returnedText.replace(/\x7F/g,'&lt;').replace(/\x8F/g,'&gt;')
            return returnedText
        },
        isSystemMessageClass() {
            return this.isSystemDataType || this.isSystemLikeMessage ? 'systemMessage' : ''
        },
        isSystemLikeMessage() {
            switch (this.chat.text) {
                case this.$t('modal.sent-contact'):
                case this.$t('modal.sent-channel'):
                case this.$t('modal.sent-post'):
                case this.$t('modal.sent-geo'):
                case this.$t('modal.sent-img'):
                case this.$t('modal.sent-video'):
                case this.$t('modal.sent-audio'):
                case this.$t('modal.sent-file'):
                case this.$t('modal.sent-poll'):
                case this.$t('modal.sent-call-availability'):
                case this.$t('chat.survey'):
                case this.$t('chat.schedule'):
                    return true
                default: 
                    return false
                
            }
        },
        isPinned() {
            return this[IS_CHAT_PINNED]({ cid: this.cid, cidType: this.cidType })
        },
        ...mapGetters(USERDATA, [GET_UID]),
        ...mapGetters(CONTENT_MANAGER, [GET_MAIN_TYPE]),
        ...mapGetters(CHATS, [GET_SELECTED_CHAT, GET_IS_CHAT_OPENED, GET_CHAT_DRAFT, GET_CHAT_TYPINGS, GET_CHAT_IS_SYSTEM_MESSAGE, IS_CHAT_PINNED]),
        ...mapGetters(CHAT, [GET_CHAT_ITEM_TEXT])
    },
    methods: {
        getFormattedText(text) {
            let { outText, entities} = this.extractInputTextFormat(text)
            let formattedText = outText.replace(/</g,'\x7F').replace(/>/g,'\x8F')
            formattedText = this.messageTextFormat(entities, outText)
            formattedText = formattedText.replace(/\x7F/g,'&lt;').replace(/\x8F/g,'&gt;')
            return this.wrapEmoji(formattedText)
        },
        doSelectChat(cidType, cid) {
            this.$store.dispatch(`${CHAT}/${ACT_OPEN_CHAT}`, {cid, cidType})
        },
        doMute() {
            this.modalOpen({
                name: 'chat-mute',
                props: {
                    cid: this.cid,
                    cidType: this.cidType
                }
            })
        },
        doUnMute() {
            this[ACT_SET_CHAT_MUTED]({ cidType: this.cidType, cid: this.cid})
        },
        watchChat() {
            const cid = this.cid
            const cidType = this.cidType
            const messages = this.$store.getters['chats/getUnwatched']({cid, cidType})
            this.chat.unwatched = null
            this[ACT_SET_CHAT_MARKED]({cid: this.cid, cidType: this.cidType, marked: false})
            this.$store.dispatch(`${CHATS}/${ACT_WATCH_MESSAGES}`, {messages, cid, cidType})
        },
        mouseOver(e) {
            const fioEl = e.target.childNodes[1] && e.target.childNodes[1].firstChild
            if (!fioEl) return
            const fioElW = fioEl.offsetWidth
            const spanEl = fioEl.childNodes[1]
            if (!spanEl) return
            const spanW = spanEl.scrollWidth
            fioElW < spanW ? this.showTitle = true : this.showTitle = false
        },
        doMenu(e) {
            const handlers = []
            const open = this.cmOpen
            e.currentTarget.style.backgroundColor = 'var(--content-container-bgcolor)'
            if (!this.chat.marked && !this.chat.unwatched)
                handlers.push({item_name: this.$t('channel-comp.mark'), handler: () => {
                    this[ACT_SET_CHAT_MARKED]({cid: this.cid, cidType: this.cidType, marked: true})
                }})
            else if (!!this.chat.unwatched || this.chat.marked) {
                handlers.push({item_name: this.$t('channel-comp.read'), handler: () => this.watchChat() })
            }
            if (this.isPinned) {
                handlers.push({ item_name: this.$t('channel-comp.unpin'), handler: () => {
                    this[ACT_SET_CHAT_PINED]({cid: this.cid, cidType: this.cidType, pinned: false})
                }})
            } else {
                handlers.push({ item_name: this.$t('channel-comp.pin'), handler: () => {
                    this[ACT_SET_CHAT_PINED]({cid: this.cid, cidType: this.cidType, pinned: true})
                }})
            }

            if (!this.isNote) {
                if (this.$store.getters['chats/isMuted']({cid: this.cid, cidType: this.cidType})) {
                    handlers.push({item_name: this.$t('mainPage.unmute'), handler: this.doUnMute})
                } else {
                    handlers.push({item_name: this.$t('mainPage.mute'), handler: this.doMute})
                }
            }
            if (this.cidType === declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP) {
                if (this.isChatMember) {
                    handlers.push({ item_name: this.$t('mainPage.leave-chat'), handler: () => this[ACT_LEAVE_FROM_CHAT]({cid: this.cid}) })
                }
            }
            handlers.push({item_name: this.$t('mainPage.delete-chat'), handler: () => {
                this[ACT_MODAL_OPEN]({
                    name: 'remove-chat',
                    props: {
                        cid: this.cid,
                        cidType: this.cidType
                    }
                })
            }})
            open(e, handlers)
        },
        getTextMsg(data) {
            if (data.dataType === 'data') {
                let text
                switch (data.data.type) {
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CONTACT:
                        text = this.$t('modal.sent-contact')
                        break
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CHANNEL:
                        text = this.$t('modal.sent-channel')
                        break
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_PUBLICATION:
                        text = this.$t('modal.sent-post')
                        break
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_LOCATION:
                        text = this.$t('modal.sent-geo')
                        break
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_IMAGE:
                        text = this.$t('modal.sent-img')
                        break
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_VIDEO:
                        text = this.$t('modal.sent-video')
                        break
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_AUDIO:
                        text = this.$t('modal.sent-audio')
                        break
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_FILE:
                        text = this.$t('modal.sent-file')
                        break
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_POLL:
                        text = this.$t('modal.sent-poll')
                        break
                    case declarations.msgDataSubTypes.MSG_DATA_SUB_TYPE_CALL_AVAILABILITY:
                        text = this.$t('modal.sent-call-availability')
                        break
                    default:
                        text = this.$t('unknown-msg-type')
                }
                return text
            } else {
                return this[GET_CHAT_ITEM_TEXT]({message: data})
            }
        },
        chatItemDragenter(e) {
            e.preventDefault()
            this.$emit('dragtochat', this.chat)
        },
        chatItemDrop(e) {
            e.preventDefault()
            e.stopPropagation()
            let arr = []
            for (let i = 0; i < e.dataTransfer.files.length; i++) {
                arr.push(e.dataTransfer.files[i])
            }
            this.modalOpen({
                component: LoaderFiles,
                props: { files: arr }
            })
        },
        chatItemDragover(e) {
            e.preventDefault()
            e.stopPropagation()
        },
        chatItemDragleave(e) {
            e.preventDefault()
            this.$emit('dragleavechat', this.chat)
        },
        ...mapActions(INFO, [ACT_INFO_PUSH]),
        ...mapActions(MODAL, [ACT_MODAL_OPEN]),
        ...mapActions(CHATS, [ACT_SET_CHAT_MUTED, ACT_LEAVE_FROM_CHAT, ACT_SET_CHAT_MARKED, ACT_SET_CHAT_PINED]),
    },
}
