//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import modal_mixin from '../modal/modal-mixin'
import CustomInputText from '../custom/CustomInputText.vue'
import DateRangePicker from '../settings/DateRangePicker.vue'
import CustomRadio from '../custom/CustomRadio.vue'
import ListItem from '../custom/ListItem.vue'
import ModalBtn from '../modal/ModalBtn.vue'
import CustomCheckbox from "../custom/CustomCheckboxRound.vue"
import CustomSelect from '../custom/CustomSelect.vue'
import {mapActions} from "vuex"
import {VM_CONF} from "../../store/modulesNames"
import {ACT_VM_CONF_SET_PARAMS, ACT_VM_CONF_CREATE, ACT_VM_GET_CONFERENCE_INFO} from "../../store/actionsTypes"
import moment from 'moment'

export default {
    name: "ScheduleVMConferenceSettings",
    components: { CustomInputText, DateRangePicker, CustomRadio, ListItem, ModalBtn, CustomCheckbox, CustomSelect },
    mixins: [modal_mixin],
    props:  {
        conf: {
            type: Object,
            required: true,
            default: {
                topic: '', 
                confid: '', 
                password: '',
                startTime: '',
                startUTime: 0,
                finishTime: '',
                finishUTime: 0,
            }
        },
        isNew: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data() {
        return {
            mainTitle: this.$t('videomost.settings-title'),
            compModalProps: {
                delay: 100,
                pivotY: 0.5,
                width: 540,
                height: 'auto',
                clickToClose: false
            },
            from: 0,
            to: 0,
            show_only_owner: '0',
            confModes: [{
                    value: '0',
                    name: this.$t('videomost.conf-discussion'),
                    selected: true
                },
                {
                    value: '1',
                    name: this.$t('videomost.conf-selector'),
                    selected: false
                },
                {
                    value: '3',
                    name: this.$t('videomost.conf-presentation'),
                    selected: false
                },
                {
                    value: '2',
                    name: this.$t('videomost.conf-seminar'),
                    selected: false
                }
            ],
            chosenVideoQuality: 'mp_Best',
            videoQuality: [{
                    value: 'mp_Best',
                    name: this.$t('videomost.quality-best'),
                    selected: true
                },
                {
                    value: 'mp_High',
                    name: this.$t('videomost.quality-high'),
                    selected: false
                },
                {
                    value: 'mp_Good',
                    name: this.$t('videomost.quality-good'),
                    selected: false
                },
            ],
            chosenVideoQualityClient: '0',
            videoQualityClient: [{
                    value: '0',
                    name: this.$t('videomost.qualityclient-default'),
                    selected: true
                },
                {
                    value: 'o_high',
                    name: this.$t('videomost.qualityclient-hd'),
                    selected: false
                },
            ],
            isAddTerminals: false,
            SIPServerValue: 1,
            SIPServerItems: [{
                    value: 2,
                    label: this.$t('videomost.terminals-internalserver'),
                },
                {
                    value: 1,
                    label: this.$t('videomost.terminals-externalserver'),
                },
            ],
            SIPServer: '',
            SIPProxy: '',
            SIPPartiesMixerLabel: this.$t('videomost.terminals-mixertype'),
            SIPPartiesMixerValue: "default",
            SIPPartiesMixerItems: [{
                    value: "0",
                    name: this.$t('videomost.mixertype-default'),
                    selected: true
                },
                {
                    value: 'individual',
                    name: this.$t('videomost.mixertype-individial'),
                    selected: false
                },
                {
                    value: 'group',
                    name: this.$t('videomost.mixertype-group'),
                    selected: false
                }
            ],
            SIPPartiesVideoQualityLabel: this.$t('videomost.video-quality'),
            SIPPartiesVideoQualityValue: 'default',
            SIPPartiesVideoQualityItems: [{
                    value: '0',
                    name:  this.$t('videomost.sipparties-video.default'),
                    selected: true
                },
                {
                    value: 'mp_Best',
                    name: this.$t('videomost.sipparties-video.quality-fhd'),
                    selected: false
                },
                {
                    value: 'mp_High',
                    name: this.$t('videomost.sipparties-video.quality-hd'),
                    selected: false
                },
                {
                    value: 'mp_Good',
                    name: this.$t('videomost.sipparties-video.quality-vga'),
                    selected: false
                },
                {
                    value: 'mp_Low',
                    name: this.$t('videomost.sipparties-video.quality-qvga'),
                    selected: false
                }
            ],
            rhythm_pattern_enabled: false,
            rhythm: {},
            rhythm_pattern_weekday_check_: null,
            periodItemValue: 'daily',
            periodItems: [{
                    value: 'daily',
                    name: this.$t('videomost.periods.days'),
                    selected: true
                },
                {
                    value: 'weekly',
                    name: this.$t('videomost.periods.weeks'),
                    selected: false
                },
                {
                    value: 'monthly',
                    name: this.$t('videomost.periods.months'),
                    selected: false
                }
            ],
            periodCounter: 1,
            isMo: false, isTu: false, isWe: false, isTh: false, isFr: false, isSa: false, isSu: false,
            isMixer: false,
            isMixerLabel: this.$t('videomost.settings-mixer'),
            okText: this.$t('videomost.conf-run'),
            cancelText: this.$t('videomost.template-save'),
            confSettingsText: this.$t('videomost.conf-settings'),
            subjectMissed: false,
            fromMissed: false,
            toMissed: false,
            confIdMissed: false,
            confPasswordMissed: false,
            initialConfId: '',
        }
    },
    mounted() {
        if (!!this.conf.confid) {
            this.initialConfId = this.conf.confid
            this[ACT_VM_GET_CONFERENCE_INFO]({confid: this.conf.confid}).then(ci => {
                if (ci) {
                    this.show_only_owner = ci.show_only_owner
                    this.chosenVideoQuality = ci.video_mix_profile
                    this.chosenVideoQualityClient = ci.video_out_profile
                    if  (ci.sip_enabled !== '0') {
                        this.isAddTerminals = true
                        this.SIPServerValue = ci.sip_enabled
                        this.SIPServer = ci.sip 
                        this.SIPProxy = ci.sip_proxy
                        this.SIPPartiesMixerValue = ci.sip_mixed_type
                        this.SIPPartiesVideoQualityValue = ci.sip_mixed_profile
                    }
                    const rhythm = JSON.parse(ci.rhythm)
                    if  (rhythm.rhythm_pattern_enabled !== '0') {
                        this.rhythm_pattern_enabled = true
                        this.rhythm = { ...rhythm }
                        this.periodItemValue = rhythm.rhythm_pattern_mode_option
                        switch (this.periodItemValue) {
                            case 'daily':
                                this.periodCounter = rhythm.rhythm_pattern_period_day
                                break;
                            case 'weekly':
                                this.periodCounter = rhythm.rhythm_pattern_period_week
                                break;
                            case 'monthly':
                                this.periodCounter = rhythm.rhythm_pattern_period_month
                                break;
                            case 'yearly':
                                this.periodCounter = rhythm.rhythm_pattern_period_year
                                break;
                            default:
                                break;
                        }
                        if (this.periodCounter === '0') this.periodCounter = '1'
                        this.isMo = rhythm.rhythm_pattern_weekday_check_monday
                        this.isTu = rhythm.rhythm_pattern_weekday_check_tuesday
                        this.isWe = rhythm.rhythm_pattern_weekday_check_wednesday
                        this.isTh = rhythm.rhythm_pattern_weekday_check_thursday
                        this.isFr = rhythm.rhythm_pattern_weekday_check_friday
                        this.isSa = rhythm.rhythm_pattern_weekday_check_saturday
                        this.isSu = rhythm.rhythm_pattern_weekday_check_sunday
                    }
                }
            })
        }
    },
    computed: {
        repeatPhrase() {
            let result = { eachWord: '', periodWord: '' }
            if (this.periodItemValue === 'daily') {
                result.eachWord = this.$t('videomost.periods.eachNomM')
                result.periodWord = this.$t('videomost.periods.dayNom')
            }
            else if (this.periodItemValue === 'weekly')  {
                result.eachWord = this.$t('videomost.periods.eachGenF')
                result.periodWord = this.$t('videomost.periods.weekGen')
            }
            else if (this.periodItemValue === 'monthly')  {
                result.eachWord = this.$t('videomost.periods.eachNomM')
                result.periodWord = this.$t('videomost.periods.monthNom')
            }
            return result
        },
        startUTime () {
            return +this.conf.startUTime
        },
        finishUTime () {
            return +this.conf.finishUTime
        },
        correctFromTo () {
            const startUTime = +this.conf.startUTime
            const finishUTime = +this.conf.finishUTime
            return startUTime && finishUTime && startUTime < finishUTime
        },
    },
    watch: {
        show_only_owner(val, oldVal) {
            this.confModes.forEach(item => {
                (item.value === val) ? item.selected = true : item.selected = false
            })
        },
        chosenVideoQuality(val, oldVal) {
            this.videoQuality.forEach(item => {
                (item.value === val) ? item.selected = true : item.selected = false
            })
        },
        chosenVideoQualityClient(val, oldVal) {
            this.videoQualityClient.forEach(item => {
                (item.value === val) ? item.selected = true : item.selected = false
            })
        },
        SIPPartiesMixerValue(val, oldVal) {
            this.SIPPartiesMixerItems.forEach(item => {
                (item.value === val) ? item.selected = true : item.selected = false
            })
        },
        SIPPartiesVideoQualityValue(val, oldVal) {
            this.SIPPartiesVideoQualityItems.forEach(item => {
                (item.value === val) ? item.selected = true : item.selected = false
            })
        },
        periodItemValue(val) {
            this.periodItems.forEach(item => {
                (item.value === val) ? item.selected = true : item.selected = false
            })
        },
        startUTime(val) {
            if (val) this.fromMissed = false
        },
        finishUTime(val) {
            if (val) this.toMissed = false
        },
        correctFromTo(val) {
            if (val) this.fromMissed = this.toMissed = false
        }
    },
    methods: {
        async saveConf(conf) {
            if (!conf.topic) this.subjectMissed = true
            if (!conf.startUTime) this.fromMissed = true
            if (!conf.finishUTime) this.toMissed = true
            if (conf.startUTime && conf.finishUTime && !this.correctFromTo) this.fromMissed = this.toMissed = true
            if (!conf.confid) this.confIdMissed = true
            if (!conf.password) this.confPasswordMissed = true
            if (this.subjectMissed || this.fromMissed || this.toMissed || this.confIdMissed || this.confPasswordMissed) return

            const confid = conf.confid
            // После обновления videoMost меняет только по unix
            // let startTime = '', finishTime = ''
            // startTime = moment.unix(conf.startUTime).utc().format("YYYY-MM-DD HH:mm:ss")
            let startUTime = +conf.startUTime
            // finishTime = moment.unix(conf.finishUTime).utc().format("YYYY-MM-DD HH:mm:ss")
            let finishUTime = +conf.finishUTime
            const utcOffset = moment.unix(this.conf.startUTime).local().utcOffset() / 60

            let newConfParams = {
                confid,
                // startTime,
                startUTime,
                // finishTime,
                finishUTime,
                topic: conf.topic,
                password: conf.password,
                show_only_owner: this.show_only_owner,
                video_mix_profile: this.chosenVideoQuality,
                video_out_profile: this.chosenVideoQualityClient
            }

            let sip_enabled = 0
            newConfParams.sip_enabled = sip_enabled
            if (this.isAddTerminals) {
                newConfParams.sip_enabled = this.SIPServerValue
                newConfParams.sip = this.SIPServer
                newConfParams.sip_proxy = this.SIPProxy
                newConfParams.sip_mixed_type = this.SIPPartiesMixerValue
                newConfParams.sip_mixed_profile = this.SIPPartiesVideoQualityValue
            }

            if (this.rhythm_pattern_enabled) {
                this.rhythm.rhythm_pattern_enabled = "1"
                this.rhythm.rhythm_pattern_mode_option = this.periodItemValue
                
                switch (this.periodItemValue) {
                    case 'daily':
                        this.rhythm.rhythm_pattern_period_day = this.periodCounter.toString()
                        break;
                    case 'weekly':
                        this.rhythm.rhythm_pattern_period_week = this.periodCounter.toString()
                        break;
                    case 'monthly':
                        this.rhythm.rhythm_pattern_period_month = this.periodCounter.toString()
                        break;
                    case 'yearly':
                        this.rhythm.rhythm_pattern_period_year = this.periodCounter.toString()
                        break;
                    default:
                        break;
                }
                this.rhythm.rhythm_pattern_weekday_check_monday = this.isMo
                this.rhythm.rhythm_pattern_weekday_check_tuesday = this.isTu
                this.rhythm.rhythm_pattern_weekday_check_wednesday = this.isWe
                this.rhythm.rhythm_pattern_weekday_check_thursday = this.isTh
                this.rhythm.rhythm_pattern_weekday_check_friday = this.isFr
                this.rhythm.rhythm_pattern_weekday_check_saturday = this.isSa
                this.rhythm.rhythm_pattern_weekday_check_sunday = this.isSu
                // this.rhythm.startTime = startTime
                this.rhythm.startUTime = startUTime
                // this.rhythm.finishTime = finishTime
                this.rhythm.finishUTime = finishUTime
                this.rhythm.rhythm_pattern_time_zone = utcOffset.toString()
            } else {
                this.rhythm.rhythm_pattern_enabled = "0"
            }
            newConfParams.rhythm = JSON.stringify(this.rhythm)
            
            if (this.isNew) {
                newConfParams.confid = conf.confid
                let result = await this[ACT_VM_CONF_CREATE](newConfParams)
                this.$bus.$emit('vmconf-created', newConfParams)
            }
            else {
                const initConfId = this.initialConfId
                const changes = {confid: initConfId, params: newConfParams}
                let result = await this[ACT_VM_CONF_SET_PARAMS](changes)
                this.$bus.$emit('vmconf-updated', newConfParams )
            }
            this.modalClose()
            this.$bus.$emit('reload-conf-lists')
        },
        getStartTime(conf) {
            return this.getLocalTime(conf.startUTime)
        },
        getFinishTime(conf) {
           return this.getLocalTime(conf.finishUTime)
        },
        getLocalTime(dt) {
            return moment.unix(dt).local().format('YYYY.MM.DD HH:mm:ss')
        },
        ...mapActions(VM_CONF, [ACT_VM_CONF_SET_PARAMS, ACT_VM_CONF_CREATE, ACT_VM_GET_CONFERENCE_INFO]),
    },
}
