//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import modal_mixin from '../modal/modal-mixin'
import CustomInputText from '../custom/CustomInputText.vue'
import DateRangePicker from '../settings/DateRangePicker.vue'
import CustomRadio from '../custom/CustomRadio.vue'
import ListItem from '../custom/ListItem.vue'
import ModalBtn from '../modal/ModalBtn.vue'
import ConfSettings from './scheduleConferenceSettings.vue'
// import event_bus from '../../eventBus'
// import VideoMostConfMenu from './mainContentVideoConfs.vue'
import {mapActions} from "vuex"
import {VM_CONF} from "../../store/modulesNames"
import {ACT_VM_CONF_CREATE} from "../../store/actionsTypes"
import moment from 'moment'

export default {
    name: "ScheduleVMConference",
    components: { CustomInputText, DateRangePicker, CustomRadio, ListItem, ModalBtn, ConfSettings, /*VideoMostConfMenu*/ },
    mixins: [modal_mixin],
    data() {
        return {
            subjectName: '',
            subjectMissed: false,
            from: 0,
            fromMissed: false,
            to: 0,
            toMissed: false,
            confID: '',
            confIdMissed: false,
            confPassword: '',
            confPasswordMissed: false,
            compModalProps: {
                delay: 100,
                pivotY: 0.33,
                width: 462,
                height: 380,
                clickToClose: false
            },
            okText: this.$t('videomost.conf-plan'),
            cancelText: this.$t('videomost.cancel'),
            confSettingsText: this.$t('videomost.conf-settings'),
        }
    },
    mounted() {
            this.from = moment().unix()
            this.to = moment().add(1, 'hours').unix()
    },
    watch: {
        from(val) {
            if (!!val) this.fromMissed = false
        },
        to(val) {
            if (!!val) this.toMissed = false
        }
    },
    computed: {
        startTimeUtc() {
            return !!this.from ? moment.unix(this.from).utc().format("YYYY-MM-DD HH:mm:ss") : ''
        },
        finishTimeUtc() {
            return !!this.to ? moment.unix(this.to).utc().format("YYYY-MM-DD HH:mm:ss") : ''
        },
        startTimeLocal() {
            return !!this.from ? moment.unix(this.from).local().format("YYYY-MM-DD HH:mm:ss") : ''
        },
        finishTimeLocal() {
            return !!this.to ? moment.unix(this.to).local().format("YYYY-MM-DD HH:mm:ss") : ''
        },
    },
    methods: {
        async createNewConf() {
            if (!this.subjectName) this.subjectMissed = true
            if (!this.from) this.fromMissed = true
            if (!this.to) this.toMissed = true
            if (!this.confID) this.confIdMissed = true
            if (!this.confPassword) this.confPasswordMissed = true
            if (this.subjectMissed || this.fromMissed || this.toMissed || this.confIdMissed || this.confPasswordMissed) return
            const startTime = moment.unix(this.from).utc().format("YYYY-MM-DD HH:mm:ss") 
            const finishTime = moment.unix(this.to).utc().format("YYYY-MM-DD HH:mm:ss")
            let newConfParams = {
                topic: this.subjectName, 
                confid: this.confID, 
                password: this.confPassword,
                startTime,
                finishTime,
            }
            this.newCreatedConf = await this[ACT_VM_CONF_CREATE](newConfParams)
            this.modalClose()
            this.$bus.$emit('reload-conf-lists')
        },
        openConfSettings() {
            this.modalClose()
            let startTime = '', finishTime = ''
            if (this.from) startTime = moment.unix(this.from).utc().format("YYYY-MM-DD HH:mm:ss") 
            if (this.to) finishTime = moment.unix(this.to).utc().format("YYYY-MM-DD HH:mm:ss")
            let conf =  {
                topic: this.subjectName, 
                confid: this.confID, 
                password: this.confPassword,
                startUTime: this.from,
                finishUTime: this.to,
                startTime,
                finishTime,
            }
            this.modalOpen({
                component: ConfSettings,
                props: { conf, isNew: true }
            })
        },
        randomString(length = 6) 
        { 
            return Math.random().toString().substr(2, length) 
        },
        ...mapActions(VM_CONF, [ACT_VM_CONF_CREATE])
    },
}
