//
//
//
//
//
//

import {mapGetters} from 'vuex'
import {GET_OPENED_MAIN_TYPE} from '../../../store/gettersTypes'
import {CONTENT_MANAGER} from '../../../store/modulesNames'
import Acs from './MainContentAcs.vue'
import Profile from './MainContentProfile.vue'
import Sessions from './MainContentSessions.vue'
import Settings from './MainContentSettings.vue'
import Support from './MainContentSupport.vue'
import About from './MainContentAbout.vue'
import MainContentLoader from './MainContentLoader.vue'
import Hotkeys from './MainContentHotkeys.vue'
import Videoconfs from '../../videomost/mainContentVideoConfs.vue'
import Decoration from  './MainContentDecoration.vue'
import Chat from  '../../chat/Chat.vue'
import Channel from  '../../channels/Channel.vue'
import IntegrationsList from "./MainContentIntegrations.vue"
import MeetingCalendar from '../../integrations/MeetingCalendar.vue'

import { MAIN_TYPES } from '../../../store/modules/content-manager'

export default {
    name: 'MainContent',
    components: {
        MainContentLoader,
        Acs,
        Profile,
        Sessions,
        Settings,
        About,
        Support,
        Hotkeys,
        Videoconfs,
        Decoration,
        Chat,
        Channel,
        IntegrationsList,
    },
    data() {
        return {
            component: null,
            need_loader: false,
            loaded: false
        }
    },
    computed: {
        opened() {
            return this[GET_OPENED_MAIN_TYPE]
        },
        type() {
            return this.opened?.type
        },
        compProps() {
            return this.opened?.params ?? {}
        },
        ...mapGetters(CONTENT_MANAGER, [GET_OPENED_MAIN_TYPE])
    },
    watch: {
        type(newVal) {
            let comp
            switch (newVal) {
                case MAIN_TYPES.ACS:
                case MAIN_TYPES.PROFILE:
                case MAIN_TYPES.SESSIONS:
                case MAIN_TYPES.SETTINGS:
                case MAIN_TYPES.SUPPORT:
                case MAIN_TYPES.ABOUT:
                case MAIN_TYPES.HOTKEYS:
                case MAIN_TYPES.DECORATION:
                case MAIN_TYPES.VIDEOCONFS:
                case MAIN_TYPES.CHANNEL:
                    comp = newVal
                    break
                case MAIN_TYPES.CONTACT:
                case MAIN_TYPES.CHAT:
                case MAIN_TYPES.NOTE:
                    comp = Chat
                    break
                case MAIN_TYPES.INTEGRATIONS:
                    comp = IntegrationsList
                    break
                case MAIN_TYPES.MEETINGCALENDAR:
                    comp = MeetingCalendar
                    break
                default:
                    comp = null
            }
            if (this.$refs.component && this.$refs.component.beforeUnmount) {
                this.$refs.component.beforeUnmount.call(this.$refs.component)
            }
            this.component = comp

        },
        component() {
            this.need_loader = false
        },
        loaded (new_val) {
            if (new_val) {
                this.need_loader = false
                this.loaded = false
            }
        }
    }
}
