import axios from 'axios'
import { XmlRpcMessage, XmlRpcResponse } from '../common/XmlRpc'
import Logger from "../common/Logger"
const logger = new Logger('VideoMostXml')

const ERRORS = {
    SESSION_EXPIRED: 'Error_session_expired'
}

class VideoMostXml {

    constructor(host, login, password) {
        logger.info(`constructor > host - ${host}, login - ${login}, password - ${password}`)
        if (!(host && login && password)) throw new Error('bad-params')
        this._serverUrl = `https://${host}/ws/`
        this._login = login
        this._password = password
        this._sessionKey = null
    }

    _buildXml({methodName, params = [], withKey = true}) {
        if (withKey) params.unshift(this._sessionKey)
        let message = new XmlRpcMessage(methodName, params)
        return message.xml().replace('\n', '')
    }

    async _parseResponse(resp) {
        let response = new XmlRpcResponse()
        let { params = [] } = await response.parse(resp)
        return params.shift()
    }

    async _apiRequest(methodName, params, withKey = true, retry = false) {
        if (withKey && !this._sessionKey) await this.login()
        let xml = this._buildXml({methodName, params, withKey})
        let resp = await axios.post(this._serverUrl, xml, {headers: {'Content-Type': 'text/xml'}})
        let { error, result } = await this._parseResponse(resp.data)
        if (error) {
            if (error.message === ERRORS.SESSION_EXPIRED && !retry) {
                this._sessionKey = null
                return this._apiRequest(methodName, params, withKey, true)
            } else {
                throw new Error(error)
            }
        }
        return result
    }

    async login() {
        try {
            logger.info(`login > login - ${this._login}, password - ${this._password}`)
            let { sessionkey } = await this._apiRequest('login', [this._login, this._password], false)
            logger.info(`login < success: sessionkey ${sessionkey}`)
            this._sessionKey = sessionkey
        } catch (e) {
            logger.error(e)
            throw e
        }
    }

    /**
     *
     * @param {Object} params
     * @param {String} params.topic conference subject
     * ... https://go.videomost.com/xmlrpcdoc/#api-API-createConferenceFull
     * @return {Promise<{confid: *, password: *, app_id: *}>}
     */
    async createConferenceFull(params) {
        try {
            logger.info(`createConferenceFull > params - ${params}`)
            //console.log("!!  - file: VideoMostXml.js - line 70 - VideoMostXml - createConferenceFull - params", params)
            let { app_id, confid, password } = await this._apiRequest('createConferenceFull', [params])
            logger.info(`createConferenceFull < app_id - ${app_id}, confid - ${confid}, password - ${password}`)
            return { app_id, confid, password }
        } catch (e) {
            logger.error(e)
            throw e
        }
    }

    /**
     *
     * @param {Object} params
     * @param {String} params.confid conference ID
     * @param {String} params.password conference password
     * @return {Promise<void>}
     */
    async startConference({confid, password}) {
        try {
            logger.info(`startConference > confid - ${confid}, password - ${password}`)
            let result = await this._apiRequest('startConference', [confid, password])
            logger.info(`startConference < result - ${result} typeof ${typeof result}`)
        } catch (e) {
            logger.error(e)
            throw e
        }
    }

    /**
     *
     * @param {Object} params
     * @param {String} params.confid conference ID
     * @return {Promise<void>}
     */
    async stopConference({confid}) {
        try {
            logger.info(`stopConference > confid - ${confid}`)
            let result = await this._apiRequest('stopConference', [confid, {force: 1}])
            logger.info(`stopConference < result - ${result} typeof ${typeof result}`)
        } catch (e) {
            logger.error(e)
            throw e
        }
    }

    /**
     *
     * @param {Object} params
     * @param {Object} params.filter - search filters
     * “l”  - search for substring, case-insensitive
     * “d” – search exact value, also default behavior
     * “cg” – greater than
     * “cl” – less than
     *  examples:
     *  {"l-topic": "conference"} - search for conferences with topic containing “conference” substring
     *  {"d-topic": "conference"} - search for conferences with topic is "conference"
     *  {"cg-startTime": "2013-11-05"} - search for conferences starting after 2013-11-05
     *  {"cl-startUTime": "1616014799", "cg-finishUTime": "1615928400"} - search for conferences starting after
     *  "1616014799" and before "1615928400" where time is Unix timestamp
     *
     * @param {Object} params.starti - offset of the first record
     * @param {Number} params.pagesize - maximum num of records
     * @param {String} params.sorting - sort by field in format "<field_name> <ASC|DESC>", for example "startTime ASC"
     * @param {Boolean} [params.ownOnly=false] - search only conferences this user created
     * @param {Boolean} [params.ownOnly=false] - search only conferences this user created //@todo мертво
     * @return {Promise<[]>}
     */
    async searchConference({filter = {}, starti = 0, pagesize = 10, sorting = '', ownOnly = false, /*includeInvited = false*/}) {
        try {
            logger.info(`searchConference > filter - ${JSON.stringify(filter)}, starti - ${starti}, pagesize - ${pagesize}, sorting - ${sorting}, ownOnly - ${starti}`)
            let { l: conferenceList } = await this._apiRequest('searchConference', [filter, starti, pagesize, sorting, ownOnly])
            logger.info(`searchConference < length - ${conferenceList.length}`)
            return conferenceList
        } catch (e) {
            logger.error(e)
            throw e
        }
    }

     /**
     * @param {Object} params
     * @param {String} params.confid conference ID
     * @param {String} params.param_name conference parameter name
     * @param {String} params.param_value conference parameter value
     * @return {int} result
     */
    async setConferenceParams({confid, param_name, param_value}) {
        try {
            logger.info(`setConferenceParams > confid - ${confid}`)
            let result = await this._apiRequest('setConferenceParams', [confid, param_name, param_value])
            logger.info(`setConferenceParams < result - ${result} typeof ${typeof result}`)
            return result
        } catch (e) {
            logger.error(e)
            throw e
        }
    }

    /**
     * @param {Object} params
     * @param {String} params.confid conference ID
     * @param {Object} params.params array of conference parameters with new values
     * @return {int} result
     */
    async setConferenceParamsArray({confid, params}) {
        try {
            logger.info(`setConferenceParamsArray > confid - ${confid}`)
            let result = await this._apiRequest('setConferenceParamsArray', [confid, params])
            logger.info(`setConferenceParamsArray < result - ${result} typeof ${typeof result}`)
            return result
        } catch (e) {
            logger.error(e)
            throw e
        }
    }

    /**
     * @param {String} params.confid conference ID
     * @return {int} result
     */
     async getConferenceInfo({confid}) {
        try {
            logger.info(`getConferenceInfo > confid - ${confid}`)
            let result = await this._apiRequest('getConferenceInfo', [confid])
            logger.info(`getConferenceInfo < result - ${result} typeof ${typeof result}`)
            return result
        } catch (e) {
            logger.error(e)
            throw e
        }
    }

    /**
     *
     * @param {Object} params
     * @param {String} params.confid conference ID
     * @return {Promise<int>} In case of successful conference deletion, 1 (?? I got false) is returned.
     */
     async deleteConference({confid}) {
        try {
            logger.info(`delConference > confid - ${confid}`)
            let result = await this._apiRequest('delConference', [confid])
            logger.info(`delConference < result - ${result} typeof ${typeof result}`)
        } catch (e) {
            logger.error(e)
            throw e
        }
    }
}

export default VideoMostXml