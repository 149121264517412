//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex';
import {BOTS, MODAL, CHATS, INFO} from "../../store/modulesNames";
import {GET_BOT_BY_ID, GET_CHAT} from "../../store/gettersTypes";
import {ACT_MODAL_OPEN, ACT_INFO_PUSH} from "../../store/actionsTypes";

import InfoMainBlock from './InfoMainBlock.vue';
import IconImage from '../IconImage.vue';
import ActionsContainer from "./ActionsContainer.vue"
import InfoIcon from "./InfoIcon.vue"
import ListItem from "../custom/ListItem.vue"
import ChatMuteSwitch from "./ChatMuteSwitch.vue"
import ChatPinSwitch from './ChatPinSwitch.vue'
import SelectChatToShare from '../modal/SelectChatToShare.vue'
import SelectChatToBotAdd from '../modal/SelectChatToBotAdd.vue'
import MediaSearchInfoItems from './MediaSearchInfoItems.vue'

export default {
    name: "BotInfo",
    props: ['params', 'canBack'],
    components: {
        'icon-image': IconImage,
        ActionsContainer,
        ListItem,
        InfoIcon,
        InfoMainBlock,
        ChatMuteSwitch,
        ChatPinSwitch,
        MediaSearchInfoItems
    },
    computed: {
        cid() { return this.params.cid },
        cidType: () => declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER,
        bot() { return this[GET_BOT_BY_ID](this.cid) },
        inChatList() {
            return this.$store.getters['chats/getChat']({
                cid: this.cid,
                cidType: this.cidType
            })
        },
        showMute() {
            return this[GET_CHAT]({cid: this.cid, cidType: this.cidType}) && !this.canBack
        },
        reference() {
            return `@[bot\\${this.bot.cid}:${this.bot.title}]`
        },
        refNewFormat() {
            return `@[${this.bot.title}][bot:${this.bot.cid}]`
        },
        refNewFormatData() {
            return { data: { type: 'text', text: this.bot.title, 
                    entities: [{ type: "bot", offset: 0, length: this.bot.title.length, botId: this.bot.cid}] } }   //`@[${this.bot.title}][bot:${this.bot.cid}]`
        },
        ...mapGetters(CHATS, [GET_CHAT]),
        ...mapGetters(BOTS, [GET_BOT_BY_ID])
    },
    methods: {
        doDeleteChat() {
            this[ACT_MODAL_OPEN]({
                name: 'remove-chat',
                props: {
                    cid: this.cid,
                    cidType: this.cidType
                }
            })
        },
        doCopyReference() {
            this.$copyText(this.refNewFormat);
        },
        doShare() {
            this.modalOpen({
                component: SelectChatToShare,
                props: {
                    'type': 'data',
                    'params': this.refNewFormatData //this.reference
                }
            })
        },
        doAddToGroup() {
            this.modalOpen({
                component: SelectChatToBotAdd,
                props: {
                    botCid: this.cid
                }
            })
        },
        openSearchMedia() {
            this[ACT_INFO_PUSH]({type: 'media-search', params: {cid: this.cid, cidType: this.cidType}})
        },
        ...mapActions(MODAL, [ACT_MODAL_OPEN]),
        ...mapActions(INFO, [ACT_INFO_PUSH]),
    }
}
