import VideoPlug from "../app/common/VideoPlug"
import getStats from "./getStats.js"
import moment from 'moment'

export function MediaWorker(localVideoSelector, remoteVideoSelector, videoPlugText) {

    this.muted = false

    let self = this,        
        localStream,
        remoteStream,
        isHold; 

    self.getLocalStream = function () {
        return localStream;
    };

    self.getRemoteStream = function () {
        return remoteStream;
    };

    self.setLocalStream = function(stream) {
        let localVideo = document.querySelector(localVideoSelector)
        if (stream) {                
            if ('srcObject' in localVideo) localVideo.srcObject = stream // Older browsers may not have srcObject
            else localVideo.src = window.URL.createObjectURL(stream) // Avoid using this in new browsers, as it is going away.
            localStream = stream
            localVideo.onloadedmetadata = () => localVideo.play()
            if (this.videoPlug) localVideo.style.transform = 'none'
        } else {
            localVideo.src = '';
            if (localStream) localStream.stop();
            localVideo.style.removeProperty('transform')
        }   
    };

    self.setRemoteStream = function(stream) {
        let remoteVideo = document.querySelector(remoteVideoSelector)
        if (stream) {                
            if ('srcObject' in remoteVideo) remoteVideo.srcObject = stream // Older browsers may not have srcObject
            else remoteVideo.src = window.URL.createObjectURL(stream) // Avoid using this in new browsers, as it is going away.
            remoteStream = stream
            remoteVideo.onloadedmetadata = () =>  remoteVideo.play()
        } else remoteVideo.src = ''
    };

    self.updateStreams = function(localVideoSelector, remoteVideoSelector) {
        setTimeout(()=> {
            self.setLocalStream(localStream);
            self.setRemoteStream(remoteStream);
        }, 0);
    };

    self.startLocal = function(options) {
        return new Promise(async (resolve, reject) => {
            let stream
            try {
                stream = await navigator.mediaDevices.getUserMedia({...{audio: options.audio}})
                if (options.video) {
                    let videoStream
                    try {
                        videoStream = await navigator.mediaDevices.getUserMedia({...{video: options.video}})
                    } catch (e) {
                        this.videoPlug = new VideoPlug({text: videoPlugText})
                        videoStream = this.videoPlug.start()
                    }
                    stream = new MediaStream([...stream.getTracks(), ...videoStream.getTracks()])
                }
            } catch (e) {
                console.log('rtc', 'MediaWorker.startLocal: getUserMedia() error: ' + e.message);
                reject(e)
            }
            if(stream && !stream.stop && stream.getTracks) {
                stream.stop = function(){
                    this.getTracks().forEach(function (track) {
                        track.stop();
                    });
                };
            }
            self.setLocalStream(stream);
            resolve([])
        })
    };

    self.stop = function() {
        self.setLocalStream(null);
        self.setRemoteStream(null);
        if (this.videoPlug) this.videoPlug.stop()
    };

    self.mute = function() {
        let enabled = true
        if (localStream && localStream.getAudioTracks) {
            enabled = !localStream.getAudioTracks()[0].enabled
            localStream.getAudioTracks()[0].enabled = enabled
        } else {
            console.log('!! localStream getAudioTracks error')
        }
        self.muted = !enabled
        return self.muted
    };

    self.hold = function(value, isVideo) {
        if (!self.muted) localStream.getAudioTracks()[0].enabled = !value;
        remoteStream.getAudioTracks()[0].enabled = !value;
        if(isVideo) {
            localStream.getVideoTracks()[0].enabled = !value;
            remoteStream.getVideoTracks()[0].enabled = !value;
        }
        isHold = value;
    };

    self.getIsHold = function() {
        return isHold;
    };

    self.changeVolumeEvent = function(data) {
        volume = data;
        if(localVideo) localVideo.volume = data / 100;
        if(remoteVideo) remoteVideo.volume = data / 100;
    }
}

export function PeerConnection(iceServers, localStream, onCandidate, onRemoteStream) {
    let self = this, pc, stats, connectionTime, noStats = false
    console.log('rtc', 'PeerConnection: ' + JSON.stringify(iceServers));
    let config = {
        iceServers: iceServers
    };
    try {
        pc = new RTCPeerConnection(config);

        self.getCallStats = () => {
            return stats
        }

        pc.onicecandidate = function(event) {
            // console.log('rtc', 'RTCPeerConnection: onicecandidate: event = ' + JSON.stringify(event.candidate));
            if (event.candidate) {
                //console.log("!! -> file: webrtc.js -> line 140 -> PeerConnection -> event.candidate", event.candidate)
                onCandidate(event.candidate);
            } 
        };
        pc.onaddstream = function(event) {
            //console.log('rtc', 'PeerConnection: onaddstream: event = ' + JSON.stringify(event));
            onRemoteStream({stream: event.stream, connectingState: false})
            noStats = false
            let repeatInterval = 5000
            getStats(pc, function(result) {
                if (noStats) result.nomore()
                result.connectionState = pc && pc.connectionState
                result.peerIceConnectionState = pc && pc.iceConnectionState
                // console.log("!! -> file: webrtc.js -> line 171 -> getStats -> pc", pc)
                result.peerIceGatheringState = pc && pc.iceGatheringState
                result.peerSignalingState = pc && pc.signalingState
                result.peerTransportType = pc && pc.peerTransportType
                result.connectionTime = connectionTime
                result.results.forEach(item => {
                    if (item.type === 'ssrc') {
                        result.mediaType = item.mediaType
                        if (item.bytesSent) result.bytesSent = item.bytesSent
                        if (item.packetsSent) result.packetsSent = item.packetsSent
                        if (item.packetsReceived) result.packetsReceived = item.packetsReceived
                        result.packetsLost = item.packetsLost ? item.packetsLost : 0
                        // console.log("🚀 ~ file: webrtc.js:164 ~ getStats ~ item", item)
                    }
                })
                const packetsLost = result.audio.packetsLost || 0
                const delayMs = result.audio.delayMs || 0
                const jitterBufferMs = result.audio.jitterBufferMs || 0
                result.MOS = +calcMOS(delayMs, jitterBufferMs, packetsLost).toFixed(2)
                stats = result
            }, repeatInterval)
            
            const calcMOS = (delayMs, jitterBufferMs, packetsLost) => {
                const effectiveLatency = delayMs + jitterBufferMs * 2 + 10
                let R
                if (effectiveLatency < 160) {
                    R = 93.2 - effectiveLatency / 40
                } else {
                    R = 93.2 - (effectiveLatency - 120) / 10
                }
                R = R - packetsLost * 2.5
                let MOS = R > 0 ? 1 + 0.035 * R + 0.000007 * R * (R - 60) * (100 - R) : 0
                return MOS
            }
        };
        pc.onremovestream = function(event) {
            // console.log('rtc', 'PeerConnection: onremovestream: event = ' + JSON.stringify(event));
            onRemoteStream({stream: null, connectingState: false})
            noStats = true
        }
        pc.oniceconnectionstatechange = function(event) {
            console.log('iceConnectionState', pc.iceConnectionState)
            // console.log('rtc', 'PeerConnection: oniceconnectionstatechange: event = ' + JSON.stringify(event));
            if(pc && pc.iceConnectionState === 'connected') {
                connectionTime = moment().unix()
                onRemoteStream({stream: false, connectingState: true})
            }
        };
        pc.addStream(localStream);
    } catch (e) {
        console.log('rtc', 'PeerConnection: RTCPeerConnection() error: ' + e.message);
    }

    self.getOffer = function(callback) {
        // console.log('rtc', 'PeerConnection.getOffer');
        pc.createOffer(
            function(sessionDescription) {
                setLocalDescription(sessionDescription);
                callback(sessionDescription);
            },
            function(e) {
                console.log('rtc', 'PeerConnection.getOffer: createOffer() error: ' + e.message);
            }
        );
    };

    self.getAnswer = function(offer, callback) {
        setRemoteDescription(new RTCSessionDescription(offer));
        // console.log('rtc', 'PeerConnection.getAnswer');
        pc.createAnswer().then(
            function (sessionDescription) {
                setLocalDescription(sessionDescription);
                callback(sessionDescription);
            },
            function (e) {
                console.log('rtc', 'PeerConnection.getAnswer: createAnswer() error: ' + e.message);
            }
        );
    };

    self.setAnswer = function(answer) {
        setRemoteDescription(new RTCSessionDescription(answer));
    };

    self.addCandidate = function(candidate_) {
        console.log('!! addCandidate', candidate_)
        let candidate = new RTCIceCandidate(candidate_)
        pc.addIceCandidate(candidate)
    };

    self.close = function() {
        console.log('rtc', 'PeerConnection.close');
        if (pc) {
            pc.close();
            pc = null;

            onRemoteStream({stream: null, connectingState: false});
        }
        noStats = true
    }

    function setLocalDescription(sessionDescription) {
        // console.log(JSON.stringify(sessionDescription));
        // console.log('rtc', 'PeerConnection.setLocalDescription: ' + JSON.stringify(sessionDescription));
        pc.setLocalDescription(sessionDescription);
    }

    function setRemoteDescription(sessionDescription) {
        // console.log('rtc', 'PeerConnection.setRemoteDescription: ' + JSON.stringify(sessionDescription));
        pc.setRemoteDescription(sessionDescription);
    }
}
