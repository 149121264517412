//
//
//
//
//
//
//
//
//
//

import ModalDialog from './ModalDialog.vue'
import modal_mixin from './modal-mixin'

import ContentLoader from "../main/body/MainContentLoader.vue"

export default {
    name: "ModalSurvey",
    mixins: [modal_mixin],
    components: { ModalDialog, ContentLoader },
    data() {
        return {
            compModalProps: {
                delay: 200,
                pivotY: 0.48,
                width: '80%',
                height: 'auto',
                clickToClose: false
            },
            srcLink: '',
            isIFrameLoading: true,
            isIFrameLoaded: false,
        }
    },
    computed: {
        title() { return this.props.title },
        link() { return this.props.link },
    },
    methods: {
        iFrameLoaded() {
            this.isIFrameLoading = false
            this.isIFrameLoaded = true
        },
        onCancel() {
            this.modalClose()
        }
    }
}
