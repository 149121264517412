//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapActions} from 'vuex';
import {
    USERDATA,
    INFO,
    MODAL,
    CHATS,
    AJAX,
} from '../../store/modulesNames'
import {
    IS_CHAT_READ_ONLY,
    GET_UID,
    IS_CHAT_ADMIN,
} from "../../store/gettersTypes";
import {
    ACT_INFO_PUSH,
    ACT_MODAL_OPEN,
    ACT_AJAX_SEND_FILE,
    ACT_LEAVE_FROM_CHAT,
} from '../../store/actionsTypes'
import IconImage from '../IconImage.vue';
import ActionsContainer from "./ActionsContainer.vue"
import InfoMainBlock from "./InfoMainBlock.vue"
import InfoIcon from "./InfoIcon.vue"
import ListItem from "../custom/ListItem.vue"
import ChatMuteSwitch from "./ChatMuteSwitch.vue"
import ChatPinSwitch from "./ChatPinSwitch.vue"
import ChatReadOnlyDialog from '../modal/ChatReadOnlyDialog.vue'
import MediaSearchInfoItems from './MediaSearchInfoItems.vue'

export default {
    name: "ChatInfo",
    components: { IconImage, ActionsContainer, InfoMainBlock, InfoIcon, 
                  ListItem, ChatMuteSwitch, ChatPinSwitch, MediaSearchInfoItems },
    props: ['params', 'canBack'],
    data() {
        return {
            title: this.$t('mainPage.chat-info'),
            image: '',
            file_name: ''
        };
    },
    computed: {
        cid() { return this.params.cid },
        cidType: () => declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP,
        chat() { return this.$store.getters['chats/getChat']({cid: this.cid, cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_GROUP}) || {} },
        icon() { return this.chat.photo || '' },
        name() { return this.chat.name || this.$t('search-comp.group-chat') },
        all_members() { return this.chat.contacts || [] },
        members() { return this.all_members.reduce((cids, contact) => { if (contact.privilege !== 'admin') cids.push(contact.cid); return cids }, []) },
        admins() { return this.all_members.reduce((cids, contact) => { if (contact.privilege === 'admin') cids.push(contact.cid); return cids }, []) },
        isChatMember() {
            if (!this.chat) return null;
            return this.chat.contacts && !!this.chat.contacts.find((member) => member.cid === this[GET_UID]);
        },
        privilege() {
            if (!this.chat) return null;
            return (this.chat.contacts && this.chat.contacts.find((member) => member.cid === this[GET_UID]) || {}).privilege;
        },
        readOnly() {
            return this[IS_CHAT_READ_ONLY]({cid: this.cid, cidType: this.cidType})
        },
        isAdmin() {
            return this[IS_CHAT_ADMIN]({cid: this.cid, cidType: this.cidType})
        },
        readOnlyText() {
            return this.readOnly ? this.$t('chat-read-only-on') : this.$t('chat-read-only-off')
        },
        ...mapGetters(USERDATA, [GET_UID]),
        ...mapGetters(CHATS, [IS_CHAT_READ_ONLY, IS_CHAT_ADMIN]),
    },
    methods: {
        doEditGroupChat() {
            this.modalOpen({
                name: 'chat-edit',
                props: {chat: this.chat}
            })
        },
        doLoadImage(input) {
            const cb = (status, file_name) => {
                    var fr = new FileReader();

                    fr.addEventListener("load", (e) => {
                        this.$set(this, 'image', e.target.result);
                        this.$set(this, 'icon', file_name);
                    });

                    fr.readAsDataURL( input.files[0] );
                    console.log(status, file_name);
                    let params = {
                        cidType: 'group',
                        cid: this.cid,
                        icon: file_name
                    };
                    this.$store.dispatch('chats/changeChat', params);
                };

            app.store.dispatch(`${AJAX}/${ACT_AJAX_SEND_FILE}`, {
                url: declarations.http_post_dirs.CHATS_ICONS,
                file: input.files[0],
                cb(err, file_name) {
                    cb && cb(err, file_name)
                }
            })
        },
        doOpenAdminsInfo() {
            this[ACT_INFO_PUSH]({type: 'chat-admins', params: {cid: this.cid}});
        },
        doOpenMembersInfo() {
            this[ACT_INFO_PUSH]({type: 'chat-members', params: {cid: this.cid}});
        },
        doDeleteChat() {
            this.modalOpen({
                name: 'remove-chat',
                props: {
                    cid: this.cid,
                    cidType: this.cidType
                }
            })
        },
        doExitFromChat() {
            this[ACT_LEAVE_FROM_CHAT]({cid: this.cid})
        },
        doOpenInfo(cid) {
            this[ACT_INFO_PUSH]({type: 'contact-info', params: {cid, cidType: declarations.chatTargetTypes.CHAT_TARGET_TYPE_USER}});
        },
        openSearchMedia(tabIndexValue) {
            this[ACT_INFO_PUSH]({type: 'media-search', params: {cid: this.cid, cidType: this.cidType, tabIndexValue}})
        },
        doChangeReadOnly() {
            this.modalOpen({
                component: ChatReadOnlyDialog,
                props: {
                    cid: this.cid,
                    cidType: this.cidType,
                    readOnly: this.readOnly,
                }
            })
        },
        ...mapActions(INFO, [ACT_INFO_PUSH]),
        ...mapActions(MODAL, [ACT_MODAL_OPEN]),
        ...mapActions(CHATS, [ACT_LEAVE_FROM_CHAT]),
    }
}
