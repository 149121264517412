
import {
    ACT_CALL_CONTINUE,
    // ACT_CONFERENCE,
    ACT_SET_CALL_STATUS,
    ACT_SUBSCRIBE_ON_FREE_REQUEST,
    ACT_TOGGLE_PHONE_FULLSCREEN,
    ACT_TOGGLE_PHONE_MINIMIZE,
    // ACT_TOGGLE_SPEAKER,
    // ACT_TRANSIT
} from "../../store/actionsTypes"
import {ASISTANTS, CLIENTDATA, CONTACTS, PHONE_CNTL} from "../../store/modulesNames"
import ipc from "../../../electron/ipc"
import {mapGetters, mapActions} from "vuex"
import {
    GET_CALL_TALK_TIME,
    GET_ANSWERED_CALL,
    GET_IS_ELECTRON,
    GET_MERGED_CONTACT_BY_ID,
    GET_MERGED_CONTACT_BY_PHONE
} from "../../store/gettersTypes"
// import { PHONE_BTN_TYPES }  from "./PhoneFunctionButton.vue"
// import {PHONE_TYPES} from "../../constants"
// import {i18n} from "../../../ext/i18n"

export default {
    name: "Phone",
    mounted () {
        window.addEventListener('unload', this.onPageUnload)
    },
    beforeDestroy() {
        window.removeEventListener('unload', this.onPageUnload)
    },
    computed: {
        // *** Переопределить в дочернем компоненте start *** //
        type() {
            return ''
        },
        id() {
            return null
        },
        cid() {
            return null
        },
        number() {
            return null
        },
        participants() {
            return []
        },
        busy() {
            return false
        },
        connected() {
            return false
        },
        statusText() {
            return ''
        },
        typeCallText() {
            return ''
        },
        phoneTerminated() {
            return false
        },
        phoneTerminatedLocal() {
            return false
        },
        pause() {
            return false
        },
        callQuality() {
            return false
        },
        callQualityText() {
            return ''
        },
        // *** Переопределить в дочернем компоненте end  *** //
        isConf() {
            return this.participants.length > 1
        },
        time () {
            return this[GET_CALL_TALK_TIME](this.type, this.id)
        },
        answeredCallStartTime() {
            const answeredCall = this[GET_ANSWERED_CALL](this.type, this.id)
            return answeredCall && answeredCall.talkStartTime
        },
        contact(){
            if (this.number) return this[GET_MERGED_CONTACT_BY_PHONE](this.number)
            else if (this.cid) return this[GET_MERGED_CONTACT_BY_ID](this.cid)
        },
        numberText() {
            const participant = !this.isConf && this.participants[0]
            if (participant && ('number' in participant)) return this.getNumberText(participant.number)
            else return ''
        },
        ...mapGetters(CONTACTS, [GET_MERGED_CONTACT_BY_PHONE, GET_MERGED_CONTACT_BY_ID]),
        ...mapGetters(CLIENTDATA, [GET_IS_ELECTRON]),
        ...mapGetters(PHONE_CNTL, [GET_CALL_TALK_TIME, GET_ANSWERED_CALL]),
    },
    methods: {
        // *** Переопределить в дочернем компоненте start *** //
        onPageUnload() { },
        togglePause() { },
        toggleMicrophone() { },
        pressDialKey () { },
        toggleSpeaker () { },
        doTransit () { },
        doConference () { },
        terminate() { },
        setActiveCall() { },
        // *** Переопределить в дочернем компоненте end *** //
        subscribeOnFree() {
            this[ACT_SUBSCRIBE_ON_FREE_REQUEST]({ type: this.type, id: this.callId, cid: this.cid, number: this.number })
        },
        continueTheCall() {
            this[ACT_CALL_CONTINUE]({ type: this.type, id: this.callId})
        },
        openChat() {
            this.minimizePhone()
            let contact = this.contact
            if (contact.cid) {
                let payload = {cid: contact.cid, cidType: 'user', isBot: false}
                if (this[GET_IS_ELECTRON]) { //@todo пришлось сделать проверку на электрон, т.к. экшен в chats, которого нет в электрон
                    ipc.send('sync-actions', {
                        command: 'chats/open',
                        data: payload
                    })
                } else {
                    this.$store.dispatch('chats/open', payload)
                }
            }
        },
        minimizePhone() {
            if (this.keysDialer) this.keysDialer = false //@todo
            this[ACT_TOGGLE_PHONE_MINIMIZE]()
        },
        getParticipant(payload = {}) {
            const { number } = payload
            const contact = this.getContact(payload)
            const numberText = this.getNumberText(number)
            const nameText = this.getContactNameText({...payload, ...{contact}})
            const iconSrc = contact && contact.photo || ''
            return {
                contact,
                numberText,
                nameText,
                iconSrc,
            }
        },
        getContact({cid, number}) {
            if (number) return this[GET_MERGED_CONTACT_BY_PHONE](number)
            else if (cid) return this[GET_MERGED_CONTACT_BY_ID](cid)
        },
        getNumberText(number) {
            if (!number) {
                return number === '' ? this.$t('modal.not-defined-number') : ''
            } else if (isNaN(number)) return number
            else return this.formatPhoneNumber(number)
        },
        getContactNameText({cid, number, contact}) {
            if (cid) return contact.fio
            else if (number) return contact ? contact.fio : this.getNumberText(number)
        },
        close() {
            ipc.send('phone-close-now')
        },
        ...mapActions(PHONE_CNTL, [
            ACT_TOGGLE_PHONE_MINIMIZE,
            ACT_TOGGLE_PHONE_FULLSCREEN,
            ACT_SET_CALL_STATUS,
            ACT_CALL_CONTINUE,
            ACT_SUBSCRIBE_ON_FREE_REQUEST,
        ]),
    },
}
