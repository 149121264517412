//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex'
import ipc from "../../../electron/ipc"
import {PHONE} from "../../store/modulesNames"
import {SAVE_SCREEN_SIZE} from "../../store/mutationsTypes"
import {ACT_PHONE_COMMANDS, ACT_TOGGLE_PHONE_MINIMIZE, ACT_TOGGLE_PHONE_FULLSCREEN} from "../../store/actionsTypes"
import CustomVideo from "../custom/CustomVideo.vue"
import CallsSelector from "./CallsSelector.vue"
import Phone from "./Phone.vue"
import WebrtcPhoneMixin from "./WebrtcPhoneMixin"

import { GET_IS_PHONE_MINIMIZED } from '../../store/gettersTypes'
import { PHONE_CNTL } from '../../store/modulesNames'
import Logger from "../../common/Logger"
const logger = new Logger('PhoneWebrtcVideo')

export default {
    name: "VideoPhone",
    extends: Phone,
    mixins: [WebrtcPhoneMixin],
    components: {
        CustomVideo,
        CallsSelector,
    },
    data() {
        return {
            panelShow: true,
            positionLeft: 0,
            positionTop: 0
        }
    },
    computed: {
        nameText() {
            if (this.cid) return this.contact.fio
            else if (this.number) return this.contact ? this.contact.fio : this.numberText
        },
        videoSize() {
            let x = this.$store.getters['phone/getScreenSize']

            if (this.fullScreen) {
                return {
                    height: 100 + '%',
                    width: 100 + '%',
                    borderRadius: 0 + 'px'
                }
            } else {
                return {
                    height: x.height + 'px',
                    width: x.width + 'px',
                    borderRadius: 10 + 'px'
                }
            }
        },
        ...mapGetters(PHONE_CNTL, { minimize: GET_IS_PHONE_MINIMIZED })
    },
    methods: {
        minimizePhone () {
            this[ACT_TOGGLE_PHONE_MINIMIZE]()
        },
        showPanels(value) {
            this.panelShow = value;
        },
        loadedmetadata () {
            let video = document.getElementById('remoteVideo')
            if(!video) return
            video.onresize = () => {
                let height, width
                if (this.phoneTerminated) return
                if (video.videoWidth > video.videoHeight) {
                    width = 544
                    height = Math.round(width / video.videoWidth * video.videoHeight)
                } else {
                    height = 544
                    width = Math.round(height / video.videoHeight * video.videoWidth)
                }
                ipc.send('set-phone-size', { width: width + 44, height: height + 44 })
                this.$store.commit(`${PHONE}/${SAVE_SCREEN_SIZE}`, { width, height })
            }
        },
        doFullScreen() {
            this[ACT_TOGGLE_PHONE_FULLSCREEN]()
            this.$store.dispatch(`${PHONE}/${ACT_PHONE_COMMANDS}`, {params: this.phone, command: 'updateStreams'});
        },
        setAudioSrc() {

        },
        ...mapActions(PHONE_CNTL, [ACT_TOGGLE_PHONE_MINIMIZE, ACT_TOGGLE_PHONE_FULLSCREEN])
    }
}
