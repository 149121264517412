var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal-dialog",
    {
      ref: "modal",
      staticClass: "dialog",
      attrs: {
        name: _vm.modalName,
        "modal-props": _vm.sumModalProps,
        title: _vm.$t("export-contact-title"),
      },
    },
    [
      _c("div", { staticClass: "contact-edition" }, [
        _c("div", { staticClass: "body" }, [
          _c(
            "div",
            {
              staticClass: "input-block",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                },
              },
            },
            _vm._l(_vm.fieldsArr, function (infoElement, i) {
              return _c("custom-checkbox-round", {
                key: i,
                ref: "customCheckBox",
                refInFor: true,
                class: infoElement.elementClass,
                attrs: {
                  default: _vm.defChbValue,
                  label: _vm.getLabel(infoElement),
                },
                on: {
                  "update:checkbox": function ($event) {
                    return _vm.onChbChecked(infoElement, $event)
                  },
                },
              })
            }),
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "footer" },
          [
            _c("i", {
              staticClass: "fas fa-check-circle",
              on: { click: _vm.checkUncheckAll },
            }),
            _c("v-spacer", { staticClass: "block" }),
            _c(
              "div",
              { staticClass: "btn cancel", on: { click: _vm.cancel } },
              [_vm._v(_vm._s(_vm.$t("cancel")))]
            ),
            _c(
              "div",
              { staticClass: "btn save", on: { click: _vm.makeExport } },
              [_vm._v(_vm._s(_vm.$t("save")))]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }