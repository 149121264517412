//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions} from 'vuex';
import {MODAL} from "../../store/modulesNames";
import {ACT_MODAL_OPEN} from "../../store/actionsTypes";
import ModalDialog from './ModalDialog.vue';
import modal_mixin from './modal-mixin';
import CustomTextarea from '../custom/CustomTextarea.vue';
import Confirm from './Confirm.vue';

export default {
    name: "PollEditor",
    components: {ModalDialog, CustomTextarea, Confirm},
    mixins: [modal_mixin],
    data() {
        return {
            compModalProps: {
                delay: 200,
                pivotY: 0.05,
                width: 350,
                height: 'auto',
                clickToClose: false,
            },
            topic: this.props.pool && this.props.pool.topic || '',
            answers: this.props.pool && this.props.pool.answers && JSON.parse(JSON.stringify(this.props.pool.answers)) || [],
            focus: false,
            maxAnswers: 6
        }
    },
    computed: {
        title() { return `${this.newPool ? this.$t('modal.creation') : this.$t('editing')} ${this.$t('modal.of-poll')}` },
        okBtn() { return this.newPool ? this.$t('modal.create') : this.$t('save') },
        newPool() { return !this.props.pool },
    },
    methods: {
        addPoll () {
            let arr = this.getQuestions();
            if (this.topic.trim() === '') {
                let element = document.getElementsByClassName('poll-input-title');
                element[0].classList.add('poll-input-red');
                element[0].placeholder = this.$t('modal.enter-question');
            } else if (arr.length === 0) {
                let element = document.getElementsByClassName('poll-input-question');
                element[0].classList.add('poll-input-red');
                element[1].classList.add('poll-input-red');
                element[0].placeholder = this.$t('modal.answer-var');
                element[1].placeholder = this.$t('modal.answer-var');
            } else if (arr.length === 1) {
                let element = document.getElementsByClassName('poll-input-question');
                console.log(element);
                for (let i = 0; i < element.length; i++) {
                    if (element[i].value === '') {
                        element[i].classList.add('poll-input-red');
                        element[i].placeholder = this.$t('modal.answer-var')
                        break;
                    }
                }
            } else {
                let obj = {
                    topic: this.topic,
                    answers: this.getQuestions()
                };
                this.props.cb && this.props.cb(obj)
                this.modalClose();
            }
        },
        cancelPoll () {
            let arr = this.getQuestions();
            if (this.topic !== '' || arr.length > 0) {
                this[ACT_MODAL_OPEN]({
                    name: 'confirm',
                    props: {
                        text: this.$t('modal.close-data-confirm'),
                        btnOk: {
                            cb: () => this.modalClose()
                        }
                    }
                })
            } else {
                this.modalClose();
            }
        },
        getQuestions () {
            return this.answers.filter((item) => item);
        },
        ...mapActions(MODAL, [ACT_MODAL_OPEN])
    },
    watch: {
        question() {
            if(this.topic !== '') {
                let element = document.getElementsByClassName('poll-input-title');
                element[0].classList.remove('poll-input-red');
                element[0].placeholder = this.$t('modal.question')
            }
        },
        answers() {
            let arr = this.getQuestions();
            if(arr.length > 0) {
                let element = document.getElementsByClassName('poll-input-question');
                for(let i = 0; i < element.length; i++) {
                    element[i].classList.remove('poll-input-red');
                    element[i].placeholder = this.$t('modal.answer-var')
                }
            }
        }
    },
}
