let store

import {MODAL} from '../store/modulesNames'
import {ACT_MODAL_OPEN, ACT_MODAL_CLOSE} from '../store/actionsTypes'

const setStore = () => {
    if (!store) store = document.querySelector('#context-menu').__vue__.$store
}

/**
 * @param payload
 * @param {String} [payload.name]
 * @param {Object} [payload.component]
 * @param {Object} [payload.props]
 * @param {Object} [payload.modalProps]
 */
export const modalOpen = (payload) => {
    setStore()
    store.dispatch(`${MODAL}/${ACT_MODAL_OPEN}`, payload)
}

/**
 * @param {string} title
 * @param {string} text
 * @param {function} cbOk
 * @param {function} [cbCancel]
 */
export const modalConfirm = (title, text, cbOk, cbCancel) => {
    setStore()
    let props = {
        title: title,
        text: text
    }
    if (cbOk) props.btnOk = {cb: cbOk}
    if (cbCancel) props.btnCancel = {cb: cbCancel}

    modalOpen({
        name: 'confirm',
        props
    })
}

export const modalClose = (payload) => {
    setStore()
    store.dispatch(`${MODAL}/${ACT_MODAL_CLOSE}`, payload)
}