var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dialer",
      attrs: { id: "dialer" },
      on: {
        keypress: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k(
                $event.keyCode,
                "backspace",
                undefined,
                $event.key,
                undefined
              )
            ) {
              return null
            }
            return _vm.doBackspace($event)
          },
          function ($event) {
            return _vm.keyPress($event)
          },
        ],
      },
    },
    [
      _c("div", { staticClass: "head" }, [
        _c("h4", { staticClass: "title" }),
        _c(
          "div",
          {
            staticClass: "close-btn",
            on: {
              mousedown: function ($event) {
                $event.stopPropagation()
                $event.preventDefault()
              },
              click: _vm.doClose,
            },
          },
          [_c("i", { staticClass: "fas fa-times" })]
        ),
      ]),
      _c("div", { staticClass: "phone-wrapper" }, [
        _c("div", { staticClass: "phone", attrs: { title: _vm.phone } }, [
          _c(
            "div",
            {
              staticClass: "replace",
              class: { "hide-fake-input": !_vm.fake_input },
              style: { fontSize: _vm.phone_size },
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.format_phone,
                      expression: "format_phone",
                    },
                  ],
                  staticClass: "fake-input",
                },
                [_vm._v(" " + _vm._s(_vm.format_phone))]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.format_phone,
                      expression: "!format_phone",
                    },
                  ],
                  staticClass: "fake-input-placeholder",
                },
                [_vm._v(_vm._s(_vm.$t("phone.enter-number")))]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "input",
              class: { "show-true-input": !_vm.fake_input },
              on: { contextmenu: _vm.prepareMenu },
            },
            [
              _c("masked-input", {
                staticClass: "phone-string",
                style: { fontSize: _vm.phone_size },
                attrs: {
                  type: "text",
                  name: "phone",
                  mask: _vm.phone_mask,
                  guide: false,
                  placeholderChar: "#",
                  autocomplete: "off",
                },
                model: {
                  value: _vm.phone,
                  callback: function ($$v) {
                    _vm.phone = $$v
                  },
                  expression: "phone",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "backspace",
              class: { show: _vm.backspace_show },
              on: {
                mousedown: _vm.setBackspaceTimer,
                mouseup: _vm.doBackspace,
              },
            },
            [_c("i", { staticClass: "fas fa-backspace" })]
          ),
        ]),
        _c("div", { staticClass: "person-name" }, [
          _c("span", [
            _vm._v(_vm._s(_vm.contact_found && _vm.contact_found.fio)),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "dialpad", on: { click: _vm.hideTrueInput } }, [
        _c(
          "div",
          { staticClass: "dialpad-row" },
          [
            _c(
              "div",
              {
                staticClass: "dialpad-col combo",
                on: {
                  mousedown: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                  },
                  click: function ($event) {
                    return _vm.doDial("1")
                  },
                },
              },
              [
                _vm._v("1"),
                _c("span", { staticClass: "letters invisible" }, [_vm._v("1")]),
              ]
            ),
            _vm._l(_vm.digits_row1, function (letters, digit) {
              return _c(
                "div",
                {
                  staticClass: "dialpad-col combo",
                  on: {
                    mousedown: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                    },
                    click: function ($event) {
                      return _vm.doDial(digit)
                    },
                  },
                },
                [
                  _vm._v(_vm._s(digit)),
                  _c("span", { staticClass: "letters" }, [
                    _vm._v(_vm._s(letters)),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "dialpad-row" },
          _vm._l(_vm.digits_row2, function (letters, digit) {
            return _c(
              "div",
              {
                staticClass: "dialpad-col combo",
                on: {
                  mousedown: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                  },
                  click: function ($event) {
                    return _vm.doDial(digit)
                  },
                },
              },
              [
                _vm._v(_vm._s(digit)),
                _c("span", { staticClass: "letters" }, [
                  _vm._v(_vm._s(letters)),
                ]),
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "dialpad-row" },
          _vm._l(_vm.digits_row3, function (letters, digit) {
            return _c(
              "div",
              {
                staticClass: "dialpad-col combo",
                on: {
                  mousedown: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                  },
                  click: function ($event) {
                    return _vm.doDial(digit)
                  },
                },
              },
              [
                _vm._v(_vm._s(digit)),
                _c("span", { staticClass: "letters" }, [
                  _vm._v(_vm._s(letters)),
                ]),
              ]
            )
          }),
          0
        ),
        _c("div", { staticClass: "dialpad-row" }, [
          _c(
            "div",
            {
              staticClass: "dialpad-col asterisk",
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                },
                click: function ($event) {
                  return _vm.doDial("*")
                },
              },
            },
            [_vm._v("*")]
          ),
          _c(
            "div",
            {
              staticClass: "dialpad-col combo",
              on: {
                mousedown: function ($event) {
                  return _vm.setDialTimer("+")
                },
                mouseup: function ($event) {
                  return _vm.doDial("0")
                },
              },
            },
            [
              _c("span", [_vm._v("0")]),
              _c("span", { staticClass: "plus" }, [_vm._v("+")]),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialpad-col hash",
              on: {
                mousedown: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                },
                click: function ($event) {
                  return _vm.doDial("#")
                },
              },
            },
            [_vm._v("#")]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "buttons",
          on: {
            contextmenu: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("phone-action-button", {
            attrs: { type: "voice-call" },
            on: { click: _vm.doCall },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }